import React, { useEffect, useMemo } from "react";
import PropTypes from "prop-types";
import { Link, useNavigate } from "react-router-dom";
import {
    Card, CardBody, Col, Container, Row, Table,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Button


} from "reactstrap";
import { isEmpty, map, set } from "lodash";
import Dropzone from "react-dropzone";
import * as XLSX from 'xlsx';
import Papa from 'papaparse';
import axios from 'axios';
import Loader from "../../../components/Loader";

import classnames from "classnames";

import TableContainer from "../../../components/Common/TableContainerClient";
import PackageDetail from "./packageDetails";




const ListPackages = (props) => {
    //meta title
    document.title =
        "TheRollNumber";


    const [data, setData] = React.useState([]);

    const [loading, setLoading] = React.useState(false);


    const [packageDetail, setPackageDetail] = React.useState({});

    const [modal, setModal] = React.useState(false);

    const toggle = () => setModal(!modal);


    const Navigate = useNavigate();

    useEffect(() => {

        getAllPackages();

    }, []);

    const getPackageDetail = async (id) => {

   
      console.log('id', id);
    
      const config = {
        headers: {
          'Content-Type': 'application/json'
        }
      };
  

     await axios.get(`${process.env.REACT_APP_BASE_URL}/service/package/${id}`, {}, config)
      .then(response => {
        console.log('package detail response',response);

        setPackageDetail(response.data[0]);

        setModal(true);


      })
      .catch(error => {
    
        console.log('error==>',error);
      });
    
    }

    const getAllPackages = async () => {

   
      console.log('packages');
    
      const config = {
        headers: {
          'Content-Type': 'application/json'
        }
      };
  

     await axios.get(`${process.env.REACT_APP_BASE_URL}/service/packages`, {}, config)
      .then(response => {
        console.log('packages response',response);

        
  
  setData(response.data);
    
      })
      .catch(error => {
    
        console.log('error==>',error);
      });
    
    }



    const columns = useMemo(
        () => [
       
          {
            Header: "Package Name",
            accessor: "package_name",
            width: "150px",
            style: {
              textAlign: "center",
              width: "10%",
              background: "#0000",
            },
            filterable: true,
            Cell: (cellProps) => {
              return cellProps.value;
            },
          },
          {
            Header: "TAT And Price Applicable On",
            accessor: "tat_price_applicable_on",
            filterable: true,
            Cell: (cellProps) => {
              return "Package";
            },
          },
          {
            Header: "Action",
            accessor: "action",
            filterable: true,
            Cell: (cellProps) => {

              return (
              <div className="d-flex gap-3" style={{justifyContent:'center'}}>
              <Link
                to="#"
              
                className="text-success"
                onClick={() => {
                  // handleOrderClick(orderData);

                  getPackageDetail(cellProps.row.original.package_id);
              
                }}
              >
                
                <i  className="mdi mdi-eye font-size-20" id="edittooltip" />
                {/* <UncontrolledTooltip placement="top" target="edittooltip" >
                  View
                </UncontrolledTooltip> */}
              </Link>
        
            </div>
              )
            },
          },
        

        ],
        []
      );

    const handleOrderClicks = () => {
      setModal(true);
    };


    return (
        <React.Fragment>

<Modal isOpen={modal} centered={true} size="xl" toggle={toggle}>
            <ModalHeader toggle={toggle} tag="h4">
              Package Detail
            </ModalHeader>  
            <ModalBody>
              <PackageDetail data={packageDetail}/>
            </ModalBody>
          </Modal>


            <Card>
                <CardBody>
                    <p className="h4 mb-4">List Packages</p>


                    <TableContainer
                        columns={columns}
                        data={data}
                        isGlobalFilter={false}
                        component_name={"packages"}
                        isAddOptions={true}
                        handleOrderClicks={handleOrderClicks}
                        customPageSize={10}
                        isPagination={true}
                        filterable={false}
                        iscustomPageSizeOptions={true}
                        tableClass="align-middle table-nowrap table-check"
                        theadClass="table-light"
                        pagination="pagination pagination-rounded justify-content-end mb-2"
                      />


                </CardBody>
            </Card>

        </React.Fragment>
    );
};


export default ListPackages;

const styles = {

    para: {
        fontSize: 12
    },
    box: {
        fontSize: 16,
        fontWeight: "unset",
        justifyContent: "space-between",
        alignItems: "center",
    },
    boxLabel: {
        fontSize: 13,
        fontWeight: "400",
    },
    boxInput: {
        fontSize: 13,
        width: '70%',
        padding: 8,
        fontWeight: "unset",
    }

}
