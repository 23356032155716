import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Link, useNavigate } from "react-router-dom";
import {
    Card, CardBody, Col, Container, Row, Table,
    Label,
    Input,
    Form,
    FormGroup,

    Nav,
    NavItem,
    NavLink,
    TabContent,
    TabPane, CardTitle


} from "reactstrap";
import { isEmpty, map, set } from "lodash";
import Dropzone from "react-dropzone";
import * as XLSX from 'xlsx';
import Papa from 'papaparse';
import axios from 'axios';
import Loader from "../../components/Loader";

import classnames from "classnames";



const ChangePassword = (props) => {
    //meta title
    document.title =
        "TheRollNumber";


    const [data, setData] = React.useState([]);

    const [loading, setLoading] = React.useState(false);


    const Navigate = useNavigate();

    const [state, setState] = React.useState({

        client_info: "",
        client_name: "",
        abbreviation: "",
        contact_person: "",
        address: "",
        country: "",
        state: "",
        city: "",
        pincode: "",
        email: "",
        landline: "",
        mobile: "",
        client_status: "",

    });

    const handleInputChange = (e) => {
        const { id, value } = e.target;
        setState((prevState) => ({
            ...prevState,
            [id]: value,
        }));
    };


    return (
        <React.Fragment>


            <Card>
                <CardBody>
                    <p className="h4 mb-4">Change Password</p>

                    <Row>

                        <Col lg="6">
                            <div className="mt-3 d-flex" style={styles.box}>
                                <Label for="basicpill-firstname-input1" style={styles.boxLabel} className="control-label me-3" >
                                    Current Password
                                    <span className="text-danger mx-1">*</span>
                                </Label>
                                <Input
                                    type="text"
                                    className="form-control me-3"
                                    value={state.client_name}
                                    // disabled={selectedValue === 'registration_no' ? false : true}
                                   onChange={handleInputChange}
                                    style={styles.boxInput}
                                    suppressHydrationWarning
                                    id="client_name"
                                />


                            </div>

                            <div className="mt-3 d-flex" style={styles.box}>
                                <Label for="basicpill-firstname-input1" style={styles.boxLabel} className="control-label me-3" >
                                    New Password
                                    <span className="text-danger mx-1">*</span>
                                </Label>
                                <Input
                                    type="text"
                                    className="form-control me-3"
                                    value={state.client_name}
                                    // disabled={selectedValue === 'registration_no' ? false : true}
                                   onChange={handleInputChange}
                                    style={styles.boxInput}
                                    suppressHydrationWarning
                                    id="client_name"
                                />


                            </div>

                            <div className="mt-3 d-flex" style={styles.box}>
                                <Label for="basicpill-firstname-input1" style={styles.boxLabel} className="control-label me-3" >
                                    Confirm Password
                                    <span className="text-danger mx-1">*</span>
                                </Label>
                                <Input
                                    type="text"
                                    className="form-control me-3"
                                    value={state.client_name}
                                    // disabled={selectedValue === 'registration_no' ? false : true}
                                   onChange={handleInputChange}
                                    style={styles.boxInput}
                                    suppressHydrationWarning
                                    id="client_name"
                                />


                            </div>
                        </Col>
          

                
                    </Row>

                    <div className="mt-5 text-center"  >
                        <button className="btn btn-primary" style={{ width: 150, }} onClick={() => { }} >Save</button>
                        </div>

                </CardBody>
            </Card>

        </React.Fragment>
    );
};


export default ChangePassword;

const styles = {

    para: {
        fontSize: 12
    },
    box: {
        fontSize: 16,
        fontWeight: "unset",
        justifyContent: "space-between",
        alignItems: "center",
    },
    boxLabel: {
        fontSize: 13,
        fontWeight: "400",
    },
    boxInput: {
        fontSize: 13,
        width: '60%',
        padding: 8,
        fontWeight: "unset",
    }

}
