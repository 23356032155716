import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  Button,
  Card,
  CardBody,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  Popover,
  UncontrolledPopover,
  PopoverHeader,
  PopoverBody,

  NavLink,
  CardTitle,
  CardText,
  
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Table,
} from "reactstrap";
import { Link } from "react-router-dom";

import classnames from "classnames";
import { Line } from 'react-chartjs-2';

import { DateRangePicker } from 'react-date-range';


import axios from 'axios'
import { addDays } from 'date-fns';


import { withTranslation } from "react-i18next";


import moment from "moment";
import Doughnut from "../../components/echart/doughnutchart";
import Pie from "../../components/echart/piechart";
import DumbellHorizontal from "../../components/echart/DumbellHorizontal";




const Dashboard = props => {


  //meta title
  document.title = "TheRollNumber";

  const [user, setUser] = useState({});

  const [cases, setCases] = useState([]);

  const [graph_data, setGraphData] = useState({})

  const [show, setShow] = useState(false);


  const [data, setData] = useState([])

  const [state, setState] = useState([
    {
      startDate: new Date(),
      endDate: addDays(new Date(), 7),
      key: 'selection'
    }
  ]);

  const options2 = {
    scales: {
      yAxes: [
        {
          // stacked: true,
          gridLines: {
            display: true,
            color: "rgba(255,99,132,0.2)"
          },
          ticks: {
            // suggestedMax: 100,
            suggestedMin: 0
          }
        }
      ],
      xAxes: [
        {
          gridLines: {
            display: false
          }
        }
      ]
    }
  };

  const pickerRef = React.useRef(null)

  React.useEffect(() => {

    const handleClickOutside = (event) => {

      if (pickerRef.current && !pickerRef.current.contains(event.target)) {

        setShow(false)

      }

    }

    document.addEventListener("mousedown", handleClickOutside);

    return () => {

      document.removeEventListener("mousedown", handleClickOutside);

    };

  }, [pickerRef]);

  useEffect(() => {

    getProfile();

    get_all_cases();

    get_data();

  }, []);

  const getProfile = async () => {

    const user = JSON.parse(localStorage.getItem('authUser')).user;

  setUser(user);

  }


  const get_all_cases = async () => {

    const config = {
      headers: {
        'Content-Type': 'application/json'
      }
    };
   
    axios.get(`${process.env.REACT_APP_BASE_URL}/service/cases`, {}, config)

    .then(response => {

      console.log('response cases==>',response.data)

      const data = response.data;


      setCases(data)


    })

    .catch(error => {

      console.log('error from server==>',error)

    })


  }

  const get_data = async() => {

    const config = {
      headers: {
        'Content-Type': 'application/json'
      }
    };
  

  
    await axios.get(`${process.env.REACT_APP_BASE_URL}/service/cases`, {}, config)
    .then(async response => {
      console.log('cases',response.data);

      const res = response.data

    let all = response.data

    let six_month = res.filter((item)=> moment(item.timestamp).format('YYYY-MM') === moment().subtract(6, 'months').format('YYYY-MM'))

    let one_month = res.filter((item)=> moment(item.timestamp).format('YYYY-MM') === moment().subtract(1, 'months').format('YYYY-MM'))

    let one_year = res.filter((item)=> moment(item.timestamp).format('YYYY-MM') === moment().subtract(1, 'years').format('YYYY-MM'))

    let labels = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October','November','December']

    let datasets = [
      {
        label: 'WIP',
        // Define the data points for the y-axis
        data: [0,0,0,0,0,0,0,0,0,0,0,0],
        fill: false,
        backgroundColor: 'rgb(75,192,192)',
        borderColor: 'rgba(75,192,192,0.2)',
        tension: 0.1 // This will smooth the line
      },
      {
        label: 'Candidate approved',
        // Define the data points for the y-axis
        data: [0,0,0,0,0,0,0,0,0,0,0,0],
        fill: false,
        backgroundColor: 'green',
        borderColor: 'green',
        tension: 0.1 // This will smooth the line
      },
      {
        label: 'Notification sent',
        // Define the data points for the y-axis
        data: [0,0,0,0,0,0,0,0,0,0,0,0],
        fill: false,
        backgroundColor: 'rgb(75,192,192)',
        borderColor: '#f1b44c',
        tension: 0.1 // This will smooth the line
      },
      {
        label: 'Partially shared',
        // Define the data points for the y-axis
        data: [0,0,0,0,0,0,0,0,0,0,0,0],
        fill: false,
        backgroundColor: 'rgb(75,192,192)',
        borderColor: '#34c38f',
        tension: 0.1 // This will smooth the line
      },
      {
        label: 'Candidate rejected',
        // Define the data points for the y-axis
        data: [0,0,0,0,0,0,0,0,0,0,0,0],
        fill: false,
        backgroundColor: 'green',
        borderColor: '#50a5f1',
        tension: 0.1 // This will smooth the line
      },
      {
        label: 'Re-verification',
        // Define the data points for the y-axis
        data: [0,0,0,0,0,0,0,0,0,0,0,0],
        fill: false,
        backgroundColor: 'rgb(75,192,192)',
        borderColor: '#e83e8c',
        tension: 0.1 // This will smooth the line
      },
      {
        label: 'Insufficiency data',
        // Define the data points for the y-axis
        data: [0,0,0,0,0,0,0,0,0,0,0,0],
        fill: false,
        backgroundColor: 'rgb(75,192,192)',
        borderColor: '#556ee6',
        tension: 0.1 // This will smooth the line
      },
      
    ]

    console.log('graph data', datasets)

     res.map((item)=> {

      let month = moment(item.timestamp).format('MMMM')

      let index = labels.indexOf(month)

      console.log('index', index, labels)

      if(index !== -1){

     
            
        if(item.verification_status === 'WIP'){
            
          datasets[1].data[index] = datasets[1].data[index] + 1
      }
        

        if(item.verification_status === 'Approved'){
            
          datasets[1].data[index] = datasets[1].data[index] + 1
      }

      if(item.verification_status === 'Notification sent'){
            
        datasets[2].data[index] = datasets[2].data[index] + 1
    }

    if(item.verification_status === 'Partially shared'){

      datasets[3].data[index] = datasets[3].data[index] + 1

    }

    if(item.verification_status === 'Candidate rejected'){

      datasets[4].data[index] = datasets[4].data[index] + 1

    }

    if(item.verification_status === 'Re-verification'){

      datasets[5].data[index] = datasets[5].data[index] + 1

    }

    if(item.verification_status === 'Insufficiency data'){

      datasets[6].data[index] = datasets[6].data[index] + 1

    }




      }

    

    })

    console.log('datasets====>', datasets)

    setGraphData({
      ...graph_data,
      labels: [...labels],
      datasets: [...datasets],
    })

    console.log('six month',six_month)

    console.log('one month',one_month)

    console.log('one year',one_year)

    console.log('all',all)

    setData(all)




    }
      
      )
  }

  
  const filterCred = async() => {

    console.log('state', state)

    const first = moment(state[0].startDate).format('YYYY-MM-DD')

    const second = moment(state[0].endDate).format('YYYY-MM-DD')

    const startDate = moment(first); // Replace with your start date
    const endDate = moment(second);   // Replace with your end date
  
    const labels = [];
    let currentDate = startDate.clone();

    let arr = []

    let arr2 = []

    let arr3 = []
  
    while (currentDate.isSameOrBefore(endDate)) {
      labels.push(currentDate.format('YYYY-MM-DD'));
      arr.push(0)
      arr2.push(0)
      arr3.push(0)

      currentDate.add(1, 'day');
    }

    console.log('labels', labels)

    let datasets = [
      {
        label: 'WIP',
        // Define the data points for the y-axis
        data: [0,0,0,0,0,0,0,0,0,0,0,0],
        fill: false,
        backgroundColor: 'rgb(75,192,192)',
        borderColor: 'rgba(75,192,192,0.2)',
        tension: 0.1 // This will smooth the line
      },
      {
        label: 'Candidate approved',
        // Define the data points for the y-axis
        data: [0,0,0,0,0,0,0,0,0,0,0,0],
        fill: false,
        backgroundColor: 'green',
        borderColor: 'green',
        tension: 0.1 // This will smooth the line
      },
      {
        label: 'Notification sent',
        // Define the data points for the y-axis
        data: [0,0,0,0,0,0,0,0,0,0,0,0],
        fill: false,
        backgroundColor: 'rgb(75,192,192)',
        borderColor: '#f1b44c',
        tension: 0.1 // This will smooth the line
      },
      {
        label: 'Partially shared',
        // Define the data points for the y-axis
        data: [0,0,0,0,0,0,0,0,0,0,0,0],
        fill: false,
        backgroundColor: 'rgb(75,192,192)',
        borderColor: '#34c38f',
        tension: 0.1 // This will smooth the line
      },
      {
        label: 'Candidate rejected',
        // Define the data points for the y-axis
        data: [0,0,0,0,0,0,0,0,0,0,0,0],
        fill: false,
        backgroundColor: 'green',
        borderColor: '#50a5f1',
        tension: 0.1 // This will smooth the line
      },
      {
        label: 'Re-verification',
        // Define the data points for the y-axis
        data: [0,0,0,0,0,0,0,0,0,0,0,0],
        fill: false,
        backgroundColor: 'rgb(75,192,192)',
        borderColor: '#e83e8c',
        tension: 0.1 // This will smooth the line
      },
      {
        label: 'Insufficiency data',
        // Define the data points for the y-axis
        data: [0,0,0,0,0,0,0,0,0,0,0,0],
        fill: false,
        backgroundColor: 'rgb(75,192,192)',
        borderColor: '#556ee6',
        tension: 0.1 // This will smooth the line
      },

    ]

    console.log('DATA', data)

    const filtered_data = data.filter((item)=>
    {
    
      let date = moment(item.timestamp).format('YYYY-MM-DD');
    
      console.log('date', date, date >= first && date <= second)
    
      return date >= first && date <= second
    
    }
         )



    filtered_data.map((item)=> {

      let date = moment(item.timestamp).format('YYYY-MM-DD')

      let index = labels.indexOf(date)

      console.log('index', index, labels, item.status)

      if(index !== -1){

     
            
        if(item.verification_status === 'WIP'){
            
          datasets[1].data[index] = datasets[1].data[index] + 1
      }
        

        if(item.verification_status === 'Approved'){
            
          datasets[1].data[index] = datasets[1].data[index] + 1
      }

      if(item.verification_status === 'Notification sent'){
            
        datasets[2].data[index] = datasets[2].data[index] + 1
    }

    if(item.verification_status === 'Partially shared'){

      datasets[3].data[index] = datasets[3].data[index] + 1

    }

    if(item.verification_status === 'Candidate rejected'){

      datasets[4].data[index] = datasets[4].data[index] + 1

    }

    if(item.verification_status === 'Re-verification'){

      datasets[5].data[index] = datasets[5].data[index] + 1

    }

    if(item.verification_status === 'Insufficiency data'){

      datasets[6].data[index] = datasets[6].data[index] + 1

    }

      }

    

    })

    console.log('datasets====>', datasets)

    setGraphData({  
      ...graph_data,
      labels: [...labels],
      datasets: [...datasets],
    })

    setShow(false)

  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <h3 className="font-weight-bold">Dashboard</h3>

<Row>
<Col lg={4}>
<Card className="h-100">
<CardBody>
<CardTitle className="mb-4">Total Cases Breakup</CardTitle>
<Doughnut data={cases} dataColors='["--bs-primary","--bs-warning", "--bs-danger","--bs-info", "--bs-success"]'/>

</CardBody>
</Card>
</Col>


<Col lg={8}>
<Card className="h-100">
<CardBody>
<CardTitle className="mb-4">Total Cases Breakup</CardTitle>
<Pie data={cases} dataColors='["--bs-primary","--bs-warning", "--bs-danger","--bs-info", "--bs-success"]'/>

</CardBody>
</Card>
</Col>


{/* <Col lg={4}>
<Card style={{height:'100%'}}>
<CardBody>
<CardTitle className="mb-4">Profile</CardTitle>
<div >
  <div style={styles.box}>
    <p className="m-0 me-2" >Name:</p>
    <h4 className="text-primary" style={{fontSize:18, fontWeight:'500'}}>{user?.first_name}</h4>
  </div>

  <div style={styles.box}>
    <p className="m-0 me-2">Role:</p>
    <h4 className="text-primary" style={{fontSize:18, fontWeight:'500'}}>{user?.role}</h4>
  </div>

  <div style={styles.box}>
    <p className="m-0 me-2">Company:</p>
    <h4 className="text-primary" style={{fontSize:18,width:300, textAlign:'right', fontWeight:'500'}}>{user?.company_name}</h4>
  </div>




  <div  style={styles.box}>
    <p className="m-0 me-2">Location:</p>
    <h4 className="text-primary" style={{fontSize:18, fontWeight:'500'}}>{user?.company_location}</h4>
  </div>
  </div>

  
</CardBody>
</Card>
</Col> */}

<Col lg={12}>
<Card className="mt-4">

       
<div className="toolbar d-flex flex-wrap gap-2 text-end" >

{ show &&  

<div ref={pickerRef} style={{marginLeft:30, height:'100%'}}>

<div style={{ position:'absolute', display:'flex',   backgroundColor:'transparent',  zIndex:9999,top:-10, left:0, right:0, alignItems:'flex-start'}}>

<DateRangePicker
onChange={item => setState([item.selection])}

footerContent={
  <div style={{backgroundColor:"#fff", padding:10, display:'flex', justifyContent:'space-around'}}>
  <button className='btn btn-primary d-block' onClick={()=> filterCred()}>submit</button>
  <button className='btn btn-secondary d-block' onClick={()=> setShow(false)}>Cancel</button>

  </div>
}

showSelectionPreview={true}
moveRangeOnFirstSelection={false}
scroll={{ enabled: true }}
months={1}
ranges={state}

direction="vertical"
/>



</div>
</div>

}

</div>
                <CardBody>
                  <CardTitle className="mb-4 text-center" style={{fontWeight:'normal', fontSize:18}}>Date Wise Case Breakup (Calculated Based On Case Indate)</CardTitle>
                  
                  <button type="button" className="btn btn-light btn-sm" onClick={()=> setShow(true)}>
    Choose date
    </button>

    <div >
    <Line data={graph_data} height={70} options={options2} />

    </div>

                  {/* <DumbellHorizontal data={cases} dataColors='["--bs-primary"]'/> */}
                </CardBody>
              </Card>
  </Col>
</Row>

</Container>
           </div>
    </React.Fragment>
  );
};

const styles = {
  para: {
    fontSize: 12
  },
  box: {
    display: "flex",
    fontSize: 16,
    justifyContent:'space-between',
    fontWeight: "unset",
    marginBottom: 20
  }
};

export default Dashboard;
