import React, { useEffect } from 'react';
import {View, StyleSheet, Text, Image, Link } from '@react-pdf/renderer';
import InvoiceTableHeader from './InvoiceTableHeader'
import InvoiceTableRow from './InvoiceTableRow'
import InvoiceTableBlankSpace from './InvoiceTableBlankSpace'
import InvoiceTableFooter from './InvoiceTableFooter'
import Aadhaar from '../../aadhaar.png'
import axios from "axios";

import moment from 'moment';
// import Logo from '../../report_images/trnlogo-cropped.png'
import Footer from './Footer';
const tableRowsCount = 11;
const borderColor = '#061c33'


const styles = StyleSheet.create({
  tableContainer: {
      flexDirection: 'row',
      flexWrap: 'wrap',
      marginTop: 24,
      padding:20,
      paddingVertical:8
    
     
  },
  tableText:{
    fontSize: 10,
    fontFamily:'Montserrat-Light',
    width: '100%',
  },
  container: {
  marginTop: 24,
},
  text:{
    fontSize: 13,
    color: '#061c33',
    lineHeight:1,
    textAlign: 'center',
    fontFamily:'Montserrat',
  
  },
  box: {
    width: '100%', // Specify the width of the box
    height: 24, // Specify the height of the box
    backgroundColor: '#d4ddf1',
    justifyContent: 'center', // Center horizontally
    alignItems: 'center', // Center vertically
    margin: 'auto', // Center the box itself if within a larger container
  },
  table: {
    display: "table",
    width: "auto",
    
    borderStyle: "solid",
    borderWidth: 0.8,
 
    borderColor: '#bfbfbf',
    borderRightWidth: 0,
    borderBottomWidth: 0,
  },
  row: {
    flexDirection: "row",
    width: "100%",
  },
  col: {
    borderStyle: "solid",
    height:'auto',
     padding:5,

    borderWidth: 0.8,
 
    borderColor: '#bfbfbf',
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },

  col2: {
    borderStyle: "solid",
    // height:'auto',
     padding:3,

    borderWidth: 0.8,
 
    borderColor: '#bfbfbf',
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },
  profile:{
    width:'100%',
   
    marginTop:10,

   
   
  },
  imageBox:{
    width:'20%',
    height:100,
    alignSelf:'center',

    justifyContent: 'center', // Center horizontally
    alignItems: 'center', // Center vertically
    margin: 'auto', // Center the box itself if within a larger container
  }
});


const doc_names = {
  "aadhaar":"Aadhaar Card",
  "pan":"PAN Card",
  "passport":"Passport",
  "doctor":"Doctor",
  "nurse":"Nurse",
  "voter_id":"Voter ID",
}



  const EpfoComp = ({caseData, data, logo,  requestedData}) => {

    const [components, setComponents] = React.useState([]);
    const [passbook, setPassbook] = React.useState([]);

    const [breakPoint, setBreakPoint] = React.useState(false);
    const [tdsObj, setTDSObj] = React.useState({});

    const getComponentFields = async (obj) => {

      const config = {
        headers: {
          'Content-Type': 'application/json'
        }
      };
  
      console.log('get component fields==>', obj)
  
     let result = await axios.post(`${process.env.REACT_APP_BASE_URL}/employer/admin/component_fields`, obj, config)
  
      console.log('result==>',result.data);
  
     return result.data.data;
  
    }
  


    useEffect(() => {

      console.log('epfo requestedData and case data==>',data, requestedData, caseData);

      // let component_fields =  getComponentFields({case_id: caseData.case_id, component_id: caseData.component_id, sub_component_id: caseData.sub_component_id});

      // component_fields.then((fields)=>{

      //   console.log('fields==>',fields);

      // }).catch((error)=>{ 

      //   console.log('error==>',error);
      // })

       let epfo_data = requestedData.find((i)=>{
        return Object.keys(i)[0] == 'epfo';
      })?.epfo;

      console.log('epfo_data==>',epfo_data);


      
      const employment = requestedData.find((i)=>{
        return Object.keys(i)[0] == 'employment';
      })?.employment;


      


      if(epfo_data !== undefined){

        
      

      const itr = requestedData.find((i)=>{
        return Object.keys(i)[0] == 'tds';
      })?.tds;

      console.log('itr==>',itr);
      const obj = {}
      const TDS = itr?.tds_data.filter((item, index)=> {

        const name = item.name_of_deductor.replace(/\s/g, '');

        if(obj[name]){

          obj[name].push(item)

        }else{

          obj[name] = [item]

        }

      })



      // setComponents(data)

      let passbook_data = []

      const companies = epfo_data?.epfo_company?.map((item, index)=> {

         passbook_data = epfo_data?.epfo_passbook.filter((i)=>{
          let member_id = i.member_id;

let newId = member_id.substring(0, member_id.length - 7).replace(/\s/g, "");

return newId ==  item.establishment_id.replace(/\s/g, "");
   
  })

  console.log('passbook_data==>',passbook_data)

  let sorted_passbook = passbook_data.sort((a, b) => {
    return new Date(b.approved_on) - new Date(a.approved_on);

  })

  console.log('sorted_passbook==>',sorted_passbook);


  const tds_content = obj[item.company_name.replace(/\s/g, "")];

        return {
           company_name: item?.company_name,
           establishment_id: item?.establishment_id,
           employment: employment.find((i)=>{
         
            return i.establishment_id ==  item.establishment_id.replace(/\s/g, "");


           }),
           member_id: epfo_data?.epfo_passbook.find((i)=>{
            let member_id = i.member_id;

            let newId = member_id.substring(0, member_id.length - 7).replace(/\s/g, "");
            
             return newId ==  item.establishment_id.replace(/\s/g, "");
                 
                })?.member_id,
           pf_uan: item?.pf_uan,
           doe: item?.doe,
            doj: item?.doj,
            tds_content: tds_content,
            content: sorted_passbook
        }
      })



      console.log('companies==>',companies, epfo_data?.epfo_passbook);


      setPassbook(companies)

    }
    else{



      const itr = requestedData.find((i)=>{
        return Object.keys(i)[0] == 'tds';
      })?.tds;

      console.log('itr==>',itr);
      const obj = {}
      const TDS = itr?.tds_data.filter((item, index)=> {

        const name = item.name_of_deductor.replace(/\s/g, '_');

        if(obj[name]){

          obj[name].push(item)

        }else{

          obj[name] = [item]

        }

      })

      console.log('TDS2==>',obj, data);


      const companies = Object.keys(obj).map((item, index)=> {

        console.log('item==>',item, obj[item]);

        const empObj = employment.find((i)=>{
        
          return i.company_name ==  item.replace(/_/g, ' ');


         })



        return {
          company_name: item.replace(/_/g, ' '),
          establishment_id: empObj?.establishment_id,
          employment: empObj,
          member_id: "",
          pf_uan: empObj?.pf_uan,
          doe: empObj?.doe,
           doj: empObj?.doj,
           tds_content: obj[item],
           content: []

        }

      })

      console.log('companies tds==>',companies);

      setPassbook(companies)


    }



    }, []);

    const renderPassbook = (passbook_data)=>{

      return (
        <View style={[ {width:'100%', flexDirection:'row', flexWrap:'wrap',  marginTop:10}]}>
{
      passbook_data?.map((content, i)=> {

    
        
        return (
<View style={{width:'32%'}} break={i % 45 === 0 && i !== 0 ? true : false}>



{(i % 45 === 0 || i % 45 === 1 || i % 45 === 2) && <View style={[styles.row, {marginTop:5, borderTopWidth:0.8, borderColor:'#bfbfbf'}]} >
              <View style={[styles.col, {width:'35%', borderLeftWidth:0.8}]}>
                <Text >Approved On</Text>
                </View>
                <View style={[styles.col, {width:'35%'}]}>
                <Text >Month</Text>
                </View>
                <View style={[styles.col, {width:'30%'}]}>
                <Text >Year</Text>
                </View>
          
        </View>}
      <View style={[styles.row]}  >
        <View style={[styles.col, {width:'35%', borderLeftWidth:0.8}]}>
          <Text style={styles.tableText}>{content?.approved_on}</Text>
          </View>
          <View style={[styles.col, {width:'35%'}]}>
          <Text style={styles.tableText}>{content?.month}</Text>
          </View>
          <View style={[styles.col, {width:'30%'}]}>
          <Text style={styles.tableText}>{content?.year}</Text>
          </View>
     
  </View>
  </View>
)
  

        
}
  )
    }
        </View>
      )

    }

    const renderTds = (tds_content)=>{

      return (
        <View style={[styles.table, {width:'100%', marginTop:10}]}  >
            <View style={styles.row}>             
        </View>
        <View style={styles.row} >
    
                <View style={[styles.col, {width:'25%'}]}>
                <Text >Transaction Date</Text>
                </View>
                <View style={[styles.col, {width:'25%'}]}>
                <Text >Date of Booking</Text>
                </View>
                <View style={[styles.col, {width:'25%'}]}>
                <Text >Amount paid</Text>
                </View>
               
                <View style={[styles.col, {width:'25%'}]}>
                <Text >Tds deposited</Text>
                </View>
                <View style={[styles.col, {width:'25%'}]}>
                <Text >Assessment year</Text>
                </View>
        </View>
    
   
    
          { 
            tds_content.map((content, i)=> {

     
            return (

          <View style={styles.row}  >
               <View style={[styles.col2, {width:'25%'}]}>
                <Text style={styles.tableText}>{content?.transaction_date}</Text>
                </View>
                <View style={[styles.col2, {width:'25%'}]}>
                <Text style={styles.tableText}>{content?.date_of_booking}</Text>
                </View>
                <View style={[styles.col2, {width:'25%'}]}>
                <Text style={styles.tableText}>{content?.amount_paid}</Text>
                </View>
               
                <View style={[styles.col2, {width:'25%'}]}>
                <Text style={styles.tableText}>{content?.tds_deposited}</Text>
                </View>
                <View style={[styles.col2, {width:'25%'}]}>
                <Text style={styles.tableText}>{content?.assessment_year}</Text>
                </View>
              {/* <View style={{
                 borderStyle: "solid",

              }}>
              <Text style={styles.tableText}>{content?.transaction_date}</Text>
              </View>
              <View style={{
                 borderStyle: "solid",

   width:'35%',
    borderWidth: 0.8,
  
    borderColor: '#bfbfbf',
    borderLeftWidth: 0,
    borderTopWidth: 0,
              }}>
              <Text style={styles.tableText}>{content?.date_of_booking}</Text>
              </View>
         
              <View style={{
                 borderStyle: "solid",
    
    borderWidth: 0.8,
    width:'30%',
    borderColor: '#bfbfbf',
    borderLeftWidth: 0,
    borderTopWidth: 0,
              }}>
              <Text style={styles.tableText}>{content?.amount_paid}</Text>
              </View>
          
              <View style={{
                 borderStyle: "solid",
    
    borderWidth: 0.8,
    width:'10%',
    borderColor: '#bfbfbf',
    borderLeftWidth: 0,
    borderTopWidth: 0,
              }}>
              <Text style={styles.tableText}>{content?.tds_deposited}</Text>
              </View>

              <View style={{
                 borderStyle: "solid",


    borderWidth: 0.8,
    width:'10%',
    borderColor: '#bfbfbf',
    borderLeftWidth: 0,
    borderTopWidth: 0,
              }}>
              <Text style={styles.tableText}>{content?.assessment_year}</Text>
              </View> */}
           
      </View>)}
      )}
    
       
    
      
      </View>
      )

    }


    const renderContent = (item) => {

      var count = 0;

      const name_of_deductor = item.employment.company_name.replace(/\s/g, '');

      console.log('name_of_deductor==>',tdsObj);

      return <View >
{/* {
      item.content?.map((content, i)=> {

    
        
        return (
<View style={{width:'32%'}} break={i % 45 === 0 && i !== 0 ? true : false}>



{(i % 45 === 0 || i % 45 === 1 || i % 45 === 2) && <View style={[styles.row, {marginTop:5, borderTopWidth:0.8, borderColor:'#bfbfbf'}]} >
              <View style={[styles.col, {width:'35%', borderLeftWidth:0.8}]}>
                <Text >Approved On</Text>
                </View>
                <View style={[styles.col, {width:'35%'}]}>
                <Text >Month</Text>
                </View>
                <View style={[styles.col, {width:'30%'}]}>
                <Text >Year</Text>
                </View>
          
        </View>}
      <View style={[styles.row]}  >
        <View style={[styles.col, {width:'35%', borderLeftWidth:0.8}]}>
          <Text style={styles.tableText}>{content?.approved_on}</Text>
          </View>
          <View style={[styles.col, {width:'35%'}]}>
          <Text style={styles.tableText}>{content?.month}</Text>
          </View>
          <View style={[styles.col, {width:'30%'}]}>
          <Text style={styles.tableText}>{content?.year}</Text>
          </View>
     
  </View>
  </View>
)
  

        
}
  )
    } */}

{
renderPassbook(item.content)

}

{/* tds company related data */}

<View style={[styles.box, {marginTop:20}]} break>
  <Text  style={styles.text}>Tds</Text>
</View>


{
  renderTds(item.tds_content)
}

  </View>

    }




    return (

<View style={styles.tableContainer} >

<View style={styles.box}>
  <Text  style={styles.text}>Employment {passbook.length}</Text>
</View>

<View style={styles.profile}>



  {
   

         passbook.length > 0 && passbook.map((item, index)=> {

            return (
<View  style={{marginTop:40}} break={index !== 0}>
<View style={{
  flexDirection:'row',
  justifyContent:'space-between',
}}>
<Text>Establishment ID:</Text>

<Text>{item.establishment_id}</Text>

</View>

<View style={{
  flexDirection:'row',
  marginTop:5,
  justifyContent:'space-between',
}}>
<Text>Member ID:</Text>

<Text>{item.member_id}</Text>

</View>


        <View style={[styles.table, {width:'100%', marginTop:10, borderLeftWidth:0}]}  >
            {/* <View style={styles.row}>
              <View style={[styles.col, {width:'100%', borderLeftWidth:0.8}]}>
                <Text style={[ {textAlign:'center'}]}>{item.company_name}</Text>
                </View>
            
        </View> */}

        <View style={styles.row}>
             <View style={[styles.col, {width:'100%', borderLeftWidth:0.8}]}>

             <View style={styles.row}>
          <View style={[styles.col, {width:'35%', borderLeftWidth:0.8}]}>
            <Text >Company name</Text>
            </View>
            <View style={[styles.col, {width:'35%'}]}>
            <Text >{item.employment?.company_name}</Text>
            </View>
            <View style={[styles.col, {width:'30%'}]}>
            <Text >{item.corrected_fields?.company_name}</Text>
            </View>
    </View>
    <View style={styles.row}>
          <View style={[styles.col, {width:'35%', borderLeftWidth:0.8}]}>
            <Text style={styles.tableText}>Designation</Text>
            </View>
            <View style={[styles.col, {width:'35%'}]}>
            <Text >{item.employment?.designation}</Text>
            </View>
            <View style={[styles.col, {width:'30%'}]}>
            <Text ></Text>
            </View>
    </View>
    <View style={styles.row}>
          <View style={[styles.col, {width:'35%', borderLeftWidth:0.8}]}>
            <Text style={styles.tableText}>Employee ID</Text>
            </View>
            <View style={[styles.col, {width:'35%'}]}>
            <Text style={styles.tableText} >{item.employment?.emp_id}</Text>
            </View>
            <View style={[styles.col, {width:'30%'}]}>
            <Text style={styles.tableText} ></Text>
            </View>
    </View>

    <View style={styles.row}>
          <View style={[styles.col, {width:'35%', borderLeftWidth:0.8}]}>
            <Text style={styles.tableText}>Date of joining</Text>
            </View>
            <View style={[styles.col, {width:'35%'}]}>
            <Text style={styles.tableText}>{moment(item.employment.doj).format('DD-MM-YYYY')}</Text>
            </View>
            <View style={[styles.col, {width:'30%'}]}>
            <Text style={styles.tableText}></Text>
            </View>
    </View>
    <View style={styles.row}>
          <View style={[styles.col, {width:'35%', borderLeftWidth:0.8
        }]}>
            <Text style={styles.tableText} >Date of relieving</Text>
            </View>
            <View style={[styles.col, {width:'35%', 
          flexDirection:'row',
          flexWrap:'wrap'

          }]}>
            <Text style={styles.tableText}>{moment(item.employment.doe).format('DD-MM-YYYY')}
</Text>
            </View>

            <View style={[styles.col, {width:'30%', 
          flexDirection:'row',
          flexWrap:'wrap'

          }]}>
            <Text style={styles.tableText}>
</Text>
            </View>
    </View>
    <View style={styles.row}>
          <View style={[styles.col, {width:'35%', borderLeftWidth:0.8}]}>
            <Text style={styles.tableText}>Salary</Text>
            </View>
            <View style={[styles.col, {width:'35%'}]}>
            <Text style={styles.tableText} >{item.employment.salary}</Text>
            </View>
            <View style={[styles.col, {width:'30%'}]}>
            <Text style={styles.tableText} ></Text>
            </View>
    </View>
    <View style={styles.row}>
          <View style={[styles.col, {width:'35%', borderLeftWidth:0.8}]}>
            <Text style={styles.tableText}>Epfo Verification Status</Text>
            </View>
            <View style={[styles.col, {width:'65%'}]}>
            <Text style={styles.tableText}>{item.employment.epfo_status?'Verified':"Not verified"}</Text>
            </View>
       
    </View>

    <View style={styles.row}>
          <View style={[styles.col, {width:'35%', borderLeftWidth:0.8}]}>
            <Text style={styles.tableText}>Tds Verification Status</Text>
            </View>
            <View style={[styles.col, {width:'65%'}]}>
            <Text style={styles.tableText}>{item.employment.epfo_status?'Verified':"Not verified"}</Text>
            </View>
            
    </View>
    <View style={styles.row}>
          <View style={[styles.col, {width:'35%', borderLeftWidth:0.8}]}>
            <Text style={styles.tableText}>Status</Text>
            </View>
            <View style={[styles.col, {width:'65%'}]}>
            <Text ></Text>
            </View>
    </View>
    <View style={styles.row}>
          <View style={[styles.col, {width:'35%', borderLeftWidth:0.8}]}>
            <Text style={styles.tableText}>Reason for leaving</Text>
            </View>
            <View style={[styles.col, {width:'65%'}]}>
            <Text ></Text>
            </View>
    </View>

    <View style={styles.row}>
          <View style={[styles.col, {width:'35%', borderLeftWidth:0.8}]}>
            <Text style={styles.tableText}>Rehire capability</Text>
            </View>
            <View style={[styles.col, {width:'65%'}]}>
            <Text ></Text>
            </View>
    </View>

    <View style={styles.row}>
          <View style={[styles.col, {width:'35%', borderLeftWidth:0.8}]}>
            <Text style={styles.tableText}>Payslip</Text>
            </View>
            <View style={[styles.col, {width:'65%'}]}>
            <Link src={item?.employment.payslip_path} target="_blank">{"View"}</Link>

            </View>
    </View>
    <View style={styles.row}>
          <View style={[styles.col, {width:'35%', borderLeftWidth:0.8}]}>
            <Text style={styles.tableText}>Offer Letter</Text>
            </View>
            <View style={[styles.col, {width:'65%'}]}>
            <Link src={item?.employment.offer_letter_path}  target={"_blank"}>{"View"}</Link>

            </View>
    </View>
    <View style={styles.row}> 

       
          <View style={[styles.col, {width:'35%', borderLeftWidth:0.8}]}>
            <Text style={styles.tableText}>Experience Letter</Text>
            </View>
            <View style={[styles.col, {width:'65%'}]}>
            <Link src={item?.employment.exp_letter_path} target={"_blank"}>{"View"}</Link>

            </View>
    </View>

    <View style={styles.row}>
          <View style={[styles.col, {width:'35%', borderLeftWidth:0.8}]}>
            <Text style={styles.tableText}>Remarks</Text>
            </View>
            <View style={[styles.col, {width:'65%'}]}>
            <Text ></Text>
            </View>
    </View>
              </View>

              {/* comment section */}
              
              
              {/* <View style={[styles.col, {width:'30%'}]}>
              <View style={[styles.table]}>
<View style={styles.row}>
              <View style={[styles.col, {width:'100%'}]}>
                <Text style={[ {textAlign:'center'}]}>comments</Text>
                </View>
              
        </View>
        <View style={styles.row}>
              <View style={[styles.col, {width:'100%', height:'100%'}]}>
                <Text style={[styles.tableText]}></Text>
                </View>
             
        </View>
</View>
                </View> */}



          </View>





  { item.content.length > 0 &&  <View style={[styles.box, {marginTop:20, marginBottom:7}]} break={true}>
  <Text  style={styles.text}>Epfo</Text>
</View>}
        {/* <View style={styles.row} >
              <View style={[styles.col, {width:'35%', borderLeftWidth:0.8}]}>
                <Text >Approved On</Text>
                </View>
                <View style={[styles.col, {width:'35%'}]}>
                <Text >Month</Text>
                </View>
                <View style={[styles.col, {width:'30%'}]}>
                <Text >Year</Text>
                </View>
          
        </View> */}
    
    
          { 

          renderContent(item)
            }
    
       

      
      </View>
      </View>
      
    )
  }
      )
  
    
  }


</View>



</View>
    )
  };
  
  export default EpfoComp