import React, { useEffect } from 'react';
import {Text, View, StyleSheet, Image, Svg, Font } from '@react-pdf/renderer';
import Aadhaar from '../../report_images/aadhaar.png'
import Pan from '../../pancard.png'
import Passport from '../../passport.png'
import IncomeTax from '../../report_images/income-tax.png'
import Epfo from '../../report_images/epfo.png'
import Voter from '../../voterid.png'
import Driving from '../../LICENSE.png'
import { ReactSVG } from 'react-svg'
import Profile from '../../report_images/profile.png'
import source from '../../../../../assets/fonts/Montserrat-SemiBold.ttf'
import source2 from '../../../../../assets/fonts/Montserrat-Regular.ttf';
import source3 from '../../../../../assets/fonts/Montserrat-Light.ttf';


const styles = StyleSheet.create({
   
    titleContainer:{
       
        flexWrap: 'wrap',
      

        marginTop: 24,
    },
    candidateInfo:{
 
        fontFamily:'Montserrat-Light',
        color:'#fff',

    },
    
    reportTitle:{
        color: '#fff',
        
        fontSize: 22,
      
        fontFamily:'Montserrat',
  
        textTransform: 'uppercase',
    }
  });


  const InvoiceTitle = ({title,data, candidate, requestedData}) => {

    Font.register({ family: 'Montserrat', src: source });
    Font.register({ family: 'Montserrat-Regular', src: source2 });
    Font.register({ family: 'Montserrat-Light', src: source3 });



    useEffect(() => {

        if(requestedData.length > 0){
            console.log('requestedData==>',requestedData);
        }


    }, [requestedData]);
   
    return (
   <View style={styles.titleContainer}>

<View style={{flexDirection:'row', justifyContent:'space-between'}}>
<View>
    <Text style={styles.reportTitle}>Background</Text>
        <Text style={styles.reportTitle}>Verification Report</Text>


    </View>




</View>
   
        <View style={{
            flexDirection:'row',
            alignItems:'center',
          
            marginTop:40,
            width:'100%',
          
        
        }}>
            <Image 
            src={Profile}
style={{
    width: 100
}}
            />

<View style={{
    width: '100%',
    
}}>
<View style={{flexDirection:'row', marginLeft:20}}>
    <Text style={[styles.candidateInfo, {fontSize:13}]}>Case ID:</Text>
    <Text style={[styles.candidateInfo, {fontSize:13}]}> {data.request_id}</Text>
</View>

<View style={{flexDirection:'row', marginLeft:20}}>
    <Text style={[styles.candidateInfo, {fontSize:13}]}>Name:</Text>
    <Text style={[styles.candidateInfo, {fontSize:13}]}> {candidate.first_name}</Text>
</View>

<View style={{ marginLeft:20}}> 
    {/* <Text style={[styles.candidateInfo, {fontSize:15}]}>Requested By:</Text> */}
    <View style={{width:'100%', height:0.5, marginTop:10, backgroundColor:"#fff"}}/>

    
    <Text style={[styles.candidateInfo, {fontSize:13, marginTop:5}]}>{data.org_name}</Text>
</View>
</View>
        </View>

        {/* <View style={{
            flexDirection:'row', 
            justifyContent:'space-between',
            marginTop:15,
            width:'100%',
        }}>
            <Image  src={Aadhaar} style={{
                width:60,
                height:50
            
            }} />
        
         
            <Image style={{
                width: 60,

                height:60
            }} src={Passport} />
            <Image style={{
                width: 60,
                height:60
            }} src={Epfo} />
            <Image style={{
                width: 60,
                height:60
            }} src={IncomeTax} />
            <Image style={{
                width: 60,
                height:60
            }} src={Voter} />
            <Image style={{
                width: 60,
                height:60
            }} src={Driving} />

            </View> */}
            

    </View>
  );
  }
  export default InvoiceTitle