


import React, { useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import { Link, useNavigate } from "react-router-dom";
import { ceil, isEmpty, set } from "lodash";
import "bootstrap/dist/css/bootstrap.min.css";
import TableContainer from "../../components/Common/TableContainerListCases";
import * as Yup from "yup";
import { useFormik } from "formik";
import axios from "axios";
import Cookies from "js-cookie";




import {
  Button,
  Col,
  Row,
  UncontrolledTooltip,
  
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  Input,
  FormFeedback,
  Label,
  Card,
  CardBody,
  Table,
} from "reactstrap";
import moment from "moment";

//Import Flatepicker
import "flatpickr/dist/themes/material_blue.css";
import Flatpickr from "react-flatpickr";

function QcComponentLevel() {
  //meta title
  document.title = "TheRollNumber";

  const navigate = useNavigate();


  const [modal, setModal] = useState(false);
  const [modal1, setModal1] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [cases, setCases] = useState([]);
  const [cancelNotificationType, setCancelNotificationType] = useState('')
  const [enableCancel, setEnableCancel] = useState(false);

  const [orderList, setOrderList] = useState([]);
  const [order, setOrder] = useState(null);

  const [colors, setColors] = useState(
    {
      'Green': 'green',
      'Red': 'red',
      'Yellow': 'yellow',
      'Amber': '#FFBF00',
    }
  );

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      orderId: (order && order.orderId) || "",
      billingName: (order && order.billingName) || "",
      orderdate: (order && order.orderdate) || "",
      total: (order && order.total) || "",
      paymentStatus: (order && order.paymentStatus) || "Paid",
      badgeclass: (order && order.badgeclass) || "success",
      paymentMethod: (order && order.paymentMethod) || "Mastercard",
    },
    validationSchema: Yup.object({
      orderId: Yup.string()
        .matches(/[0-9\.\-\s+\/()]+/, "Please Enter Valid Order Id")
        .required("Please Enter Your Order Id"),
      billingName: Yup.string().required("Please Enter Your Billing Name"),
      orderdate: Yup.string().required("Please Enter Your Order Date"),
      total: Yup.string()
        .matches(/[0-9\.\-\s+\/()]+/, "Please Enter Valid Amount")
        .required("Total Amount"),
      paymentStatus: Yup.string().required("Please Enter Your Payment Status"),
      badgeclass: Yup.string().required("Please Enter Your Badge Class"),
      paymentMethod: Yup.string().required("Please Enter Your Payment Method"),
    }),
    onSubmit: (values) => {
      if (isEdit) {
        const updateOrder = {
          id: order ? order.id : 0,
          orderId: values.orderId,
          billingName: values.billingName,
          orderdate: values.orderdate,
          total: values.total,
          paymentStatus: values.paymentStatus,
          paymentMethod: values.paymentMethod,
          badgeclass: values.badgeclass,
        };
        // update order
        // dispatch(onUpdateOrder(updateOrder));
        // validation.resetForm();
      } else {
        const newOrder = {
          id: Math.floor(Math.random() * (30 - 20)) + 20,
          orderId: values["orderId"],
          billingName: values["billingName"],
          orderdate: values["orderdate"],
          total: values["total"],
          paymentStatus: values["paymentStatus"],
          paymentMethod: values["paymentMethod"],
          badgeclass: values["badgeclass"],
        };
        // save new order
        // dispatch(onAddNewOrder(newOrder));
        validation.resetForm();
      }
      toggle();
    },
  });

  const [selectedNotification, setSelectedNotification] = useState({});

  const toggleViewModal = () => setModal1(!modal1);

  useEffect(() => {

 getQCComponentCases()

  }, []);



  const getQCComponentCases = async () => {

    const user = JSON.parse(Cookies.get('authUser'))


    let body = {
      team: 'QC Component Manager',
      case_status: 'Assigned',

      user_id: user.user_id
     
    }

    const config = {
      headers: {
        'Content-Type': 'application/json'
      }
    };

    console.log('Get qc cases request body==>',body)

   
   await axios.post(`${process.env.REACT_APP_BASE_URL}/employer/admin/qcCases`, body, config)

    .then(response => {

      console.log('response process member cases==>',response)

      const data = response.data.data;

      let arr = []

     data.map((item)=> {

  
      
      arr.push({
        ...item,
        select:false
      })

    

      })

    

      setCases(arr)


    })

    .catch(error => {

      console.log('error from server==>',error)

    })


  }

  const [isLoading, setLoading] = useState('')



  const toggle = () => {
    if (modal) {
      setModal(false);
      setOrder(null);
    } else {
      setModal(true);
    }
  };

  const handleOrderClick = (arg) => {
    const order = arg;
    setOrder({
      id: order.id,
      orderId: order.orderId,
      billingName: order.billingName,
      orderdate: order.orderdate,
      total: order.total,
      paymentStatus: order.paymentStatus,
      paymentMethod: order.paymentMethod,
      badgeclass: order.badgeclass,
    });

    setIsEdit(true);

    toggle();
  };

  const selectAll = (value, data) => {

    console.log('select all', value)

    let arr = [...data]

    arr.map((item)=> {

      item.select = value

    } )

    setCases(arr)

    setEnableCancel(value)



  }

  //delete order
  const [deleteModal, setDeleteModal] = useState(false);

  const onClickDelete = (order) => {
    setOrder(order);
    // setDeleteModal(true);
  };

  const handleDeleteOrder = () => {
    if (order && order.id) {
      // dispatch(onDeleteOrder(order.id));
      // setDeleteModal(false);
      setOrder("");
    }
  };
  const handleOrderClicks = () => {
    setOrderList("");
    setIsEdit(false);
    toggle();
  };

  const columns = useMemo(
    () => [
      {
        Header: (cellProps) => <div className="form-check font-size-16" >
          <input className="form-check-input"  name="all" onClick={(e)=>{

            selectAll(e.target.checked, cellProps.data)
          
          }} type="checkbox" id="checkAll" />
          <label className="form-check-label" htmlFor="checkAll"></label>
        </div>,
        accessor: 'select',
        width: '20px',
        filterable: false,
        disableFilters: false,
        
      
        Cell: (cellProps) => (
          <div className="form-check font-size-16" >
            <Input className="form-check-input" type="checkbox" onClick={()=> {
              let arr = [...cellProps.data]


              // console.log('index', cellProps)
              arr[cellProps.row.index].select = !arr[cellProps.row.index].select

              setCases(arr)

              let selected = arr.filter((item)=> item.select === true)

              if(selected.length > 1) {
                setEnableCancel(true)
              }else{
                setEnableCancel(false)
              }

            
            }} checked={cellProps.value}   />
            <label className="form-check-label" htmlFor="checkAll"></label>
          </div>
        )
      },
      {
        Header: "Case Ref",
        accessor: "request_id",
        width: "150px",
        style: {
          textAlign: "center",
          width: "10%",
          background: "#0000",
        },
        filterable: true,
        Cell: (cellProps) => {
          return <Link to={`#`} disabled={true} onClick={(e)=>
          {
            e.preventDefault();

            const  status = cellProps.row.original.verification_status;

           
              
              navigate('/admin/qc-comp-detail', {state: cellProps.row.original}) 

            

          }
          } >{cellProps.value}</Link>;
        },
      },
      {
        Header: "Location",
        accessor: "location",
        filterable: true,
        Cell: (cellProps) => {
          return cellProps.value;
        },
      },
      {
        Header: "Received Date",
        accessor: "case_received_date",
        filterable: true,
        Cell: (cellProps) => {
          return moment(cellProps.row.original.timestamp).format("DD-MM-YYYY");
        },
      },
      {
        Header: "Name",
        accessor: "candidate_name",
        filterable: true,
        Cell: (cellProps) => {
          return cellProps.value;
        },
      },
    
      {
        Header: "Father Name",
        accessor: "candidate_father_name",
        filterable: true,
        Cell: (cellProps) => {
          return cellProps.value;
        },
      },

      {
        Header: "DOB",
        accessor: "candidate_dob",
        filterable: true,
        Cell: (cellProps) => {
          return cellProps.value;
        },
      },

      {
        Header: "Case In Date",
        accessor: "timestamp",
        filterable: true,
        Cell: (cellProps) => {
          return moment(cellProps.value).format("DD-MM-YYYY");
        },
      },

      {
        Header: "Case Due Date",
        accessor: "dueDate",
        filterable: true,
        Cell: (cellProps) => {
          return "";
        },
      },
 

      {
        Header: "Report",
        accessor: "report",
        filterable: true,
        Cell: (cellProps) => {
          return cellProps.value;
        },
      },
  

      {
        Header: "Component",
        accessor: "name",
        filterable: true,
        Cell: (cellProps) => {
          return cellProps.value;
        },
      },
  
      {
        Header: "Status",
        accessor: "status",
 
        getProps: (state, rowInfo, column) => {
          return {
              style: {
                  background: 'red',
              },
          };
      },
        
        filterable: true,


        Cell: (cellProps) => {

         
          return <div style={{
            color: colors[cellProps.row.original.colorcode]  
          }} >Complete - Send to QC</div>;
        },
      },
      {
        Header: "Action",
        accessor: "action",
        disableFilters: true,
        Cell: (cellProps) => {

            const status  = cellProps.row.original.verification_status;

          const enableView = (status == 'Approved' || status == 'Dispatch done' || status == 'Complete' || status == 'QC complete') ? true : false;

          const enableCancel = (status == 'Dispatch done' || status == 'Complete' || status == 'QC complete') ? false : true;

          return (
            <div className="d-flex text-center align-items-center" style={{justifyContent:'space-evenly', width:50}} >
         
          
            </div>
          );
        },
      },
    ],
    []
  );

  const [modalConfirm, setModalConfirm] = useState(false);

  const toggleConfirm = () => setModalConfirm(!modalConfirm);



  return (
    <React.Fragment>

      <div className="page-content">
        <div className="container-fluid">
          {/* <Breadcrumbs title="Ecommerce" breadcrumbItem="Orders" /> */}
          <Row>
            {
            
                <Col xs="12">
                  <Card>
                    <CardBody>
                      <p className="card-title mb-4" style={{fontSize:20, fontWeight:'500'}}>My Cases</p>



                      <TableContainer
                        columns={columns}
                        data={cases.length > 0 ? cases : []}
                        isFilter={true}
                        isGlobalFilter={false}
                    
                        isAddOptions={true}
                        enableCancel={enableCancel}
                        handleOrderClicks={handleOrderClicks}
                        customPageSize={10}
                        isPagination={true}
                        filterable={false}
                        iscustomPageSizeOptions={true}
                        tableClass="align-middle table-nowrap table-check"
                        theadClass="table-light"
                        pagination="pagination pagination-rounded justify-content-end mb-2"
                      />
                    </CardBody>
                  </Card>
                </Col>
            }
          </Row>


          <Modal isOpen={modalConfirm} centered={true} toggle={toggleConfirm}>
            <ModalHeader toggle={toggleConfirm} tag="h4">
              Cancel Notification
            </ModalHeader>
            <ModalBody>
         
                <Row>
                  <Col className="col-12">
                    {
                      cancelNotificationType === 'single' && 
                      <p style={{fontSize:16}}>Are you sure you want to cancel this notification?</p>
                    }
                    {
                      cancelNotificationType === 'multiple' && 
                      <p style={{fontSize:16}}>Are you sure you want to cancel all selected notifications?</p>
                    }
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div className="text-center">
                      <button
                        type="button"
                        onClick={()=>{
                          if(cancelNotificationType === 'single') {
                          }

                          if(cancelNotificationType === 'multiple') {
                          }
                        }}
                        className="btn btn-primary save-user me-2"
                      >
                        Yes
                      </button>
                      <button
                        type="button"
                        onClick={() => { setModalConfirm(false) }}
                        className="btn btn-primary save-user"
                      >
                        No
                      </button>
                    </div>
                  </Col>
                </Row>
        
            </ModalBody>
          </Modal>

          <Modal isOpen={modal} centered={true} toggle={toggle}>
            <ModalHeader toggle={toggle} tag="h4">
              Add Candidate
            </ModalHeader>
            <ModalBody>
              <Form
                onSubmit={(e) => {
                  e.preventDefault();
                  validation.handleSubmit();
                  return false;
                }}
              >
                <Row>
                  <Col className="col-12">
               
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div className="text-center">
                      <button
                        type="submit"
                        onClick={() => { navigate("/search") }}
                        className="btn btn-primary save-user me-2"
                      >
                        Manual
                      </button>
                      <button
                        type="submit"
                        onClick={() => { navigate("/excel-import") }}
                        className="btn btn-primary save-user"
                      >
                        Excel Import
                      </button>
                    </div>
                  </Col>
                </Row>
              </Form>
            </ModalBody>
          </Modal>
        </div>
      </div>

    </React.Fragment>
  );
}
QcComponentLevel.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
};

export default QcComponentLevel;
