import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Modal,
  ModalBody,
  CardBody,
  Table,
  CardSubtitle,
  Card,
Button,
  Container,
} from "reactstrap";


import axios from 'axios';
import moment from 'moment';

import { Link, useLocation, useNavigate } from "react-router-dom";





const Loader = () => {

    const navigate = useNavigate()

    return (
        <div style={{
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'rgba(0,0,0,0.5)',
            zIndex: 9999,
        }}>
        <div className="preloader" style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
        }}>
            <div className="status">
                <div className="spinner-border avatar-md text-white m-2" role="status">
                    <span className="visually-hidden">Loading...</span>
                </div>
            </div>
            </div>
        </div>
    )


}

export default Loader