import React, {useEffect, useState} from 'react';
import { Input, CustomInput,

  

  Label,
  Row,
  Col,
Container

} from 'reactstrap';

import axios from 'axios';
import moment from 'moment';

export const CaseFilter = ({ data, onFilter }) => {

  const [searchCaseRef, setSearchCaseRef] = useState('');
  const [searchLocation, setSearchLocation] = useState('');
  const [reset, setReset] = useState(false);
  const [searchCandidateName, setSearchCandidateName] = useState('');
  const [caseIn, setCaseIn] = useState({
    from: '',
    to: ''
  });

  const [caseDue, setCaseDue] = useState({
    from: '',
    to: ''
  });

  const [verificationStatus, setVerificationStatus] = useState('');

  const [listVerificationStatus, setListVerificationStatus] = useState([
    {
      id: 2,
      name: 'Notification sent'
    },
    {
      id: 3,
      name: 'Notification cancelled'
    },
    {
      id: 4,
      name: 'Notification expired'
    },
    {
      id: 5,
      name: 'Candidate approved'
    },
    {
      id: 6,
      name: 'Candidate rejected'
    },
    {
      id: 7,
      name: 'Partially shared'
    },
    {
      id: 8,
      name: 'Re-verification'
    },
    {
      id: 9,
      name: 'Approved'
    },
    {
      id: 10,
      name: 'Dispatch Done'
    },
    {
      id: 11,
      name: 'Complete'
    }
  



]);

  const [location, setLocation] = useState([]);


  const handleSubmit = () => {

    console.log('data filter==>',data);



    const filtered = data.filter(item => {

      // console.log('item==>',searchCaseRef, searchLocation, searchCandidateName, verificationStatus);

      let caseInFilter = (caseIn.from !== '' && caseIn.to !== '')?moment(item.timestamp).isBetween(caseIn.from, caseIn.to):true;


      return item.request_id.includes(searchCaseRef) && item.location.includes(searchLocation) && item.candidate_name.includes(searchCandidateName)
      && item.verification_status.includes(verificationStatus) && caseInFilter;
    });

console.log('filtered==>',filtered);

 onFilter(filtered);
    
  };

  useEffect(() => {

    getAllLocation();

  }, []);



  const resetFilter = () => {

    // setSearchCaseRef('');
    // setSearchLocation('');
    // setSearchCandidateName('');
    // setVerificationStatus('');
    // setCaseIn({
    //   from: '',
    //   to: ''
    // });

    // setCaseDue({
    //   from: '',
    //   to: ''
    // });


    onFilter(data);

  }


  const getAllLocation = async () => {

  
  
    const config = {
      headers: {
        'Content-Type': 'application/json'
      }
    };


  
   await axios.get(`${process.env.REACT_APP_BASE_URL}/service/companyLocation`, {}, config)
    .then(response => {
      console.log('all location response',response);

        let data = response.data;

        // if(data.length > 0) {
        //   setSearchLocation(data[0].LocationID);
        // } 

        setLocation(data);
  
    })
    .catch(error => {
  
      console.log('error==>',error);
    });

  };

  return (
    <div style={{ marginTop: 5 }}>
     <div style={{border:'1px solid rgba(0,0,0,.125)', padding:'10px',  marginBottom:20}}>
  <Row>
    <Col lg={4}>
    <div className="mb-3">
                        <Label className="form-label">Case Ref.#</Label>
             
                        <Input
                          name="old_password"
                    

                          value={searchCaseRef}
                          type="text"
                          placeholder=""
                          onChange={(e)=>{ 
                            // setState({...state, old_password:e.target.value})

                            setSearchCaseRef(e.target.value);
                           }}
                          
                     
                        />
                    
                     
                      </div>
      </Col>
    <Col lg={4}>
    <div className="mb-3">
                        <Label className="form-label">Location</Label>
    <select className="form-select"
    
    onChange={(e)=> {
  
        setSearchLocation(e.target.value);

}}>
  <option value="">All</option>
{
  location.map((item, index) => {
    return (
      <option key={index} value={item.LocationID}>{item.location_name}</option>
    );
  
  })
}

</select>
      </div>

    </Col>
    <Col lg={4}>
    <div className="mb-3">
                        <Label className="form-label">Candidate First Name</Label>
             
                        <Input
                          name="old_password"
                    

                          value={searchCandidateName}
                          type="text"
                          placeholder=""
                          onChange={(e)=>{ 
                            // setState({...state, old_password:e.target.value})

                            setSearchCandidateName(e.target.value);
                           }}
                          
                     
                        />
                    
                     
                      </div>
      </Col>

      <Col lg={4}>
    <div className="mb-3">
                        <Label className="form-label">Verification Status</Label>
    <select className="form-select"  onChange={(e)=> {

        setVerificationStatus(e.target.value);

}}>
<option value="">All</option>

{
  listVerificationStatus.map((item, index) => {
    return (
      <option key={index} value={item.name}>{item.name}</option>
    );
  
  }
  )
}

</select>
      </div>

    </Col>
    <Col lg={4}>

    <div style={{display:'flex', flexDirection:'row', justifyContent:'space-between'}}>
      <div className="mb-3" style={{width:'100%'}}>
                        <Label className="form-label">Case In Date</Label>
             
             <div style={{display:'flex', justifyContent:"space-between"}}>
             <Input
                          name="case_in_date"
                          style={{width:'48%'}}

                          value={caseIn.from}
                          type="date"
                          placeholder=""
                          
                          onChange={(e)=>{ 
                            console.log('date==>', moment(e.target.value).format('DD-MM-YYYY'));

                            setCaseIn({...caseIn, from:e.target.value});
                            // setState({...state, old_password:e.target.value})
                           }}
                          
                     
                        />
                       <Input
                          name="case_in_date"
                    
                          style={{width:'48%'}}

                          value={caseIn.to}
                          type="date"
                          placeholder=""
                          onChange={(e)=>{ 

                            setCaseIn({...caseIn, to:e.target.value});
                            // setState({...state, old_password:e.target.value})
                           }}
                          
                     
                        />
             </div>
                        
                     
                      </div>

                    
      </div>

      </Col>
      <Col lg={4}>
      <div style={{display:'flex', flexDirection:'row', justifyContent:'space-between'}}>
      <div className="mb-3" style={{width:'100%'}}>
                        <Label className="form-label">Case Due Date</Label>
             
             <div style={{display:'flex', justifyContent:"space-between"}}>
             <Input
                          name="case_in_date"
                          style={{width:'48%'}}

                          value={caseDue.from}
                          type="date"
                          placeholder=""
                          onChange={(e)=>{ 

                            setCaseDue({...caseDue, from:e.target.value});
                            // setState({...state, old_password:e.target.value})
                           }}
                          
                     
                        />
                       <Input
                          name="case_in_date"
                    
                          style={{width:'48%'}}

                          value={caseDue.to}
                          type="date"
                          placeholder=""
                          onChange={(e)=>{ 

                            setCaseDue({...caseDue, to:e.target.value});
                            // setState({...state, old_password:e.target.value})
                           }}
                          
                     
                        />
             </div>
                        
                     
                      </div>

                    
      </div>
      </Col>

      <Col lg={4}>
    <div >
      <button type="button" className="btn btn-primary save-user me-2" onClick={handleSubmit}>
        Search
      </button>
      <button type="button" className="btn btn-primary save-user" onClick={()=>{
         resetFilter();
      }}>
        Reset
      </button>
      </div>
        </Col>
  </Row>

</div>
    </div>
  );
};





