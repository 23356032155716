


import React, { useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import { Link, useNavigate, useLocation, useParams } from "react-router-dom";
import { ceil, get, isEmpty, set } from "lodash";
import "bootstrap/dist/css/bootstrap.min.css";
import TableContainer from "../../components/Common/TableContainerListCases";
import * as Yup from "yup";
import { useFormik } from "formik";
import axios from "axios";
import Accordion from "../../../components/Accordian";

import Cookies from "js-cookie";
import Loader from "../../components/Loader";



import {
  Button,
  Col,
  Row,
  UncontrolledTooltip,
  
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  Input,
  FormFeedback,
  Label,
  Card,
  CardBody,
  Table,
} from "reactstrap";
import moment from "moment";

//Import Flatepicker
import "flatpickr/dist/themes/material_blue.css";
import Flatpickr from "react-flatpickr";

function ViewTds() {
  //meta title
  document.title = "TheRollNumber";

  const navigate = useNavigate();

  const params = useParams();

  const location = useLocation();


  const [user, setUser] = useState(null)

  const [tds, setTds] = useState(null)

  const [state, setState] = useState({
    itr_data: "",
    tds: [],
    contentData: [],
    mappedData: null,
    isLoading: true
  })

  useEffect(() => {

    console.log('location and params===>', location, params)

    get_requested_data()

    search_wallet(params.walletID)


  }, []);
  
  const search_wallet = async (value) => {

    const config = {
      headers: {
        'Content-Type': 'application/json'
      }
    };

 
    axios.get(`${process.env.REACT_APP_BASE_URL}/service/wallet/${value}`, {}, config)
    .then(response => {
      console.log('wallet data',response.data);

      let data = response.data;

      console.log('data==>',data);

      setUser(data.user);


    })
    .catch(error => {
  
  
      console.log('error==>',error);
    });

  }

  const compareLatestYear =(a, b)=> {
    const yearA = parseInt(a.assessment_year.split("-")[1], 10);
    const yearB = parseInt(b.assessment_year.split("-")[1], 10);

  
    return yearB - yearA; // Sort in descending order (latest year first)
  }

  const compareLatestYear2 =(a, b)=> {

    const yearA = parseInt(a[1].assessment_year,b[1].assessment_year.split("-")[1], 10);
    const yearB = parseInt(b[1].assessment_year,b[1].assessment_year.split("-")[1], 10);

  
     return yearB - yearA; // Sort in descending order (latest year first)
  }


  const get_requested_data = async () => {

    const caseData = {
      walletID: params.walletID,
      org_client_id: params.client_id
    }

    const config = {
      headers: {
        'Content-Type': 'application/json',

      }
    };

    const body = {

      org_user_client_id: caseData.org_client_id,
    
    }

    console.log('body requested ==>',body);

     await axios.post(`${process.env.REACT_APP_BASE_URL}/service/requestedData/${caseData.walletID}`, body, config)
      .then(response => {
        console.log('requested data', response);

        let data = response.data.data

        let found =   data.find((item) => Object.keys(item)[0] === 'tds').tds

        console.log('tds===>',found);

        if(found){

          let tds = found.tds.sort(compareLatestYear)

          let itr = found.itr_26as
      
          let tds_data = found.tds_data.sort(compareLatestYear)
      
      
        
          let contentData =  tds.map((c)=>{
      
            return {
              title: c.assessment_year,
              id: c.tds_id,
              content: tds_data.filter((i)=> i.assessment_year == c.assessment_year),
             }
      
      
      
           
         })

         const mappedData = tds_data.reduce((result, item) => {
          const deductorName = item.name_of_deductor;
  
          const tan_of_deductor = item.tan_of_deductor;
  
          const total_amount_paid = item.total_amount_paid;
  
          const total_tax_deducted = item.total_tax_deducted;
  
          const total_tds_deposited = item.total_tds_deposited;

          const assessment_year = item.assessment_year;

          const section = item.section;

          const pan_no = item.pan_no;
  
          if (!result[deductorName]) {
              result[deductorName] = {
                  name_of_deductor: deductorName,
                  tan_of_deductor: tan_of_deductor,
                  total_amount_paid: total_amount_paid,
                  total_tax_deducted: total_tax_deducted,
                  total_tds_deposited: total_tds_deposited,
                  assessment_year: assessment_year,
                  pan_no: pan_no,
                  section: section,
                  content: []
              };
          }
      
          const deductorData = {
              sr_no: item.sr_no,
              total_amount_paid: item.total_amount_paid,
              total_tax_deducted: item.total_tax_deducted,
              total_tds_deposited: item.total_tds_deposited,
              tan_of_deductor: item.tan_of_deductor,
              section: item.section,
              transaction_date: item.transaction_date,
              status_of_booking: item.status_of_booking,
              date_of_booking: item.date_of_booking,
              assessment_year: item.assessment_year,
              remarks: item.remarks,
              amount_paid: item.amount_paid,
              tax_deducted: item.tax_deducted,
              tds_deposited: item.tds_deposited,
          };
      
          result[deductorName].content.push(deductorData);
  
          
          return result
      }, {});

         

        console.log('mappedData object===>', Object.entries(mappedData).sort(compareLatestYear2).reduce((acc, [key, value]) => 
        {
          acc[key] = value;
          return acc;
        }, {})
        
        );



        // sort mappedData by assessment year


        console.log('contentData===>',tds_data, tds);

        // sort mappedData object by assessment year

         setState({...state,itr_data: itr, mappedData,  contentData: tds_data, tds, isLoading:false})
        }
     

      })
      .catch(error => {
        console.log('error', error);

      })

  
 
  }




  const [isLoading, setLoading] = useState(false)

  const columns = useMemo(
    () => [


      {
        Header: "Transaction Date",
        accessor: "transaction_date",
        filterable: true,
        Cell: (cellProps) => {
          return cellProps.value;
        },
      },

  
      {
        Header: "Date of Booking",
        accessor: "date_of_booking",
        filterable: true,
        Cell: (cellProps) => {
          return cellProps.value;
        },
      },

      {
        Header: "Amount Paid",
        accessor: "amount_paid",
        filterable: true,
        Cell: (cellProps) => {
          return cellProps.value;
        },
      },

      {
        Header: "Tax Deducted",
        accessor: "tax_deducted",
        filterable: true,
        Cell: (cellProps) => {
          return cellProps.value;
        },
      },

      {
        Header: "Assessment Year",
        accessor: "assessment_year",
        filterable: true,
        Cell: (cellProps) => {
          return cellProps.value;
        },
      },

     
    ],
    [state.contentData]
  );


  return (
    <React.Fragment>

      <div className="page-content">
        <div className="container-fluid">
          {/* <Breadcrumbs title="Ecommerce" breadcrumbItem="Orders" /> */}
          <Row>
            {
            
                <Col xs="12">
       
                      {
                       state.mappedData && Object.keys(state.mappedData).map((item, index) => {

                          return   <Card> <CardBody><div key={index}>
                        <div>
      <div  style={{justifyContent:'space-between'}}>

<Row>

<div className="text-center mb-5 mt-3">
  <p className="text-primary" style={{fontSize:18, fontWeight:'500'}}>{item}</p>
</div>
    
<div  style={{display:'flex', justifyContent:'space-around'}}>
      <div style={{display:'flex'}}>

<p style={{fontSize:16, fontWeight:'500'}}>Candidate name</p>
<p style={{fontSize:16, fontWeight:'300'}}>: {user?.first_name}</p>
</div>

<div style={{display:'flex'}}>

<p style={{fontSize:16, fontWeight:'500'}}>Pan</p>
<p style={{fontSize:16, fontWeight:'300'}}>: {state.mappedData[item].pan_no}</p>
</div>

<div style={{display:'flex'}}>

<p style={{fontSize:16, fontWeight:'500'}}>Section</p>
<p style={{fontSize:16, fontWeight:'300'}}>: {state.mappedData[item].section}</p>
</div>
        </div>

    <Col lg={12} className="text-end mt-4">

      <div style={{display:'flex', justifyContent:'space-around'}}>
      <div style={{display:'flex'}}>

<p style={{fontSize:16, fontWeight:'500'}}>Total Tax paid/ credited</p>
<p style={{fontSize:16, fontWeight:'300'}}>: {state.mappedData[item].total_amount_paid}</p>
</div>

<div style={{display:'flex'}}>

<p style={{fontSize:16, fontWeight:'500'}}>Total Tax deducted</p>
<p style={{fontSize:16, fontWeight:'300'}}>: {state.mappedData[item].total_tax_deducted}</p>
</div>
        </div>
  
    </Col>
</Row>
      </div>

      <TableContainer
                        columns={columns}
                        data={[...state.mappedData[item].content]}
                        isFilter={false}
                        

                        isGlobalFilter={false}
                        isAddOptions={true}
         
                        customPageSize={12}
                        isPagination={true}
                        filterable={false}
                        iscustomPageSizeOptions={true}
                        tableClass="align-middle table-nowrap table-check"
                        theadClass="table-light"
                        pagination="pagination pagination-rounded justify-content-end mb-2"
                      />


    </div>
    </div>
    </CardBody></Card>
  
                        })

  
  }

                
                </Col>
            }
          </Row>


        </div>
      </div>

    </React.Fragment>
  );
}
ViewTds.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
};

export default ViewTds;
