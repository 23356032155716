import React, { Component, useState } from "react";
import ReactDOM from "react-dom";
import makeAnimated from "react-select/animated";
import { colourOptions } from "./data.js";
import MySelect from "./MySelect.js";
import { components } from "react-select";

const Option = props => {
  return (
    <div>
      <components.Option {...props}>
        <input
          type="checkbox"
          checked={props.isSelected}
          onChange={() => null}
        />{" "}
        <label>{props.label}</label>
      </components.Option>
    </div>
  );
};

const MultiValue = props => (
  <components.MultiValue {...props}>
    <span>{props.data.label}</span>
  </components.MultiValue>
);

const animatedComponents = makeAnimated();
const Index = (props)=> {

    const [state, setState] = useState({
    optionSelected: []
    });


  const handleChange = selected => {
    setState({ optionSelected: selected });
    props.selectedOption(selected);
  };


    return (
      <MySelect
        options={props.options}
        isMulti
        closeMenuOnSelect={false}
        hideSelectedOptions={false}
        components={{ Option, MultiValue, animatedComponents }}
        onChange={handleChange}
        allowSelectAll={true}
        value={state.optionSelected}
      />
    );
  
}

export default Index;
