import React, { useEffect, useMemo } from "react";
import PropTypes from "prop-types";
import { Link, useNavigate } from "react-router-dom";
import {
    Card, CardBody, Col, Container, Row, Table,
    Label,
    Input,
    Form,
    FormGroup,

    Nav,
    NavItem,
    NavLink,
    TabContent,
    TabPane, CardTitle


} from "reactstrap";
import { isEmpty, map, set } from "lodash";
import Dropzone from "react-dropzone";
import * as XLSX from 'xlsx';
import Papa from 'papaparse';
import axios from 'axios';
import Loader from "../../../components/Loader";


import classnames from "classnames";
import TableContainer from "../../../components/Common/TableContainerPackages";



const PackageDetail = (props) => {
    //meta title
    document.title =
        "TheRollNumber";


    const [data, setData] = React.useState([]);

    const [loading, setLoading] = React.useState(false);

    const [disabled, setDisabled] = React.useState(false);

    const [locations, setLocation] = React.useState([

]);

    const handleOrderClicks = () => {
    }


    const Navigate = useNavigate();

    const [user, setUser] = React.useState({});

    const [components, setComponents] = React.useState([]);



    const [state, setState] = React.useState({

  package_name: '',
    tat_price_applicable_on: '',
    price: '',
    tat: '',
    package_status: '',



    });

    const handleInputChange = (e) => {
        const { id, value } = e.target;
        setState((prevState) => ({
            ...prevState,
            [id]: value,
        }));
    };

    const columns = useMemo(
        () => [
       
          {
            Header: "Sr. No.",
            accessor: "id",
            width: "150px",
            style: {
              textAlign: "center",
              width: "10%",
              background: "#0000",
            },
            filterable: true,
            Cell: (cellProps, index) => {
              return cellProps.value;
            },
          },
          {
            Header: "Component",
            accessor: "component",
            filterable: true,
            Cell: (cellProps) => {
              return cellProps.value;
            },
          },
          
          {
            Header: "Rate Per Check (Rs.)",
            accessor: "rate",
            filterable: true,
            Cell: (cellProps) => {
              return "0";
            },
          },

          {
            Header: "TAT(Days)",
            accessor: "tat",
            filterable: true,
            Cell: (cellProps) => {
              return "0";
            },
          },

        ],
        []
      );



    // useEffect(() => {


    //     if (props.data) {

    //         console.log('props.data', props.data);

    //         const packageComponent =  JSON.parse(props.data.req_data);

    //         console.log('packageComponent', packageComponent);

    //         let arr = [...packageComponent.identity, ...packageComponent.professional_identity]

    //         console.log('arr', arr);

    //         let newArr = arr.map((item, index) => {

    //             return {

    //                 id: index + 1,
    //                 component: item,
          
    //             }
    //         })

    //         setComponents(newArr);

    //         setState((prevState) => ({
    //             ...prevState,
    //             package_name: props.data.package_name,
    //             tat_price_applicable_on: 'package',
    //             price: props.data.price,
    //             tat: props.data.tat,
    //             package_status: props.data.status,
    //         }));

          
    //     }

    // }, [props.data]);





    return (
        <React.Fragment>


            <Card>
                <CardBody>


                    <Row>

                        <Col lg="6">
                            <div className="mt-3 d-flex" style={styles.box}>
                                <Label for="basicpill-firstname-input1" style={styles.boxLabel} className="control-label me-3" >
                                    Package Name
                                    <span className="text-danger mx-1">*</span>
                                </Label>
                                <Input
                                    type="text"
                                    className="form-control me-3 w-50"
                                    value={state.package_name}
                                    disabled={true}
                                   onChange={handleInputChange}
                                    style={styles.boxInput}
                                    suppressHydrationWarning
                                    id="package_name"
                                 
                                />


                            </div>
                        </Col>
                        <Col lg="6">
                            <div className="mt-3 d-flex" style={styles.box} >
                                <Label for="basicpill-firstname-input1" style={styles.boxLabel} className="control-label me-3" >
                                TAT and Price Applicable on
                                    {/* <span className="text-danger mx-1">*</span> */}

                                </Label>
                                <Input
                                    type="text"
                                    className="form-control me-3 w-50"
                                    value={state.tat_price_applicable_on}
                                    disabled={true}
                                   onChange={handleInputChange}
                                    style={styles.boxInput}
                                    suppressHydrationWarning
                                    id="tat_price_applicable_on"
                                 
                                />


                            </div>
                        </Col>

                        <Col lg="6">
                            <div className="mt-3 d-flex" style={styles.box}>
                                <Label for="basicpill-firstname-input1" style={styles.boxLabel} className="control-label me-3" >
                                Package Price
                                    {/* <span className="text-danger mx-1">*</span> */}
                                </Label>
                                <Input
                                    type="text"
                                    className="form-control me-3 w-50"
                                    value={state.price}
                                    disabled={true}
                                   onChange={handleInputChange}
                                    style={styles.boxInput}
                                    suppressHydrationWarning
                                    id="price"
                                 
                                />


                            </div>
                        </Col>

                        
                        <Col lg="6">
                            <div className="mt-3 d-flex" style={styles.box}>
                                <Label for="basicpill-firstname-input1" style={styles.boxLabel} className="control-label me-3" >
                                Package TAT
                                    {/* <span className="text-danger mx-1">*</span> */}
                                </Label>
                                <Input
                                    type="text"
                                    className="form-control me-3 w-50"
                                    value={state.tat}
                                    disabled={true}
                                   onChange={handleInputChange}
                                    style={styles.boxInput}
                                    suppressHydrationWarning
                                    id="tat"
                                 
                                />


                            </div>
                        </Col>

                        <Col lg="6">
                            <div className="mt-3 d-flex" style={styles.box}>
                                <Label for="basicpill-firstname-input1" style={styles.boxLabel} className="control-label me-3" >
                                Status
                                    {/* <span className="text-danger mx-1">*</span> */}
                                </Label>
                                <Input
                                    type="text"
                                    className="form-control me-3 w-50"
                                    value={state.package_status}
                                    disabled={true}
                                   onChange={handleInputChange}
                                    style={styles.boxInput}
                                    suppressHydrationWarning
                                    id="package_status"
                                 
                                />


                            </div>
                        </Col>

                        <Col lg="12">

                            <div className="mt-4">
                        <TableContainer
                        columns={columns}
                        data={components}
                        isGlobalFilter={false}
                        isAddOptions={true}
                        handleOrderClicks={handleOrderClicks}
                        customPageSize={10}
                        isPagination={true}
                        filterable={false}
                        iscustomPageSizeOptions={true}
                        tableClass="align-middle table-nowrap table-check"
                        theadClass="table-light"
                        pagination="pagination pagination-rounded justify-content-end mb-2"
                      />
                            </div>
                            </Col>
                    
                    </Row>

           

                </CardBody>
            </Card>

        </React.Fragment>
    );
};


export default PackageDetail;

const styles = {

    para: {
        fontSize: 12
    },
    box: {
        fontSize: 16,
        fontWeight: "unset",
        justifyContent: "space-between",
        alignItems: "center",
    },
    boxLabel: {
        fontSize: 13,
        fontWeight: "400",
    },
    boxInput: {
        fontSize: 13,
        width: '70%',
        padding: 8,
        fontWeight: "unset",
    }

}
