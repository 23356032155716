import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Modal,
  ModalBody,
  CardBody,
  Collapse,
 
  Table,
  CardSubtitle,
  Label,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,


  Card,
Button,
  Container,
  Input,
} from "reactstrap";

import './App.css';

import classnames from "classnames";

import axios from 'axios';

import moment from 'moment';
import Header from '../components/Header';

import { Link, useLocation, useNavigate } from "react-router-dom";



import Select from "react-select";
import Loader from "../components/Loader";


const Search = () => {

  //meta title
  document.title = "TheRollNumber";


  const location = useLocation();

  const navigate = useNavigate();

  const [wallet_id, setWalletId] = useState('');  

  const [isLoading, setIsLoading] = useState(false);

  const [userFound, setUserFound] = useState(false);

  const [email, setEmail] = useState('');

  const [option, setOption] = useState([
    { value: "wallet", label: "Wallet ID" },
    { value: "email", label: "Email ID" },
  ]);

  const [selectedOption, setSelectedOption] = useState(option[0]);

  const [company_id, setCompanyId] = useState('');

  const [menu, setMenu] = useState(false);

  const [empHistory, setEmpHistory] = useState({
    to: moment().format('YYYY-MM-DD'),
    from: moment().format('YYYY-MM-DD')
  });
  const [eduHistory, setEduHistory] = useState({
    to: moment().format('YYYY-MM-DD'),
    from: moment().format('YYYY-MM-DD')
  
  });


  const [user, setUser] = useState({});

  const [col1, setcol1] = useState(true);
  const [col2, setcol2] = useState(false);
  const [col3, setcol3] = useState(false);
  const [col4, setcol4] = useState(false);
  const [col5, setcol5] = useState(false);
  const [col6, setcol6] = useState(false);
  const [col7, setcol7] = useState(false);
  const [col8, setcol8] = useState(false);


  const [show, setShow] = useState(false);
  const [identity, setIdentity] = useState([
    {
      doc_name: "aadhaar",
      checked: false,

    },
    {
      doc_name: "pan",
      checked: false,

    },

    {
      doc_name: "voter_id",
      checked: false,

    },
    {
      doc_name: "driving_licence",
      checked: false,

    }
    
   
  ]);


  const [proIdentity, setProIdentity] = useState([
    {
      doc_name: "doctor",
      checked: false,

    },
    {
      doc_name: "nurse",
      checked: false,

    },

    {
      doc_name: "icai",
      checked: false,

    }

    
   
  ]);


  const [employment, setEmployment] = useState([

    {
      doc_name: "history",
      checked: false,

    }
,
    {
      doc_name: "tds",
      checked: false,

    },
    {
      doc_name: "epfo",
      checked: false,

    },


    
   
  ]);

  const [education, setEducation] = useState([
    {
      doc_name: "history",
      checked: false,

    },
 
  
    
   
  ]);

  const [creditScore, setCreditScore] = useState(false);

  const [courtRecord, setCourtRecord] = useState(false);

  const [address, setAddress] = useState(false);



  const [selectedValue, setSelectedValue] = useState('registration_no');
  const [state, setState] = useState({
     history_type_emp: 'all',
     history_type_edu: 'all',
    
  });

  const t_col1 = () => {
    setcol1(!col1);
    setcol2(false);
    setcol3(false);
    setcol4(false)
    setcol5(false)
  };

  const t_col2 = () => {
    setcol2(!col2);
    setcol1(false);
    setcol3(false);
    setcol4(false)
    setcol5(false)

  };

  const t_col3 = () => {
    setcol3(!col3);
    setcol1(false);
    setcol2(false);
    setcol4(false)
    setcol5(false)

  };

  const t_col4 = () => {
    setcol4(!col4);
    setcol1(false);
    setcol2(false);
    setcol3(false)
    setcol5(false)

  };

  const t_col5 = () => {
    setcol5(!col5);
    setcol1(false);
    setcol2(false);
    setcol3(false)
    setcol4(false)

  };

  const t_col6 = () => {
    setcol6(!col6);


  };

  const t_col7 = () => {
    setcol7(!col7);


  };

  const t_col8 = () => {
    setcol8(!col8);


  };




  const [selectedGroup, setselectedGroup] = useState(null);

  function handleSelectGroup(selectedGroup) {
    setselectedGroup(selectedGroup);
  }

  const [optionGroup, setoptionGroup] = useState(null);

  // const optionGroup = [
  //   {
  //     label: "universities",
  //     options: [
  //       { label: "Mustard", value: "Mustard" },
  //       { label: "Ketchup", value: "Ketchup" },
  //       { label: "Relish", value: "Relish" }
  //     ]
  //   },

  // ];

  const [registration_no, setRegistration_no] = useState('');
  const onCloseClick = () => {
     setShow(!show);
   };

  useEffect(() => {

getCin();

  }, []);


  const send_bgv_request = async (body) => {


    console.log('send bgv request');
  
    const config = {
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*'
      }
    };

  
  console.log('body==>',body);
  
  // https://trnapi.therollnumber.com
  
   await axios.post(`${process.env.REACT_APP_BASE_URL}/service/request_identity_org`, body, config)
    .then(response => {
      console.log('bgv response',response);

      setIsLoading(false);

      navigate('/client/on-going-cases')
  
    })
    .catch(error => {

      setIsLoading(false);

      alert('unable to send request')
  
      console.log('error==>',error);
    });
  
  }


const send_bgv = async () => {

  setIsLoading(true);

  try {

    console.log('send bgv', empHistory, employment);

  const title = JSON.parse(localStorage.getItem('authUser')).user.company_name;

  const location = JSON.parse(localStorage.getItem('authUser')).user.company_location;


  const config = {
    headers: {
      'Content-Type': 'application/json'
    }
  };

  const req_data = {
    education: {
        type_of_graduation: "Degree/Bachelors",
        from:"2023-11",
        to:"2023-11"
    },
    
    identity: [],
   professional_identity: [],
    // court_record: courtRecord,
    // address: address, 
    employment: {
        tds: false,
        epfo: false,
        history_type:"",
        companies_list_from: {
            from: "2023-11",
            to: "2023-11"
        }
    }
}

  if(identity.length > 0){

    identity.map((item, index) => {
      if(item.checked){
        req_data.identity.push(item.doc_name);
      }
    })

  }

    employment.map((item, index) => {

      console.log('item==>',item);


      if(item.doc_name == 'tds' && item.checked){


        req_data.employment.tds = true;

      }

      if(item.doc_name == 'epfo' && item.checked){

        req_data.employment.epfo = true;


      }

    })



  if(proIdentity.length > 0){

    proIdentity.map((item, index) => {

      if(item.checked){

        req_data.professional_identity.push(item.doc_name);

      }

    })

  }



let body = {
  id_identity_user: wallet_id,
  org_id: company_id,
  title: title,
  email: user.email,
  education: false,
  employment: false,
  credit_score: creditScore,

  requester_category: 'emp',
  identity: false,
  professional_identity: false,
  req_data: JSON.stringify(req_data),
  requester_fcm_token:"23423f32423423",
  company_location: location,
  candidate_dob: '',
  candidate_name: `${user.first_name} ${user.last_name}`,
  packageType: "À la carte",
  candidate_father_name: '',
  body:"Request for documents"

  
}

employment.map((item, index) => {

  console.log('item==>',item);

  if(item.doc_name == 'history' && item.checked){

    body.employment = true;
    req_data.employment.history_type = state.history_type_emp;

  }
})


if(body.employment){

  console.log('state.history_type_emp==>',state.history_type_emp);

  if(state.history_type_emp == 'custom'){

    req_data.employment.companies_list_from.from = empHistory.from;
    req_data.employment.companies_list_from.to = empHistory.to;

  }
}

education.map((item, index) => {

  // if(item.checked){

  //   body.education = true;

  // }

  console.log('item==>',item);

  if(item.doc_name == 'history' && item.checked){

    body.education = true;
    req_data.education.history_type = state.history_type_edu;

  }

})

if(body.education){

  console.log('state.history_type_edu==>',state.history_type_edu);

  if(state.history_type_edu == 'custom'){

    req_data.education.from = eduHistory.from;
    req_data.education.to = eduHistory.to;

  }
}

body.req_data = JSON.stringify(req_data);


if(req_data.identity.length > 0){

  body.identity = true;



}

if(req_data.professional_identity.length > 0){

  body.professional_identity = true;

}






console.log('body==>',body);


if(body.education == false && body.identity == false && body.professional_identity == false && body.employment == false && body.credit_score == false){
  
    alert('Please select at least one component');
  
    setIsLoading(false);
  
    return;
  
  
}


if(selectedOption.value == 'email'){

  send_bgv_request_email(body);

}

if(selectedOption.value == 'wallet'){

  send_bgv_request(body);

}




    
  } catch (error) {

    setIsLoading(false);

    
  }



}


const getCin = async () => {

  const config = {

    headers: {
      'Content-Type': 'application/json'
    }

  };

  const company_name = JSON.parse(localStorage.getItem('authUser')).user.company_name

    axios.get(`${process.env.REACT_APP_BASE_URL}/service/cin/${company_name}`, {}, config)
  .then(response => {
    console.log('response',response);

    let data = response.data[0];

    setCompanyId(data.cin);

  })
  .catch(error => {

    console.log('error==>',error);
  });
}

  const handleCheckbox = (e, type) => {

    console.log('e.target.checked',e.target.checked);

    let name = e.target.name;

    console.log('name==>',name);

    console.log('type==>',type);

    let checked = e.target.checked;

    if(type == 'identity'){

      let data = [...identity];

      data.map((item, index) => {

        if(item.doc_name == name){

          item.checked = checked;

        }

      })

      setIdentity(data);

    }

    if(type == 'proIdentity'){

      let data = [...proIdentity];

      data.map((item, index) => {

        if(item.doc_name == name){

          item.checked = checked;

        }

      })

      setProIdentity(data);

    }

 

  }




   const search_wallet = async (value) => {

    const config = {
      headers: {
        'Content-Type': 'application/json'
      }
    };

    let id = wallet_id;

  
    axios.get(`${process.env.REACT_APP_BASE_URL}/service/wallet/${value}`, {}, config)
    .then(response => {
      console.log('wallet data',response.data);

      let data = response.data;

      console.log('data==>',data);

      setUser(data.user);

      setUserFound(true);


    })
    .catch(error => {
  
      setUserFound(false);
  
      console.log('error==>',error);
    });

  }

 // send bgv request using email

 const send_bgv_request_email = async (body) => {

  body.email = email;
  body.candidate_name = "";

  console.log('send bgv request email');

  const config = {
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*'
    }
  };


console.log('body==>',body);

// https://trnapi.therollnumber.com

await axios.post(`${process.env.REACT_APP_BASE_URL}/service/queue_notification`, body, config)
.then(response => {
  console.log('bgv response',response);

  setIsLoading(false);

  navigate('/client/on-going-cases')

})
.catch(error => {

  setIsLoading(false);

  alert('unable to send request')

  console.log('error==>',error);
});

}

  return (


    <React.Fragment>
    

{isLoading && <Loader/>}
      <div className="page-content" style={{background:'#fff'}}>



        <Container fluid={true}>

<Row>
  <Col className="col-12">

    <div style={{display:'flex', justifyContent:'center'}}>
<Card style={{width:'60%', alignSelf:'center'}}>
  <CardBody>
    <div className="p-3 pb-0">

<div style={{display:'flex', justifyContent:'space-around', alignItems:'center'}}>
<div className="d-flex" style={{   alignItems:'center', alignSelf:'center'}}>
{/* <Label for="basicpill-firstname-input1" className="control-label me-3" style={{marginRight:10, fontWeight:"unset",  fontSize:16}}>
                                    Wallet ID
                                  </Label> */}
                                      <p className="text-center me-3 mb-0" style={{
      fontSize:18,
      fontWeight:400,
    }}>Please enter your </p>

<Dropdown
                        isOpen={menu}
                        toggle={() => setMenu(!menu)}
                      >
                        <DropdownToggle className="btn btn-secondary" caret>
                          {selectedOption.label}{" "}
                          <i className="mdi mdi-chevron-down" />
                        </DropdownToggle>
                        <DropdownMenu>
                          {option.map((option, index) => {
                            return (
                              <DropdownItem
                                key={index}
                                onClick={() => {
                                  setSelectedOption(option);
                                }}
                              >
                                {option.label}
                              </DropdownItem>
                            );
                          })}
                   
                        </DropdownMenu>
                      </Dropdown>

    </div>

    <div style={{display:'flex', width:'65%'}}>
   { selectedOption.value == 'wallet'? <Input
                                    type="text"
                                    className="form-control me-3"
                                    value={wallet_id}
                                    style={{
                                      fontSize:14,
                                      height:45,
                                      
                                    }}
                                    
                                    // disabled={selectedValue === 'registration_no' ? false : true}
                                    onChange={(e) => {setWalletId(e.target.value); search_wallet(e.target.value)}}
                                    suppressHydrationWarning
                                    id="basicpill-firstname-input1"
                                    placeholder="Wallet ID"
                                  />

                                  : 

                                  <Input
                                    type="text"
                                    className="form-control w-75 me-3"
                                    value={email}
                                    style={{
                                      fontSize:15,
                                      height:45
                                    }}
                                    // disabled={selectedValue === 'registration_no' ? false : true}
                                    onChange={(e) => {setEmail(e.target.value)}}
                                    suppressHydrationWarning
                                    id="basicpill-firstname-input1"
                                    placeholder="Email ID"
                                  />
                                }

<div className="d-flex me-3">
{selectedOption.value == 'wallet'?<Button disabled={!userFound} style={{
  height:45
}} onClick={send_bgv} className="btn btn-primary"  >Submit</Button>
:
<Button disabled={!email} style={{
  height:45
}} onClick={send_bgv} className="btn btn-primary"  >Submit</Button>
}
    </div>
                               

</div>
</div>

{userFound && <p className="mt-5 text-center" style={style.heading}>Name: <span style={{color:'green'}}>{user?.first_name}</span></p>}

   </div>
    </CardBody>
</Card>


</div>
</Col>



<Col className="col-12 d-flex" style={{justifyContent:'center'}}>

<Card style={{display:'flex', alignSelf:'center', width:'40%'}}>
  <CardBody>

    <p className="text-center" style={{
      fontSize:18,
      fontWeight:400,
    }}>Please select required components</p>
  <div >
<div className="accordion" id="accordion">
      <div className="accordion-item m-2">
        <h2 className="accordion-header" id="headingOne">
          <button
            className={classnames(
              "accordion-button",
              "fw-medium",
              { collapsed: !col4 }
            )}
            type="button"
            onClick={t_col4}
            style={{ cursor: "pointer" }}
          >
          IDENTITY
          </button>
        </h2>

        

        <Collapse isOpen={col4} className="accordion-collapse">


  
<div className="accordion-body">
          <div className="text-muted">
                            <Row>
    <Col md="12">
    <div className="table-responsive">
                        <table className="table table-nowrap align-middle mb-0">
                          <tbody>
                          {
                              identity && identity.map((item, index) => (
                                <tr >
                                  <td style={{ width: "40px" }}>
                                    <div className="form-check font-size-16">
                                      <input className="form-check-input" name={`${item.doc_name}`}  checked={item.checked} onClick={(e)=>handleCheckbox(e, 'identity')} type="checkbox" id="upcomingtaskCheck01" />
                                      <label className="form-check-label" htmlFor="upcomingtaskCheck01"></label>
                                    </div>
                                  </td>
                                  <td>
                                    <h5 className="text-truncate font-size-14 m-0"><Link to="#" className="text-dark">{item.doc_name}</Link></h5>
                                  </td>
                               
                                </tr>

                              ))
                            }
                       
                          </tbody>
                        </table>
                      </div>
    </Col>
 
   </Row>
            <div className="text-muted">



  
            </div>
          </div>
        </div>
        </Collapse>
      </div>
      <div className="accordion-item m-2">
        <h2 className="accordion-header" id="headingTwo">
          <button
            className={classnames(
              "accordion-button",
              "fw-medium",
              { collapsed: !col5 }
            )}
            type="button"
            onClick={t_col5}
            style={{ cursor: "pointer" }}
          >
            PROFESSIONAL IDENTITY
          </button>
        </h2>

        <Collapse isOpen={col5} className="accordion-collapse">
          <div className="accordion-body">
            <div className="text-muted">
         
            <div className="table-responsive">
                        <table className="table table-nowrap align-middle mb-0">
                          <tbody>
                          {
                              proIdentity && proIdentity.map((item, index) => (
                                <tr >
                                  <td style={{ width: "40px" }}>
                                    <div className="form-check font-size-16">
                                      <input className="form-check-input" name={`${item.doc_name}`}  checked={item.checked} onClick={(e)=>handleCheckbox(e, 'proIdentity')} type="checkbox" id="upcomingtaskCheck01" />
                                      <label className="form-check-label" htmlFor="upcomingtaskCheck01"></label>
                                    </div>
                                  </td>
                                  <td>
                                    <h5 className="text-truncate font-size-14 m-0"><Link to="#" className="text-dark">{item.doc_name}</Link></h5>
                                  </td>
                               
                                </tr>

                              ))
                            }
                       
                          </tbody>
                        </table>
                      </div>

            </div>
          </div>
        </Collapse>
      </div>

      <div className="accordion-item m-2">
        <h2 className="accordion-header" id="headingThree">
          <button
            className={classnames(
              "accordion-button",
              "fw-medium",
              { collapsed: !col3 }
            )}
            type="button"
            onClick={t_col3}
            style={{ cursor: "pointer" }}
          >
            EMPLOYMENT
          </button>
        </h2>

        <Collapse isOpen={col3} className="accordion-collapse">
          <div className="accordion-body">
            <div className="text-muted">
         
            <div className="table-responsive">
                        <table className="table table-nowrap align-middle mb-0">
                          <tbody>
                    
                 

                                {
                              employment && employment.map((item, index) => {

                                if(item.doc_name == 'history'){

                                 return ( 
                                 <tr >
                                    <td style={{ width: "40px" }}>
                                      <div className="form-check font-size-16">
                                        <input className="form-check-input" onClick={(e)=>{
                                          let checked = e.target.checked;
                                          let data = [...employment];
                                          data.map((item, index) => {
                                            if(item.doc_name == 'history'){
                                              item.checked = checked;
                                            }
                                          })

                                          setEmployment(data);
                                        }} type="checkbox" id="upcomingtaskCheck01" />
                                        <label className="form-check-label" htmlFor="upcomingtaskCheck01"></label>
                                      </div>
                                    </td>
                                    <td>
                                      <h5 className="text-truncate font-size-14 m-0 mb-2"><Link to="#" className="text-dark">{item.doc_name}</Link></h5>

                                      <div className="d-flex">

<div className="d-flex" style={{justifyContent:'space-between'}}>
<div className="form-check mb-3 me-3">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="exampleRadios1"
                            id="exampleRadios7"
                            value="all"
                            defaultChecked
                            onChange={(e)=>{
                              let value = e.target.value;
                              let data = {...state};
                              data.history_type_emp = value;
                              setState(data);
                            }
                          }
                            
                          />
                          <label
                            className="form-check-label"
                            htmlFor="exampleRadios7"
                          >
                            All
                          </label>
                        </div>

                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="exampleRadios1"
                            id="exampleRadios2"
                            value="custom"
                            onChange={(e)=>{
                              let value = e.target.value;
                              console.log('value==>',value);
                              let data = {...state};
                              data.history_type_emp = value;
                              setState(data);
                            }
                          }
                          />
                          <label
                            className="form-check-label"
                            htmlFor="exampleRadios2"
                          >
                            Custom
                          </label>

              

                        
                        </div>
                   
                     
</div>

                                    </div> 
                                    <div className="d-flex">
                          <div>
                                      
                                      <label
                        htmlFor="example-date-input"
                        className="col-md-2 col-form-label p-0"
                      >
                        From
                      </label>
                      <div className="col-md-10">
                        <Input
                          className="form-control"
                          type="date"
                          disabled={state.history_type_emp == 'all' ? true : false}
                        
                          onChange={(e)=>setEmpHistory({...empHistory, from:e.target.value}) }
                   value={empHistory.from}
                          id="example-date-input"
                        />
                      </div>
                                      </div>
  
                                      <div>
                                      <label
                        htmlFor="example-date-input"
                        className="col-md-2 col-form-label p-0"
                      >
                        To
                      </label>
                      <div className="col-md-10">
                        <input
                          className="form-control"
                          type="date"
                          disabled={state.history_type_emp == 'all' ? true : false}
                          onChange={(e)=>setEmpHistory({...empHistory, to:e.target.value})}
                          value={empHistory.to}
                          id="example-date-input"
                        />
                      </div>
                                      </div>
                            </div>
                                    </td>
                                 
                                
                                  
                                </tr>

                                 )
                               
                                }else{

                                  return (
                                    <tr >
                                    <td style={{ width: "40px" }}>
                                      <div className="form-check font-size-16">
                                        <input className="form-check-input" name={`${item.doc_name}`} onClick={(e)=>{
                                          let checked = e.target.checked;
                                          let name = e.target.name;
                                          console.log('checked==>',checked, name);

                                          let data = [...employment];
                                      
                                          let idx = data.findIndex((item2, index) => item2.doc_name == item.doc_name);

                                          console.log('data==>',data);

                                          data[idx].checked = checked;

                                          setEmployment(data);
                                        }} type="checkbox" id="upcomingtaskCheck01" />
                                        <label className="form-check-label" htmlFor="upcomingtaskCheck01"></label>
                                      </div>
                                    </td>
                                    <td>
                                      <h5 className="text-truncate font-size-14 m-0"><Link to="#" className="text-dark">{item.doc_name}</Link></h5>
                                    </td>
                                 
                                  </tr>
                                  )

                                }

                            

})

                            }


                                
                       
                       
                          </tbody>
                        </table>
                      </div>

            </div>
          </div>
        </Collapse>
      </div>

      <div className="accordion-item m-2">
        <h2 className="accordion-header" id="headingFour">
          <button
            className={classnames(
              "accordion-button",
              "fw-medium",
              { collapsed: !col2 }
            )}
            type="button"
            onClick={t_col2}
            style={{ cursor: "pointer" }}
          >
            EDUCATION
          </button>
        </h2>

        <Collapse isOpen={col2} className="accordion-collapse">
          <div className="accordion-body">
            <div className="text-muted">

            <div className="table-responsive">
                        <table className="table table-nowrap align-middle mb-0">
                          <tbody>
                 
                          {
                              education && education.map((item, index) => {


                                 return ( 
                                 <tr >
                                    <td style={{ width: "40px" }}>
                                      <div className="form-check font-size-16">
                                        <input className="form-check-input" onClick={
                                          (e)=>{
                                          let checked = e.target.checked;
                                          let data = [...education];
                                          data.map((item, index) => {
                                            if(item.doc_name == 'history'){
                                              item.checked = checked;
                                            }
                                          })

                                          setEducation(data);
                                        }} type="checkbox" id="upcomingtaskCheck01" />
                                      
                                        <label className="form-check-label" htmlFor="upcomingtaskCheck01"></label>
                                      </div>
                                    </td>
                                    <td>
                                      <h5 className="text-truncate font-size-14 m-0 mb-2"><Link to="#" className="text-dark">{item.doc_name}</Link></h5>

                                      <div className="d-flex">

<div className="d-flex" style={{justifyContent:'space-between'}}>
<div className="form-check mb-3 me-3">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="exampleRadios"
                            id="exampleRadios1"
                            value="all"
                            onChange={(e)=>{
                              let value = e.target.value;
                              console.log('value==>',value);
                              let data = {...state};
                              data.history_type_edu = value;
                              setState(data);
                            }
                          }
                            defaultChecked
                          />
                          <label
                            className="form-check-label"
                            htmlFor="exampleRadios1"
                          >
                            All
                          </label>
                        </div>

                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="radio"
                            
                            name="exampleRadios"
                            id="exampleRadios4"
                            onChange={(e)=>{
                              let value = e.target.value;
                              console.log('value==>',value);
                              let data = {...state};
                              data.history_type_edu = value;
                              setState(data);
                            }
                          }
                            value="custom"
                          />
                          <label
                            className="form-check-label"
                            htmlFor="exampleRadios4"
                          >
                            Custom
                          </label>


                        
                        </div>
                     
</div>

                                    </div> 
                                    
                          <div className="d-flex">
                          <div>
                                      
                                      <label
                        htmlFor="example-date-input"
                        className="col-md-2 col-form-label p-0"
                      >
                        From
                      </label>
                      <div className="col-md-10">
                        <Input
                          className="form-control"
                          type="date"
                          disabled={state.history_type_edu == 'all' ? true : false}
                          onChange={(e)=>setEduHistory({...eduHistory, from:e.target.value}) }
                          value={eduHistory.from}
                          id="example-date-input"
                        />
                      </div>
                                      </div>
  
                                      <div>
                                      <label
                        htmlFor="example-date-input"
                        className="col-md-2 col-form-label p-0"
                      >
                        To
                      </label>
                      <div className="col-md-10">
                        <Input
                          className="form-control"
                          type="date"
                          disabled={state.history_type_edu == 'all' ? true : false}
                          onChange={(e)=>setEduHistory({...eduHistory, to:e.target.value})}
                          value={eduHistory.to}
                          id="example-date-input"
                        />
                      </div>
                                      </div>
                            </div>
                                    </td>
                                 
                                
                                  
                                </tr>

                                 )
                               
                                

                            

})

                            }

                          </tbody>
                        </table>
                      </div>
         
            {/* <h5 className="text-truncate font-size-14 m-0"><Link to="#" className="text-dark"></Link></h5> */}
                                    

            </div>
          </div>
        </Collapse>
      </div>


      <div className="accordion-item m-2">
        <h2 className="accordion-header" id="headingTwo">
          <button
            className={classnames(
              "accordion-button",
              "fw-medium",
              { collapsed: !col6 }
            )}
            type="button"
            onClick={t_col6}
            style={{ cursor: "pointer" }}
          >
            CREDIT SCORE
          </button>
        </h2>

        <Collapse isOpen={col6} className="accordion-collapse">
          <div className="accordion-body">
            <div className="text-muted">
         
            <div className="table-responsive">
                        <table className="table table-nowrap align-middle mb-0">
                          <tbody>
                       
                                <tr >
                                  <td style={{ width: "40px" }}>
                                    <div className="form-check font-size-16">
                                      <input className="form-check-input" name={`credit_score`}  checked={creditScore} onClick={(e)=>setCreditScore(e.target.checked)} type="checkbox" id="upcomingtaskCheck01" />
                                      <label className="form-check-label" htmlFor="upcomingtaskCheck01"></label>
                                    </div>
                                  </td>
                                  <td>
                                    <h5 className="text-truncate font-size-14 m-0"><Link to="#" className="text-dark">Credit Score</Link></h5>
                                  </td>
                               
                                </tr>

                       
                          </tbody>
                        </table>
                      </div>

            </div>
          </div>
        </Collapse>
      </div>
    </div>
  </div>
  </CardBody>
</Card>



</Col>

<Col className="col-12 d-flex" style={{justifyContent:'center'}}>
<div style={{display:'flex', justifyContent:'center', alignSelf:'center', width:'70%'}}>

             
  </div>

</Col>
            
          </Row>
        </Container>
      </div>
      

    </React.Fragment>
  )
}

const style = {
  heading:{
    fontSize:14,
    letterSpacing:0.5,
    fontWeight:600,
    lineHeight:1.5,
 
    color:'#444'
  },
  subHeading:{
    fontSize:14,
    letterSpacing:0.5,
    fontWeight:600,
    lineHeight:1.5,
 
    color:'grey'
  }
}

export default Search
