import React, { useEffect } from 'react';
import {View, StyleSheet, Text, Image, Link } from '@react-pdf/renderer';
import InvoiceTableHeader from './InvoiceTableHeader'
import InvoiceTableRow from './InvoiceTableRow'
import InvoiceTableBlankSpace from './InvoiceTableBlankSpace'
import InvoiceTableFooter from './InvoiceTableFooter'
import Aadhaar from '../../aadhaar.png'
// import Logo from '../../report_images/trnlogo-cropped.png'

const tableRowsCount = 11;
const borderColor = '#061c33'

const styles = StyleSheet.create({
  tableContainer: {
      flexDirection: 'row',
      flexWrap: 'wrap',
      marginTop: 24,

      padding:20,
      paddingVertical:8
    
     
  },
  tableText:{
    fontSize: 10,
    fontFamily:'Montserrat-Light',
    width: '100%',
  },
  container: {
  marginTop: 24,
},
  text:{
    fontSize: 13,
    color: '#061c33',
    lineHeight:1,
    textAlign: 'center',
    fontFamily:'Montserrat',
  
  },
  box: {
    width: '100%', // Specify the width of the box
    height: 24, // Specify the height of the box
    backgroundColor: '#d4ddf1',
    justifyContent: 'center', // Center horizontally
    alignItems: 'center', // Center vertically
    margin: 'auto', // Center the box itself if within a larger container
  },
  table: {
    display: "table",
    width: "auto",
    borderStyle: "solid",
    borderWidth: 0.8,
    borderColor: '#bfbfbf',
    borderRightWidth: 0,
    borderBottomWidth: 0,
  },
  row: {
    flexDirection: "row",
    width: "100%",
  },
  col: {
    borderStyle: "solid",
    height:'auto',
     padding:5,

    borderWidth: 0.8,
 
    borderColor: '#bfbfbf',
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },
  profile:{
    width:'100%',
    display: "flex",
    marginTop:10,
    flexDirection: "row",
    justifyContent: "space-between",
   
   
  },
  imageBox:{
    width:'20%',
    height:100,
    alignSelf:'center',

    justifyContent: 'center', // Center horizontally
    alignItems: 'center', // Center vertically
    margin: 'auto', // Center the box itself if within a larger container
  }
});


const doc_names = {
  "aadhaar":"Aadhaar Card",
  "pan":"PAN Card",
  "passport":"Passport",
  "doctor":"Doctor",
  "nurse":"Nurse",
  "voter_id":"Voter ID",
}



  const CreditScore = ({invoice, data, colors, caseData, logo, requestedData}) => {

    const [components, setComponents] = React.useState([]);

    const [doc, setDoc] = React.useState({});

    useEffect(() => {

      console.log('credit score requestedData==>',data);

      // setDoc(data);

    }, []);


    return (

<View style={styles.tableContainer} >

<View style={styles.box}>
  <Text  style={styles.text}>Credit Score</Text>
</View>

<View style={styles.profile}>

{/* image section */}

{/* <View style={styles.imageBox}>

<Image src={logo} style={{width: 80, alignSelf:'flex-start'}} />

</View> */}


{/* table */}
<View style={[styles.table, {width:'100%'}]}>
<View style={styles.row}>
          <View style={[styles.col, {width:'35%'}]}>
            <Text >Pan number</Text>
            </View>
            <View style={[styles.col, {width:'65%'}]}>
            <Text style={styles.tableText}>{data?.pan}</Text>
            </View>
    </View>
    <View style={styles.row}>
          <View style={[styles.col, {width:'35%'}]}>
            <Text >Name</Text>
            </View>
            <View style={[styles.col, {width:'65%'}]}>
            <Text style={styles.tableText}>{data?.name}</Text>
            </View>
    </View>
    <View style={styles.row}>
          <View style={[styles.col, {width:'35%'}]}>
            <Text >Mobile</Text>
            </View>
            <View style={[styles.col, {width:'65%'}]}>
            <Text style={styles.tableText}>{data?.mobile}</Text>
            </View>
    </View>

    <View style={styles.row}>
          <View style={[styles.col, {width:'35%'}]}>
            <Text >Credit Score</Text>
            </View>
            <View style={[styles.col, {width:'65%'}]}>
            <Text style={styles.tableText}>{data?.credit_score}</Text>
            </View>
    </View>
  
    <View style={styles.row}>
          <View style={[styles.col, {width:'35%', 
        }]}>
            <Text >Status</Text>
            </View>
            <View style={[styles.col, {width:'65%', 
          flexDirection:'row',
          flexWrap:'wrap',
          backgroundColor:colors[caseData?.colorcode]?.backgroundColor,
          }]}>
            <Text style={[styles.tableText, {
              color: colors[caseData?.colorcode]?.color
            }]}>
              {caseData?.colorcode}
</Text>
            </View>
    </View>


    <View style={styles.row}>
          <View style={[styles.col, {width:'35%', 
        }]}>
            <Text >Deposition Remarks</Text>
            </View>
            <View style={[styles.col, {width:'65%', 
          flexDirection:'row',
          flexWrap:'wrap'

          }]}>
            <Text style={styles.tableText}>
              {caseData?.reason}
</Text>
            </View>
    </View>

    <View style={styles.row}>
          <View style={[styles.col, {width:'35%', 
        }]}>
            <Text >Credit report link</Text>
            </View>
            <View style={[styles.col, {width:'65%', 
          flexDirection:'row',
          flexWrap:'wrap'

          }]}>

<Link src={data?.credit_report_link} target={"_blank"}>{"View"}</Link>
   
            </View>
    </View>

    <View style={styles.row}>
          <View style={[styles.col, {width:'35%', 
        }]}>
            <Text >Comment</Text>
            </View>
            <View style={[styles.col, {width:'65%', 
          flexDirection:'row',
          flexWrap:'wrap'

          }]}>
            <Text style={styles.tableText}>
              {caseData?.comment}
</Text>
            </View>
    </View>
  
  </View>
</View>



</View>
    )
  };
  
  export default CreditScore