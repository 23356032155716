import React, { useEffect } from "react"
import ReactApexChart from "react-apexcharts"
import getChartColorsArray from "../../components/Common/ChartsDynamicColor";
import { set } from "lodash";

const DumbelHorizontalChart = ({dataColors, data}) => {
  const DumbelHorizontalChartColors = getChartColorsArray(dataColors);

const [series, setSeries] = React.useState([{
    data: []
}]);


  const options = {    
    chart: {    
        zoom: {
            enabled: false
        }
    },
    colors: DumbelHorizontalChartColors,
    plotOptions: {
        bar: {
            horizontal: true,
            isDumbbell: true,
            dumbbellColors: DumbelHorizontalChartColors
        }
    },
    title: {
        text: 'Case Breakup'
    },
    legend: {
        show: false,
        showForSingleSeries: true,
        position: 'top',
        horizontalAlign: 'left',
        customLegendItems: ['Female', 'Male']
    },
    fill: {
        type: 'gradient',
        gradient: {
            gradientToColors: ['#36BDCB'],
            inverseColors: false,
            stops: [0, 100]
        }
    },
    grid: {
        xaxis: {
            lines: {
                show: true
            }
        },
        yaxis: {
            lines: {
                show: false
            }
        }
    }
};

useEffect(() => {

    console.log('total cases',data);

    if(data.length > 0) {
        const wip = data && data.filter((item) => item.verification_status === 'WIP');

        const notificationSent = data.filter((item) => item.verification_status === 'Notification sent');
    
        const notificationCancelled = data.filter((item) => item.verification_status === 'Notification cancelled');
    
        const notificationExpired = data.filter((item) => item.verification_status === 'Notification expired');
    
        const candidateApproved = data.filter((item) => item.verification_status === 'Candidate approved');
    
        const candidateRejected = data.filter((item) => item.verification_status === 'Candidate rejected');
    
        const partiallyShared = data.filter((item) => item.verification_status === 'Partially shared');
    
        const reVerification = data.filter((item) => item.verification_status === 'Re-verification');
    
        const clientApproved = data.filter((item) => item.verification_status === 'Approved');
    
        const dispatchDone = data.filter((item) => item.verification_status === 'Dispatch Done');
    
        const complete = data.filter((item) => item.verification_status === 'Complete');
    
    
    
        series[0].data = [
            {
                x: 'WIP',
                y: [0, wip.length]
            },
            {
                x: 'Notification sent',
                y: [0, notificationSent.length]
            },
            {
                x: 'Notification cancelled',
                y: [0, notificationCancelled.length]
            },
            {
                x: 'Notification expired',
                y: [0, notificationExpired.length]
            },
            {
                x: 'Candidate approved',
                y: [0, candidateApproved.length]
            },
            {
                x: 'Candidate rejected',
                y: [0, candidateRejected.length]
            },
            {
                x: 'Partially shared',
                y: [0, partiallyShared.length]
            },
            {
                x: 'Re-verification',
                y: [0, reVerification.length]
            },
            {
                x: 'Client approved',
                y: [0, clientApproved.length]
            },
            {
                x: 'Dispatch Done',
                y: [0, dispatchDone.length]
            },
            {
                x: 'Complete',
                y: [0, complete.length]
            },
          
        ]
    
        console.log('series==>',series);
    
        setSeries(series);
    }



}

, [data]);




  return (
    <>
        <ReactApexChart options={options} series={series} type="rangeBar" height="390" />
</>
  )
}

export default DumbelHorizontalChart
