


import React, { useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { isEmpty } from "lodash";
import "bootstrap/dist/css/bootstrap.min.css";
import TableContainer from "../components/Common/TableContainerVerify";
import * as Yup from "yup";
import { useFormik } from "formik";
import Papa from 'papaparse'
import axios from 'axios';


import * as XLSX from 'xlsx';


import {
  Button, 
  Col,
  Row,
  UncontrolledTooltip,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  Input,
  FormFeedback,
  Label,
  Card,
  CardBody,
  Table,
} from "reactstrap";
import moment from "moment";

//Import Flatepicker
import "flatpickr/dist/themes/material_blue.css";
import Flatpickr from "react-flatpickr";

function Verify() {
  //meta title
  document.title = "TheRollNumber";

  const navigate = useNavigate();

  const location = useLocation();


  const [modal, setModal] = useState(false);
  const [modal1, setModal1] = useState(false);
  const [isEdit, setIsEdit] = useState(false);

  const [orderList, setOrderList] = useState([]);
  const [order, setOrder] = useState(null);
  const [data, setData] = useState([]);




  const toggleViewModal = () => setModal1(!modal1);

  useEffect(() => {

    // const file = location.state?.zipFile;

    // console.log('verify',file);

    // handleFileUpload(file);

    fetch_Wallet_details();
    

  }, []);



  const handleFileUpload = (file) => {

    setData(file);

    
    // const reader = new FileReader();

    // reader.onload = (evt) => {
    //   // Parse data
    //   const bstr = evt.target.result;
    //   const wb = XLSX.read(bstr, { type: 'binary' });
    //   // Get first worksheet
    //   const wsname = wb.SheetNames[0];
    //   const ws = wb.Sheets[wsname];
    //   // Convert array of arrays
    //   const csvData = XLSX.utils.sheet_to_csv(ws);
    //   // Parsing CSV data with Papa Parse
    //   Papa.parse(csvData, {
    //     complete: (results) => {
    //       console.log('Parsed Results:', results.data);

    //       fetchWallet(results.data)
    //       // setData(results.data);
    //     },
    //     header: true,
    //   });
    // };

    // reader.readAsBinaryString(file);
  };


const importExcel = (file) => {

  console.log('file',file)

  Papa.parse(file, {
    complete: (results) => {
      // 'results.data' contains the parsed CSV data as an array of objects
      console.log('Parsed data:', results.data);


      // setDepCsv(results.data)

      // console.log('depCsv', results.data)

    },
    error: (error) => {
      console.log('Error:', error);
    },
    header: true, // Set this to true if your CSV file has a header row
    skipEmptyLines: true, // Skip empty lines while parsing
  });

}


  const [isLoading, setLoading] = useState('')



  const toggle = () => {
    if (modal) {
      setModal(false);
      setOrder(null);
    } else {
      setModal(true);
    }
  };

  //delete order
  const [deleteModal, setDeleteModal] = useState(false);

  const onClickDelete = (order) => {
    setOrder(order);
    // setDeleteModal(true);
  };

  const handleDeleteOrder = () => {
    if (order && order.id) {
      // dispatch(onDeleteOrder(order.id));
      // setDeleteModal(false);
      setOrder("");
    }
  };
  const handleOrderClicks = async() => {

    const body = {

      emp_id: 1,

      csvData: data

    }

    const config = {
      headers: {
        'Content-Type': 'application/json'
      }
    };
   
    axios.post(`${process.env.REACT_APP_BASE_URL}/service/wallet`, body, config)

    .then(response => {

      console.log('response from server==>',response)

      

    })

    .catch(error => {

      console.log('error from server==>',error)


    })
  };

  const fetch_Wallet_details = async () => {
    const config = {
      headers: {
        'Content-Type': 'application/json'
      }
    };
   
    axios.get(`${process.env.REACT_APP_BASE_URL}/service/wallet_details/1`, {}, config)

    .then(response => {

      console.log('response from server==>',response)

      setData(response.data)

      // Navigate('/verify', {state: {zipFile: response.data}})

      

    })

    .catch(error => {

      console.log('error from server==>',error)


    })
  }


  const get_package_details = async (wallet) => {

    console.log('package details wallet', wallet)
    

    const config = {
      headers: {
        'Content-Type': 'application/json'
      }
    };
   
    await axios.get(`${process.env.REACT_APP_BASE_URL}/service/package_detail/${wallet.packageType}`, {}, config)

    .then(async response => {

      // console.log('response from server==>',response)

     await send_bgv(response.data[0]);


    })

    .catch(error => {

      console.log('error from server==>',error)
    })

  }


  const send_bgv = async (obj) => {

    console.log('send bgv');

    // const company_location = localStorage.getItem('authUser').company_location;
  
    const config = {
      headers: {
        'Content-Type': 'application/json'
      }
    };

    let body = {
      id_identity_user: obj.id_identity_user,
      org_id: obj.org_id,
      title: obj.title,
      education: obj.education,
      employment: obj.employment,
      company_location: '',
      requester_category: obj.requester_category,
      identity: obj.identity,
      professional_identity: obj.professional_identity,
      req_data: obj.req_data,
      requester_fcm_token:"23423f32423423",
      body:"Request for documents"
  
      
  }
  
  
  
  // console.log('body==>',body);
  
  // https://trnapi.therollnumber.com
  
   await axios.post(`${process.env.REACT_APP_BASE_URL}/service/request_identity_org`, body, config)
    .then(response => {
      console.log('bgv response',response);
  
    })
    .catch(error => {
  
      console.log('error==>',error);
    });
  
  }

  const columns = useMemo(
    () => [
   
      {
        Header: "Wallet ID",
        accessor: "walletID",
        width: "150px",
        style: {
          textAlign: "center",
          width: "10%",
          background: "#0000",
        },
        filterable: true,
        Cell: (cellProps) => {
          return cellProps.cell.row.original.search_status == 'Found'?<p style={{color:'green'}}>{cellProps.value}</p>: <p style={{color:'red'}}>{cellProps.value}</p>;
        },
      },
      {
        Header: "Package Name",
        accessor: "packageType",
        filterable: true,
        Cell: (cellProps) => {
          return cellProps.value;
        },
      },
      {
        Header: "Candidate Name",
        accessor: "candidate_name",
        filterable: true,
        Cell: (cellProps) => {

          // console.log('cellProps',cellProps)

          return cellProps.value?cellProps.value:'--';
        },
      },
      {
        Header: "Email",
        accessor: "email",
        filterable: true,
        Cell: (cellProps) => {

          // console.log('cellProps',cellProps)

          return cellProps.value;
        },
      },

      {
        Header: "Search Status",
        accessor: "search_status",
        filterable: true,
        Cell: (cellProps) => {
          return cellProps.value;
        },
      },
      {
        Header: "Verification Status",
        accessor: "package",
        filterable: true,
        Cell: (cellProps) => {
          return "";
        },
      },
      {
        Header: "Timestamp",
        accessor: "timestamp",
        filterable: true,
        Cell: (cellProps) => {
          return moment(cellProps.value).format("DD-MM-YYYY hh:mm A");
        },
      },
      {
        Header: "Action",
        accessor: "action",
        disableFilters: true,
        Cell: (cellProps) => {
          return (
            <div className="d-flex gap-3">
              <Link
                to="#"
                className="text-success"
                onClick={() => {
                  const orderData = cellProps.row.original;
                  // handleOrderClick(orderData);
                }}
              >
                <i className="mdi mdi-pencil font-size-18" id="edittooltip" />
                <UncontrolledTooltip placement="top" target="edittooltip">
                  Edit
                </UncontrolledTooltip>
              </Link>
              <Link
                to="#"
                className="text-danger"
                onClick={() => {
                  const orderData = cellProps.row.original;
                  // onClickDelete(orderData);
                }}
              >
                <i className="mdi mdi-delete font-size-18" id="deletetooltip" />
                <UncontrolledTooltip placement="top" target="deletetooltip">
                  Delete
                </UncontrolledTooltip>
              </Link>
            </div>
          );
        },
      },



    ],
    []
  );

  const bulk_request = async() => {

    let promises = []

    console.log('promise');

   let arr = data.map((item, index) => {

      let promise = new Promise(async(resolve, reject) => {

      if(item.search_status == 'Found'){

try {

  await get_package_details(item);

  resolve();
  
} catch (error) {

  console.log('error',error);
  reject();

}

      }else{
        resolve();
      }


    })


    promises.push(promise);

    })

    await Promise.all(promises);
    console.log('done');
    navigate('/client/cases')


  }

  return (
    <React.Fragment>
      {/* <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteOrder}
        onCloseClick={() => setDeleteModal(false)}
      /> */}
      <div className="page-content">
        <div className="container-fluid">
          {/* <Breadcrumbs title="Ecommerce" breadcrumbItem="Orders" /> */}
          <Row>
            {
            
                <Col xs="12">
                  <Card>
                    <CardBody>
                      <h2 className="card-title mb-4">Verify</h2>
                      <TableContainer
                        columns={columns}
                        data={data}
                        isGlobalFilter={true}
                        isAddOptions={true}
                        handleOrderClicks={handleOrderClicks}
                        customPageSize={10}
                        isPagination={true}
                        filterable={false}
                        iscustomPageSizeOptions={true}
                        tableClass="align-middle table-nowrap table-check"
                        theadClass="table-light"
                        pagination="pagination pagination-rounded justify-content-end mb-2"
                      />

                      <button onClick={()=>navigate('/cases')} className="btn btn-primary">
                        Proceed to Verify
                      </button>
                    </CardBody>
                  </Card>
                </Col>
            }
          </Row>
     
        </div>
      </div>

    </React.Fragment>
  );
}


export default Verify;
