import React, { Fragment } from 'react';
import {Text, View, StyleSheet } from '@react-pdf/renderer';
import moment from 'moment';

const styles = StyleSheet.create({
    invoiceNoContainer: {
        flexDirection: 'row',
        marginTop: 36,
        justifyContent: 'flex-end'
    },
    invoiceDateContainer: {
        flexDirection: 'row',
        marginTop:20,
        justifyContent: 'flex-start'
    },
    invoiceDate: {
            fontSize: 12,
            fontStyle: 'bold',
            color: '#fff'
    },
    label: {
        
        fontFamily:'Montserrat-Light',
        fontSize:13,
        color:'#fff'
    }
    
  });

  // show date in this format 29 Feb 2024 using moment






  const InvoiceNo = ({invoice, data}) => (
        <Fragment>


            <View style={{flexDirection:'row', width:'100%', justifyContent:'space-around', marginTop:40}}>
            <View style={{}}>
                <Text style={styles.label}>Case Initiated On </Text>
                <Text style={{
                    color:'#fff', 
                    fontFamily:'Montserrat-Light',
                    fontSize: 13,
                }}>{moment(data.timestamp).format('DD MMM YYYY')}</Text>
            </View >
            <View style={{}}>
                <Text style={styles.label}>Case Closed On </Text>
                <Text style={{
                    color:'#fff', 
                    fontSize: 13,
                    fontFamily:'Montserrat-Light'
                }}>--</Text>
            </View >
            </View>
          
        </Fragment>
  );
  
  export default InvoiceNo