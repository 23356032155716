import React, { useEffect, useMemo } from "react";
import PropTypes from "prop-types";
import { Link, useNavigate } from "react-router-dom";
import {
    Card, CardBody, Col, Container, Row, Table,
    Modal,
    ModalHeader,
    ModalBody,
    Label,
    Input,
    Form,
    FormGroup,
UncontrolledTooltip,
    Nav,
    NavItem,
    NavLink,
    TabContent,
    TabPane, CardTitle


} from "reactstrap";
import { isEmpty, map, set } from "lodash";
import Dropzone from "react-dropzone";
import * as XLSX from 'xlsx';
import Papa from 'papaparse';
import axios from 'axios';
import Loader from "../../../components/Loader";

import classnames from "classnames";

import TableContainer from "../../../components/Common/TableContainerClient";
import moment from "moment";
import AddRates from "./AddRates";



const ListRates = (props) => {
    //meta title
    document.title =
        "TheRollNumber";


    const [data, setData] = React.useState([]);

    const [loading, setLoading] = React.useState(false);

    const [data_columns, setDataColumns] = React.useState([]);

    const [modal, setModal] = React.useState(false);

    const toggle = () => setModal(!modal);


    const Navigate = useNavigate();

    useEffect(() => {

        getListRates();

    }, []);



    const getListRates = async () => {

      const id = JSON.parse(localStorage.getItem('authUser')).user.org_id;

   
      console.log('packages');
    
      const config = {
        headers: {
          'Content-Type': 'application/json'
        }
      };
  

     await axios.get(`${process.env.REACT_APP_BASE_URL}/service/rates/${id}`, {}, config)
      .then(response => {
        console.log('rates response',response);


        const res = response.data.data;
  
  setData(response.data.data);

  let col = generateColumns(res[0]);
  console.log('col',col);

  setDataColumns(col);


      })
      .catch(error => {
    
        console.log('error==>',error);
      });
    
    }

    const generateColumns = (arr) => {
      return [...Object.keys(arr).map((key, value) => {
        let columnDefinition = {
          Header: arr[key].component_name,
          accessor: key,
          filterable: true,
          Cell: (cellProps) => {
      
            console.log('cellProps',cellProps)
      
            return <div style={{ minWidth: 100, maxWidth: 300}}>
              Rs. {cellProps.value.rate}
            </div>;
          },
        }
  

  
        return columnDefinition;
      })];
    };

    const columns = useMemo(
        () => [
       
          {
            Header: "W.E.F",
            accessor: "wef",
            width: "150px",
            style: {
              textAlign: "center",
              width: "10%",
              background: "#0000",
            },
            filterable: true,
            Cell: (cellProps) => {
              return <div style={{width:100}}>
                {moment(cellProps.value).format('DD-MM-YYYY')}
              </div>;
            },
          },
          {
            Header: "W.E.T",
            accessor: "wet",
            filterable: true,
            Cell: (cellProps) => {
              return <div style={{width:100}}>
                {moment(cellProps.value).format('DD-MM-YYYY')}
              </div>;
            },
          },
          {
            Header: "Education",
            accessor: "education",
            filterable: true,
            Cell: (cellProps) => {
    
              // console.log('cellProps',cellProps)
    
              return <div>
                Rs. {cellProps.value}
              </div>;
            },
          },
        
          {
            Header: "Address",
            accessor: "address",
            filterable: true,
            Cell: (cellProps) => {
    
              // console.log('cellProps',cellProps)
    
              return <div>
              Rs. {cellProps.value}
            </div>;
            },
          },

          {
            Header: "Court(Criminal)",
            accessor: "court",
            filterable: true,
            Cell: (cellProps) => {
    
              // console.log('cellProps',cellProps)
    
              return <div>
              Rs. {cellProps.value}
            </div>;
            },
          },


          {
            Header: "Police (Criminal)",
            accessor: "police",
            filterable: true,
            Cell: (cellProps) => {
    
              // console.log('cellProps',cellProps)
    
              return <div>
              Rs. {cellProps.value}
            </div>;
            },
          },

          {
            Header: "Employment",
            accessor: "employment",
            filterable: true,
            Cell: (cellProps) => {
    
              return <div>
              Rs. {cellProps.value}
            </div>;
            },
          },

          {
            Header: "Reference",
            accessor: "reference",
            filterable: true,
            Cell: (cellProps) => {
    
              return <div>
              Rs. {cellProps.value}
            </div>;
            },
          },

          {
            Header: "Database",
            accessor: "data_base",
            filterable: true,
            Cell: (cellProps) => {
    
              return <div>
              Rs. {cellProps.value}
            </div>;
            },
          },

          {
            Header: "Identity",
            accessor: "identity",
            filterable: true,
            Cell: (cellProps) => {
    
              return <div>
              Rs. {cellProps.value}
            </div>;
            },
          },

          {
            Header: "Passport",
            accessor: "passport",
            filterable: true,
            Cell: (cellProps) => {
    
              return <div>
              Rs. {cellProps.value}
            </div>;
            },
          },


          {
            Header: "Driving License",
            accessor: "dl",
            filterable: true,
            Cell: (cellProps) => {
    
              return <div>
              Rs. {cellProps.value}
            </div>;
            },
          },

          {
            Header: "Drug",
            accessor: "drug",
            filterable: true,
            Cell: (cellProps) => {
    
              return <div>
              Rs. {cellProps.value}
            </div>;
            },
          },

          
          {
            Header: "Directorship",
            accessor: "directorship",
            filterable: true,
            Cell: (cellProps) => {
    
              return <div>
              Rs. {cellProps.value}
            </div>;
            },
          },

          {
            Header: "Repudiation",
            accessor: "repudiation",
            filterable: true,
            Cell: (cellProps) => {
    
              return <div>
              Rs. {cellProps.value}
            </div>;
            },
          },

          {
            Header: "Credit",
            accessor: "credit",
            filterable: true,
            Cell: (cellProps) => {
    
              return <div>
              Rs. {cellProps.value}
            </div>;
            },
          },

          {
            Header: "Global Database Check SW",
            accessor: "globalDatabaseChecksw",
            filterable: true,
            Cell: (cellProps) => {
    
              return <div>
              Rs. {cellProps.value}
            </div>;
            },
          },

          {
            Header: "Adverse Media",
            accessor: "adverseMediaCheck",
            filterable: true,
            Cell: (cellProps) => {
    
              return <div>
              Rs. {cellProps.value}
            </div>;
            },
          },


          {
            Header: "Global Database Check PEP",
            accessor: "adverseMediaCheckpep",
            filterable: true,
            Cell: (cellProps) => {
    
              return <div>
              Rs. {cellProps.value}
            </div>;
            },
          },

          
          {
            Header: "Address Validation",
            accessor: "addressValidationCheck",
            filterable: true,
            Cell: (cellProps) => {
    
              return <div>
              Rs. {cellProps.value}
            </div>;
            },
          },

          
          {
            Header: "Company Credit Report",
            accessor: "companyCreditReportCheck",
            filterable: true,
            Cell: (cellProps) => {
    
              return <div>
              Rs. {cellProps.value}
            </div>;
            },
          },

          {
            Header: "CV Reconciliation",
            accessor: "cvReconciliationCheck",
            filterable: true,
            Cell: (cellProps) => {
    
              return <div>
              Rs. {cellProps.value}
            </div>;
            },
          },

          {
            Header: "Doc Certificate Verification",
            accessor: "docCertificateVerificationCheck",
            filterable: true,
            Cell: (cellProps) => {
    
              return <div>
              Rs. {cellProps.value}
            </div>;
            },
          },
          {
            Header: "Finger Priting Check",
            accessor: "fingerPritingCheck",
            filterable: true,
            Cell: (cellProps) => {
    
              return <div>
              Rs. {cellProps.value}
            </div>;
            },
          },
          {
            Header: "Gap Verification",
            accessor: "gapVerificationCheck",
            filterable: true,
            Cell: (cellProps) => {
    
              return <div>
              Rs. {cellProps.value}
            </div>;
            },
          },
          {
            Header: "5 Panel Drug Test",
            accessor: "panel5DrugTest",
            filterable: true,
            Cell: (cellProps) => {
    
              return <div>
              Rs. {cellProps.value}
            </div>;
            },
          },


          
        
          {
            Header: "7 Panel Drug Test",
            accessor: "panel7DrugTest",
            filterable: true,
            Cell: (cellProps) => {
    
              return <div>
              Rs. {cellProps.value}
            </div>;
            },
          },
        
          {
            Header: "9 Panel Drug Test",
            accessor: "panel9DrugTest",
            filterable: true,
            Cell: (cellProps) => {
    
              return <div>
              Rs. {cellProps.value}
            </div>;
            },
          },

          {
            Header: "10 Panel Drug Test",
            accessor: "panel10DrugTest",
            filterable: true,
            Cell: (cellProps) => {
    
              return <div>
              Rs. {cellProps.value}
            </div>;
            },
          },

          {
            Header: "11 Panel Drug Test",
            accessor: "panel11DrugTest",
            filterable: true,
            Cell: (cellProps) => {
              return <div>
              Rs. {cellProps.value}
            </div>;
            },
          },

          {
            Header: "12 Panel Drug Test",
            accessor: "panel12DrugTest",
            filterable: true,
            Cell: (cellProps) => {
    
              return <div>
              Rs. {cellProps.value}
            </div>;
            },
          },

    
        ],
        []
      );

    const handleOrderClicks = () => {
      toggle();
    };


    return (
        <React.Fragment>


<Modal isOpen={modal} centered={true} size="xl" toggle={toggle}>
            <ModalHeader toggle={toggle} tag="h4">
              Add Rates
            </ModalHeader>  
            <ModalBody>
              <AddRates />
            </ModalBody>
          </Modal>

            <Card>
                <CardBody>
                    <p className="h4 mb-4">List Rates</p>

                    <TableContainer
                        columns={data_columns}
                        data={data}
                        isGlobalFilter={false}
                        component_name="rates"
                        isAddOptions={true}
                        handleOrderClicks={handleOrderClicks}
                        customPageSize={10}
                        isPagination={true}
                        filterable={false}
                        iscustomPageSizeOptions={true}
                        tableClass="align-middle table-nowrap table-check"
                        theadClass="table-light"
                        pagination="pagination pagination-rounded justify-content-end mb-2"
                      />


                </CardBody>
            </Card>

        </React.Fragment>
    );
};


export default ListRates;

const styles = {

    para: {
        fontSize: 12
    },
    box: {
        fontSize: 16,
        fontWeight: "unset",
        justifyContent: "space-between",
        alignItems: "center",
    },
    boxLabel: {
        fontSize: 13,
        fontWeight: "400",
    },
    boxInput: {
        fontSize: 13,
        width: '70%',
        padding: 8,
        fontWeight: "unset",
    }

}
