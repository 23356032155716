


import React, { useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import { Link, useNavigate } from "react-router-dom";
import { ceil, isEmpty, reject, set } from "lodash";
import "bootstrap/dist/css/bootstrap.min.css";
import TableContainer from "../../components/Common/TableContainerNewCasesByClient";
import * as Yup from "yup";
import { useFormik } from "formik";
import axios from "axios";
import Cookies from "js-cookie";
import Loader from "../../components/Loader";




import {
  Button,
  Col,
  Row,
  UncontrolledTooltip,
  
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  Input,
  FormFeedback,
  Label,
  Card,
  CardBody,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,


  Table,
} from "reactstrap";
import moment from "moment";

import classnames from "classnames";

//Import Flatepicker
import "flatpickr/dist/themes/material_blue.css";
import Flatpickr from "react-flatpickr";

function LeaderCases() {
  //meta title
  document.title = "TheRollNumber";

  const navigate = useNavigate();


  const [modal, setModal] = useState(false);
  const [modal1, setModal1] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [cases, setCases] = useState([]);
  const [cancelNotificationType, setCancelNotificationType] = useState('')
  const [enableCancel, setEnableCancel] = useState(false);
  // const [caseData, setCaseData] = useState({});
  const [reqData, setReqData] = useState({});


  const [orderList, setOrderList] = useState([]);
  const [order, setOrder] = useState(null);
  const [components, setComponents] = useState([]);

  const [memberCases, setMemberCases] = useState([]);

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      orderId: (order && order.orderId) || "",
      billingName: (order && order.billingName) || "",
      orderdate: (order && order.orderdate) || "",
      total: (order && order.total) || "",
      paymentStatus: (order && order.paymentStatus) || "Paid",
      badgeclass: (order && order.badgeclass) || "success",
      paymentMethod: (order && order.paymentMethod) || "Mastercard",
    },
    validationSchema: Yup.object({
      orderId: Yup.string()
        .matches(/[0-9\.\-\s+\/()]+/, "Please Enter Valid Order Id")
        .required("Please Enter Your Order Id"),
      billingName: Yup.string().required("Please Enter Your Billing Name"),
      orderdate: Yup.string().required("Please Enter Your Order Date"),
      total: Yup.string()
        .matches(/[0-9\.\-\s+\/()]+/, "Please Enter Valid Amount")
        .required("Total Amount"),
      paymentStatus: Yup.string().required("Please Enter Your Payment Status"),
      badgeclass: Yup.string().required("Please Enter Your Badge Class"),
      paymentMethod: Yup.string().required("Please Enter Your Payment Method"),
    }),
    onSubmit: (values) => {
      if (isEdit) {
        const updateOrder = {
          id: order ? order.id : 0,
          orderId: values.orderId,
          billingName: values.billingName,
          orderdate: values.orderdate,
          total: values.total,
          paymentStatus: values.paymentStatus,
          paymentMethod: values.paymentMethod,
          badgeclass: values.badgeclass,
        };
        // update order
        // dispatch(onUpdateOrder(updateOrder));
        // validation.resetForm();
      } else {
        const newOrder = {
          id: Math.floor(Math.random() * (30 - 20)) + 20,
          orderId: values["orderId"],
          billingName: values["billingName"],
          orderdate: values["orderdate"],
          total: values["total"],
          paymentStatus: values["paymentStatus"],
          paymentMethod: values["paymentMethod"],
          badgeclass: values["badgeclass"],
        };
        // save new order
        // dispatch(onAddNewOrder(newOrder));
        validation.resetForm();
      }
      toggle();
    },
  });

  const [selectedNotification, setSelectedNotification] = useState({});

  const [PTL, setPTL] = useState([])

  const toggleViewModal = () => setModal1(!modal1);

  const [activeTab, setActiveTab] = useState('1');
  const [tabData, setTabData] = useState([]);

  const toggleTab = tab => {
    if(activeTab !== tab) setActiveTab(tab);
  }

  useEffect(() => {


 getAssignedCases()

  get_components()

  getAdminUsers()


  }, []);



  const getAssignedCases = async () => {

    const user = JSON.parse(Cookies.get('authUser'))

    setLoading(true)


    let body = {
      team: 'Process Team Leader',
     
    }

    const config = {
      headers: {
        'Content-Type': 'application/json'
      }
    };
   
    axios.post(`${process.env.REACT_APP_BASE_URL}/employer/admin/processTeamCases`, body, config)

    .then(response => {

      console.log('response process leader cases==>',response)

      const data = response.data.data;

      let arr = []

     data.map((item)=> {

  
      
      arr.push({
        ...item,
        select:false
      })

    

      })

    

      setCases(arr)


    })

    .catch(error => {

      setLoading(false)

      console.log('error from server==>',error)

    })


  }

  const checkPTMCompExist = async (caseData) => {



    let body = {
      team: 'Process Team Member',
      case_id: caseData.request_id,
      // component_id: caseData.component_id
     
    }

    console.log('body ptm exist comp==>',body)

    const config = {
      headers: {
        'Content-Type': 'application/json'
      }
    };
   
    axios.post(`${process.env.REACT_APP_BASE_URL}/employer/admin/ptmcomp`, body, config)

    .then(response => {

      console.log('response ptm exist comp==>',response.data.data)

      const data = response.data.data;

      get_request_identity_org(caseData, data)

      // let isExist = response.data.data;

      // alert(isExist)


      // if(!isExist){

      //   get_request_identity_org(caseData)


      // }
   


    })

    .catch(error => {

      console.log('error from server==>',error)

    })


  }


  useEffect(() => {

    // console.log('checkPTMExist components==>',components)
    // console.log('checkPTMExist cases==>',cases)

    if (components.length > 0 && cases.length > 0){

      console.log('checkPTMExist ==>',components, cases)

      for(let item of cases) {

        checkPTMCompExist(item);
        // get_requested_data(item)

      }

    }

  }, [components, cases]);

  const get_requested_data = async (caseData) => {

    console.log('caseData',caseData);

    const config = {
      headers: {
        'Content-Type': 'application/json',

      }
    };

    const body = {

      org_user_client_id: caseData.org_client_id,
    
    }

    console.log('body requested ==>',body);

   let result =  await axios.post(`${process.env.REACT_APP_BASE_URL}/service/requestedData/${caseData.walletID}`, body, config)

   return result.data.data
 
  }



  const get_request_identity_org = async (caseData, members) => {

    const org_client_id = caseData?.org_client_id;

    console.log('org_client_id',org_client_id);

    const requested_data = await get_requested_data(caseData);

    console.log('requested_data from requested identity org ==>',requested_data);

    const config = {
      headers: {
        'Content-Type': 'application/json'
      }
    };

    axios.get(`${process.env.REACT_APP_BASE_URL}/service/request_identity_org/${org_client_id}`, {}, config)
    .then(response => {
      console.log('requested identity org response',response);

      let res = response.data.data;

      console.log('request identity data==>',res);

      if(res.length > 0) {

        let data = res[0];

      let req_data = JSON.parse(data.req_data);
      console.log('req_data==>',req_data);

      let education = requested_data.find((item)=> Object.keys(item)[0] == 'education');

      let employment = requested_data.find((item)=> Object.keys(item)[0] == 'employment');

      let tds = requested_data.find((item)=> Object.keys(item)[0] == 'tds');

      let epfo = requested_data.find((item)=> Object.keys(item)[0] == 'epfo');

      let credit_score = requested_data.find((item)=> Object.keys(item)[0] == 'credit_score');

      if(req_data.professional_identity.length > 0){
        
        let professional_identity = req_data.professional_identity;

        console.log('professional_identity==>',req_data, caseData)

        professional_identity.map((item)=> {

          components.find((component)=> {

            if(component.code === item) {

              let isExist = members.find((member)=> member.component_id === component.id)

              if(!isExist) {

              let idx =  component.cases.findIndex((caseItem)=> {
                
                if(caseItem.notification_id === caseData.notification_id) {

                  console.log('caseItem==>',caseItem)

                  return true;

                }

              
              })

              // do not push if already exists

               if(idx === -1) {

                 component.cases.push(caseData)

               }

              }

            }

          })

        })
      }

      if(credit_score){

        components.find((component)=> {

          if(component.code === 'credit_score') {

           let isExist = members.find((member)=> member.component_id === component.id)

           console.log('push case data component====>',caseData)

           if(!isExist) {

           let idx =  component.cases.findIndex((caseItem)=> {
         

             
              if(caseItem.notification_id === caseData.notification_id) {

                console.log('caseItem==>',caseItem)

                return true;

              }

            
            })

            // do not push if already exists

             if(idx === -1) {
               
               
               let unique_id = Math.floor(Math.random() * 1000000000);

               component.cases.push({
                 ...caseData,
                 unique_id: unique_id
               
               })

             }

           }
}

        })

      }

      if(req_data.identity.length > 0) {

        let identity = req_data.identity;

        console.log('identity==>',caseData, components)

         identity.map((item)=> {

           components.find((component)=> {

             if(component.code === item) {

              let isExist = members.find((member)=> member.component_id === component.id)

              console.log('push case data component====>',caseData)

              if(!isExist) {

              let idx =  component.cases.findIndex((caseItem)=> {
            

                
                 if(caseItem.notification_id === caseData.notification_id) {

                   console.log('caseItem==>',caseItem)

                   return true;

                 }

               
               })

               // do not push if already exists

            

                if(idx === -1) {
                  
                  let unique_id = Math.floor(Math.random() * 1000000000);

                  component.cases.push({
                    ...caseData,
                    unique_id: unique_id
                  
                  })

                }

              }
  }

           })

         })

      }

      if(tds){
          
          components.find((component)=> {
  
            if(component.code === 'tds') {
  
              let idx =  component.cases.findIndex((caseItem)=> {
                
                if(caseItem.notification_id === caseData.notification_id) {
  
                  console.log('caseItem==>',caseItem)
  
                  return true;
  
                }
  
              
              })
  
              // do not push if already exists
  
              if(idx === -1) {
  
                component.cases.push(caseData)
  
              }
  
            }
  
          })
      }

      if(epfo){
          
        components.find((component)=> {

          if(component.code === 'epfo') {

            let idx =  component.cases.findIndex((caseItem)=> {
              
              if(caseItem.notification_id === caseData.notification_id) {

                console.log('caseItem==>',caseItem)

                return true;

              }

            
            })

            // do not push if already exists

            if(idx === -1) {

              component.cases.push(caseData)

            }

          }

        })
    }

      if(education){
          
         components.find((component)=> {  

          if(component.code === 'education') {


            let idx =  component.cases.findIndex((caseItem)=> {
              
              if(caseItem.notification_id === caseData.notification_id) {

                console.log('caseItem==>',caseItem)

                return true;

              }

            
            })


            // do not push if already exists

             if(idx === -1) {

              //  component.cases.push(caseData)
                  
              let universities = requested_data.filter((item)=> Object.keys(item)[0] === 'education')[0].education;

              console.log('universities==>',universities)

              universities.map((university)=> {

            console.log('university==>',university)

               let isExist = members.findIndex((member)=>( (member.component_id === component.id) && member.sub_component_id == university.registration_number))

               console.log('isExist==>',isExist, university.registration_number)

               if(isExist === -1) {

                let unique_id = Math.floor(Math.random() * 1000000000);

                component.cases.push({
                  ...caseData,
                  unique_id: unique_id,
                  sub_component_id: university.registration_number
                })

               }


              })

             }

          

          } 

          })
      }

      if(employment){

          components.find((component)=> {

            if(component.code === 'employment') {

    

              let idx =  component.cases.findIndex((caseItem)=> {
                
                if(caseItem.notification_id === caseData.notification_id) {

                  console.log('caseItem==>',caseItem)

                  return true;

                }

              
              })

              // do not push if already exists

               if(idx === -1) {

                
                let companies = requested_data.filter((item)=> Object.keys(item)[0] === 'employment')[0].employment;

                console.log('companies==>',companies)

                companies.map((company)=> {

              console.log('company==>',company)

              console.log('requested data employment==>',requested_data)

                 let isExist = members.findIndex((member)=>( (member.component_id === component.id) && member.sub_component_id == company.id))

                 console.log('isExist==>',isExist, company.id)

                 if(isExist === -1) {

                  let unique_id = Math.floor(Math.random() * 1000000000);

                  component.cases.push({
                    ...caseData,
                    unique_id: unique_id,
                    tds: tds?true:false,
                    epfo: epfo?true:false,
                    company_name: company.company_name,
                    sub_component_id: company.id
                  })

                 }


                })



               }

              

            }

          })

        
      }


      console.log('set components with case data==>',components)

    
      setReqData(req_data);  

    }

    })
    .catch(error => {
  
      
  
      console.log('error==>',error);
    });

  }



  const getAdminUsers = async () => {

    const config = {
      headers: {
        'Content-Type': 'application/json'
      }
    };
   
    axios.get(`${process.env.REACT_APP_BASE_URL}/employer/admin/users`, {}, config)

    .then(response => {

      console.log('admin response cases==>',response.data.data)

      const data = response.data.data.filter((item)=> (item.roles.includes('Process Team Member') || item.roles.includes('Administrator')) );

        console.log('new case users data==>',data)

        setPTL(data)

        setLoading(false)


    })

    .catch(error => {

      setLoading(false)

      console.log('error from server==>',error)

    })


  }

  const [isLoading, setLoading] = useState(false)



  const toggle = () => {
    if (modal) {
      setModal(false);
      setOrder(null);
    } else {
      setModal(true);
    }
  };

  const assignToMember = async(assignedTo) => {

    console.log('assignedTo==>',assignedTo)



    setLoading(true)

    const config = {
      headers: {
        'Content-Type': 'application/json'
      }
    };

    console.log('cases==>',components[activeTab-1].cases.filter((item)=> item.select === true))

    if(assignedTo == '') {
      alert('Please select a user to assign the case to')
      return
    }

    if(components[activeTab-1].cases.filter((item)=> item.select === true).length == 0) {
      alert('Please select a case to assign')
      return
    }

    let promises = []

    components[activeTab-1].cases.map((item)=> {

      let promise = new Promise(async(resolve, reject)=>{

      if(item.select) {

        let body = {
          case_id: item.request_id,
          org_emp_id: assignedTo,
          team: 'Process Team Member',
          component_id: components[activeTab-1].id,
          case_status: 'Assigned'
        }

      if(components[activeTab-1].code === 'employment' || components[activeTab-1].code === 'education') {
          
        body.sub_component_id = item.sub_component_id
  
          
      }


    console.log('assign to member body request==>', body)

   await axios.post(`${process.env.REACT_APP_BASE_URL}/employer/admin/assignCase`, body, config)

    .then(async response => {

      console.log('assign case==>',response)

    })

    .catch(error => {

      reject(error)

      // setLoading(false)

      console.log('error from server==>',error)

    })


  }

  resolve('success')



})

  promises.push(promise)
      

    })

  Promise.all(promises).then((values)=> {
        
        console.log('Cases Assigned to Process Team Member==>',values)
  
        alert('Cases Assigned to Process Team Member')

        setComponents([])

        setCases([])
  
        getAssignedCases()

        get_components()
  
        setLoading(false)
  
      }

    ).catch(err=>{

      setLoading(false)

    })
  
  };

  const updateAssignCase = async (obj) => {

    const config = {
      headers: {
        'Content-Type': 'application/json'
      }
    };

    const body = {
      case_id: obj.request_id,
      assigned_id: obj.assigned_id,
      description: 'Case Assigned to process team Member',
      case_status: 'complete'
     
    }

    console.log('update case==>', body)

    axios.put(`${process.env.REACT_APP_BASE_URL}/employer/admin/assignCase`, body, config)

    .then(response => {

      console.log('update assign case==>',response)

      alert('Case Assigned to Process Team Member')

      // navigate('/admin/my-cases')
   

    })

    .catch(error => {

      console.log('error from server==>',error)

    })
  }

  const selectAll = (value, data) => {

    console.log('select all', value)

    let arr = [...data]

    arr.map((item)=> {

      item.select = value

    } )

    setCases(arr)

    setEnableCancel(value)



  }

  //delete order
  const [deleteModal, setDeleteModal] = useState(false);

  const onClickDelete = (order) => {
    setOrder(order);
    // setDeleteModal(true);
  };

  const handleDeleteOrder = () => {
    if (order && order.id) {
      // dispatch(onDeleteOrder(order.id));
      // setDeleteModal(false);
      setOrder("");
    }
  };


  const columns = useMemo(
    () => [
      {
        Header: (cellProps) => <div className="form-check font-size-16" >
          <input className="form-check-input"  name="all" onClick={(e)=>{

            selectAll(e.target.checked, cellProps.data)
          
          }} type="checkbox" id="checkAll" />
          <label className="form-check-label" htmlFor="checkAll"></label>
        </div>,
        accessor: 'select',
        width: '20px',
        filterable: false,
        disableFilters: false,
        
      
        Cell: (cellProps) => (
          <div className="form-check font-size-16" >
            <Input className="form-check-input" type="checkbox" onClick={()=> {
              let arr = [...cellProps.data]

       

              console.log('cellProps check all', arr)

              console.log('active Tab==>',activeTab)

              console.log('components check all==>', components)

              let compArr = [...components]
             

              arr[cellProps.row.index].select = !arr[cellProps.row.index].select

              console.log('compArr==>',compArr)

              compArr[activeTab - 1].cases = arr;

          


              setComponents(compArr)


              // setCases(arr)

              // let selected = arr.filter((item)=> item.select === true)

              // if(selected.length > 1) {
              //   setEnableCancel(true)
              // }else{
              //   setEnableCancel(false)
              // }

            
            }} checked={cellProps.row.original.select}   />
            <label className="form-check-label" htmlFor="checkAll"></label>
          </div>
        )
      },
      {
        Header: "Case Ref",
        accessor: "request_id",
        width: "150px",
        style: {
          textAlign: "center",
          width: "10%",
          background: "#0000",
        },
        filterable: true,
        Cell: (cellProps) => {

           let tds = cellProps.row.original.tds;
            let epfo = cellProps.row.original.epfo;

          return <div><Link to={`#`} disabled={true} onClick={(e)=>
          {
            e.preventDefault();

            const  status = cellProps.row.original.verification_status;

            if(status == 'Approved' || status == 'Dispatch done' || status == 'Complete' || status == 'QC complete') {
              
              navigate('/edit-progress', {state: cellProps.row.original}) 

            }

          }
          } >{cellProps.value}</Link>
          {(tds || epfo) && <p style={{textAlign:'center', fontSize:10}}>{tds && '(TDS)'} {epfo && '(EPFO)'}</p>}
          </div>
          ;
        },
      },
      {
        Header: "Sub Ref ID",
        accessor: "sub_component_id",
        filterable: true,
        Cell: (cellProps) => {
          return cellProps.value;
        },
      },
      {
        Header: "Location",
        accessor: "location",
        filterable: true,
        Cell: (cellProps) => {
          return cellProps.value;
        },
      },
      {
        Header: "Case Received Date",
        accessor: "case_received_date",
        filterable: true,
        Cell: (cellProps) => {
          return moment(cellProps.row.original.timestamp).format("DD-MM-YYYY");
        },
      },
      {
        Header: "Candidate Name",
        accessor: "candidate_name",
        filterable: true,
        Cell: (cellProps) => {
          return cellProps.value;
        },
      },
    
      {
        Header: "Candidate Father Name",
        accessor: "candidate_father_name",
        filterable: true,
        Cell: (cellProps) => {
          return cellProps.value;
        },
      },

      {
        Header: "Candidate DOB",
        accessor: "candidate_dob",
        filterable: true,
        Cell: (cellProps) => {
          return cellProps.value;
        },
      },

      {
        Header: "Case In Date",
        accessor: "timestamp",
        filterable: true,
        Cell: (cellProps) => {
          return moment(cellProps.value).format("DD-MM-YYYY");
        },
      },

      {
        Header: "Case Due Date",
        accessor: "dueDate",
        filterable: true,
        Cell: (cellProps) => {
          return "";
        },
      },
      {
        Header: "Status",
        accessor: "packageType",
        filterable: true,
        Cell: (cellProps) => {
          return cellProps.value;
        },
      },

      {
        Header: "Report",
        accessor: "report",
        filterable: true,
        Cell: (cellProps) => {
          return cellProps.value;
        },
      },
  

      {
        Header: "Client Employee ID",
        accessor: "client_employee_id",
        filterable: true,
        Cell: (cellProps) => {
          return cellProps.value;
        },
      },
  
    
      {
        Header: "Action",
        accessor: "action",
        disableFilters: true,
        Cell: (cellProps) => {

          const status  = cellProps.row.original.verification_status;

          const enableView = (status == 'Approved' || status == 'Dispatch done' || status == 'Complete' || status == 'QC complete') ? true : false;

          const enableCancel = (status == 'Dispatch done' || status == 'Complete' || status == 'QC complete') ? false : true;

          return (
            <div className="d-flex text-center align-items-center" style={{justifyContent:'space-evenly', width:50}} >
               {enableView && <Link
                to="#"
                className="text-success"
                onClick={(e) => {

                  e.preventDefault();
                  console.log('cellProps==>',cellProps.row.original)

                  navigate('/edit-progress', {state: cellProps.row.original}) 

                }}
              >
              
                <i className="mdi mdi-eye font-size-20" id="edittooltip2" />
                <UncontrolledTooltip placement="top" target="edittooltip2">
                  View Report
                </UncontrolledTooltip>
              </Link>}
              {enableCancel && <Link
                to="#"
                className="text-danger"
                onClick={() => {
setCancelNotificationType('single')
                  setSelectedNotification(cellProps.row.original);
                  toggleConfirm();
                }}
              >
              
                <i className="bx bx-stop-circle font-size-18" id="edittooltip" />
                <UncontrolledTooltip placement="top" target="edittooltip">
                  Stop Verification
                </UncontrolledTooltip>
              </Link>}
          
            </div>
          );
        },
      },
    ],
    [components]
  );

  const [modalConfirm, setModalConfirm] = useState(false);

  const toggleConfirm = () => setModalConfirm(!modalConfirm);


  const cancelNotification = async(notification_id, walletID, email) => {

    console.log('selectedNotification==>',selectedNotification)


    const config = {
      headers: {
        'Content-Type': 'application/json'
      }
    };

    const id = notification_id;

    const body = {
      id_identity_user: walletID? walletID : '',
      email: email
    }

    console.log('id==>',id, body)

    axios.put(`${process.env.REACT_APP_BASE_URL}/service/cancelNotification/${id}`, body, config)

    .then(response => {

      console.log('cancel notification==>',response)

      setModalConfirm(false)



    })

    .catch(error => {

      console.log('error from server==>',error)

    })


  }

  const handleCancelClicks = () => {

    console.log('cancel clicked', cases)

   setCancelNotificationType('multiple')

   setModalConfirm(true)

   
  }

  const cancelAllNotification = async() => {

    console.log('cases cancel all==>',cases)

      cases.map((item)=> {

        if(item.select) {
          cancelNotification(item.notification_id, item.walletID, item.email)
        }

      }
      )

  }

  
  const get_components = async () => {

    const config = {
      headers: {
        'Content-Type': 'application/json'
      }
    };
   
    axios.get(`${process.env.REACT_APP_BASE_URL}/service/components`, {}, config)

    .then(response => {

      console.log('response components==>',response)

      const data = response.data;

     console.log('data==>',data)

     let arr = [];

      data.map((item)=> {

        if(item.name !== 'TDS' && item.name !== 'EPFO' && item.name !== 'Attestation') {

          arr.push({
            component: item.name,
            code: item.code,
            cases: [],
            checked: false,
            id: item.id
          })

        }
 

      })


      setComponents(arr)




    })

    .catch(error => {

      console.log('error from server==>',error)

    })


  }

  return (
    <React.Fragment>

      <div className="page-content">
        <div className="container-fluid">
          {/* <Breadcrumbs title="Ecommerce" breadcrumbItem="Orders" /> */}

{
  isLoading && <Loader />
}

          <Row>
            {
            
                <Col xs="12">
                  <Card>
                    <CardBody>
                      <p className="card-title mb-4" style={{fontSize:20, fontWeight:'500'}}>Process Team Leader Cases</p>

                      <div className="mb-5">
      <Nav pills>
        {components.map((tab, index) => (
          <NavItem key={tab.id}                         
          >
            <NavLink
  
  style={{
    border: '1px solid #CCC',
    margin: '5px',
  }}
              className={classnames({ active: activeTab === `${index + 1}`,
              inactive_PTL_tab: activeTab !== `${index + 1}`, // Add the inactive class when the tab is not active

            })}
              onClick={() => { toggleTab(`${index + 1}`); }}
            >
              {tab.component} <span className={
                classnames({
                  'text-danger': activeTab !== `${index + 1}`,
                  'text-white': activeTab === `${index + 1}`
                })
              }
            
              >({tab.cases.length})</span>
            </NavLink>
          </NavItem>
        ))}
      </Nav>
      <TabContent activeTab={activeTab}>
        {components.map((tab, index) =>{ 

          let cols = columns;

          if(tab.code === 'employment') {

            // add another column for company name at 2nd index

            cols.splice(2, 0, {
              Header: "Company Name",
              accessor: "company_name",
              filterable: true,
              Cell: (cellProps) => {
                return cellProps.value;
              },
            })

            

          }else{
            // remove company name column
            cols = cols.filter((item)=> item.accessor !== 'company_name')
          }
          
          return(
          <TabPane tabId={`${index + 1}`} key={tab.id}>
            <TableContainer
                        columns={cols}
                        data={tab.cases.length > 0 ? tab.cases : []}
                        isFilter={false}
                        det={PTL.filter((item)=> item.components.includes(tab.id))}

                        isGlobalFilter={false}
                        isAddOptions={true}
                        enableCancel={enableCancel}
                        handleOrderClicks={assignToMember}
                        handleCancelClicks={handleCancelClicks}
                        customPageSize={10}
                        isPagination={true}
                        filterable={false}
                        iscustomPageSizeOptions={true}
                        tableClass="align-middle table-nowrap table-check"
                        theadClass="table-light"
                        pagination="pagination pagination-rounded justify-content-end mb-2"
                      />
          </TabPane>
        )}
        
        )}
      </TabContent>
    </div>

                      {/* <TableContainer
                        columns={columns}
                        data={cases.length > 0 ? cases : []}
                        isFilter={false}
                        det={PTL}

                        isGlobalFilter={false}
                        isAddOptions={true}
                        enableCancel={enableCancel}
                        handleOrderClicks={handleOrderClicks}
                        handleCancelClicks={handleCancelClicks}
                        customPageSize={10}
                        isPagination={true}
                        filterable={false}
                        iscustomPageSizeOptions={true}
                        tableClass="align-middle table-nowrap table-check"
                        theadClass="table-light"
                        pagination="pagination pagination-rounded justify-content-end mb-2"
                      /> */}
                    </CardBody>
                  </Card>
                </Col>
            }
          </Row>


          <Modal isOpen={modalConfirm} centered={true} toggle={toggleConfirm}>
            <ModalHeader toggle={toggleConfirm} tag="h4">
              Cancel Notification
            </ModalHeader>
            <ModalBody>
         
                <Row>
                  <Col className="col-12">
                    {
                      cancelNotificationType === 'single' && 
                      <p style={{fontSize:16}}>Are you sure you want to cancel this notification?</p>
                    }
                    {
                      cancelNotificationType === 'multiple' && 
                      <p style={{fontSize:16}}>Are you sure you want to cancel all selected notifications?</p>
                    }
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div className="text-center">
                      <button
                        type="button"
                        onClick={()=>{
                          if(cancelNotificationType === 'single') {
                            cancelNotification(selectedNotification.notification_id, selectedNotification.walletID, selectedNotification.email)
                          }

                          if(cancelNotificationType === 'multiple') {
cancelAllNotification()
                          }
                        }}
                        className="btn btn-primary save-user me-2"
                      >
                        Yes
                      </button>
                      <button
                        type="button"
                        onClick={() => { setModalConfirm(false) }}
                        className="btn btn-primary save-user"
                      >
                        No
                      </button>
                    </div>
                  </Col>
                </Row>
        
            </ModalBody>
          </Modal>

          <Modal isOpen={modal} centered={true} toggle={toggle}>
            <ModalHeader toggle={toggle} tag="h4">
              Add Candidate
            </ModalHeader>
            <ModalBody>
              <Form
                onSubmit={(e) => {
                  e.preventDefault();
                  validation.handleSubmit();
                  return false;
                }}
              >
                <Row>
                  <Col className="col-12">
               
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div className="text-center">
                      <button
                        type="submit"
                        onClick={() => { navigate("/search") }}
                        className="btn btn-primary save-user me-2"
                      >
                        Manual
                      </button>
                      <button
                        type="submit"
                        onClick={() => { navigate("/excel-import") }}
                        className="btn btn-primary save-user"
                      >
                        Excel Import
                      </button>
                    </div>
                  </Col>
                </Row>
              </Form>
            </ModalBody>
          </Modal>
        </div>
      </div>

    </React.Fragment>
  );
}
LeaderCases.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
};

export default LeaderCases;
