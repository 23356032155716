import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Link, useNavigate } from "react-router-dom";
import { Card, CardBody,
  Form,
  Input,
  Label,
  FormFeedback,
  Button,
  Col, Container, Row, Table } from "reactstrap";
import { isEmpty, map, set } from "lodash";
import Dropzone from "react-dropzone";
import * as XLSX from 'xlsx';
import Papa from 'papaparse';
import axios from 'axios';
import Loader from "../components/Loader";
import { useFormik } from "formik";
import * as Yup from "yup";




const ExcelImport = (props) => {
  //meta title
  document.title =
    "TheRollNumber";


    const [data, setData] = React.useState([]);

    const [loading, setLoading] = React.useState(false);


  const Navigate = useNavigate();

    const [zipFile, setZipFile] = React.useState(null)


  const printInvoice = () => {
    window.print();
  };

  const fetch_Wallet_details = async (value) => {
    const config = {
      headers: {
        'Content-Type': 'application/json'
      }
    };
   
    axios.get(`${process.env.REACT_APP_BASE_URL}/service/wallet_details/1`, {}, config)

    .then(response => {

      console.log('response from server==>',response)

      setLoading(false)

      Navigate('/admin/verify')

      

    })

    .catch(error => {

      setLoading(false)

      console.log('error from server==>',error)


    })
  }

  const handleOrderClicks = async(data) => {

    console.log('upload excel data',data)

    setLoading(true)

    const body = {

      emp_id: 1,

      csvData: data

    }

    const config = {
      headers: {
        'Content-Type': 'application/json'
      }
    };

    console.log('body',body)
   
    axios.post(`${process.env.REACT_APP_BASE_URL}/service/wallet`, body, config)

    .then(response => {

      console.log('response from server==>',response)

      fetch_Wallet_details()


    })

    .catch(error => {

      setLoading(false)

      console.log('error from server==>',error)


    })
  };

  const search_wallet = async (value) => {

    return new Promise((resolve, reject) => {
 
     const config = {
       headers: {
         'Content-Type': 'application/json'
       }
     };
 
 
     axios.get(`${process.env.REACT_APP_BASE_URL}/service/wallet/${value}`, {}, config)
     .then(response => {
       console.log('wallet data',response.data);
 
       let data = response.data;
 
       console.log('data==>',data);
 
       resolve(data);
       
 
     })
     .catch(error => {
   
       reject(error);
   
       console.log('error==>',error);
     });
 
   });
 
 
 
   }

  const fetchWallet = async(walletData) => {

    console.log('walletData',walletData)

    let arr = []


    let promises = []

    walletData.map((item) => {

    let promise =  new Promise((resolve, reject) => {


      search_wallet(item.walletID)
      .then((data) => {
        console.log('data',data?.user);

        arr.push({
          walletID: item.walletID,
          packageType: item.packageType,
          email: item.email,
          candidate_name: data?.user?.first_name,
          candidate_father_name: item.candidate_father_name,
          candidate_dob: item.candidate_dob,
          search_status: data?.user !== undefined ? 'Found' : 'Not Found',
        
        })

        resolve();


      })
      .catch((error) => {

        arr.push({
          walletID: item.walletID,
          packageType: item.packageType,
          email: item.email,
          canidate_father_name: item.canidate_father_name,
          candiate_dob: item.candiate_dob,
          candidate_name: data?.user?.first_name,
          search_status:  data?.user !== undefined ? 'Found' : 'Not Found',

        })

        resolve();
        console.log('error',error);
      })




    })

    promises.push(promise);

    
    });

    Promise.all(promises)
    .then(() => {
      console.log('arr',arr);

     handleOrderClicks(arr)

    })

  }

  const handleFileUpload = (file) => {
    
    const reader = new FileReader();

    reader.onload = (evt) => {
      // Parse data
      const bstr = evt.target.result;
      const wb = XLSX.read(bstr, { type: 'binary' });
      // Get first worksheet
      const wsname = wb.SheetNames[0];
      const ws = wb.Sheets[wsname];
      // Convert array of arrays
      const csvData = XLSX.utils.sheet_to_csv(ws);
      // Parsing CSV data with Papa Parse
      Papa.parse(csvData, {
        complete: (results) => {
          console.log('Parsed Results:', results.data);

          fetchWallet(results.data)
          // setData(results.data);
        },
        header: true,
      });
    };

    reader.readAsBinaryString(file);
  };

  async function handleAcceptedFiles(files) {
    console.log(files)
    
    setZipFile(files)
    
    // const extractedFiles = await unzip(files[0]);
    
    // setMatch(true)
    
    // setEnable(true)
    
    // setSuccessModal(true)
    
    
      }

      const importExcel = async() => {

        console.log(zipFile)

        handleFileUpload(zipFile[0])

        // Navigate('/verify', {state: {zipFile: zipFile}})

      }


      const validationType = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,
    
        initialValues: {
          url: ''
        },
        validationSchema: Yup.object().shape({
          url: Yup.string().required(
            "This value is required"
          ),
    
          // url: Yup.string()
          //   .matches(
          //     /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
          //     "Enter correct url!"
          //   )
          //   .required("Please enter correct Url"),
       
        }),
        onSubmit: (values) => {
    
          handleGoogleSheetLink(values.url)
    
          console.log("values", values.url);
        }
      });

      
const handleGoogleSheetLink = (url)=>{

  const sheetId = getSheetIdFromUrl(url);
  
  getGoogleSheetData(sheetId)
  
  }

  const getGoogleSheetData = async(sheetId) => {


    const url = `https://sheets.googleapis.com/v4/spreadsheets/${sheetId}/values/A1:Z1000?key=AIzaSyAPsi9Nmp9Wvo8kQqfztOPwMEmRGE5F_NQ`;
  
    console.log(url)
  
    await axios.get(url)
    .then(response => {
      const jsonData = response.data;
      const csv = Papa.unparse(jsonData.values);
  
      console.log('csv google sheet==>',jsonData.values)
  
  //     if (jsonData.values.length > 0) {
  //       setColumns([...Object.keys(jsonData.values[0]).map((key) => ({ Header: jsonData.values[0][key], accessor: key })),    {
  //         Header: 'View Details',
  //         accessor: 'view',
  //         index:true,
  //         width:600,
  //         Cell: ({ row }) => (
  // <Button
  //           type="button"
  //           color="primary"
  //           style={{width:'100px'}}
  //           className="btn-sm btn-rounded"
  //           onClick={()=>alert('sdf')}
  //         >
  //           View
  //         </Button>            ),
  //       },
  //       {
  //         Header: 'Action',
  //         accessor: 'action',
  //         disableFilters: true,
  //         Cell: (cellProps) => {
  //           return (
  //             <div className="d-flex gap-3">
  //               <Link
  //                 to="#"
  //                 className="text-success"
  //                 onClick={() => {
  //                   const orderData = cellProps.row.original;
  //                   handleOrderClick(orderData);
  //                 }}
  //               >
  //                 <i className="mdi mdi-pencil font-size-18" id="edittooltip" />
  //                 <UncontrolledTooltip placement="top" target="edittooltip">
  //                   Edit
  //                 </UncontrolledTooltip>
  //               </Link>
  //               <Link
  //                 to="#"
  //                 className="text-danger"
  //                 onClick={() => {
  //                   const orderData = cellProps.row.original;
  //                   onClickDelete(orderData);
  //                 }}
  //               >
  //                 <i className="mdi mdi-delete font-size-18" id="deletetooltip" />
  //                 <UncontrolledTooltip placement="top" target="deletetooltip">
  //                   Delete
  //                 </UncontrolledTooltip>
  //               </Link>
  //             </div>
  //           );
  //         }
  //       },
      
  //     ]);
  //     }
  
  
  //       dispatch(addCsvSuccess(jsonData.values))
  
    })
    .catch(error => {
      console.error('Error fetching Google Sheet data:', error);
    });
  
  
  };

  function getSheetIdFromUrl(url) {
    try {
      const pattern = /\/d\/([a-zA-Z0-9-_]+)\//;
      let matches = /\/([\w-_]{18,})\/(.*?gid=(\d+))?/.exec(url);

      const match = url.match(pattern);
      if (matches && matches.length >= 2) {
        return matches[1];
      } else {
        throw new Error('Invalid Google Sheets URL');
      }
    } catch (error) {
      console.error('Error extracting sheet ID:', error);
      return null;
    }
  }

  return (
    <React.Fragment>
      <div >
{
  loading && <Loader />
}
      
                <Card>
                  <CardBody>
                
                
                    {/* <div className="py-2">
                      <h3 className="fw-600" style={{fontSize:20}}>Add Candidate</h3>
                    </div> */}

<div style={{width:'100%'}}>
  <h3 className="mb-3" style={{ fontWeight:'normal', fontSize:16}}>Upload Excel</h3>

  <p style={styles.para}>Please upload the document in the same format as that of sample provided</p>
  <p style={styles.para}>First Name, Last Name,Email ID,Category, Gender,Client Location fields are Mandatory in Excel</p>
  <p style={styles.para}>The Correct format of Date of Birth and Date of Joining should be in dd/mm/yyyy</p>
  <p style={styles.para}>EPFO Number should be 12 digit numerical value</p>
  <p style={styles.para}>You Can import maximum 100 Candidates at a time through Excel</p>

<div style={{width: '40%'}}>
<Dropzone

                     
                        onDrop={acceptedFiles => {
                          handleAcceptedFiles(acceptedFiles)
                        }}
                      >
                        {({ getRootProps, getInputProps }) => (
                          <div className="dropzone">
                            <div
                              className="dz-message needsclick mt-2"
                              {...getRootProps()}
                            >
                              <input {...getInputProps()} />
                              <div className="mb-3">
                                <i className="display-4 text-muted bx bxs-cloud-upload" />
                              </div> 

                              <p style={{fontSize:14}}>Click here to upload</p>
                            </div>
                          </div>
                        )}
                      </Dropzone>
</div>


<button onClick={importExcel} className="btn btn-primary mt-3">Save & Continue</button>
</div>
                    
           
                  </CardBody>
                </Card>

                {/* <h4 className="text-center">OR</h4> */}

                {/* <Card>
                  <CardBody>
                  <Form
                    onSubmit={(e) => {
                      e.preventDefault();
                      validationType.handleSubmit();
                      return false;
                    }}>
                    <div className="mb-3">
                      <Label className="form-label">Required</Label>
                      <Input
                        name="url"
                        placeholder="Google link"
                        type="text"
                        onChange={validationType.handleChange}
                        onBlur={validationType.handleBlur}
                        value={validationType.values.url || ""}
                        invalid={
                          validationType.touched.url && validationType.errors.url ? true : false
                        }
                      />
                      {validationType.touched.url && validationType.errors.url ? (
                        <FormFeedback type="invalid">{validationType.errors.url}</FormFeedback>
                      ) : null}
                    </div>
                    <div className="d-flex flex-wrap gap-2">
                      <Button type="submit" color="primary" >
                        Submit
                      </Button>
                    </div>
                    </Form>
                  </CardBody>
                </Card>
    */}
      </div>
    </React.Fragment>
  );
};


export default ExcelImport;

const styles = {

  para:{
    fontSize:12
  }

}
