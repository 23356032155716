import React, { useEffect, useRef , useState} from 'react';
import { Page, Document, Image, StyleSheet,View, usePDF, Text } from '@react-pdf/renderer';
import InvoiceTitle from './InvoiceTitle'
import BillTo from './BillTo'
import InvoiceNo from './InvoiceNo'
import InvoiceItemsTable from './InvoiceItemsTable'

import InvoiceThankYouMsg from './InvoiceThankYouMsg'
import logo from '../../report_images/therollnumber_logo.png'
import ComponentDetail from './ComponentDetail';
import AadhaarComp from './AadhaarComp';
import PanComp from './PanComp';
import DLComp from './DLComp';
import VoterIDComp from './VoterIDComp';
import DoctorComp from './DoctorComp';
import CAComp from './CAComp';
import NurseComp from './NurseComp';
import Header from './Header';
import Footer from './Footer';
import AadhaarLogo from '../../aadhaar.png'
import PanCardLogo from '../../report_images/income-tax.png'
import DLLogo from '../../LICENSE.png'
import VoterIDLogo from '../../voterid.png'
import NurseLogo from '../../NUID.png'
import CALogo from '../../ICIA.png'
import EducationComp from './EducationComp';
import EmploymentComp from './EmploymentComp';
import EpfoComp from './Epfo';
import TdsComp from './Tds';
import CreditScore from './CreditScore';



const styles = StyleSheet.create({
    page: {
        fontFamily: 'Helvetica',
        backgroundColor:'#061c33',
        fontSize: 11,
        paddingTop: 30,
        paddingLeft:60,
        paddingRight:60,
        lineHeight: 1.5,
        flexDirection: 'column',
    }, 
    logo: {
        width:200,
      marginBottom:30,
        
    }
  });

  const Invoice = ({invoice, comp, data, candidate, requestedData})=>{




    const [document, updateDocument] = usePDF();

    const [sortedData, setSortedData] = useState([]);

    const [show, setShow] = useState({
      epfo: false,
      tds: false,
      aadhaar: false,
      pan: false,
      dl: false,
      voter_id: false,
      doctor: false,
      nurse: false,
      education: false,
      employment: false,
      creditScore: false,
    });


    useEffect(() => {

//       const sortList = ["aadhaar", "pan", "voter_id", "dl", "doctor", "nurse", "education", "employment", "epfo", "tds"];
    
    
// const sortedObj = requestedData.sort((a, b) => {

//   console.log('a==>',sortList.indexOf(Object.keys(a)[0]));
//     return (
//         sortList.indexOf(Object.keys(a)[0]) - sortList.indexOf(Object.keys(b)[0])
//     );
// });

// console.log('sortedObj==>',sortedObj);

const epfo = comp.findIndex((obj) => obj === 'epfo');

const tds = comp.findIndex((obj) => obj === 'tds');

const aadhaar = comp.findIndex((obj) => obj === 'aadhaar');

const pan = comp.findIndex((obj) => obj === 'pan');

const dl = comp.findIndex((obj) => obj === 'dl');

const voter_id = comp.findIndex((obj) => obj === 'voter_id');

const doctor = comp.findIndex((obj) => obj === 'doctor');

const nurse = comp.findIndex((obj) => obj === 'nurse');

const education = comp.findIndex((obj) => obj === 'education');

const employment = comp.findIndex((obj) => obj === 'employment');

const creditScore = comp.findIndex((obj) => obj === 'credit_score');


let obj = {
  epfo: epfo > -1 ? true : false,
  tds: tds > -1 ? true : false,
  aadhaar: aadhaar > -1 ? true : false,
  pan: pan > -1 ? true : false,
  dl: dl > -1 ? true : false,
  voter_id: voter_id > -1 ? true : false,
  doctor: doctor > -1 ? true : false,
  nurse: nurse > -1 ? true : false,
  education: education > -1 ? true : false,
  employment: employment > -1 ? true : false,
  creditScore: creditScore > -1 ? true : false,

}

console.log('invoice obj==>',data);

setShow({
  ...show,
  ...obj
})

// setSortedData(sortedObj);

    }, []);

    const pageRef = useRef(null);

  
      const aadhaar = (item)=>{

        return (
          <Page size="A4" style={[styles.page, {backgroundColor:'#fff', padding:0}]}>
             <Header/>
           <AadhaarComp invoice={invoice} data={item} logo={AadhaarLogo} requestedData={requestedData} />
           <Footer data={data} />

           
       </Page>
        )

      }

      const Pan = (item)=>{

        return (
          <Page size="A4" style={[styles.page, {backgroundColor:'#fff', padding:0}]}>
           <Header/>
          <PanComp invoice={invoice} logo={PanCardLogo} data={item} requestedData={requestedData} />
        <Footer data={data} />
          
      </Page>
        )

      }

      const Credit = (item)=>{

        return (
          <Page size="A4" style={[styles.page, {backgroundColor:'#fff', padding:0}]}>
           <Header/>
          <CreditScore invoice={invoice} logo={PanCardLogo} data={item} requestedData={requestedData} />
        <Footer data={data} />
          
      </Page>
        )

      }

      const Employment = (item)=>{

        return (
          <Page size="A4" style={[styles.page, {backgroundColor:'#fff', padding:0}]}>
           <Header/>
          <EmploymentComp invoice={invoice} logo={PanCardLogo} data={item} requestedData={requestedData} />
        <Footer data={data} />
          
      </Page>
        )

      }

      const Epfo = (item)=>{

        return (
          <Page size="A4" ref={pageRef} style={[styles.page, {backgroundColor:'#fff', padding:0}]} >
           <Header/>
          <EpfoComp invoice={invoice}   logo={PanCardLogo} data={item} requestedData={requestedData} />
        <Footer data={data} />
          
      </Page>
        )

      }

      const Tds = (item)=>{

        return (
          <Page size="A4" style={[styles.page, {backgroundColor:'#fff', padding:0}]}>
           <Header/>
          <TdsComp invoice={invoice} logo={PanCardLogo} data={item} requestedData={requestedData} />
        <Footer data={data} />
          
      </Page>
        )

      }
      
      const Education = (item)=>{

        return (
          <Page size="A4" style={[styles.page, {backgroundColor:'#fff', padding:0}]}>
           <Header/>
          <EducationComp invoice={invoice} logo={PanCardLogo} data={item} requestedData={requestedData} />
        <Footer data={data} />
          
      </Page>
        )

      }
      

      const DL = (item)=>{

        return (
          <Page size="A4" style={[styles.page, {backgroundColor:'#fff', padding:0}]}>
                      <Header/>

           <DLComp invoice={invoice} data={item} logo={DLLogo} requestedData={requestedData} />
           <Footer data={data} />

           
       </Page>
        )

      }

      const voter = (item)=>{

        return (
          <Page size="A4" style={[styles.page, {backgroundColor:'#fff', padding:0}]}>
                      <Header/>

           <VoterIDComp invoice={invoice} logo={VoterIDLogo} data={item} requestedData={requestedData} />
         
           <Footer data={data} />

       </Page>
        )

      }
      

      const doctor = (item)=>{

        return (
          <Page size="A4" style={[styles.page, {backgroundColor:'#fff', padding:0}]}>
                                 <Header/>

           <DoctorComp invoice={invoice} data={item} logo={CALogo} requestedData={requestedData} />
           <Footer data={data} />

           
       </Page>
        )

      }

      const nurse = (item)=>{

        return (
          <Page size="A4" style={[styles.page, {backgroundColor:'#fff', padding:0}]}>
                                            <Header/>

           <NurseComp invoice={invoice} data={item} logo={NurseLogo} requestedData={requestedData} />
           <Footer data={data} />

           
       </Page>
        )

      }
      
      
  
    
    return (
                   <Document>

                    {/* First page */}
                <Page size="A4" style={styles.page} >
                    <Image style={styles.logo} src={logo} />
                    <InvoiceTitle title='Background Verification Report' candidate={candidate} data={data} requestedData={requestedData}/>
                    <InvoiceNo invoice={invoice} data={data}/>
                    <BillTo invoice={invoice} requestedData={requestedData} candidate={candidate} />
                    <InvoiceThankYouMsg />

                    
                </Page>
                <Page size="A4" style={[styles.page, {backgroundColor:'#fff', padding:0}]}>
             <Header />
                    <InvoiceItemsTable invoice={invoice}  data={data} candidate={candidate} requestedData={requestedData} />

                    
                  <Footer data={data} /> 
                
                </Page>

                {
                  show.aadhaar && requestedData.length > 0 && requestedData.map((item, index)=>{

                    if(Object.keys(item)[0]== 'aadhaar'){
                  
                      return aadhaar(item.aadhaar);
                    }
                  
                  
                  })
                }

{
                  show.pan && requestedData.length > 0 && requestedData.map((item, index)=>{
                    
                      if(Object.keys(item)[0]== 'pan'){
                    
                        return Pan(item.pan);
                      }
                    
                    
                    }
                  )
                }

{
                  show.creditScore && requestedData.length > 0 && requestedData.map((item, index)=>{
                    
                      if(Object.keys(item)[0]== 'credit_score'){
                    
                        return Credit(item.credit_score[0]);
                      }
                    
                    
                    }
                  )
                }

{
                  show.voter_id && requestedData.length > 0 && requestedData.map((item, index)=>{
                        
                          if(Object.keys(item)[0]== 'voter_id'){
                        
                            return voter(item.voter_id);
                          }
                        
                        }
                  )
           }

{
                  show.dl && requestedData.length > 0 && requestedData.map((item, index)=>{
                      
                        if(Object.keys(item)[0]== 'dl'){
                      
                          return DL(item.dl);
                        }
                      
                      }

                  )
                }

{
                  show.doctor && requestedData.length > 0 && requestedData.map((item, index)=>{
                          
                            if(Object.keys(item)[0]== 'doctor'){
                          
                              return doctor(item.doctor);
                            }
                          
                          }
                  )
           }

           {
                  show.nurse && requestedData.length > 0 && requestedData.map((item, index)=>{

                    if(Object.keys(item)[0]== 'nurse'){
                  
                      return nurse(item.nurse);
                    }
                  
                  } 

                  )

           }

{
                  show.education && requestedData.length > 0 && requestedData.map((item, index)=>{

                    if(Object.keys(item)[0]== 'education'){
                  
                      return Education(item.education);
                    }
                  
                  }
                  )
                }

                {
                  show.employment && requestedData.length > 0 && requestedData.map((item, index)=>{

                    if(Object.keys(item)[0]== 'employment'){
                  
                      return Employment(item.employment);
                    }
                  
                  } 

                  )
                }

                {/* {
                  (show.employment && !show.tds && !show.epfo) && requestedData.length > 0 && requestedData.map((item, index)=>{

                    if(Object.keys(item)[0]== 'employment'){
                  
                      return Employment(item.employment);
                    }
                  
                  }
                  )
                } */}

        
                {
                  (show.epfo || show.tds) && requestedData.length > 0 && requestedData.map((item, index)=>{

                    console.log('epfo called==>',item);
                      
                    if(Object.keys(item)[0]== 'epfo' || Object.keys(item)[0]== 'tds'){
                          return Epfo(item.epfo);
                    }
                      
                      
                      }   
                  )
                }

      



      
{


  

  // sortedData.length > 0 && sortedData.map((item, index)=>{

  //   if(Object.keys(item)[0]== 'aadhaar'){

  //     return aadhaar(item.aadhaar);
  //   }

  //   if(Object.keys(item)[0]== 'pan'){

  //     return Pan(item.pan);
  //   }

  //   if(Object.keys(item)[0]== 'epfo'){

  //     return Epfo(item.epfo);
  //   }

  //   if(Object.keys(item)[0]== 'education'){

  //     return Education(item.education);
  //   }

  //   if(Object.keys(item)[0]== 'dl'){

  //     return DL(item.dl);
  //   }

  //   if(Object.keys(item)[0]== 'voter_id'){

  //     return voter(item.voter_id);
  //   }

  //   if(Object.keys(item)[0]== 'doctor'){

  //     return doctor(item.doctor);
  //   }

  //   if(Object.keys(item)[0]== 'nurse'){

  //     return nurse(item.nurse);
  //   }

  // })
}
      

    
            </Document>
    )
  }
  

  
  export default Invoice