import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Link, useNavigate } from "react-router-dom";
import {
    Card, CardBody, Col, Container, Row, Table,
    Label,
    Input,
    Form,
    FormGroup,

    Nav,
    NavItem,
    NavLink,
    TabContent,
    TabPane, CardTitle


} from "reactstrap";
import { isEmpty, map, set } from "lodash";
import Dropzone from "react-dropzone";
import * as XLSX from 'xlsx';
import Papa from 'papaparse';
import axios from 'axios';
// import Loader from "../../components/Loader";

import classnames from "classnames";



const ClientInfo = (props) => {
    //meta title
    document.title =
        "TheRollNumber";


    const [data, setData] = React.useState([]);

    const [loading, setLoading] = React.useState(false);


    const Navigate = useNavigate();

    const [state, setState] = React.useState({

        client_info: "",
        client_name: "",
        abbreviation: "",
        contact_person: "",
        address: "",
        country: "",
        state: "",
        city: "",
        pincode: "",
        email: "",
        landline: "",
        mobile: "",
        client_status: "",

    });

    const handleInputChange = (e) => {
        const { id, value } = e.target;
        setState((prevState) => ({
            ...prevState,
            [id]: value,
        }));
    };

    useEffect(() => {

        getClientInfo();

    }, []);

    const getClientInfo = async () => {

        const company_name = JSON.parse(localStorage.getItem("authUser")).user.company_name;


        const config = {
            headers: {
              'Content-Type': 'application/json'
            }
          };
      
      const body = {
        company_name: company_name,
    
      }
        
         await axios.post(`${process.env.REACT_APP_BASE_URL}/employer/clientInfo`, body, config)
          .then(response => {
            console.log('client info response',response);

            const data = response.data.data[0];

            setState((prevState) => ({
                ...prevState,
                client_name: data.company_name,
                abbreviation: data.abbreviation,
                contact_person: data.first_name,
                address: data.address,
                country: data.country,
                state: data.state,
                city: data.city,
                pincode: data.pincode,
                email: data.email,
                landline: data.landline,
                mobile: data.phone_number,
                client_status: data.client_status,
            }));
      
         
          })
          .catch(error => {
        
            console.log('error==>',error);
          });

    }


    return (
        <React.Fragment>


            <Card>
                <CardBody>
                    <p className="h4 mb-4">Client Information</p>

                    <Row>

                        <Col lg="6">
                            <div className="mt-3 d-flex" style={styles.box}>
                                <Label for="basicpill-firstname-input1" style={styles.boxLabel} className="control-label me-3" >
                                    Client Name
                                    <span className="text-danger mx-1">*</span>
                                </Label>
                                <Input
                                    type="text"
                                    className="form-control me-3"
                                    value={state.client_name}
                                    
                                    // disabled={selectedValue === 'registration_no' ? false : true}
                                   onChange={handleInputChange}
                                    style={styles.boxInput}
                                    suppressHydrationWarning
                                    id="client_name"
                                    placeholder="Client Name"
                                />


                            </div>
                        </Col>
                        <Col lg="6">
                            <div className="mt-3 d-flex" style={styles.box} >
                                <Label for="basicpill-firstname-input1" style={styles.boxLabel} className="control-label me-3" >
                                    Abbreviation
                                    {/* <span className="text-danger mx-1">*</span> */}

                                </Label>
                                <Input
                                    type="text"
                                    className="form-control me-3"
                                    value={state.abbreviation}
                                    style={styles.boxInput}
                                    

                                    // disabled={selectedValue === 'registration_no' ? false : true}
                                    onChange={handleInputChange}
                                    suppressHydrationWarning
                                    id="abbreviation"
                                    placeholder="Abbreviation"
                                />


                            </div>
                        </Col>

                        <Col lg="6">
                            <div className="mt-3 d-flex" style={styles.box}>
                                <Label for="basicpill-firstname-input1" style={styles.boxLabel} className="control-label me-3" >
                                    Contact Person
                                    {/* <span className="text-danger mx-1">*</span> */}
                                </Label>
                                <Input
                                    type="text"
                                    className="form-control me-3"
                                    value={state.contact_person}
                                    
                                    // disabled={selectedValue === 'registration_no' ? false : true}
                                    onChange={handleInputChange}
                                    style={styles.boxInput}
                                    suppressHydrationWarning
                                    id="contact_person"
                                    placeholder="Contact Person"
                                />


                            </div>
                        </Col>
                        <Col lg="6">
                            <div className="mt-3 d-flex" style={styles.box} >
                                <Label for="basicpill-firstname-input1" style={styles.boxLabel} className="control-label me-3" >
                                    Address
                                    {/* <span className="text-danger mx-1">*</span> */}

                                </Label>
                                <Input
                                    type="text"
                                    className="form-control me-3"
                                    value={state.address}
                                    style={styles.boxInput}

                                    

                                    // disabled={selectedValue === 'registration_no' ? false : true}
                                    onChange={handleInputChange}
                                    suppressHydrationWarning
                                    id="address"
                                    placeholder="Address"
                                />


                            </div>
                        </Col>

                        <Col lg="6">
                            <div className="mt-3 d-flex" style={styles.box}>
                                <Label for="basicpill-firstname-input1" style={styles.boxLabel} className="control-label me-3" >
                                    Country
                                    <span className="text-danger mx-1">*</span>
                                </Label>
                                <Input
                                    type="text"
                                    className="form-control me-3"
                                    value={state.country}
                                    
                                    // disabled={selectedValue === 'registration_no' ? false : true}
                                    onChange={handleInputChange}
                                    style={styles.boxInput}
                                    suppressHydrationWarning
                                    id="country"
                                    placeholder="Country"
                                />


                            </div>
                        </Col>
                        <Col lg="6">
                            <div className="mt-3 d-flex" style={styles.box} >
                                <Label for="basicpill-firstname-input1" style={styles.boxLabel} className="control-label me-3" >
                                    State/Province
                                    {/* <span className="text-danger mx-1">*</span> */}

                                </Label>
                                <Input
                                    type="text"
                                    className="form-control me-3"
                                    value={state.state}
                                    style={styles.boxInput}
                                    

                                    // disabled={selectedValue === 'registration_no' ? false : true}
                                    onChange={handleInputChange}
                                    suppressHydrationWarning
                                    id="state"
                                    placeholder="State/Province"
                                />


                            </div>
                        </Col>
                        <Col lg="6">
                            <div className="mt-3 d-flex" style={styles.box}>
                                <Label for="basicpill-firstname-input1" style={styles.boxLabel} className="control-label me-3" >
                                    City
                                    <span className="text-danger mx-1">*</span>
                                </Label>
                                <Input
                                    type="text"
                                    className="form-control me-3"
                                    value={state.city}
                                    
                                    // disabled={selectedValue === 'registration_no' ? false : true}
                                    onChange={handleInputChange}
                                    style={styles.boxInput}
                                    suppressHydrationWarning
                                    id="city"
                                    placeholder="City"
                                />


                            </div>
                        </Col>
                        <Col lg="6">
                            <div className="mt-3 d-flex" style={styles.box} >
                                <Label for="basicpill-firstname-input1" style={styles.boxLabel} className="control-label me-3" >
                                    Pincode
                                    {/* <span className="text-danger mx-1">*</span> */}

                                </Label>
                                <Input
                                    type="text"
                                    className="form-control me-3"
                                    value={state.pincode}
                                    style={styles.boxInput}
                                    

                                    // disabled={selectedValue === 'registration_no' ? false : true}
                                    onChange={handleInputChange}
                                    suppressHydrationWarning
                                    id="pincode"
                                    placeholder="Pincode"
                                />


                            </div>
                        </Col>
                        <Col lg="6">
                            <div className="mt-3 d-flex" style={styles.box}>
                                <Label for="basicpill-firstname-input1" style={styles.boxLabel} className="control-label me-3" >
                                    Email ID
                                    <span className="text-danger mx-1">*</span>
                                </Label>
                                <Input
                                    type="text"
                                    className="form-control me-3"
                                    value={state.email}
                                    
                                    // disabled={selectedValue === 'registration_no' ? false : true}
                                    onChange={handleInputChange}
                                    style={styles.boxInput}
                                    suppressHydrationWarning
                                    id="email"
                                    placeholder="Email ID"
                                />


                            </div>
                        </Col>
                        <Col lg="6">
                            <div className="mt-3 d-flex" style={styles.box} >
                                <Label for="basicpill-firstname-input1" style={styles.boxLabel} className="control-label me-3" >
                                    Landline #
                                    {/* <span className="text-danger mx-1">*</span> */}

                                </Label>
                                <Input
                                    type="text"
                                    className="form-control me-3"
                                    value={state.landline}
                                    style={styles.boxInput}
                                    

                                    // disabled={selectedValue === 'registration_no' ? false : true}
                                    onChange={handleInputChange}
                                    suppressHydrationWarning
                                    id="landline"
                                    placeholder="Landline"
                                />


                            </div>
                        </Col>
                        <Col lg="6">
                            <div className="mt-3 d-flex" style={styles.box}>
                                <Label for="basicpill-firstname-input1" style={styles.boxLabel} className="control-label me-3" >
                                    Mobile
                                    {/* <span className="text-danger mx-1">*</span> */}
                                </Label>
                                <Input
                                    type="text"
                                    className="form-control me-3"
                                    value={state.mobile}
                                    
                                    // disabled={selectedValue === 'registration_no' ? false : true}
                                    onChange={handleInputChange}
                                    style={styles.boxInput}
                                    suppressHydrationWarning
                                    id="mobile"
                                    placeholder="Mobile"
                                />


                            </div>
                        </Col>
                        <Col lg="6">
                        <div className="d-flex mt-4" style={{justifyContent:'space-between', width:'60%'}}>
                        <Label for="basicpill-firstname-input1" style={styles.boxLabel} className="control-label me-3" >
                                    Status
                                    {/* <span className="text-danger mx-1">*</span> */}
                                </Label>

                                <div className="form-check me-3 d-flex">
<div className="form-check me-3">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="exampleRadios1"
                      
                            id="exampleRadios7"
                            value="active"
                            defaultChecked
                            onChange={(e)=>{
                              let value = e.target.value;
                              
                              setState({...state, client_status: value});
                            }
                          }
                            
                          />
                          <label
                            className="form-check-label"
                            htmlFor="exampleRadios7"
                          >
                            Active
                          </label>
                        </div>

                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="exampleRadios1"
                            id="exampleRadios2"
                       
                            value="inactive"
                            onChange={(e)=>{
                                let value = e.target.value;
                                
                                setState({...state, client_status: value});
                            }
                          }
                          />
                          <label
                            className="form-check-label"
                            htmlFor="exampleRadios2"
                          >
                            Inactive
                          </label>

              

                        
                        </div>
                        </div>
                   
                     
</div>
                            </Col>



                    </Row>

                    <div className="mt-4"  >
      <button type="button" className="btn btn-primary me-2" onClick={()=>{}}>
        Save
      </button>
      <button type="button" className="btn btn-primary" onClick={()=>{
   
      }}>
        Cancel
      </button>
      </div>

                </CardBody>
            </Card>

        </React.Fragment>
    );
};


export default ClientInfo;

const styles = {

    para: {
        fontSize: 12
    },
    box: {
        fontSize: 16,
        fontWeight: "unset",
        justifyContent: "space-between",
        alignItems: "center",
    },
    boxLabel: {
        fontSize: 13,
        fontWeight: "400",
    },
    boxInput: {
        fontSize: 13,
        width: '70%',
        padding: 8,
        fontWeight: "unset",
    }

}
