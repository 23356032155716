


import React, { useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { get, isEmpty, set } from "lodash";
import "bootstrap/dist/css/bootstrap.min.css";
import * as Yup from "yup";
import { useFormik } from "formik";
import axios from "axios";
import classnames from "classnames";
// import {Buffer} from 'buffer';
// import { PDFDownloadLink, pdf, usePDF, BlobProvider } from '@react-pdf/renderer';
import Accordion from "../../../components/Accordian";
import { TDSDATA, PassbookDetail, EPFO } from "../../../components/IdentityInfo";

import Loader from "../../components/Loader";

import Cookies from "js-cookie";



import {
  Button,
  Col,
  Row,
  UncontrolledTooltip,
  Modal,
  ModalHeader,
  ModalBody,
  Collapse,
  Form,
  Input,
  FormFeedback,
  Label,
  Card,
  CardBody,
  Table,
} from "reactstrap";
import moment from "moment";

//Import Flatepicker
import "flatpickr/dist/themes/material_blue.css";
import Flatpickr from "react-flatpickr";
// import PdfDocViewer from "../components/PdfDocViewer";
// import FeatherIcon from "feather-icons-react";

const component_names = {
  "aadhaar": "Aadhaar Card",
  "pan": "Pan Card",
  "voter_id": "Voter Id",
  "driving_licence": "Driving License",
  "nurse": "Nurse",
  "icai": "ICAI",
  "doctor": "Doctor",


};

function DECaseDetails() {
  //meta title
  document.title = "TheRollNumber";

  const navigate = useNavigate();

  // const [instance, updateInstance] = usePDF({ document: PdfDocViewer });


  const location = useLocation();

  const [isOpen, setIsOpen] = useState({});

  const [uploaded_documents, setUploadedDocuments] = useState([]);


  const [caseData, setCaseData] = useState({});

  const [education, setEducation] = useState([]);

  const [employment, setEmployment] = useState([]);

  const [identity, setIdentity] = useState([]);

  const [professional, setProfessional] = useState([]);

  const [reqData, setReqData] = useState(null);

  const [selectedComponents, setSelectedComponents] = useState([]);

  const [sharedDocs, setSharedDocs] = useState([]);

  const [documents, setDocuments] = useState([]);


  const [user, setUser] = useState({});


const [state, setState] = useState({
  client_status: "no",
});


  const [modalConfirm, setModalConfirm] = useState(false);

  const [modalConfirm2, setModalConfirm2] = useState(false);

  const toggleConfirm = () => setModalConfirm(!modalConfirm);

  const toggleConfirm2 = () => setModalConfirm2(!modalConfirm2);

  const [isLoading, setLoading] = useState(false);



  const [docNames, setDocNames] = useState({
    aadhaar: "Aadhaar Card",
    pan: "Pan Card",
    voter_id: "Voter Id",
    driving_licence: "Driving License",
    nurse: "Nurse",
    icai: "ICAI",
    doctor: "Doctor",


   
  });
 

  // Toggle function to open or close an accordion item
  const toggle2 = (index) => {
    setIsOpen((prevState) => ({
      ...prevState,
      [index]: !prevState[index]
    }));
  };


  const [section, setSection] = useState([

    
  ]);

  const [components, setComponents] = useState([]);
  const [modal, setModal] = useState(false);
  const [modal1, setModal1] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [packages, setPackages] = useState([]);
  const [packageComponents, setPackageComponents] = useState([]);

  const [orderList, setOrderList] = useState([]);
  const [order, setOrder] = useState(null);
  const [col1, setcol1] = useState(true);
  const [col2, setcol2] = useState(false);
  const [col3, setcol3] = useState(false);
  const [col4, setcol4] = useState(false);
  const [col5, setcol5] = useState(false);
  const [col6, setcol6] = useState(false);
  const [col7, setcol7] = useState(false);
  const [col8, setcol8] = useState(false);
  const [col9, setcol9] = useState(false);
  const [col10, setcol10] = useState(false);
  const [col11, setcol11] = useState(false);
  const [col12, setcol12] = useState(false);
  const [col13, setcol13] = useState(false);
  const [col14, setcol14] = useState(false);
  const [col15, setcol15] = useState(false);

  const t_col1 = () => {
    setcol1(!col1);

  };

  const t_col2 = () => {
    setcol2(!col2);


  };

  const t_col3 = () => {
    setcol3(!col3);


  };

  const t_col4 = () => {
    setcol4(!col4);
    setcol1(false);
    setcol2(false);
    setcol3(false)
    setcol5(false)

  };

  const t_col5 = () => {
    setcol5(!col5);
    setcol1(false);
    setcol2(false);
    setcol3(false)
    setcol4(false)

  };

  const t_col6 = () => {
    setcol6(!col6);


  };

  const t_col7 = () => {
    setcol7(!col7);

  }

  const t_col8 = () => {
    setcol8(!col8);

  }

  const t_col9 = () => {
    setcol9(!col9);

  }

  const t_col10 = () => {

    setcol10(!col10);

  }

  const t_col11 = () => {

    setcol11(!col11);

  }

  const t_col12 = () => {

    setcol12(!col12);

  }


  // validation

  const toggleViewModal = () => setModal1(!modal1);

  useEffect(() => {

    get_case();

    get_components();

    getAllPackages();

  }, []);

  useEffect(() => {

    if (Object.keys(caseData).length > 0){

      console.log('caseData==>',caseData);

      get_request_identity_org();
     
      get_requested_data();
    }

  }, [caseData]);



  const get_components = async () => {

    setLoading(true);

    const config = {
      headers: {
        'Content-Type': 'application/json'
      }
    };
   
    axios.get(`${process.env.REACT_APP_BASE_URL}/service/components`, {}, config)

    .then(response => {

      console.log('response components==>',response)

      const data = response.data;

     console.log('data==>',data)

     let arr = [];

      data.map((item)=> {

        arr.push({
          component: item.name,
          code: item.code,
          checked: false,
          id: item.id
        })

      })


      setComponents(arr)

      setLoading(false);




    })

    .catch(error => {

      setLoading(false);

      console.log('error from server==>',error)

    })


  }


  const get_requested_data = async () => {

    console.log('caseData',caseData);

    const config = {
      headers: {
        'Content-Type': 'application/json',

      }
    };

    const body = {

      org_user_client_id: caseData.org_client_id,
    
    }

    console.log('body requested ==>',body);

    axios.post(`${process.env.REACT_APP_BASE_URL}/service/requestedData/${caseData.walletID}`, body, config)
    .then(response => {
      console.log('requested data',response);

      let data = response.data.data;

      console.log('data==>',data);



      setSharedDocs(data);

      

    })
    .catch(error => {
  
      
  
      console.log('error==>',error);
    });

 
  }


  const get_request_identity_org = async () => {

    const org_client_id = caseData?.org_client_id;

    console.log('org_client_id',org_client_id);

    const config = {
      headers: {
        'Content-Type': 'application/json'
      }
    };


    
    axios.get(`${process.env.REACT_APP_BASE_URL}/service/request_identity_org/${org_client_id}`, {}, config)
    .then(response => {
      console.log('requested identity org response',response);

      let data = response.data.data[0];

      console.log('request identity data==>',data);

      let req_data = JSON.parse(data.req_data);
      console.log('req_data==>',req_data);

      setReqData(req_data);  


    })
    .catch(error => {
  
      
  
      console.log('error==>',error);
    });

  }

  const get_wallet = async (value) => {

    const config = {
      headers: {
        'Content-Type': 'application/json'
      }
    };

  
    axios.get(`${process.env.REACT_APP_BASE_URL}/service/wallet/${value}`, {}, config)
    .then(response => {
      console.log('wallet data',response.data);

      let data = response.data;

      console.log('data==>',data);

      setUser(data.user);



    })
    .catch(error => {
  
      
  
      console.log('error==>',error);
    });

  }

  const get_case = async () => {

   
    const data = location.state;

    console.log('param data',data)

    if (data) {
      setCaseData(data);
      get_wallet(data.walletID);
    }



  }


  // const [isLoading, setLoading] = useState('')

  const getAllPackages = async () => {

   
    console.log('packages');
  
    const config = {
      headers: {
        'Content-Type': 'application/json'
      }
    };


   await axios.get(`${process.env.REACT_APP_BASE_URL}/service/packages`, {}, config)
    .then(response => {
      console.log('packages response',response);

      

setPackages(response.data);
  
    })
    .catch(error => {
  
      console.log('error==>',error);
    });
  
  }


  const get_package_details = async (package_id) => {

    console.log('package_id==>',package_id);

    const config = {
      headers: {
        'Content-Type': 'application/json'
      }
    };
   
    await axios.get(`${process.env.REACT_APP_BASE_URL}/service/package_detail/${package_id}`, {}, config)

    .then(async response => {

      console.log('response package detail from server==>',response)

      let data = response.data[0];

      console.log('package components data==>',data);

      let identityArr = []; 

      let professionalArr = [];


          let req_data = JSON.parse(data.req_data);

          console.log('req_data==>',req_data);

          if(req_data.identity){

            req_data.identity.map((item)=> {

              identityArr.push({
                component: docNames[item],
                checked: false
              })
            })

          }

          if(req_data.professional_identity){

            req_data.professional_identity.map((item)=> {

              professionalArr.push({
                component: docNames[item],
                checked: false
              })
            })

          }


          setIdentity(identityArr);
          setProfessional(professionalArr);



    })

    .catch(error => {

      console.log('error from server==>',error)
    })

  }


  const toggle = () => {
    if (modal) {
      setModal(false);
      setOrder(null);
    } else {
      setModal(true);
    }
  };


  //delete order
  const [deleteModal, setDeleteModal] = useState(false);

  const onClickDelete = (order) => {
    setOrder(order);
    // setDeleteModal(true);
  };

  const handleDeleteOrder = () => {
    if (order && order.id) {
      // dispatch(onDeleteOrder(order.id));
      // setDeleteModal(false);
      setOrder("");
    }
  };
  const handleOrderClicks = () => {
    setOrderList("");
    setIsEdit(false);
    toggle();
  };

  const update_case = async(status) => {

    console.log('status==>',status);

    const config = {
      headers: {
        'Content-Type': 'application/json'
      }
    };

    const {notification_id} = caseData;

    const body = {

      notification_id: notification_id,
      verification_status: status

    }

    console.log('update case body==>',body);

  
    axios.put(`${process.env.REACT_APP_BASE_URL}/service/case_status`, body, config)
    .then(response => {
      console.log('update case res',response);


      navigate('/on-going-cases');



    })
    .catch(error => {
  
      
  
      console.log('update case error==>',error);
    });

  }

  useEffect(() => {

    if(components.length > 0 && reqData !== null && sharedDocs.length > 0){

      console.log('set selected components==>',components);

      let arr = [...components];

      let selectedArr = [];

      console.log('arr==>',arr);

      let reqDataArr = Object.keys(reqData);

      console.log('reqDataArr==>',reqDataArr);

      console.log('sharedDocs==>',sharedDocs);

      let employment = sharedDocs.findIndex((item)=> {

         console.log('share doc item==>',Object.keys(item));
          
          return Object.keys(item)[0] === 'employment'

      });

      let tds = sharedDocs.findIndex((item)=> Object.keys(item)[0] === 'tds');

      let epfo = sharedDocs.findIndex((item)=> Object.keys(item)[0] === 'epfo');

      let education = sharedDocs.findIndex((item)=> Object.keys(item)[0] === 'education');

      let credit_score = sharedDocs.findIndex((item)=> Object.keys(item)[0] === 'credit_score');

      if(credit_score !== -1){

        let index = arr.findIndex((element)=> element.component === 'Credit');

        console.log('index==>',index);

        if(index !== -1){

          selectedArr.push(arr[index]);

        }

      }


      if(education !== -1){
          

            let index = arr.findIndex((element)=> element.component === 'Education');
  
            console.log('index==>',index);
  
            if(index !== -1){
  
              selectedArr.push(arr[index]);
  
            }
  
          
      }

      if(employment !== -1){
          
  
        let index = arr.findIndex((element)=> element.component === 'Employment');

        console.log('index==>',index);


        if(index !== -1){

          selectedArr.push(arr[index]);

        }

      
  }

  if(tds !== -1){

    let index = arr.findIndex((element)=> element.component === 'TDS');

    console.log('index==>',index);

    if(index !== -1){

      selectedArr.push(arr[index]);

    }

  }

  if(epfo !== -1){

    let index = arr.findIndex((element)=> element.component === 'EPFO');

    console.log('index==>',index);

    if(index !== -1){

      selectedArr.push(arr[index]);

    }

  }

      if(reqData.identity){

        reqData.identity.map((item)=> {

          let index = arr.findIndex((element)=> element.code == item);

          console.log('selected index==>',index, arr[index]);


          if(index !== -1){

            selectedArr.push(arr[index]);

          }

        })

      }

      if(reqData.professional_identity){

        reqData.professional_identity.map((item)=> {

          let index = arr.findIndex((element)=> element.code == item);

          console.log('selected index==>',index, arr[index]);

          if(index !== -1){

            selectedArr.push(arr[index]);

          }

        })

      }

      setSelectedComponents(selectedArr);

      console.log('arr==>',arr);

      // reqDataArr.map((item)=> {

      //   let index = arr.findIndex((element)=> element.component === item);

      //   console.log('index==>',index);

      //   if(index !== -1){

      //     arr[index].checked = true;

      //   }

      // })

      // setComponents(arr);

    }

  }, [components, reqData, sharedDocs]);

  const updateAssignCase = async () => {

    const config = {
      headers: {
        'Content-Type': 'application/json'
      }
    };

    const body = {
      case_id: caseData.request_id,
      assigned_id: caseData.assigned_id,
      org_client_id: caseData.org_client_id,
      description: 'Case Assigned to process team leader',
      case_status: 'complete'
     
    }

    console.log('update case==>', body)

    axios.put(`${process.env.REACT_APP_BASE_URL}/employer/admin/assignCase`, body, config)

    .then(response => {

      console.log('update assign case==>',response)

       setLoading(false);

      alert('Case Assigned to Process Team Leader')

       navigate('/admin/my-cases')
   

    })

    .catch(error => {

      setLoading(false);

      console.log('error from server==>',error)

    })
  }

  const handleComponentSubmit = async () => {

    const user = JSON.parse(Cookies.get('authUser'))


    setLoading(true);

    const config = {
      headers: {
        'Content-Type': 'application/json'
      }
    };

    const body = {
      case_id: caseData.request_id,
      org_emp_id: user.user_id,
      case_status: 'Assigned',
      org_client_id: caseData.org_client_id,
      team: 'Process Team Leader',
     
    }

    console.log('submit case==>', body)

    axios.post(`${process.env.REACT_APP_BASE_URL}/employer/admin/assignCase`, body, config)

    .then(response => {

      console.log('assign to process team lead==>',response)

      updateAssignCase();
   

    })

    .catch(error => {

      setLoading(false);

      console.log('error from server==>',error)

    })
  }

  
const send_bgv = async () => {


  try {

    const arr = {
      'aadhaar': 'identity',
      'pan': 'identity',
      'voter_id': 'identity',
      'driving_licence': 'identity',
      'nurse': 'professional_identity',
      'icai': 'professional_identity',

      'doctor': 'professional_identity',

    }

    console.log('send bgv', components);

  // const title = JSON.parse(localStorage.getItem('authUser')).user.company_name;

  // const location = JSON.parse(localStorage.getItem('authUser')).user.company_location;

  


  const config = {
    headers: {
      'Content-Type': 'application/json'
    }
  };

  const req_data = {
    education: {
        type_of_graduation: "Degree/Bachelors",
        from:"2023-11",
        to:"2023-11"
    },
    
    identity: [],
   professional_identity: [],
    // court_record: courtRecord,
    // address: address, 
    employment: {
        tds: false,
        epfo: false,
        history_type:"",
        companies_list_from: {
            from: "2023-11",
            to: "2023-11"
        }
    }
}


components.map((item, index) => {

  if(item.checked){

    if(arr[item.code] === 'identity'){

      req_data.identity.push(item.code);

    }else if(arr[item.code] === 'professional_identity'){

      req_data.professional_identity.push(item.code);

    }

  }

})

  // if(identity.length > 0){

  //   identity.map((item, index) => {
  //     if(item.checked){
  //       req_data.identity.push(item.doc_name);
  //     }
  //   })

  // }

  //   employment.map((item, index) => {

  //     console.log('item==>',item);


  //     if(item.doc_name == 'tds' && item.checked){


  //       req_data.employment.tds = true;

  //     }

  //     if(item.doc_name == 'epfo' && item.checked){

  //       req_data.employment.epfo = true;


  //     }

  //   })



  // if(proIdentity.length > 0){

  //   proIdentity.map((item, index) => {

  //     if(item.checked){

  //       req_data.professional_identity.push(item.doc_name);

  //     }

  //   })

  // }



let body = {
  id_identity_user: caseData.walletID,
  org_id: caseData.org_id,
  title: caseData.org_name,
  email: caseData.email,
  education: false,
  employment: false,
  credit_score: false,
  notification_id: caseData.notification_id,
  request_id: caseData.request_id,
  requester_category: 'emp',
  identity: false,
  professional_identity: false,
  req_data: JSON.stringify(req_data),
  requester_fcm_token:"23423f32423423",
  company_location: '',
  candidate_dob: '',
  candidate_name: `${caseData.candidate_name}`,
  packageType: "À la carte",
  candidate_father_name: '',
  body:"Request for documents"

  
}

// employment.map((item, index) => {

//   console.log('item==>',item);

//   if(item.doc_name == 'history' && item.checked){

//     body.employment = true;
//     req_data.employment.history_type = state.history_type_emp;

//   }
// })


// if(body.employment){

//   console.log('state.history_type_emp==>',state.history_type_emp);

//   if(state.history_type_emp == 'custom'){

//     req_data.employment.companies_list_from.from = empHistory.from;
//     req_data.employment.companies_list_from.to = empHistory.to;

//   }
// }

body.req_data = JSON.stringify(req_data);


if(req_data.identity.length > 0){

  body.identity = true;



}

if(req_data.professional_identity.length > 0){

  body.professional_identity = true;

}


console.log('body==>',body, caseData);


// education.map((item, index) => {

//   if(item.checked){

//     body.education = true;

//   }

// })


// console.log('body==>',body);


if(body.identity == false && body.professional_identity == false){
  
    alert('Please select at least one component');
  
  
    return;
  
  
}



  send_bgv_request(body);




    
  } catch (error) {

    // setIsLoading(false);

    
  }



}


const send_bgv_request = async (body) => {


  console.log('send bgv request');

  const config = {
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*'
    }
  };


console.log('body==>',body);

// https://trnapi.therollnumber.com

 await axios.put(`${process.env.REACT_APP_BASE_URL}/service/admin/update_request_identity_org`, body, config)
  .then(response => {
    console.log('bgv response',response);


    navigate('/admin/my-cases')

  })
  .catch(error => {


    alert('unable to send request')

    console.log('error==>',error);
  });

}


useEffect(() => {

  if(selectedComponents.length > 0){

    let documents = []

selectedComponents.map((item, index) => {

   let comp = sharedDocs.findIndex((doc)=> Object.keys(doc)[0] === item.code);

    console.log('comp==>',comp);

    let componentData = sharedDocs[comp][item.code];

    console.log('componentData==>',componentData);

        Object.keys(componentData).map((key)=> {

        
        if(key.includes('payslip_path') || key.includes('offer_letter_path') || key.includes('exp_letter_path')){

          let obj = {
            payslip_path: "Payslip",
            offer_letter_path: "Offer Letter",
            exp_letter_path: "Experience Letter"
          }

          if(componentData[key] != null && componentData[key] != ''){


          documents.push({
            name: obj[key],
            value: componentData[key],
            case_id: caseData.request_id,
            component_id: caseData.component_id,
            sub_component_id: caseData.sub_component_id,
            assigned_id: caseData.assigned_id,
            selected: false,
            description: '',
            uploaded_by: 'candidate'


          });

        }

        }else if(key.includes('uploaded_document')){


          if(componentData[key] != null && componentData[key] != ''){

          documents.push({

            name: item.component,
            selected: false,
            value: componentData[key],
            case_id: caseData.request_id,
            component_id: caseData.component_id,
            sub_component_id: caseData.sub_component_id,
            assigned_id:caseData.assigned_id,
            description: '',
            uploaded_by: 'candidate'

          });

        } 
          
          }else if(key.includes('original_degree')){


            if(componentData[key] != null && componentData[key] != ''){

            documents.push({

              name: 'Degree',
              selected: false,
              value: componentData[key],
              case_id: caseData.request_id,
              component_id: caseData.component_id,
              sub_component_id: caseData.sub_component_id,
              assigned_id:caseData.assigned_id,
              description: '',
              uploaded_by: 'candidate'

            });

          } 
            
            }   

      
    })




})

setUploadedDocuments(documents);



  }

  // sharedDocs.findIndex((item)=> Object.keys(item)[0] === 'epfo');

  console.log('uploaded documents', selectedComponents, sharedDocs )

}, [selectedComponents]);


  return (
    <React.Fragment>
      {/* <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteOrder}
        onCloseClick={() => setDeleteModal(false)}
      /> */}
      <div className="page-content">
        <div className="container-fluid">
          {/* <Breadcrumbs title="Ecommerce" breadcrumbItem="Orders" /> */}

          {
            isLoading && <Loader />
          }

          <Row>
            <Col lg="12">
              <div className="d-flex mb-4" style={{justifyContent:'space-between'}}>
                <h2 className="page-title">{caseData?.candidate_name}</h2>

                <button className="btn btn-primary"  onClick={()=>navigate('/on-going-cases')}>back</button>

              </div>
            <div className="table-responsive">
                    <Table className="table mb-3">
                      <thead className="table-dark" >
                        <tr>
                          <th>Case Ref.#</th>

                          <th>Client Name</th>

                          <th>Location</th>

                          <th>Case In Date</th>
                          <th>Case Due Date</th>

                          <th>Father's Name</th>
                          <th>Dob</th>
                          <th>Client Employee ID</th>

                        </tr>
                      </thead>
                      <tbody>
                        {caseData &&<tr className="table-light">
                          <th >{caseData?.request_id}</th>
                          <td>{caseData?.location}</td>
                          <td>{moment(caseData?.timestamp).format('DD-MM-YYYY')}</td>
                          <td></td>
                          <td>{caseData?.candidate_name}</td>

                          <td>{caseData?.candidate_father_name}</td>
                          <td>{caseData?.candidate_dob}</td>
                          <td></td>
                        </tr>}
                    
                      </tbody>
                    </Table>
                  </div>


              </Col>
          </Row>

          <Row>
            <Col lg="6">

            <Card>
                  <CardBody>

                    <div className="">
                    <p style={{fontSize:16}} className="mb-4">Components Service</p>

                    <div className="mb-3">
                        <Label className="form-label">Select Package</Label>
    <select className="form-select"
    
    onChange={(e)=> {
  
      let value = e.target.value;
  
      console.log('package value==>',value);

      if(value !== ''){

        get_package_details(value);

      }else{
        setIdentity([]);
        setProfessional([]);
      
      }

}}>
  <option value="">Please Select Package</option>

  {
    packages.map((item, index) => {

      return (

        <option key={index} value={item.package_id}  >{item.package_name}</option>

      )

    })

  }



</select>
      </div>



{identity.length> 0 && <Table striped bordered >

          <thead>
            <tr>
              <th style={{width:100}}>Select</th>
              <th>Identity</th>
            </tr>
          </thead>

          <tbody>
            {identity.map((item, index) => (
              <tr key={item}>
                <td>
                  <input
                    type="checkbox"

                    checked={true}
                  

                  />
                  </td>
                <td>{item.component}</td>
              </tr>

            ))}

            </tbody>

  </Table>}

  {professional.length >0 && <Table striped bordered >

<thead>
  <tr>
    <th style={{width:100}}>Select</th>
    <th>Professional Identity</th>
  </tr>
</thead>

<tbody>
  {professional.map((item, index) => (
    <tr key={item}>
      <td>
        <input
          type="checkbox"

          checked={true}
        

        />
        </td>
      <td>{item.component}</td>
    </tr>

  ))}

  </tbody>

</Table>}

        
                    </div>
          
                    </CardBody>
                </Card>

            
            </Col>
            <Col lg="6">


            <Card>
                  <CardBody>
            <div className="d-flex" style={{justifyContent:'space-between'}}>
                    <Table bordered >
          <thead>
            <tr>
              <th style={{width:100}}>Select</th>
              <th>Component Name</th>
            </tr>
          </thead>
          <tbody>
            {components.map((item, index) => (
              <tr key={item}>
                <td>
                  <input
                    type="checkbox"
                    checked={item.checked}
                    onChange={() => {
                       
                      let arr = [...components]

                      arr[index].checked = !arr[index].checked

                      setComponents(arr)
                    }}
                  />
                </td>
                <td 
                style={{
                  background: selectedComponents.includes(item) ? 'lightgreen' : 'white',
                }}
                ><div >{item.component}</div></td>
              </tr>
            ))}
          </tbody>
        </Table>


                    </div>
                    </CardBody>
                    </Card>

  

               
            </Col>
          </Row>
<Row>
  <Col lg="12">

  <Card>
                  <CardBody>
                  <p style={{fontSize:15, fontWeight:500}} className="mb-3">Uploaded Documents</p>

                  <Table bordered  style={{
                      borderWidth:1,
                    }}>

          <thead>
            <tr >
              {/* <th>Show in report</th> */}
              <th>File Name</th>
              <th>Description</th>
              {/* <th>Component</th> */}
              <th>Uploaded By</th>
              <th>Uploaded On</th>
              <th>Action</th>


            </tr>

          </thead>

          <tbody >


          {
            uploaded_documents.length > 0 && uploaded_documents.map((item, index) => {
                
                return <tr key={index}>
                  {/* <td>

                  <Input className="form-check-input" type="checkbox" onClick={()=> {
           
                      let obj = {...componentData}

                      console.log('select component data', componentData)

                       let newDocs = obj.documents

                       newDocs[index].selected = !newDocs[index].selected;

  
                      setComponentData(obj)
  
                    }} 

            
            checked={item.selected}   />
                  </td> */}
                <td>{item.name}</td>
                <td>{item.description}</td>
                {/* <td></td> */}
                <td>{item.uploaded_by}</td>
                <td></td>
                <td><button 
                onClick={()=>{
                  window.open(item.value, '_blank')

                }}
                className="btn btn-primary btn-sm">View</button></td>
              </tr>
            })
          }
   
           </tbody>

                      </Table>
                  
                      <div className="d-flex" style={{
                        justifyContent:'space-between',
                        width:'40%',
                        marginTop:80
                      
                      }}>
                      <button className="btn btn-primary" >Proceed to Data Entry</button>
  <button className="btn btn-primary" onClick={send_bgv} >Send to Candidate</button>
  <button className="btn btn-primary" 
  
  onClick={handleComponentSubmit}

  >Submit Case</button>
                        </div>
                  </CardBody>
                </Card>
  </Col>  
                
</Row>
    
        </div>
      </div>

    </React.Fragment>
  );
}
DECaseDetails.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
};

export default DECaseDetails;
