import React, {useState, useEffect} from 'react';
import {Text, View, StyleSheet, Image } from '@react-pdf/renderer';



import CheckIcon from '../../report_images/check-icon.png'
import RedIcon from '../../report_images/pdf/Red.png'
import YellowIcon from '../../report_images/pdf/Yellow.png'
import AmberIcon from '../../report_images/pdf/Amber.png'

const styles = StyleSheet.create({
   
    titleContainer:{
        flexDirection: 'row',
        justifyContent:'center',
        marginTop: 30
    },
    reportTitle:{
        fontSize: 13,
        color:'#fff',
        marginTop: 5,
        paddingHorizontal:5,
        textAlign: 'center',
      
    }
  });


  const InvoiceThankYouMsg = (props) => {

    const [colors, setColors] = useState(
        {
          'Green': 'green',
          'Red': 'red',
          'Yellow': 'yellow',
          'Amber': '#FFBF00',
        }
      );

const [colorcode, setColorCode] = useState('')


useEffect(() => {

    // set color code based on severity of case

    let data = props.qcData;

   let red_index = data.findIndex((item) => item.colorcode === 'Red')
   
   let yellow_index = data.findIndex((item) => item.colorcode === 'Yellow')

    let amber_index = data.findIndex((item) => item.colorcode === 'Amber')

    let green_index = data.findIndex((item) => item.colorcode === 'Green')

    if(red_index !== -1){

        setColorCode('Red')

    }else if(yellow_index !== -1){

        setColorCode('Yellow')

    }else if(amber_index !== -1){

        setColorCode('Amber')

    }else if(green_index !== -1){

        setColorCode('Green')

    }else{

        setColorCode('Green')
    }
    





}   

, [])


    return (
    <View style={styles.titleContainer}>
        <View style={{
           
            height: 30,
         borderWidth: 1,
         width:'35%',
         flexDirection:'row',
         borderRadius:20,
          justifyContent:'center',
            borderColor: colors[colorcode],
            
            alignItems: 'center',
       
        }}>

{
    colorcode === 'Green' ? <Image src={CheckIcon} style={{width: 15, marginLeft:5, objectFit:'contain'}}/> : null
}

{
    colorcode === 'Red' ? <Image src={RedIcon} style={{width: 20, marginLeft:5, objectFit:'contain'}}/> : null

}

{

    colorcode === 'Yellow' ? <Image src={YellowIcon} style={{width: 20, marginLeft:5, objectFit:'contain'}}/> : null

}

{

    colorcode === 'Amber' ? <Image src={AmberIcon} style={{width: 20, marginLeft:5, objectFit:'contain'}}/> : null

}

                   <Text style={styles.reportTitle}>Verified | {colorcode} Case</Text>


        </View>
    </View>
    );
  };
  
  export default InvoiceThankYouMsg