import React from 'react';
import {Text, View, StyleSheet, Image } from '@react-pdf/renderer';

import CheckIcon from '../../report_images/check-icon.png'

const styles = StyleSheet.create({
   
    titleContainer:{
        flexDirection: 'row',
        justifyContent:'center',
        marginTop: 30
    },
    reportTitle:{
        fontSize: 13,
        color:'#fff',
        marginTop: 5,
        paddingHorizontal:5,
        textAlign: 'center',
      
    }
  });


  const InvoiceThankYouMsg = () => (
    <View style={styles.titleContainer}>
        <View style={{
           
            height: 30,
         borderWidth: 1,
         width:'35%',
         flexDirection:'row',
         borderRadius:20,
          justifyContent:'center',
            borderColor: 'green',
            
            alignItems: 'center',
       
        }}>
            <Image src={CheckIcon} style={{width: 15, marginLeft:5, objectFit:'contain'}}/>
                   <Text style={styles.reportTitle}>Verified | Green Case</Text>


        </View>
    </View>
  );
  
  export default InvoiceThankYouMsg