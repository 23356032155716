import React from "react"

import { Row, Col, Card, CardBody } from "reactstrap"
import { Link } from "react-router-dom"

import avatar1 from "../../../assets/images/companies/img-1.png"
import profileImg from "../../../assets/images/profile-img.png"
import { useSelector } from "react-redux"
import Cookies from "js-cookie"



const WelcomeComp = ({user, univ}) => {

  const profile = ''

  const [profileData, setProfileData] = React.useState(null)

  React.useEffect(() => {

    const data = JSON.parse(Cookies.get('authUser'))

    setProfileData(data)

  }, [])

  return (
    <React.Fragment>
      <Card className="overflow-hidden">
        <div className="bg-primary bg-soft">
          <Row>
            <Col xs="7">
              <div className="text-primary p-3">
                <h5 className="text-primary">Welcome Back !</h5>
                <p>{profileData?.name}</p>
              </div>
            </Col>
            <Col xs="5" className="align-self-end">
              <img src={profileImg} alt="" className="img-fluid" />
            </Col>
          </Row>
        </div>
        <CardBody className="pt-0">
          <Row>
            <Col sm="4">
              <div className="avatar-md profile-user-wid mb-4">
                {
                  profile?
                  <img
                  src={profile}
                  alt=""
                  className="img-thumbnail rounded-circle"
                />:
                <img
                src={avatar1}
                alt=""
                className="img-thumbnail rounded-circle"
              />
                }
              
              </div>
              <h5 className="font-size-15 text-truncate">{user?.name}</h5>
            </Col>

            <Col sm="8">
              <div className="pt-4">
                {/* <Row>
                  <Col xs="6">
                    <h5 className="font-size-15">125</h5>
                    <p className="text-muted mb-0">Projects</p>
                  </Col>
                  <Col xs="6">
                    <h5 className="font-size-15">$1245</h5>
                    <p className="text-muted mb-0">Revenue</p>
                  </Col>
                </Row> */}
                <div className="mt-4">
                  <Link
                    to="/admin/profile"
                    className="btn btn-primary  btn-sm"
                  >
                    View Profile <i className="mdi mdi-arrow-right ms-1"></i>
                  </Link>
                </div>
              </div>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </React.Fragment>
  )
}
export default WelcomeComp
