import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Link, useNavigate } from "react-router-dom";
import { Card, CardBody, Col, Container, Row, Table } from "reactstrap";
import { isEmpty, map, set } from "lodash";
import Dropzone from "react-dropzone";
import * as XLSX from 'xlsx';
import Papa from 'papaparse';
import axios from 'axios';
import Loader from "../components/Loader";




const ExcelImport = (props) => {
  //meta title
  document.title =
    "TheRollNumber";


    const [data, setData] = React.useState([]);

    const [loading, setLoading] = React.useState(false);


  const Navigate = useNavigate();

    const [zipFile, setZipFile] = React.useState(null)


  const printInvoice = () => {
    window.print();
  };

  const fetch_Wallet_details = async (value) => {
    const config = {
      headers: {
        'Content-Type': 'application/json'
      }
    };
   
    axios.get(`${process.env.REACT_APP_BASE_URL}/service/wallet_details/1`, {}, config)

    .then(response => {

      console.log('response from server==>',response)

      setLoading(false)

      Navigate('/client/verify')

      

    })

    .catch(error => {

      setLoading(false)

      console.log('error from server==>',error)


    })
  }

  const handleOrderClicks = async(data) => {

    console.log('upload excel data',data)

    setLoading(true)

    const body = {

      emp_id: 1,

      csvData: data

    }

    const config = {
      headers: {
        'Content-Type': 'application/json'
      }
    };

    console.log('body',body)
   
    axios.post(`${process.env.REACT_APP_BASE_URL}/service/wallet`, body, config)

    .then(response => {

      console.log('response from server==>',response)

      fetch_Wallet_details()


    })

    .catch(error => {

      setLoading(false)

      console.log('error from server==>',error)


    })
  };

  const search_wallet = async (value) => {

    return new Promise((resolve, reject) => {
 
     const config = {
       headers: {
         'Content-Type': 'application/json'
       }
     };
 
 
     axios.get(`${process.env.REACT_APP_BASE_URL}/service/wallet/${value}`, {}, config)
     .then(response => {
       console.log('wallet data',response.data);
 
       let data = response.data;
 
       console.log('data==>',data);
 
       resolve(data);
       
 
     })
     .catch(error => {
   
       reject(error);
   
       console.log('error==>',error);
     });
 
   });
 
 
 
   }

  const fetchWallet = async(walletData) => {

    console.log('walletData',walletData)

    let arr = []


    let promises = []

    walletData.map((item) => {

    let promise =  new Promise((resolve, reject) => {


      search_wallet(item.walletID)
      .then((data) => {
        console.log('data',data?.user);

        arr.push({
          walletID: item.walletID,
          packageType: item.packageType,
          email: item.email,
          candidate_name: data?.user?.first_name,
          candidate_father_name: item.candidate_father_name,
          candidate_dob: item.candidate_dob,
          search_status: data?.user !== undefined ? 'Found' : 'Not Found',
        
        })

        resolve();


      })
      .catch((error) => {

        arr.push({
          walletID: item.walletID,
          packageType: item.packageType,
          email: item.email,
          canidate_father_name: item.canidate_father_name,
          candiate_dob: item.candiate_dob,
          candidate_name: data?.user?.first_name,
          search_status:  data?.user !== undefined ? 'Found' : 'Not Found',

        })

        resolve();
        console.log('error',error);
      })




    })

    promises.push(promise);

    
    });

    Promise.all(promises)
    .then(() => {
      console.log('arr',arr);

     handleOrderClicks(arr)

    })

  }

  const handleFileUpload = (file) => {
    
    const reader = new FileReader();

    reader.onload = (evt) => {
      // Parse data
      const bstr = evt.target.result;
      const wb = XLSX.read(bstr, { type: 'binary' });
      // Get first worksheet
      const wsname = wb.SheetNames[0];
      const ws = wb.Sheets[wsname];
      // Convert array of arrays
      const csvData = XLSX.utils.sheet_to_csv(ws);
      // Parsing CSV data with Papa Parse
      Papa.parse(csvData, {
        complete: (results) => {
          console.log('Parsed Results:', results.data);

          fetchWallet(results.data)
          // setData(results.data);
        },
        header: true,
      });
    };

    reader.readAsBinaryString(file);
  };

  async function handleAcceptedFiles(files) {
    console.log(files)
    
    setZipFile(files)
    
    // const extractedFiles = await unzip(files[0]);
    
    // setMatch(true)
    
    // setEnable(true)
    
    // setSuccessModal(true)
    
    
      }

      const importExcel = async() => {

        console.log(zipFile)

        handleFileUpload(zipFile[0])

        // Navigate('/verify', {state: {zipFile: zipFile}})

      }

  return (
    <React.Fragment>
      <div className="page-content">
{
  loading && <Loader />
}
        <Container fluid>
          {/* Render Breadcrumbs */}
          {/* <Breadcrumbs title="Invoices" breadcrumbItem="Invoice Detail" /> */}
          { (
            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>
                
                
                    <div className="py-2">
                      <h3 className="fw-600" style={{fontSize:20}}>Add Candidate</h3>
                    </div>

<div style={{width:'50%'}}>
  <h3 className="mb-3" style={{ fontWeight:'normal', fontSize:16}}>Upload Excel</h3>

  <p style={styles.para}>Please upload the document in the same format as that of sample provided</p>
  <p style={styles.para}>First Name, Last Name,Email ID,Category, Gender,Client Location fields are Mandatory in Excel</p>
  <p style={styles.para}>The Correct format of Date of Birth and Date of Joining should be in dd/mm/yyyy</p>
  <p style={styles.para}>EPFO Number should be 12 digit numerical value</p>
  <p style={styles.para}>You Can import maximum 100 Candidates at a time through Excel</p>

<div style={{width: '40%'}}>
<Dropzone

                     
                        onDrop={acceptedFiles => {
                          handleAcceptedFiles(acceptedFiles)
                        }}
                      >
                        {({ getRootProps, getInputProps }) => (
                          <div className="dropzone">
                            <div
                              className="dz-message needsclick mt-2"
                              {...getRootProps()}
                            >
                              <input {...getInputProps()} />
                              <div className="mb-3">
                                <i className="display-4 text-muted bx bxs-cloud-upload" />
                              </div> 



                        


                              <p style={{fontSize:14}}>Click here to upload</p>
                            </div>
                          </div>
                        )}
                      </Dropzone>
</div>


<button onClick={importExcel} className="btn btn-primary mt-3">Save & Continue</button>
</div>
                    
           
                  </CardBody>
                </Card>
              </Col>
            </Row>
          )}
        </Container>
      </div>
    </React.Fragment>
  );
};


export default ExcelImport;

const styles = {

  para:{
    fontSize:12
  }

}
