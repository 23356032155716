import React from "react";
import { Navigate } from "react-router-dom";



// Authentication related pages
import Login from "../pages/Authentication/Login";
import AdminLogin from "../Admin/pages/Authentication/Login";


import Credential from "../pages/credential";
import Search from "../pages/search";
import History from "../pages/history";
import EmailVerification from "../pages/Authentication/auth-email-verification";
import AdminEmailVerification from "../Admin/pages/Authentication/auth-email-verification";

import Dashboard from "../pages/Dashboard";
import Report from "../pages/report";
import OnGoingCases from "../pages/OnGoingCases";
import ExcelImport from "../pages/ExcelImport";
import Verify from "../pages/Verify";
import Cases from "../pages/cases";
import EditProgress from "../pages/EditProgress";
import Account from "../pages/Account";
import PdfDocViewer from "../components/PdfGenerator/App";
import ClosedCases from "../pages/ClosedCases";

import AdminDashboard from "../Admin/pages/DashboardAdmin";
import ListCases from "../Admin/pages/Cam/ListCases";
import MyCases from "../Admin/pages/DataEntryTeam/MyCases";
import CreateCase from "../Admin/pages/DataEntryTeam/CreateCase";
import NewCasesByClient from "../Admin/pages/DataEntryTeam/NewCasesByClient";
import LeaderCases from "../Admin/pages/ProcessTeam/LeaderCases";
import MemberCases from "../Admin/pages/ProcessTeam/MemberCases";
import QcCaseLevel from "../Admin/pages/QC/QcCaseLevel";
import QcComponentLevel from "../Admin/pages/QC/QcComponentLevel";
import ManageUsers from "../Admin/pages/Settings/ManageUsers";
import ManageClients from "../Admin/pages/Settings/ManageClients";
import ManageCompanies from "../Admin/pages/Settings/ManageCompanies";
import ManageLocations from "../Admin/pages/Settings/ManageLocations";
import ManageLocationsAdmin from "../Admin/pages/Settings/ManageLocations";
import Profile from "../Admin/pages/Account/Profile";
import CaseDetails from "../Admin/pages/Cam/CaseDetails";
import AddNewUser from "../Admin/pages/Settings/AddNewUser";
import DECaseDetails from "../Admin/pages/DataEntryTeam/DECaseDetails";
import MemberCaseDetail from "../Admin/pages/ProcessTeam/MemberCaseDetail";
import QcCaseDetail from "../Admin/pages/QC/QcCaseDetail";
import QcCompDetail from "../Admin/pages/QC/QcCompDetail";
import PdfDocViewerAdmin from "../Admin/components/PdfGenerator/App";
import ReportAdmin from "../Admin/pages/report";
import ViewTds from "../Admin/pages/ProcessTeam/ViewTds";
import ViewEpfo from "../Admin/pages/ProcessTeam/ViewEpfo";
import EmailSettings from "../Admin/pages/Account/EmailSettings";
import AdminVerify from "../Admin/pages/Verify";
import AdminCases from "../Admin/pages/cases";
import EditClient from "../Admin/pages/Settings/EditClient/index";


const authProtectedRoutes = [


  // client
  // { path: "/credential/:univ/:id", component: <Credential /> },
  { path: "/client/search", component: <Search /> },
  { path: "/client/history", component: <History /> },
  { path: "/client/dashboard", component: <Dashboard /> },
  { path: "/client/view-report/:id", component: <Report /> },
  { path: "/client/on-going-cases", component: <OnGoingCases /> },
  { path: "/client/closed-cases", component: <ClosedCases /> },

  { path: "/client/excel-import", component: <ExcelImport /> },
  { path: "/client/verify", component: <Verify /> },
  { path: "/client/cases", component: <Cases /> },
  { path: "/client/edit-progress", component: <EditProgress /> },
  { path: "/client/account", component: <Account /> },
  { path: "/client/report", component: <PdfDocViewer /> },

  // admin

 { path: "/admin/dashboard", component: <AdminDashboard /> },
 { path: "/admin/verify", component: <AdminVerify />},
  { path: "/admin/cases", component: <AdminCases /> },


  { path: "/admin/list-cases", component: <ListCases /> },
  { path: "/admin/my-cases", component: <MyCases /> },
  { path: "/admin/create-case", component: <CreateCase /> },
  { path: "/admin/new-cases", component: <NewCasesByClient /> },
  { path: "/admin/leader-cases", component: <LeaderCases /> },
  { path: "/admin/member-cases", component: <MemberCases /> },
  { path: "/admin/qc-case-level", component: <QcCaseLevel /> },
  { path: "/admin/qc-component-level", component: <QcComponentLevel /> },
  { path: "/admin/manage-users", component: <ManageUsers /> },
  { path: "/admin/manage-clients", component: <ManageClients /> },
  { path: "/admin/manage-companies", component: <ManageCompanies /> },
  { path: "/admin/manage-locations", component: <ManageLocationsAdmin /> },
  { path: "/admin/profile", component: <Profile /> },
  { path: "/admin/case-details", component: <CaseDetails /> },
  { path: "/admin/add-new-user", component: <AddNewUser /> },
  { path: "/admin/de-case-details", component: <DECaseDetails /> },
  { path: "/admin/member-case-detail", component: <MemberCaseDetail /> },
  { path: "/admin/qc-case-detail", component: <QcCaseDetail /> },
  { path: "/admin/qc-comp-detail", component: <QcCompDetail /> },
  { path: "/admin/report", component: <PdfDocViewerAdmin /> },
  { path: "/admin/view-report/:id", component: <ReportAdmin /> },
  { path: "/admin/view-tds/:client_id/:walletID", component: <ViewTds /> },
  { path: "/admin/view-epfo/:client_id/:walletID", component: <ViewEpfo /> },
  { path: "/admin/email-settings", component: <EmailSettings /> },
  { path: "/admin/edit-client", component: <EditClient /> },

  

  {
    path: "/client",
    exact: true,
    component: <Navigate to="/client/dashboard" />,
  },
  {
    path: "/admin",
    exact: true,
    component: <Navigate to="/admin/dashboard" />,
  },

];

const publicRoutes = [
  { path: "/client/login", component: <Login /> },
  { path: "/admin/login", component: <AdminLogin /> },

  { path: "/client/password-reset", component: <EmailVerification /> },
  { path: "/admin/password-reset", component: <AdminEmailVerification /> }


];

export { authProtectedRoutes, publicRoutes };
