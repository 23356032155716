import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Link, useNavigate } from "react-router-dom";
import { Card, CardBody, Col, Container, Row, Table, 

  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane, CardTitle


} from "reactstrap";
import { isEmpty, map, set } from "lodash";
import Dropzone from "react-dropzone";
import * as XLSX from 'xlsx';
import Papa from 'papaparse';
import axios from 'axios';
import Loader from "../../components/Loader";

import classnames from "classnames";
import ClientInfo from "./ClientInfo";
import ManageLocation from "./ManageLocation";
import ChangePassword from "./ChangePassword";
import Profile from "./Profile";
import ListTAT from "./ListTAT";
import ListComponents from "./ListComponents";
import ListRates from "./ListRates";
import ListPenalties from "./ListPenalties";
import ListContracts from "./ListContracts";
import ListHolidays from "./ListHolidays";
import ListPackages from "./ListPackages";
import ManageUsers from "./ManageUsers";





const Account = (props) => {
  //meta title
  document.title =
    "TheRollNumber";


  const [data, setData] = React.useState([]);

  const [loading, setLoading] = React.useState(false);

  const [menus, setMenus] = React.useState([
{
    id: 1,
    name: "Client Information",

},
{
    id: 2,
    name: "Manage Locations",
},
{
  id: 3,
  name: "List Components",
},
{
    id: 4,
    name: "Manage Users",
},
{
    id: 5,
    name: "List Rates",
},
{

    id: 6,
    name: "List TAT",
},
{
    id: 7,

    name: "List Penalties",
},
{
    id: 8,
    name: "List Contracts",
},
{
  id: 9,
  name: "List Holidays",
},
{
    id: 10,
    name: "List Packages",

},
{
    id: 11,
    name: "List Profile",
},
{
    id: 12,
    name: "Change Password",
}



  ]);


  const Navigate = useNavigate();

const [verticalActiveTab, setVerticalActiveTab] = React.useState("1");

  const toggleVertical = (tab) => {

    if (verticalActiveTab !== tab) {
      setVerticalActiveTab(tab);
    }
  }




  return (
    <React.Fragment>
      <div className="page-content">
{
  loading && <Loader />
}
        <Container fluid>
      <Row>
        <Col lg="12">
        <Card>
                <CardBody>
                  <p className="h4 mb-4">My Account</p>
                  {/* <p className="card-title-desc">
                    Example of Vertical nav tabs
                  </p> */}
                  <Row>
                    <Col md="2">
                      <Nav pills className="flex-column">

                        {
                          map(menus, (menu, key) => {
                            return (
                              <NavItem key={key}>
                                <NavLink
                                  style={{ cursor: "pointer", fontWeight:'unset', fontSize:13 }}
                                  className={classnames({
                                    "mb-2": true,
                                    active: verticalActiveTab === menu.id.toString(),
                                  })}
                                  onClick={() => {
                                    toggleVertical(menu.id.toString());
                                  }}
                                >
                                  {menu.name}
                                </NavLink>
                              </NavItem>
                            );
                          })
                        }
            
                      </Nav>
                    </Col>
                    <Col md="10">
                      <TabContent
                        activeTab={verticalActiveTab}
                        className="text-muted mt-4 mt-md-0"
                      >
                        <TabPane tabId="1">
                          <ClientInfo />
                        </TabPane>
                        <TabPane tabId="2">

                     <ManageLocation />
                    
                        </TabPane>
                        <TabPane tabId="3">
                        <ListComponents />
                    
                       
                        </TabPane>

                        <TabPane tabId="4">
                        <ManageUsers />
                       
                        
                        </TabPane>
                        <TabPane tabId="5">
                        <ListRates />

                        </TabPane>

                        <TabPane tabId="6">
                        <ListTAT />
                        </TabPane>

                        <TabPane tabId="7">

                        <ListPenalties />
                        </TabPane>

                        <TabPane tabId="8">

                        <ListContracts />

                        </TabPane>

                        <TabPane tabId="9">

                        <ListHolidays />

                        </TabPane>

                        <TabPane tabId="10">

                        <ListPackages />

                        </TabPane>

                        <TabPane tabId="11">

                        <Profile />

                        </TabPane>

                        <TabPane tabId="12">

<ChangePassword />

</TabPane>
                      </TabContent>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
        </Col>
      </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};


export default Account;

const styles = {

  para:{
    fontSize:12
  }

}
