import { useState } from 'react';

function Accordion(props) {
  const [isOpen, setIsOpen] = useState(false);

  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="accordion" style={{cursor:'pointer'}}>
      <div className="accordion-item">
        <div className="accordion-header" onClick={toggleAccordion}>
          {props.header}
        </div>
        {isOpen && (
          <div className="accordion-body" style={props.style}>
            {props.children}
          </div>
        )}
      </div>
    </div>
  );
}

export default Accordion;
