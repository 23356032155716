import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Link, useNavigate } from "react-router-dom";
import {
    Card, CardBody, Col, Container, Row, Table,
    Label,
    Input,
    Form,
    FormGroup,

    Nav,
    NavItem,
    NavLink,
    TabContent,
    TabPane, CardTitle


} from "reactstrap";
import { isEmpty, map, set } from "lodash";
import Dropzone from "react-dropzone";
import * as XLSX from 'xlsx';
import Papa from 'papaparse';
import axios from 'axios';
// import Loader from "../../components/Loader";

import classnames from "classnames";



const Profile = (props) => {
    //meta title
    document.title =
        "TheRollNumber";


    const [data, setData] = React.useState([]);

    const [loading, setLoading] = React.useState(false);

    const [locations, setLocation] = React.useState([
    ]);


    const Navigate = useNavigate();

    const [state, setState] = React.useState({

        first_name: "",
        last_name: "",
        address: "",
        mobile: "",
        city: "",
        country: "",
        state: "",
        pin_code: "",




    });

    const handleInputChange = (e) => {
        const { id, value } = e.target;
        setState((prevState) => ({
            ...prevState,
            [id]: value,
        }));
    };


    const getUser = async () => {

        const user = JSON.parse(localStorage.getItem('authUser')).user;

        console.log('user', user);

    

        setState({
            ...state,
            first_name: user.first_name,
            last_name: user.last_name,
            email: user.email,
            contact: user.phone_number,
            role: user.role,
            client_location: user.client_location
        })


    };
    
    const getAllLocation = async () => {

  
  
        const config = {
          headers: {
            'Content-Type': 'application/json'
          }
        };
    
    
      
       await axios.get(`${process.env.REACT_APP_BASE_URL}/service/companyLocation`, {}, config)
        .then(response => {
          console.log('all location response',response.data);
    
            let data = response.data;

            let arr = []
    
            data.map((location, index) => {

                arr.push({id: location.LocationID, name: location.location_name, selected: false});
            }
            )

            setLocation(arr);
          
      
        })
        .catch(error => {
      
          console.log('error==>',error);
        });
  
      };

        useEffect(() => {

       

        }, []);

    return (
        <React.Fragment>

<div className="page-content">
        <div className="container-fluid">
            <Card>
                <CardBody>
                    <p className="h4 mb-4">Manage Profile</p>

                    <Row>

                        <Col lg="6">
                            <div className="mt-3 d-flex" style={styles.box}>
                                <Label for="basicpill-firstname-input1" style={styles.boxLabel} className="control-label me-3" >
                                    First Name
                                    <span className="text-danger mx-1">*</span>
                                </Label>
                                <Input
                                    type="text"
                                    className="form-control me-3"
                                    value={state.first_name}
                                    // disabled={selectedValue === 'registration_no' ? false : true}
                                   onChange={handleInputChange}
                                    style={styles.boxInput}
                                    suppressHydrationWarning
                                    id="first_name"
                                />


                            </div>
                        </Col>
                        <Col lg="6">
                            <div className="mt-3 d-flex" style={styles.box} >
                                <Label for="basicpill-firstname-input1" style={styles.boxLabel} className="control-label me-3" >
                                    Last Name
                                    {/* <span className="text-danger mx-1">*</span> */}

                                </Label>
                                <Input
                                    type="text"
                                    className="form-control me-3"
                                    value={state.last_name}
                                    style={styles.boxInput}

                                    // disabled={selectedValue === 'registration_no' ? false : true}
                                    onChange={handleInputChange}
                                    suppressHydrationWarning
                                    id="last_name"
                                />


                            </div>
                        </Col>

                     
                        <Col lg="6">
                            <div className="mt-3 d-flex" style={styles.box} >
                                <Label for="basicpill-firstname-input1" style={styles.boxLabel} className="control-label me-3" >
                                    Mobile
                                    {/* <span className="text-danger mx-1">*</span> */}

                                </Label>
                                <Input
                                    type="text"
                                    className="form-control me-3"
                                    value={state.mobile}
                                    style={styles.boxInput}

                                    // disabled={selectedValue === 'registration_no' ? false : true}
                                    onChange={handleInputChange}
                                    suppressHydrationWarning
                                    id="mobile"
                                    placeholder="eg. 9876543210"
                                />


                            </div>
                        </Col>
                        <Col lg="6">
                            <div className="mt-3 d-flex" style={styles.box}>
                                <Label for="basicpill-firstname-input1" style={styles.boxLabel} className="control-label me-3" >
                                    Address
                                    {/* <span className="text-danger mx-1">*</span> */}
                                </Label>
                                <Input
                                    type="text"
                                    className="form-control me-3"
                                    value={state.address}
                                    // disabled={selectedValue === 'registration_no' ? false : true}
                                    onChange={handleInputChange}
                                    style={styles.boxInput}
                                    suppressHydrationWarning
                                    id="contact_person"
                                    placeholder="eg 123, xyz street"
                                    // placeholder="Contact Person"
                                />


                            </div>
                        </Col>
                        <Col lg="6">
                            <div className="mt-3 d-flex" style={styles.box}>
                                <Label for="basicpill-firstname-input1" style={styles.boxLabel} className="control-label me-3" >
                                    Country
                                    {/* <span className="text-danger mx-1">*</span> */}
                                </Label>
                                <Input
                                    type="text"
                                    className="form-control me-3"
                                    value={state.country}
                                    // disabled={selectedValue === 'registration_no' ? false : true}
                                    onChange={handleInputChange}
                                    style={styles.boxInput}
                                    suppressHydrationWarning
                                    id="contact_person"
                                    placeholder="eg. India"
                                    // placeholder="Contact Person"
                                />


                            </div>
                        </Col>
                        <Col lg="6">
                            <div className="mt-3 d-flex" style={styles.box}>
                                <Label for="basicpill-firstname-input1" style={styles.boxLabel} className="control-label me-3" >
                                    State
                                    {/* <span className="text-danger mx-1">*</span> */}
                                </Label>
                                <Input
                                    type="text"
                                    className="form-control me-3"
                                    value={state.state}
                                    // disabled={selectedValue === 'registration_no' ? false : true}
                                    onChange={handleInputChange}
                                    style={styles.boxInput}
                                    suppressHydrationWarning
                                    id="contact_person"
                                    placeholder="eg. Maharashtra"
                                    // placeholder="Contact Person"
                                />


                            </div>
                        </Col>
                        <Col lg="6">
                            <div className="mt-3 d-flex" style={styles.box}>
                                <Label for="basicpill-firstname-input1" style={styles.boxLabel} className="control-label me-3" >
                                    City
                                    {/* <span className="text-danger mx-1">*</span> */}
                                </Label>
                                <Input
                                    type="text"
                                    className="form-control me-3"
                                    value={state.city}
                                    // disabled={selectedValue === 'registration_no' ? false : true}
                                    onChange={handleInputChange}
                                    style={styles.boxInput}
                                    suppressHydrationWarning
                                    id="contact_person"
                                    placeholder="eg. Mumbai"
                                    // placeholder="Contact Person"
                                />


                            </div>
                        </Col>
                        <Col lg="6">
                            <div className="mt-3 d-flex" style={styles.box}>
                                <Label for="basicpill-firstname-input1" style={styles.boxLabel} className="control-label me-3" >
                                    Pin Code
                                    {/* <span className="text-danger mx-1">*</span> */}
                                </Label>
                                <Input
                                    type="text"
                                    className="form-control me-3"
                                    value={state.pin_code}
                                    // disabled={selectedValue === 'registration_no' ? false : true}
                                    onChange={handleInputChange}
                                    style={styles.boxInput}
                                    suppressHydrationWarning
                                    id="contact_person"
                                    placeholder="eg. 123456"
                                    // placeholder="Contact Person"
                                />


                            </div>
                        </Col>
                    </Row>

                    <div className="mt-5 text-center"  >
                        <button className="btn btn-primary" style={{ width: 150, }} onClick={() => { }} >Save</button>
                        </div>

                </CardBody>
            </Card>
        </div>

    </div>

        </React.Fragment>
    );
};


export default Profile;

const styles = {

    para: {
        fontSize: 12
    },
    box: {
        fontSize: 16,
        fontWeight: "unset",
        justifyContent: "space-between",
        alignItems: "center",
    },
    boxLabel: {
        fontSize: 13,
        fontWeight: "400",
    },
    boxInput: {
        fontSize: 13,
        width: '70%',
        padding: 8,
        fontWeight: "unset",
    }

}
