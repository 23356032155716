


import React, { useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import { Link, useNavigate } from "react-router-dom";
import { ceil, isEmpty, set } from "lodash";
import "bootstrap/dist/css/bootstrap.min.css";
import TableContainer from "../../components/Common/TableContainerListCases";
import * as Yup from "yup";
import { useFormik } from "formik";
import axios from "axios";

//Import Breadcrumb




import {
  Button,
  Col,
  Row,
  UncontrolledTooltip,
  
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  Input,
  FormFeedback,
  Label,
  Card,
  CardBody,
  Table,
} from "reactstrap";
import moment from "moment";

//Import Flatepicker
import "flatpickr/dist/themes/material_blue.css";
import Flatpickr from "react-flatpickr";

function ManageClients() {
  //meta title
  document.title = "TheRollNumber";

  const navigate = useNavigate();


  const [modal, setModal] = useState(false);
  const [modal1, setModal1] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [cases, setCases] = useState([]);
  const [cancelNotificationType, setCancelNotificationType] = useState('')
  const [enableCancel, setEnableCancel] = useState(false);

  const [orderList, setOrderList] = useState([]);
  const [order, setOrder] = useState(null);

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      orderId: (order && order.orderId) || "",
      billingName: (order && order.billingName) || "",
      orderdate: (order && order.orderdate) || "",
      total: (order && order.total) || "",
      paymentStatus: (order && order.paymentStatus) || "Paid",
      badgeclass: (order && order.badgeclass) || "success",
      paymentMethod: (order && order.paymentMethod) || "Mastercard",
    },
    validationSchema: Yup.object({
      orderId: Yup.string()
        .matches(/[0-9\.\-\s+\/()]+/, "Please Enter Valid Order Id")
        .required("Please Enter Your Order Id"),
      billingName: Yup.string().required("Please Enter Your Billing Name"),
      orderdate: Yup.string().required("Please Enter Your Order Date"),
      total: Yup.string()
        .matches(/[0-9\.\-\s+\/()]+/, "Please Enter Valid Amount")
        .required("Total Amount"),
      paymentStatus: Yup.string().required("Please Enter Your Payment Status"),
      badgeclass: Yup.string().required("Please Enter Your Badge Class"),
      paymentMethod: Yup.string().required("Please Enter Your Payment Method"),
    }),
    onSubmit: (values) => {
      if (isEdit) {
        const updateOrder = {
          id: order ? order.id : 0,
          orderId: values.orderId,
          billingName: values.billingName,
          orderdate: values.orderdate,
          total: values.total,
          paymentStatus: values.paymentStatus,
          paymentMethod: values.paymentMethod,
          badgeclass: values.badgeclass,
        };
        // update order
        // dispatch(onUpdateOrder(updateOrder));
        // validation.resetForm();
      } else {
        const newOrder = {
          id: Math.floor(Math.random() * (30 - 20)) + 20,
          orderId: values["orderId"],
          billingName: values["billingName"],
          orderdate: values["orderdate"],
          total: values["total"],
          paymentStatus: values["paymentStatus"],
          paymentMethod: values["paymentMethod"],
          badgeclass: values["badgeclass"],
        };
        // save new order
        // dispatch(onAddNewOrder(newOrder));
        validation.resetForm();
      }
      toggle();
    },
  });

  const [selectedNotification, setSelectedNotification] = useState({});

  const toggleViewModal = () => setModal1(!modal1);

  useEffect(() => {

    get_all_clients();

  }, []);



  const get_all_clients = async () => {

    const config = {
      headers: {
        'Content-Type': 'application/json'
      }
    };
   
    axios.get(`${process.env.REACT_APP_BASE_URL}/employer/allUser`, {}, config)

    .then(response => {

      console.log('response cases==>',response.data.data)

      const data = response.data.data;


    

      setCases(data)


    })

    .catch(error => {

      console.log('error from server==>',error)

    })


  }

  const [isLoading, setLoading] = useState('')



  const toggle = () => {
    if (modal) {
      setModal(false);
      setOrder(null);
    } else {
      setModal(true);
    }
  };

  const handleOrderClick = (arg) => {
    const order = arg;
    setOrder({
      id: order.id,
      orderId: order.orderId,
      billingName: order.billingName,
      orderdate: order.orderdate,
      total: order.total,
      paymentStatus: order.paymentStatus,
      paymentMethod: order.paymentMethod,
      badgeclass: order.badgeclass,
    });

    setIsEdit(true);

    toggle();
  };

  const selectAll = (value, data) => {

    console.log('select all', value)

    let arr = [...data]

    arr.map((item)=> {

      item.select = value

    } )

    setCases(arr)

    setEnableCancel(value)



  }

  //delete order
  const [deleteModal, setDeleteModal] = useState(false);

  const onClickDelete = (order) => {
    setOrder(order);
    // setDeleteModal(true);
  };

  const handleDeleteOrder = () => {
    if (order && order.id) {
      // dispatch(onDeleteOrder(order.id));
      // setDeleteModal(false);
      setOrder("");
    }
  };
  const handleOrderClicks = () => {
    setOrderList("");
    setIsEdit(false);
    toggle();
  };

  const columns = useMemo(
    () => [
 
      {
        Header: "Name",
        accessor: "username",
        width: "150px",
        style: {
          textAlign: "center",
          width: "10%",
          background: "#0000",
        },
        filterable: true,
        Cell: (cellProps) => {
          return <Link to={`#`} disabled={true} onClick={(e)=>
          {
            e.preventDefault();

            const  status = cellProps.row.original.verification_status;

            if(status == 'Approved' || status == 'Dispatch done' || status == 'Complete' || status == 'QC complete') {
              
              navigate('/edit-progress', {state: cellProps.row.original}) 

            }

          }
          } >{cellProps.value}</Link>;
        },
      },
      {
        Header: "Abbrevation",
        accessor: "abbreviation",
        filterable: true,
        Cell: (cellProps) => {
          return cellProps.value;
        },
      },
      {
        Header: "Country",
        accessor: "country",
        filterable: true,
        Cell: (cellProps) => {
          return cellProps.value;
        },
      },
      {
        Header: "Status",
        accessor: "candidate_name",
        filterable: true,
        Cell: (cellProps) => {
          return cellProps.value;
        },
      },
    
      {
        Header: "Client Since",
        accessor: "account_creation_timestamp",
        filterable: true,
        Cell: (cellProps) => {
          return moment(cellProps.value).format("DD-MM-YYYY");
        },
      },


  
    
      {
        Header: "Action",
        accessor: "action",
        disableFilters: true,
        Cell: (cellProps) => {

          const status  = cellProps.row.original.verification_status;

          const enableView = (status == 'Approved' || status == 'Dispatch done' || status == 'Complete' || status == 'QC complete') ? true : false;

          const enableCancel = (status == 'Dispatch done' || status == 'Complete' || status == 'QC complete') ? false : true;

          return (
            <div className="d-flex text-center align-items-center" style={{justifyContent:'space-evenly', width:50}} >
               {true && <Link
                to="#"
                className="text-success"
                onClick={(e) => {

                  e.preventDefault();
                  console.log('cellProps==>',cellProps.row.original)

                  navigate('/admin/edit-client', {state: cellProps.row.original}) 

                }}
              >
              
                <i className="mdi mdi-eye font-size-20" id="edittooltip2" />
                <UncontrolledTooltip placement="top" target="edittooltip2">
                  View Report
                </UncontrolledTooltip>
              </Link>}
       
          
            </div>
          );
        },
      },
    ],
    []
  );

  const [modalConfirm, setModalConfirm] = useState(false);

  const toggleConfirm = () => setModalConfirm(!modalConfirm);


  const cancelNotification = async(notification_id, walletID, email) => {

    console.log('selectedNotification==>',selectedNotification)


    const config = {
      headers: {
        'Content-Type': 'application/json'
      }
    };

    const id = notification_id;

    const body = {
      id_identity_user: walletID? walletID : '',
      email: email
    }

    console.log('id==>',id, body)

    axios.put(`${process.env.REACT_APP_BASE_URL}/service/cancelNotification/${id}`, body, config)

    .then(response => {

      console.log('cancel notification==>',response)

      setModalConfirm(false)

      get_all_clients();


    })

    .catch(error => {

      console.log('error from server==>',error)

    })


  }

  const handleCancelClicks = () => {

    console.log('cancel clicked', cases)

   setCancelNotificationType('multiple')

   setModalConfirm(true)

   
  }

  const cancelAllNotification = async() => {

    console.log('cases cancel all==>',cases)

      cases.map((item)=> {

        if(item.select) {
          cancelNotification(item.notification_id, item.walletID, item.email)
        }

      }
      )

  }

  return (
    <React.Fragment>

      <div className="page-content">
        <div className="container-fluid">
          {/* <Breadcrumbs title="Ecommerce" breadcrumbItem="Orders" /> */}
          <Row>
            {
            
                <Col xs="12">
                  <Card>
                    <CardBody>
                      <p className="card-title mb-4" style={{fontSize:20, fontWeight:'500'}}>Manage Clients</p>



                      <TableContainer
                        columns={columns}
                        data={cases.length > 0 ? cases : []}
                        isFilter={true}
                        isGlobalFilter={false}
                        isAddOptions={true}
                        enableCancel={enableCancel}
                        handleOrderClicks={handleOrderClicks}
                        handleCancelClicks={handleCancelClicks}
                        customPageSize={10}
                        isPagination={true}
                        filterable={false}
                        iscustomPageSizeOptions={true}
                        tableClass="align-middle table-nowrap table-check"
                        theadClass="table-light"
                        pagination="pagination pagination-rounded justify-content-end mb-2"
                      />
                    </CardBody>
                  </Card>
                </Col>
            }
          </Row>


          <Modal isOpen={modalConfirm} centered={true} toggle={toggleConfirm}>
            <ModalHeader toggle={toggleConfirm} tag="h4">
              Cancel Notification
            </ModalHeader>
            <ModalBody>
         
                <Row>
                  <Col className="col-12">
                    {
                      cancelNotificationType === 'single' && 
                      <p style={{fontSize:16}}>Are you sure you want to cancel this notification?</p>
                    }
                    {
                      cancelNotificationType === 'multiple' && 
                      <p style={{fontSize:16}}>Are you sure you want to cancel all selected notifications?</p>
                    }
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div className="text-center">
                      <button
                        type="button"
                        onClick={()=>{
                          if(cancelNotificationType === 'single') {
                            cancelNotification(selectedNotification.notification_id, selectedNotification.walletID, selectedNotification.email)
                          }

                          if(cancelNotificationType === 'multiple') {
cancelAllNotification()
                          }
                        }}
                        className="btn btn-primary save-user me-2"
                      >
                        Yes
                      </button>
                      <button
                        type="button"
                        onClick={() => { setModalConfirm(false) }}
                        className="btn btn-primary save-user"
                      >
                        No
                      </button>
                    </div>
                  </Col>
                </Row>
        
            </ModalBody>
          </Modal>

          <Modal isOpen={modal} centered={true} toggle={toggle}>
            <ModalHeader toggle={toggle} tag="h4">
              Add Candidate
            </ModalHeader>
            <ModalBody>
              <Form
                onSubmit={(e) => {
                  e.preventDefault();
                  validation.handleSubmit();
                  return false;
                }}
              >
                <Row>
                  <Col className="col-12">
               
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div className="text-center">
                      <button
                        type="submit"
                        onClick={() => { navigate("/search") }}
                        className="btn btn-primary save-user me-2"
                      >
                        Manual
                      </button>
                      <button
                        type="submit"
                        onClick={() => { navigate("/excel-import") }}
                        className="btn btn-primary save-user"
                      >
                        Excel Import
                      </button>
                    </div>
                  </Col>
                </Row>
              </Form>
            </ModalBody>
          </Modal>
        </div>
      </div>

    </React.Fragment>
  );
}
ManageClients.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
};

export default ManageClients;
