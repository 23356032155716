import React, { useState } from 'react';
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from 'react-places-autocomplete';

import { Label } from "reactstrap";

const LocationSearchInput = (props) => {
  const [address, setAddress] = useState('');

  const handleChange = (address) => {
    setAddress(address);
  };

  const handleSelect = (address) => {

    console.log('address',address)
    geocodeByAddress(address)
      .then(results => {getLatLng(results[0]); console.log('results',results); props.onSelect(results[0]) })
      .then(latLng => console.log('Success', latLng))
      .catch(error => console.error('Error', error));
    setAddress(address);
  };

  return (

    <div >

<Label for="basicpill-firstname-input1"  className="control-label me-3" >
                                    Search Location
                                </Label>
    <PlacesAutocomplete
      value={address}
      onChange={handleChange}
      onSelect={handleSelect}

    >
      {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
        <div>
          <input
            {...getInputProps({
              placeholder: 'Search Places ...',
              className: 'form-control me-3',
            })}
        
          />
          <div className="autocomplete-dropdown-container px-3" style={{
            position: 'absolute',
            top: '100%',
            left: 0,
            right: 0,
            zIndex: 1051,
  
            // border: '1px solid #ccc',
           
            backgroundColor: '#fff',
            borderRadius: '4px',
            // boxShadow: '0 2px 4px rgba(0,0,0,0.2)',
            overflow: 'auto',
          
          }}>
            {loading && <div>Loading...</div>}
            {suggestions.map(suggestion => {
              const className = suggestion.active
                ? 'suggestion-item--active my-2 px-3 py-2'
                : 'suggestion-item my-2 px-3 py-2';
              // inline style for demonstration purposes
              const style = suggestion.active
                ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                : { backgroundColor: '#ffffff', cursor: 'pointer' };
              return (
                <div
                  {...getSuggestionItemProps(suggestion, {
                    className,
                    style,
                    
                  })}
                >
                  <span>{suggestion.description}</span>
                </div>
              );
            })}
          </div>
        </div>
      )}
    </PlacesAutocomplete>
    </div>
  );
};

export default LocationSearchInput;
