import React , {useState, useEffect} from "react"
import { Card, CardBody, Col, Container, Row, Button , Alert, 

  Form,
  Input,
  FormFeedback,
  Label,
InputGroup


} from "reactstrap"
import { Link, useLocation, useNavigate } from "react-router-dom";

// import images
import LightLogo from "../../assets/images/logo/light_logo_2.png"
import axios from "axios";
import * as Yup from "yup";
import { useFormik } from "formik";


const EmailVerification = () => {

  //meta title
  document.title="Email Verification | TheRollNumber";


  const navigate = useNavigate();

  const location = useLocation();
  const [verified, setVerified] = useState(false)

  const [password_verified, setPasswordVerified] = useState(false)
  const [state, setState] = useState({
    isLoading:false,
    email:'',
    userName:'',
    old_password:'',


  })

  const [userName, setUserName] = useState('')


  useEffect(() => {


    let search = location.search;
    let params = new URLSearchParams(search);
    let foo = params.get('authCode');
    let email = params.get('email');

    setState({...state, email:email, userName:userName})

    verifyEmail()

  }, []);

  const verifyEmail =async()=>{


    let search = location.search;
    let params = new URLSearchParams(search);
    let foo = params.get('authCode');
    let email = params.get('email');
    let userName = params.get('userName');

    setState({...state, isLoading:true})

let obj =  {

  email: email,
  authCode: foo
  
  }

const config = {
  headers: {
    'Content-Type': 'application/json'
  }
};


await axios.put(`${process.env.REACT_APP_BASE_URL}/employer/verify`,obj, config)
.then(response => {
  
  console.log('Email verified==>',response);

  if(response.data.message == 'expired'){

    // toast.error("Link expired")

    setState({...state, isLoading:false})


  }else{

    setVerified(true)

    setState({...state, isLoading:false})


  }


})
.catch(error => {

  // this.setState({isLoading:false})
  alert("Unable to verify")

  setState({...state, isLoading:false})

  console.log('error occured on verification==>',error);
});
}

const validation = useFormik({
  // enableReinitialize : use this  flag when initial values needs to be changed
  enableReinitialize: true,

  initialValues: {
    new_password: "",
    confirm_password: "",
  },
  validationSchema: Yup.object({
    new_password: Yup.string().required("Please Enter new Password"),
    confirm_password: Yup.string().required("Please Enter confirm Password"),



  }),
  onSubmit: (values) => {

    console.log('form data',values)

    update_password(values)
  }
});

const update_password = async(values)=>{

  let search = location.search;
  let params = new URLSearchParams(search);

  let email = params.get('email');
  setState({...state, isLoading:true})

  let obj = {
    email: email,
    password: values.new_password,
  }


  const config = {
    headers: {
      'Content-Type': 'application/json'
    }
  };

  await axios.put(`${process.env.REACT_APP_BASE_URL}/employer/update_password`,obj, config)
  .then(response => {

    console.log('password updated==>',response);

// use react toast notification here



    setState({...state, isLoading:false})

     // redirect to login page
      navigate('/login', { replace: true });

  })
  .catch(error => {

    // this.setState({isLoading:false})
    alert("Unable to update password")

    setState({...state, isLoading:false})

    console.log('error occured on verification==>',error);

  });
}



const verify_password = async()=>{

  let search = location.search;
  let params = new URLSearchParams(search);

  let email = params.get('email');

  const config = {
    headers: {
      'Content-Type': 'application/json'
    }
  };

  let obj = {
    email: email,
    password: state.old_password,
  }
  
  
  await axios.put(`${process.env.REACT_APP_BASE_URL}/employer/verify_password`,obj, config)
  .then(response => {
    
    console.log('password verified==>',response);

    setPasswordVerified(true)
  
  
  })
  .catch(error => {
  
    // this.setState({isLoading:false})
    alert("Unable to verify")
  
    setState({...state, isLoading:false})
  
    console.log('error occured on verification==>',error);
  });
}


  return (
    <React.Fragment>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row>
            <Col lg={12}>
              <div className="text-center mb-0 text-muted">
                <Link to="dashboard" className="d-block auth-logo">
                  <img
                    src={LightLogo}
                    alt=""
                    width={300}
                   
                    className="auth-logo-dark mx-auto"
                  />
           
                </Link>
                {/* <p className="mt-3">React Admin & Dashboard Template</p> */}
              </div>
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card>
                <CardBody>
                  <div className="p-2">
                    <div className="text-center">
                      <div className="avatar-md mx-auto">
                        <div className="avatar-title rounded-circle bg-light">
                          <i className="bx bxs-envelope h1 mb-0 text-primary"></i>
                        </div>
                      </div>
                      <div className="p-2 mt-4">
                        <h4>Password Reset</h4>
                

                        {verified && ( 
                          
                          <p className="text-success">Email verified successfully</p>
                        )}



                        {/* {
                          !verified && (
                         <div className="mt-4">
                          <Button
                      
                            onClick={verifyEmail}
                            className="btn btn-success w-md"
                          >
                            {state.isLoading ? 'Loading...' : 'Verify'}
                          </Button>
                        </div>)} */}

                      
                      </div>

                      
                    </div>

                    
<div className="text-left">
<Form
                      className="form-horizontal"
                    
                      onSubmit={(e) => {
                        e.preventDefault();
                        validation.handleSubmit();
                        return false;
                      }}
                    >

                      <div className="mb-3">
                        <Label className="form-label">Old Password</Label>
                        <InputGroup className="mb-3">
                           
                        <Input
                          name="old_password"
                          disabled={!verified}

                          value={state.old_password || ""}
                          type="password"
                          placeholder="Enter Old Password"
                          onChange={(e)=>{ 
                            setState({...state, old_password:e.target.value})
                           }}
                          
                     
                        />
                             <div className="input-group-prepend">
                              <button
                                type="button"
                                className="btn btn-primary"
                                onClick={verify_password}
                              >
                                verify
                              </button>
                            </div>
                          </InputGroup>
                      </div>

                      <div className="mb-3">
                        <Label className="form-label">New Password</Label>
                        <Input
                          name="new_password"
                          disabled={!password_verified}

                          value={validation.values.new_password || ""}
                          type="password"
                          placeholder="Enter new Password"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          invalid={
                            validation.touched.new_password && validation.errors.new_password ? true : false
                          }
                        />
                        {validation.touched.new_password && validation.errors.new_password ? (
                          <FormFeedback type="invalid">{validation.errors.new_password}</FormFeedback>
                        ) : null}
                      </div>

                      <div className="mb-3">
                        <Label className="form-label">Confirm New Password</Label>
                        <Input
                          name="confirm_password"
                          value={validation.values.confirm_password || ""}
                          type="password"
                          disabled={!password_verified}

                          placeholder="Confirm Password"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          invalid={
                            validation.touched.confirm_password && validation.errors.confirm_password ? true : false
                          }
                        />
                        {validation.touched.confirm_password && validation.errors.confirm_password ? (
                          <FormFeedback type="invalid">{validation.errors.confirm_password}</FormFeedback>
                        ) : null}
                      </div>

                      <div className="mt-3 d-grid">
                        <button
                                                  disabled={!password_verified}

                          className="btn btn-primary btn-block"
                          type="submit"
                        >
                          create
                        </button>
                      </div>

           

                      <div className="mt-4 text-center">
                        <Link to="/forgot-password" className="text-muted">
                          <i className="mdi mdi-lock me-1" />
                          Forgot your password?
                        </Link>
                      </div>
                    </Form>
</div>
                  </div>
                </CardBody>
              </Card>
              <div className="mt-5 text-center">
                <p>
                  Didn&apos;t receive an email ?{" "}
                  <a href="#" className="fw-medium text-primary">
                    {" "}
                    Resend{" "}
                  </a>{" "}
                </p>
                <p>
                  © {new Date().getFullYear()} TheRollNumber.
         
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}
export default EmailVerification
