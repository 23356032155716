import React, { useEffect } from 'react';
import {View, StyleSheet, Text, Image, Page } from '@react-pdf/renderer';
import InvoiceTableHeader from './InvoiceTableHeader'
import InvoiceTableRow from './InvoiceTableRow'
import InvoiceTableBlankSpace from './InvoiceTableBlankSpace'
import InvoiceTableFooter from './InvoiceTableFooter'
import Aadhaar from '../../aadhaar.png'

// import Logo from '../../report_images/trnlogo-cropped.png'

const tableRowsCount = 11;
const borderColor = '#061c33'

const styles = StyleSheet.create({
  tableContainer: {
      flexDirection: 'row',
      flexWrap: 'wrap',
      marginTop: 24,
      marginBottom:40,
      padding:20,
      paddingVertical:8
    
     
  },

  page: {
    fontFamily: 'Helvetica',
    backgroundColor:'#061c33',
    fontSize: 11,
    paddingTop: 30,
    paddingLeft:60,
    paddingRight:60,
    lineHeight: 1.5,
    flexDirection: 'column',
}, 
  tableText:{
    fontSize: 10,
    fontFamily:'Montserrat-Light',
    width: '100%',
  },
  container: {
  marginTop: 24,
},
  text:{
    fontSize: 13,
    color: '#061c33',
    lineHeight:1,
    textAlign: 'center',
    fontFamily:'Montserrat',
  
  },
  box: {
    width: '100%', // Specify the width of the box
    height: 24, // Specify the height of the box
    backgroundColor: '#d4ddf1',
    justifyContent: 'center', // Center horizontally
    alignItems: 'center', // Center vertically
    margin: 'auto', // Center the box itself if within a larger container
  },
  table: {
    display: "table",
    width: "auto",
    borderStyle: "solid",
    borderWidth: 0.8,
    borderColor: '#bfbfbf',
    borderRightWidth: 0,
    borderBottomWidth: 0,
  },
  row: {
    flexDirection: "row",
    width: "100%",
  },
  col: {
    borderStyle: "solid",
    height:'auto',
     padding:5,

    borderWidth: 0.8,
 
    borderColor: '#bfbfbf',
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },
  profile:{
    width:'100%',
 
    marginTop:10,

   
   
  },
  imageBox:{
    width:'20%',
    height:100,
    alignSelf:'center',

    justifyContent: 'center', // Center horizontally
    alignItems: 'center', // Center vertically
    margin: 'auto', // Center the box itself if within a larger container
  }
});


const doc_names = {
  "aadhaar":"Aadhaar Card",
  "pan":"PAN Card",
  "passport":"Passport",
  "doctor":"Doctor",
  "nurse":"Nurse",
  "voter_id":"Voter ID",
}



  const EducationComp = ({invoice, data, colors, caseData, logo, attachments, requestedData}) => {

    const [components, setComponents] = React.useState([]);

    useEffect(() => {

      console.log('education requestedData==>',data);

      setComponents(data)

    }, []);



    return (

<View style={styles.tableContainer} >

<View style={styles.box}>
  <Text  style={styles.text}>Education</Text>
</View>

<View style={styles.profile} >


{
    components.map((item, index) => {


    
    const sub_comp_idx = caseData.findIndex((element) => element.sub_component_id == item.registration_number);

    const docs = attachments.filter((attachment) => attachment.sub_component_id == item.registration_number);


    return (


      <View >

   
    <View  style={[styles.table, index!==0 && {marginTop:20}]} >

 
<View style={styles.row} >
          <View style={[styles.col, {width:'35%'}]}>
            <Text >Board</Text>
            </View>
            <View style={[styles.col, {width:'65%'}]}>
            <Text >{item.university_name}</Text>
            </View>
    </View>
    <View style={styles.row}>
          <View style={[styles.col, {width:'35%'}]}>
            <Text >Course</Text>
            </View>
            <View style={[styles.col, {width:'65%'}]}>
            <Text >{item.type_of_graduation}</Text>
            </View>
    </View>
    <View style={styles.row}>
          <View style={[styles.col, {width:'35%'}]}>
            <Text >Percentage/CGPA</Text>
            </View>
            <View style={[styles.col, {width:'65%'}]}>
            <Text >{item.CGPA}</Text>
            </View>
    </View>

    <View style={styles.row}>
          <View style={[styles.col, {width:'35%'}]}>
            <Text >Graducation Year</Text>
            </View>
            <View style={[styles.col, {width:'65%'}]}>
            <Text >{item.year_of_graduation}</Text>
            </View>
    </View>
    <View style={styles.row}>
          <View style={[styles.col, {width:'35%', 
        }]}>
            <Text >Start Date</Text>
            </View>
            <View style={[styles.col, {width:'65%', 
          flexDirection:'row',
          flexWrap:'wrap'

          }]}>
            <Text style={styles.tableText}>
              {item.start_date}
</Text>
            </View>
    </View>

  

    <View style={styles.row}>
          <View style={[styles.col, {width:'35%', 
        }]}>
            <Text >Status</Text>
            </View>
            <View style={[styles.col, {width:'65%', 
          flexDirection:'row',
          flexWrap:'wrap',
          backgroundColor:colors[caseData[sub_comp_idx]?.colorcode]?.backgroundColor,
          }]}>
            <Text style={[styles.tableText, {
              color: colors[caseData[sub_comp_idx]?.colorcode]?.color
            }]}>
              {caseData[sub_comp_idx]?.colorcode}  
</Text>
            </View>
    </View>


    <View style={styles.row}>
          <View style={[styles.col, {width:'35%', 
        }]}>
            <Text >Deposition Remarks</Text>
            </View>
            <View style={[styles.col, {width:'65%', 
          flexDirection:'row',
          flexWrap:'wrap'

          }]}>
            <Text style={styles.tableText}>
              {caseData[sub_comp_idx]?.reason}
</Text>
            </View>
    </View>
    <View style={[styles.row]} >
          <View style={[styles.col, {width:'35%', 
        }]}>
            <Text >Comment</Text>
            </View>
            <View style={[styles.col, {width:'65%', 
          flexDirection:'row',
          flexWrap:'wrap'

          }]}>
            <Text style={styles.tableText}>
              {
                caseData[sub_comp_idx]?.comment
              }
</Text>
            </View>
    </View>


   

  </View>


  <View style={[styles.table, {marginTop:20,}]}  break>
<View style={styles.row}>
              <View style={[styles.col, {width:'100%'}]} >
                <Text style={[ {textAlign:'center'}]}>Attachments</Text>
                </View>

        </View>

        <View style={[styles.row]}>
              <View style={[styles.col, {width:'100%'}]} >

              {

                
                  docs.map((attachment, index) => {

                    return(


                      <Image  src={attachment?.file_url} style={{width: '100%', height:300, objectFit:"contain", marginTop:10, alignSelf:'center'}} />

                   
                    )

                  })
                }


              </View>
             
        </View>
</View>

  </View>

        )
  })
        }


      
</View>




</View>
    )
  };
  
  export default EducationComp