import React from 'react';
import {Text, View, StyleSheet, Image } from '@react-pdf/renderer';
import logo from '../../report_images/therollnumber_logo.png'

const styles = StyleSheet.create({
    headerContainer: {
        marginTop: 36
    },
    billTo: {
        marginTop: 20,
        paddingBottom: 3,
        fontFamily: 'Helvetica-Oblique'
    },
  });


  const Header = ({invoice, candidate}) => (
    <View style={{
        backgroundColor:'#061c33',
        height:65,
        paddingHorizontal:60,
        flexDirection:'row',
        alignItems:'center',
       justifyContent:'space-between',
        width:'100%',
      }}>
          <Image style={{
            width:100,
            
          }} src={logo} />

          <Text style={{
            width: 250,
            color:'#fff',
            fontSize: 10,
            fontFamily:'Montserrat-Regular',
          }}>
          Krishe Emerald, WeWork, Kondapur, Hyderabad, Telangana 500081
          </Text>

      </View>
  );
  
  export default Header