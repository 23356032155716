import React, { useEffect } from 'react';
import {Text, View, StyleSheet, Image } from '@react-pdf/renderer';

const styles = StyleSheet.create({
    headerContainer: {
        marginTop: 36
    },
    billTo: {
        marginTop: 20,
        paddingBottom: 3,
        fontFamily: 'Helvetica-Oblique'
    },
  });


  const Footer = ({invoice, data}) => {


    
return (
    <View style={{
        backgroundColor:'#061c33',
        height:40,
        paddingHorizontal:60,
        zIndex:999999999999999999,
        flexDirection:'row',
        alignItems:'center',
         position:'absolute',
         bottom:0,
        width:'100%',
      }}>

<Text style={{
    color:'#fff',
    fontSize: 10,
    fontFamily:'Montserrat',
    
}}>
    Case ID: {data?.request_id}
</Text>

      </View>
)
  };
  
  export default Footer