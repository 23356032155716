import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Modal,
  ModalBody,
  CardBody,
  
  Table,
  
  CardSubtitle,
  Label,

  Card,
Button,
  Container,
  Input,
} from "reactstrap";

import './App.css';

import axios from 'axios';
import moment from 'moment';
import Header from '../components/Header';

import { Link, useLocation } from "react-router-dom";

import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file

import { DateRangePicker } from 'react-date-range';
import { addDays } from 'date-fns';
import TableContainer from "../components/Common/TableContainer";



const History = () => {

  //meta title
  document.title = "TheRollNumber";


  const location = useLocation();

  const [data, setData] = useState([]);
  const [history, setHistory] = useState([]);
  const [status, setStatus] = useState('');
  const [state, setState] = useState({
   registration_no: '',
    university_name: '',
    
  });

  const [dateState, setDateState] = useState([
    {
      startDate: new Date(),
      endDate: addDays(new Date(), 7),
      key: 'selection'
    }
  ]);

  const [show, setShow] = useState(false);

    

  const pickerRef = React.useRef(null)

  React.useEffect(() => {

    const handleClickOutside = (event) => {

      if (pickerRef.current && !pickerRef.current.contains(event.target)) {

        setShow(false)

      }

    }

    document.addEventListener("mousedown", handleClickOutside);

    return () => {

      document.removeEventListener("mousedown", handleClickOutside);

    };

  }, [pickerRef]);



  useEffect(() => {

    get_cred_history();
  }, []);


   const get_cred_history = async () => {

    const id = JSON.parse(localStorage.getItem('authUser')).user.university_id;

    const config = {
      headers: {
        'Content-Type': 'application/json'
      }
    };


    axios.get(`http://localhost:5001/university/cred_history/${id}`, {}, config)
    .then(response => {
      console.log('get credential data',response.data.data);

      let data = response.data.data;

      setData(data);
      setHistory(data);

    })
    .catch(error => {
  
      console.log('error==>',error);
    });

  }

  


  const filterCred = async() => {

    console.log('state', dateState)

    const first = moment(dateState[0].startDate).format('YYYY-MM-DD')

    const second = moment(dateState[0].endDate).format('YYYY-MM-DD')


    const filtered_data = [...data].filter((item)=>
    {
    
      let date = moment(item.timestamp).format('YYYY-MM-DD');
    
      console.log('date', date, date >= first && date <= second)

    
    
      return date >= first && date <= second
    
    
    }

         )


console.log('filtered_data', filtered_data)


    setShow(false)

    setHistory(filtered_data)

  }

  const [columns, setColumns] = useState([
    {
      header: "#",
      accessor: "id",
    },
    {
      header: "Certificate/Registration No",
      accessor: "log"
    },
    {
      header: "status",
      accessor: "status",
    },
    {
      header: "timestamp",
      accessor: "timestamp",
    },
  ]);

  const cols = React.useMemo(
    () => [
      {
        Header:'#',
        accessor:"id",
        Cell: cellProps => {
          return <div>{cellProps.value}</div>;
        },
      }, 
      {
        Header:'Certificate/Registration No',
        accessor:"log",
        Cell: cellProps => {
          return <div>{cellProps.value}</div>;
        },
      }, 
      {
        Header:'status',
        accessor:"status",
        Cell: cellProps => {
          return <div>{cellProps.value}</div>;
        },
      }, 

      {
        Header:'Timestamp',
        accessor:"timestamp",
        Cell: cellProps => {
          return <div>{moment(cellProps.value).format('DD-MM-YYYY hh:mm')}</div>;
        },
      }, 
    
    ],
    [columns]
  );

  // const tableData = React.useMemo(
  //   () => [...data.
      
  
  //     ],
  //   [data]
  // );
 
  return (


    <React.Fragment>
    


      <div className="page-content px-0" style={{background:'rgb(231, 231, 231)', height:'100vh'}}>
 
<Header/>

        <Container fluid={true} >

<Row>
  <Col className="col-12">

    <div style={{display:'flex', justifyContent:'center'}}>
<Card style={{width:'70%', alignSelf:'center'}}>
  <CardBody>
<div className="d-flex mb-4" style={{justifyContent:'space-between'}}>

<div className="d-flex align-items-center">
<h5 style={{textAlign:'center', fontSize:16}}>Credential History</h5>
<select className="form-select" style={{width:200, height:35, marginLeft:15}} onChange={(e)=> {
setStatus(e.target.value);

if(e.target.value === 'all') {
  setHistory(data)
}else{
  setHistory([...data].filter((item)=> item.status === e.target.value))
}


}}>
<option value="all">All</option>
<option value="success">success</option>
<option value="failed">failed</option>
</select>
</div>

<button type="button" className="btn btn-light btn-sm mb-4 font-size-14" onClick={()=> setShow(true)}>
      <i className="bx bx-calendar me-2"></i>
    filter
    </button>
</div>



            
                <div className="toolbar d-flex flex-wrap gap-2 text-end ">

                { show &&  
                
                <div ref={pickerRef} style={{marginLeft:30}}>

<div style={{ position:'absolute', padding:10, width:'60%', backgroundColor:'#fff', zIndex:9999,top:-10, left:0, right:0}}>

<DateRangePicker
onChange={item => setDateState([item.selection])}
style={{height:'100%'}}
showSelectionPreview={true}
moveRangeOnFirstSelection={false}
scroll={{ enabled: true }}
months={1}
ranges={dateState}

direction="vertical"
/>

<div style={{backgroundColor:"#fff", padding:10, }}>
<button className='btn btn-primary d-block' onClick={()=> filterCred()}>submit</button>

</div>

</div>
                </div>
                
                }
              
                </div>

                <TableContainer
  columns={cols}
  data={history}
  isGlobalFilter={true}
  isAddOptions={false}
  showCount={false}
show={true}
  pagination={true}
  // handleOrderClicks={handleOrderClicks}
  customPageSize={5}
/>

{/* <Table responsive>
<thead>
<tr>
<th>#</th>
<th>Certificate/Registration No</th>
<th>status</th>

<th>timestamp</th>
</tr>

</thead>
<tbody>
{history.map((item, index) => {
return(
<tr>
<td>{item.id}</td>
<td>{item.log}</td>
<td>{item.status}</td>
<td>{moment(item.timestamp).format('DD-MM-YYYY hh:mm')}</td>
</tr>
)
}
)}
</tbody>
</Table> */}

    </CardBody>
</Card>

</div>
</Col>
            
          </Row>
        </Container>
      </div>
      

    </React.Fragment>
  )
}

const style = {
  heading:{
    fontSize:14,
    letterSpacing:0.5,
    fontWeight:600,
    lineHeight:1.5,
 
    color:'#444'
  },
  subHeading:{
    fontSize:14,
    letterSpacing:0.5,
    fontWeight:600,
    lineHeight:1.5,
 
    color:'grey'
  }
}

export default History
