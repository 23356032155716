import React from "react";
import { Card, CardBody, CardTitle } from "reactstrap";
import { Link } from "react-router-dom";
import moment from "moment";
import { useNavigate } from "react-router-dom";

const ActivityComp = ({logs}) => {

  const navigate = useNavigate()
  return (
    <React.Fragment>
      <Card>
        <CardBody>
          <CardTitle className="mb-5">Activity</CardTitle>
          <ul className="verti-timeline list-unstyled">

            {

              logs.map((log, index) => {

                if(index < 5){
                  return (
                    <li className="event-list" key={index}>
                    <div className="event-timeline-dot">
                      <i className="bx bx-right-arrow-circle font-size-18" />
                    </div>
                    <div className="flex-shrink-0 d-flex">
                      <div className="me-3">
                        <h5 className="font-size-14">
                          {/* 22 Nov{" "} */}
  
  
                          {
                            
                            moment(log.timestamp).format('DD MMM')
                          }
                          <i className="bx bx-right-arrow-alt font-size-16 text-primary align-middle ms-2" />
                        </h5>
                      </div>
                      <div className="flex-grow-1">
                        <div>{log.message}</div>
                      </div>
                    </div>
                  </li>
                  )

                }
               
            }

            )

            }
         

       
          </ul>
          <div className="text-center mt-4">
            <button
             
             onClick={() => 
            
              navigate('/ViewActivity')

            
            }
              className="btn btn-primary waves-effect waves-light btn-sm"
            >
              View More <i className="mdi mdi-arrow-right ms-1" />
            </button>
          </div>
        </CardBody>
      </Card>
    </React.Fragment>
  );
};

export default ActivityComp;