import React, { useEffect, useMemo } from "react";
import PropTypes from "prop-types";
import { Link, useNavigate } from "react-router-dom";
import {
    Card, CardBody, Col, Container, Row, Table,

    Modal,
    ModalHeader,
    ModalBody,
} from "reactstrap";
import { isEmpty, map, set } from "lodash";
import Dropzone from "react-dropzone";
import * as XLSX from 'xlsx';
import Papa from 'papaparse';
import axios from 'axios';
import Loader from "../../../components/Loader";

import classnames from "classnames";

import TableContainer from "../../../components/Common/TableContainerClient";
import moment from "moment";
import AddSla from "./AddSla";



const ListTAT = (props) => {
    //meta title
    document.title =
        "TheRollNumber";


    const [data, setData] = React.useState([]);

    const [loading, setLoading] = React.useState(false);

    const [data_columns, setDataColumns] = React.useState([]);

    const [modal, setModal] = React.useState(false);

    const toggle = () => setModal(!modal);


    const Navigate = useNavigate();

    useEffect(() => {

        getListTats();

    }, []);

    const generateColumns = (arr) => {
      return [...Object.keys(arr).map((key, value) => {
        let columnDefinition = {
          Header: arr[key].component_name,
          accessor: key,
          filterable: true,
          Cell: (cellProps) => {
      
            console.log('cellProps',cellProps)
      
            return <div style={{ minWidth: 100, maxWidth: 300}}>
               {cellProps.value.tat} days
            </div>;
          },
        }
  

  
        return columnDefinition;
      })];
    };


    const getListTats = async () => {

      const id = JSON.parse(localStorage.getItem('authUser')).user.org_id;

      console.log('packages');
    
      const config = {
        headers: {
          'Content-Type': 'application/json'
        }
      };
  

     await axios.get(`${process.env.REACT_APP_BASE_URL}/service/tats/${id}`, {}, config)
      .then(response => {
        console.log('packages response',response);

        let res = response.data.data;

  setData(response.data.data);

  let col = generateColumns(res[0]);
  console.log('col',col);

  setDataColumns(col);
    
      })
      .catch(error => {
    
        console.log('error==>',error);
      });
    
    }

    const columns = useMemo(
        () => [
       
          {
            Header: "W.E.F",
            accessor: "wef",
            width: "150px",
            style: {
              textAlign: "center",
              width: "10%",
              background: "#0000",
            },
            filterable: true,
            Cell: (cellProps) => {
              return <div style={{width:100}}>
                {moment(cellProps.value).format('DD-MM-YYYY')}
              </div>;
            },
          },
          {
            Header: "W.E.T",
            accessor: "wet",
            filterable: true,
            Cell: (cellProps) => {
              return <div style={{width:100}}>
                {moment(cellProps.value).format('DD-MM-YYYY')}
              </div>;
            },
          },
          {
            Header: "Education",
            accessor: "education",
            filterable: true,
            Cell: (cellProps) => {
    
              // console.log('cellProps',cellProps)
    
              return <div>
                {cellProps.value} Days
              </div>;
            },
          },
        
          {
            Header: "Address",
            accessor: "address",
            filterable: true,
            Cell: (cellProps) => {
    
              // console.log('cellProps',cellProps)
    
              return <div>
              {cellProps.value} Days
            </div>;
            },
          },

          {
            Header: "Court(Criminal)",
            accessor: "role",
            filterable: true,
            Cell: (cellProps) => {
    
              // console.log('cellProps',cellProps)
    
              return <div>
              {cellProps.value} Days
            </div>;
            },
          },

          {
            Header: "Employment",
            accessor: "employment",
            filterable: true,
            Cell: (cellProps) => {
    
              return <div>
              {cellProps.value} Days
            </div>;
            },
          },

          {
            Header: "Driving License",
            accessor: "dl",
            filterable: true,
            Cell: (cellProps) => {
    
              return <div>
              {cellProps.value} Days
            </div>;
            },
          },

          {
            Header: "5 Panel Drug Test",
            accessor: "5",
            filterable: true,
            Cell: (cellProps) => {
    
              return <div>
              {cellProps.value} Days
            </div>;
            },
          },
        
          {
            Header: "7 Panel Drug Test",
            accessor: "7",
            filterable: true,
            Cell: (cellProps) => {
    
              return <div>
              {cellProps.value} Days
            </div>;
            },
          },
        
          {
            Header: "9 Panel Drug Test",
            accessor: "9",
            filterable: true,
            Cell: (cellProps) => {
    
              return <div>
              {cellProps.value} Days
            </div>;
            },
          },

          {
            Header: "10 Panel Drug Test",
            accessor: "10",
            filterable: true,
            Cell: (cellProps) => {
    
              return <div>
              {cellProps.value} Days
            </div>;
            },
          },

          {
            Header: "11 Panel Drug Test",
            accessor: "11",
            filterable: true,
            Cell: (cellProps) => {
              return <div>
              {cellProps.value} Days
            </div>;
            },
          },

          {
            Header: "12 Panel Drug Test",
            accessor: "12",
            filterable: true,
            Cell: (cellProps) => {
    
              return <div>
              {cellProps.value} Days
            </div>;
            },
          },

    
        ],
        []
      );

    const handleOrderClicks = () => {
    setModal(true);
    };


    return (
        <React.Fragment>
<Modal isOpen={modal} centered={true} size="xl" toggle={toggle}>
            <ModalHeader toggle={toggle} tag="h4">
              Add SLA
            </ModalHeader>  
            <ModalBody>
              <AddSla />
            </ModalBody>
          </Modal>

            <Card>
                <CardBody>
                    <p className="h4 mb-4">List TAT</p>

             { data &&      <TableContainer
                        columns={data_columns}
                        data={data}
                        isGlobalFilter={false}
                        component_name={'sla'}
                        isAddOptions={true}
                        handleOrderClicks={handleOrderClicks}
                        customPageSize={10}
                        isPagination={true}
                        filterable={false}
                        iscustomPageSizeOptions={true}
                        tableClass="align-middle table-nowrap table-check"
                        theadClass="table-light"
                        pagination="pagination pagination-rounded justify-content-end mb-2"
                      />}


                </CardBody>
            </Card>

        </React.Fragment>
    );
};


export default ListTAT;

const styles = {

    para: {
        fontSize: 12
    },
    box: {
        fontSize: 16,
        fontWeight: "unset",
        justifyContent: "space-between",
        alignItems: "center",
    },
    boxLabel: {
        fontSize: 13,
        fontWeight: "400",
    },
    boxInput: {
        fontSize: 13,
        width: '70%',
        padding: 8,
        fontWeight: "unset",
    }

}

