import React, { useEffect, useMemo } from "react";
import PropTypes from "prop-types";
import { Link, useNavigate } from "react-router-dom";
import {
    Card, CardBody, Col, Container, Row, Table,
    Label,
    Input,
    Form,
    FormGroup,
 UncontrolledAccordion,

 UncontrolledTooltip,
    Nav,
    NavItem,
    NavLink,
    TabContent,
    TabPane, CardTitle,
    Modal,
    ModalHeader,
    ModalBody,
    


} from "reactstrap";
import { isEmpty, map, set } from "lodash";
import Dropzone from "react-dropzone";
import * as XLSX from 'xlsx';
import Papa from 'papaparse';
import axios from 'axios';
import Loader from "../../components/Loader";

import classnames from "classnames";
import TableContainer from "../../components/Common/TableContainerLocation";
import Location from "./Location";




const ManageLocation = (props) => {
    //meta title
    document.title =
        "TheRollNumber";


    const [data, setData] = React.useState([]);

    const [loading, setLoading] = React.useState(false);


    const Navigate = useNavigate();

    useEffect(() => {

        getAllLocation();

    }, []);


    const columns = useMemo(
        () => [
       
          {
            Header: "Location Name",
            accessor: "location_name",
            width: "150px",
            style: {
              textAlign: "center",
              width: "10%",
              background: "#0000",
            },
            filterable: true,
            Cell: (cellProps) => {
              return cellProps.value;
            },
          },
          {
            Header: "Country",
            accessor: "country",
            filterable: true,
            Cell: (cellProps) => {
              return cellProps.value;
            },
          },
          {
            Header: "State",
            accessor: "state",
            filterable: true,
            Cell: (cellProps) => {
    
              // console.log('cellProps',cellProps)
    
              return cellProps.value;
            },
          },
        
          {
            Header: "City",
            accessor: "city",
            filterable: true,
            Cell: (cellProps) => {
    
              // console.log('cellProps',cellProps)
    
              return cellProps.value;
            },
          },
        
        
          {
            Header: "Action",
            accessor: "action",
            disableFilters: true,
            Cell: (cellProps) => {
              return (
                <div className="d-flex gap-3">
                  <Link
                    to="#"
                    className="text-success"
                    onClick={() => {
                      // handleOrderClick(orderData);

                      setIsEdit(true);
                      setEditLoc(cellProps.row.original);

                      setModal(true);
                    }}
                  >
                    <i className="mdi mdi-pencil font-size-18" id="edittooltip" />
                    <UncontrolledTooltip placement="top" target="edittooltip">
                      Edit
                    </UncontrolledTooltip>
                  </Link>
            
                </div>
              );
            },
          },
    
    
    
        ],
        []
      );

    const [modal, setModal] = React.useState(false);

    const [isEdit, setIsEdit] = React.useState(false);

    const [editLoc, setEditLoc] = React.useState({});

    const toggle = () => setModal(!modal);

    const getAllLocation = async () => {

  
  
      const config = {
        headers: {
          'Content-Type': 'application/json'
        }
      };
  
  
    
     await axios.get(`${process.env.REACT_APP_BASE_URL}/service/companyLocation`, {}, config)
      .then(response => {
        console.log('all location response',response);
  
          let data = response.data;
  
          setData(data);
    
      })
      .catch(error => {
    
        console.log('error==>',error);
      });

    };

    const handleOrderClicks = () => {
        
          setIsEdit(false);
          setEditLoc({});
          toggle(); 
    };

    const reFetch = async () => {

      setModal(false);
  
      getAllLocation();
  
    }





    return (
        <React.Fragment>

<Modal isOpen={modal} centered={true} size="xl" toggle={toggle}>
            <ModalHeader toggle={toggle} tag="h4">
              {isEdit ? "Edit Location" : "Add Location"}
            </ModalHeader>  
            <ModalBody>
          <Location data={editLoc} isEdit={isEdit} reFetch={reFetch} />
            </ModalBody>
          </Modal>


            <Card>
                <CardBody>
                    <p className="h4 mb-4">Manage Location</p>

                   
                    <TableContainer
                        columns={columns}
                        data={data}
                        isGlobalFilter={false}
                        isAddOptions={true}
                        handleOrderClicks={handleOrderClicks}
                        customPageSize={10}
                        isPagination={true}
                        filterable={false}
                        iscustomPageSizeOptions={true}
                        tableClass="align-middle table-nowrap table-check"
                        theadClass="table-light"
                        pagination="pagination pagination-rounded justify-content-end mb-2"
                      />
                </CardBody>
            </Card>

        </React.Fragment>
    );
};


export default ManageLocation;

const styles = {

    para: {
        fontSize: 12
    },
    box: {
        fontSize: 16,
        fontWeight: "unset",
        justifyContent: "space-between",
        alignItems: "center",
    },
    boxLabel: {
        fontSize: 13,
        fontWeight: "400",
    },
    boxInput: {
        fontSize: 13,
        width: '70%',
        padding: 8,
        fontWeight: "unset",
    }

}
