import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Link, useNavigate } from "react-router-dom";
import {
    Card, CardBody, Col, Container, Row, Table,
    Label,
    Input,
    Form,
    FormGroup,

    Nav,
    NavItem,
    NavLink,
    TabContent,
    TabPane, CardTitle


} from "reactstrap";
import { get, isEmpty, map, set } from "lodash";
import Dropzone from "react-dropzone";
import * as XLSX from 'xlsx';
import Papa from 'papaparse';
import axios from 'axios';
// import Loader from "../../components/Loader";

import classnames from "classnames";



const EmailSettings = (props) => {
    //meta title
    document.title =
        "TheRollNumber";


    const [data, setData] = React.useState([]);

    const [loading, setLoading] = React.useState(false);

    const [locations, setLocation] = React.useState([
    ]);


    const Navigate = useNavigate();

    const [state, setState] = React.useState({

        display_name: "",
        ssl: 'active',
        smtp_username: "",
        smtp_port: "",
        smtp_host: "",
        smtp_password: "",
     



    });

    const handleInputChange = (e) => {
        const { id, value } = e.target;
        setState((prevState) => ({
            ...prevState,
            [id]: value,
        }));
    };

    const update = async () => {

  
  
        const config = {
          headers: {
            'Content-Type': 'application/json'
          }
        };

        let body = {
            host: state.smtp_host,
            port: state.smtp_port,
            email: state.smtp_username,
            useSSL: state.ssl === 'active' ? true : false,
            user: state.display_name,
            password: state.smtp_password,
        }
    

        console.log('body==>',body);
    
      
       await axios.put(`${process.env.REACT_APP_BASE_URL}/service/email_credential/2`, body, config)
        .then(response => {
          console.log('email cred updated',response);
    
      
        
          
      
        })
        .catch(error => {
      
          console.log('error updating email cred ==>',error);
        });
  
      };

    
    const getEmailCred = async () => {

  
  
        const config = {
          headers: {
            'Content-Type': 'application/json'
          }
        };
    
    
      
       await axios.get(`${process.env.REACT_APP_BASE_URL}/service/email_credential/2`, {}, config)
        .then(response => {
          console.log('email cred response',response.data);
    
            let data = response.data[0];

            console.log('data==>',data);

 setState({
            smtp_host: data.host,
display_name: data.user,
            ssl: data.useSSL?'active':'inactive',
            smtp_port: data.port,
            smtp_password: data.password,
            smtp_username: data.email,
 })
        
          
      
        })
        .catch(error => {
      
          console.log('error==>',error);
        });
  
      };

        useEffect(() => {

       getEmailCred();

        }, []);

    return (
        <React.Fragment>

<div className="page-content">
        <div className="container-fluid">
            <Card>
                <CardBody>
                    <p className="h4 mb-4">Email Settings</p>

                    <Row>

                        <Col lg="4">
                            <div className="mt-3 d-flex" style={styles.box}>
                                <Label for="basicpill-firstname-input1" style={styles.boxLabel} className="control-label me-3" >
                                    Display Name
                                    <span className="text-danger mx-1">*</span>
                                </Label>
                                <Input
                                    type="text"
                                    className="form-control me-3"
                                    value={state.display_name}
                                    // disabled={selectedValue === 'registration_no' ? false : true}
                                   onChange={handleInputChange}
                                    style={styles.boxInput}
                                    suppressHydrationWarning
                                    id="display_name"
                                />


                            </div>
                        </Col>
                        <Col lg="4">
                        <div className="d-flex mt-3" style={{justifyContent:'space-between', alignItems:"center", width:'60%'}}>
                        <Label for="basicpill-firstname-input1" style={styles.boxLabel} className="control-label me-3" >
                                    SSL
                                    {/* <span className="text-danger mx-1">*</span> */}
                                </Label>

                                <div className="form-check me-3 d-flex">
<div className="form-check me-3">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="exampleRadios1"
                            id="exampleRadios7"
                            value="active"
                            checked={state.ssl === 'active' ? true : false}
                            defaultChecked
                            onChange={(e)=>{
                              let value = e.target.value;
                              
                              setState({...state, ssl: value});
                            }
                          }
                            
                          />
                          <label
                            className="form-check-label"
                            htmlFor="exampleRadios7"
                          >
                            Active
                          </label>
                        </div>

                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="exampleRadios1"
                            checked={state.ssl === 'inactive' ? true : false}
                            id="exampleRadios2"
                            value="inactive"
                            onChange={(e)=>{
                                let value = e.target.value;
                                
                                setState({...state, ssl: value});
                            }
                          }
                          />
                          <label
                            className="form-check-label"
                            htmlFor="exampleRadios2"
                          >
                            Inactive
                          </label>

              

                        
                        </div>
                        </div>
                   
                     
</div>
                        </Col>

                     
                        <Col lg="4">
                            <div className="mt-3 d-flex" style={styles.box} >
                                <Label for="basicpill-firstname-input1" style={styles.boxLabel} className="control-label me-3" >
                                    SMTP Username
                                    {/* <span className="text-danger mx-1">*</span> */}

                                </Label>
                                <Input
                                    type="text"
                                    className="form-control me-3"
                                    value={state.smtp_username}
                                    style={styles.boxInput}

                                    // disabled={selectedValue === 'registration_no' ? false : true}
                                    onChange={handleInputChange}
                                    suppressHydrationWarning
                                    id="smtp_username"
                                />


                            </div>
                        </Col>
                        <Col lg="4">
                            <div className="mt-3 d-flex" style={styles.box}>
                                <Label for="basicpill-firstname-input1" style={styles.boxLabel} className="control-label me-3" >
                                    SMTP Port
                                    {/* <span className="text-danger mx-1">*</span> */}
                                </Label>
                                <Input
                                    type="text"
                                    className="form-control me-3"
                                    value={state.smtp_port}
                                    // disabled={selectedValue === 'registration_no' ? false : true}
                                    onChange={handleInputChange}
                                    style={styles.boxInput}
                                    suppressHydrationWarning
                                    id="smtp_port"
                                    // placeholder="Contact Person"
                                />


                            </div>
                        </Col>
                        <Col lg="4">
                            <div className="mt-3 d-flex" style={styles.box}>
                                <Label for="basicpill-firstname-input1" style={styles.boxLabel} className="control-label me-3" >
                                    SMTP Host
                                    {/* <span className="text-danger mx-1">*</span> */}
                                </Label>
                                <Input
                                    type="text"
                                    className="form-control me-3"
                                    value={state.smtp_host}
                                    // disabled={selectedValue === 'registration_no' ? false : true}
                                    onChange={handleInputChange}
                                    style={styles.boxInput}
                                    suppressHydrationWarning
                                    id="smtp_host"
                                    // placeholder="Contact Person"
                                />


                            </div>
                        </Col>
                        <Col lg="4">
                            <div className="mt-3 d-flex" style={styles.box}>
                                <Label for="basicpill-firstname-input1" style={styles.boxLabel} className="control-label me-3" >
                                    SMTP Password
                                    {/* <span className="text-danger mx-1">*</span> */}
                                </Label>
                                <Input
                                    type="text"
                                    className="form-control me-3"
                                    value={state.smtp_password}
                                    // disabled={selectedValue === 'registration_no' ? false : true}
                                    onChange={handleInputChange}
                                    style={styles.boxInput}
                                    suppressHydrationWarning
                                    id="smtp_password"
                                    // placeholder="Contact Person"
                                />


                            </div>
                        </Col>
                  
                    </Row>

                    <div className="mt-5 text-center"  >
                        <button className="btn btn-primary" style={{ width: 150, }} onClick={update} >Save</button>
                        </div>

                </CardBody>
            </Card>
        </div>

    </div>

        </React.Fragment>
    );
};


export default EmailSettings;

const styles = {

    para: {
        fontSize: 12
    },
    box: {
        fontSize: 16,
        fontWeight: "unset",
        justifyContent: "space-between",
        alignItems: "center",
    },
    boxLabel: {
        fontSize: 13,
        fontWeight: "400",
    },
    boxInput: {
        fontSize: 13,
        width: '70%',
        padding: 8,
        fontWeight: "unset",
    }

}
