


import React, { useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { flatten, get, identity, isEmpty, set, update } from "lodash";
import "bootstrap/dist/css/bootstrap.min.css";
import * as Yup from "yup";
import { useFormik } from "formik";
import axios from "axios";
import classnames from "classnames";
// import {Buffer} from 'buffer';
// import { PDFDownloadLink, pdf, usePDF, BlobProvider } from '@react-pdf/renderer';
import Accordion from "../../../components/Accordian";
import { TDSDATA, PassbookDetail, EPFO } from "../../../components/IdentityInfo";

import Select, { components } from "react-select";

import Cookies from "js-cookie";



import {
  Button,
  Col,
  Row,
  UncontrolledTooltip,
  Modal,
  ModalHeader,
  ModalBody,
  Collapse,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
Input,
  DropdownItem,


  Label,
  Card,
  CardBody,
  Table,
} from "reactstrap";
import moment from "moment";

//Import Flatepicker
import "flatpickr/dist/themes/material_blue.css";
import Flatpickr from "react-flatpickr";
import MySelect from "../../components/SelectOption";
// import PdfDocViewer from "../components/PdfDocViewer";
// import FeatherIcon from "feather-icons-react";

function QcCaseDetail() {
  //meta title
  document.title = "TheRollNumber";

  const navigate = useNavigate();

  // const [instance, updateInstance] = usePDF({ document: PdfDocViewer });


  const location = useLocation();

  const [isOpen, setIsOpen] = useState({});

  const [caseData, setCaseData] = useState({});

  const [education, setEducation] = useState([]);

  const [employment, setEmployment] = useState([]);

  const [colorcodematrix, setColorCodeMatrix] = useState([]);

  const [colorCode, setColorCode] = useState('');

  const [reason, setReason] = useState('');

  const [log_call_attempt, setLogCallAttempt] = useState('no');

  const [log_activity, setLogActivity] = useState('');


  const [depStatusOption, setDepStatusOption] = useState([]);

const [depStatusColor, setDepStatusColor] = useState('');

  const [user, setUser] = useState({});

  const [colors, setColors] = useState(
    {
      'Green': 'green',
      'Red': 'red',
      'Yellow': 'yellow',
      'Amber': '#FFBF00',
    }
  );

  const [report_status, setReportStatus] = useState('');

  const [activityLogs, setActivityLogs] = useState([]);

  const [requestedDocs, setRequestedDocs] = useState([]);

  const [requestedData, setRequestedData] = useState([]);

const [credit_score, setCreditScore] = useState({});

const [selectedStatus , setSelectedStatus] = useState('');

const [depositionStatus, setDepositionStatus] = useState('');

const [dropdownOpen, setDropdownOpen] = useState(false);

const [selectedOption, setSelectedOption] = useState([]);

const [uploaded_documents, setUploadedDocuments] = useState([]);



const toggleDropdown = () => setDropdownOpen(prevState => !prevState);

const [enable_generate_report, setEnableGenerateReport] = useState(false);

const [showDoc, setShowDoc] = useState({

  identity: false,
  professional_identity: false,
  education: false,
  employment: false,
  credit_score: false,
  physical_verification: false,
  court: false,
  drug: false,
  epfo: false,
  tds: false,




});

const [state, setState] = useState({
  client_status: "no",
});

  const [epfo_data, setEpfoData] = useState({});
  const [epfo_companies, setEpfoCompanies] = useState([]);
  const [epfo_passbook, setEpfoPassbook] = useState([]);

  const [itr, setItr] = useState({});

  const [tds, setTds] = useState([]);

  const [options, setOptions] = useState([]);

  const [tds_data, setTdsData] = useState([]);

  const [modalConfirm, setModalConfirm] = useState(false);

  const [modalConfirm2, setModalConfirm2] = useState(false);

  const toggleConfirm = () => setModalConfirm(!modalConfirm);

  const toggleConfirm2 = () => setModalConfirm2(!modalConfirm2);

  const [component_to_verify, setComponentToVerify] = useState(null);

  const [components, setComponents] = useState([]);

// const [setQCM, setQCM] = useState([]);




  const [docNames, setDocNames] = useState({
    aadhaar: "Aadhaar Card",
    pan: "Pan Card",
    voter_id: "Voter Id",
    driving_licence: "Driving License",
   
  });
 

  // Toggle function to open or close an accordion item
  const toggle2 = (index) => {
    setIsOpen((prevState) => ({
      ...prevState,
      [index]: !prevState[index]
    }));
  };


  const [section, setSection] = useState([

    
  ]);


  const [modal, setModal] = useState(false);
  const [modal1, setModal1] = useState(false);
  const [isEdit, setIsEdit] = useState(false);

  const [orderList, setOrderList] = useState([]);
  const [order, setOrder] = useState(null);
  const [col1, setcol1] = useState(true);
  const [col2, setcol2] = useState(false);
  const [col3, setcol3] = useState(false);
  const [col4, setcol4] = useState(false);
  const [col5, setcol5] = useState(false);
  const [col6, setcol6] = useState(false);
  const [col7, setcol7] = useState(false);
  const [col8, setcol8] = useState(false);
  const [col9, setcol9] = useState(false);
  const [col10, setcol10] = useState(false);
  const [col11, setcol11] = useState(false);
  const [col12, setcol12] = useState(false);
  const [col13, setcol13] = useState(false);
  const [col14, setcol14] = useState(false);
  const [col15, setcol15] = useState(false);

  const [cases, setCases] = useState([]);

  

  const t_col1 = () => {
    setcol1(!col1);

  };

  const t_col2 = () => {
    setcol2(!col2);


  };

  const t_col3 = () => {
    setcol3(!col3);


  };

  const t_col4 = () => {
    setcol4(!col4);
    setcol1(false);
    setcol2(false);
    setcol3(false)
    setcol5(false)

  };

  const t_col5 = () => {
    setcol5(!col5);
    setcol1(false);
    setcol2(false);
    setcol3(false)
    setcol4(false)

  };

  const t_col6 = () => {
    setcol6(!col6);


  };

  const t_col7 = () => {
    setcol7(!col7);

  }

  const t_col8 = () => {
    setcol8(!col8);

  }

  const t_col9 = () => {
    setcol9(!col9);

  }

  const t_col10 = () => {

    setcol10(!col10);

  }

  const t_col11 = () => {

    setcol11(!col11);

  }

  const t_col12 = () => {

    setcol12(!col12);

  }


  // validation

  const toggleViewModal = () => setModal1(!modal1);

  useEffect(() => {

    getAdminUsers();

    get_case();

    get_components();

    // getUploadedDocuments()

    

  }, []);

  useEffect(() => {

    if (Object.keys(caseData).length > 0){
      getQCcaseComponents();

   

    }

  }, [caseData]);

  const getQCcaseComponents = async () => {


    const config = {
      headers: {
        'Content-Type': 'application/json'
      }
    };


    if(caseData.request_id){
   
   await axios.get(`${process.env.REACT_APP_BASE_URL}/employer/admin/qc_case_components/${caseData.request_id}`, {}, config)

    .then(response => {


      console.log('response qc cases==>',response)

      const data = response.data.data;

      let arr = []

     data.map((item)=> {

  
      
      arr.push({
        ...item,
        select:false
      })

    

      })

    

      setCases(arr)


    })

    .catch(error => {


      console.log('error qc cases from server==>',error)

    })
  }

  }

  const getComponentFields = async (obj) => {

    const config = {
      headers: {
        'Content-Type': 'application/json'
      }
    };

    console.log('get component fields==>', obj)

   let result = await axios.post(`${process.env.REACT_APP_BASE_URL}/employer/admin/component_fields`, obj, config)

    console.log('result==>',result.data.data);

   return result.data.data;

  }


  const update_case_report = async (obj) => {

    const config = {
      headers: {
        'Content-Type': 'application/json'
      }
    };

    console.log('update case report==>', obj)

    await axios.post(`${process.env.REACT_APP_BASE_URL}/employer/admin/case_report`, obj, config)
    .then(response => {

      console.log('response==>',response);

  

    })
    .catch(error => {

      console.log('error==>',error);

    })

  

  }



  const getAdminUsers = async () => {

    const config = {
      headers: {
        'Content-Type': 'application/json'
      }
    };
   
    axios.get(`${process.env.REACT_APP_BASE_URL}/employer/admin/users`, {}, config)

    .then(response => {

      console.log('admin response cases==>',response.data.data)

      const data = response.data.data.filter((item)=> (item.roles.includes('QC Component Manager') || item.roles.includes('Administrator')) );

        console.log('new case users data==>',data)

        // setPTL(data)


    })

    .catch(error => {

      console.log('error from server==>',error)

    })


  }


  const get_components = async () => {

    const config = {
      headers: {
        'Content-Type': 'application/json'
      }
    };
   
    axios.get(`${process.env.REACT_APP_BASE_URL}/service/components`, {}, config)

    .then(response => {

      console.log('response components==>',response)

      const data = response.data;

     console.log('data==>',data)

     let arr = [];

      data.map((item)=> {

        arr.push({
          component: item.name,
          code: item.code,
          checked: false,
          id: item.id
        })

      })


      setComponents(arr)




    })

    .catch(error => {

      console.log('error from server==>',error)

    })


  }


  const get_requested_data = async () => {

    console.log('caseData',caseData);

    const config = {
      headers: {
        'Content-Type': 'application/json',

      }
    };

    const body = {

      org_user_client_id: caseData.org_client_id,
    
    }

    console.log('body requested ==>',body);

    axios.post(`${process.env.REACT_APP_BASE_URL}/service/requestedData/${caseData.walletID}`, body, config)
    .then(response => {
      console.log('requested data',response);

      let data = response.data.data;

      console.log('data==>',data);

      if(data.length > 0){

        setRequestedData(data);

        let isEducation = data.find((doc) => Object.keys(doc)[0] === 'education');

        console.log('isEducation==>',isEducation?.education);

        if (isEducation && isEducation.education.length > 0){

        
          setEducation(isEducation.education);
        }

    

        let isCreditScore = data.find((doc) => Object.keys(doc)[0] === 'credit_score');

        console.log('isCreditScore==>',isCreditScore?.credit_score);

        if (isCreditScore && isCreditScore.credit_score.length > 0){

          
          setCreditScore(isCreditScore.credit_score[0]);
        }

        let isEmployment = data.find((doc) => Object.keys(doc)[0] === 'employment');

        console.log('isEmployment==>',isEmployment?.employment);

        if (isEmployment && isEmployment.employment.length > 0){
          setEmployment(isEmployment.employment);
        }

        let isEpfo = data.find((doc) => Object.keys(doc)[0] === 'epfo');

        // console.log('isEpfo==>',Object.keys(isEpfo.epfo).length);

        if (isEpfo && Object.keys(isEpfo.epfo).length > 0){

          

          console.log('epfo data==>',isEpfo.epfo.epfo_company);
  
          setEpfoData(isEpfo.epfo.epfo_employee);

          setEpfoCompanies(isEpfo.epfo.epfo_company);

          setEpfoPassbook(isEpfo.epfo.epfo_passbook);
          
         

        }

        let isItr = data.find((doc) => Object.keys(doc)[0] === 'tds');

        console.log('isItr==>',isItr?.tds);

        if (isItr && isItr.tds.tds.length > 0){

       

          setItr(isItr.tds.itr_26AS);

          setTds(isItr.tds.tds);

          setTdsData(isItr.tds.tds_data);



        }


      }


 

    })
    .catch(error => {
  
      
  
      console.log('error==>',error);
    });

 
  }

  useEffect(() => {

    if (Object.keys(caseData).length > 0){
      get_request_identity_org();
      activity_logs();
      get_requested_data();
      getUploadedDocuments()
    }

  }, [caseData, cases]);


  const updateDocument = async () => {

    const docs = [...uploaded_documents];

    console.log('update docs body==>',docs);

    const config = {
      headers: {
        'Content-Type': 'application/json'
      }
    };

    const body = {
      documents: docs,
    }

    console.log('update document body==>',body);

    await axios.post(`${process.env.REACT_APP_BASE_URL}/employer/admin/update_uploaded_document`, body, config)

    .then(response => {
        
        console.log('update document==>',response)
  
      }
    )

    .catch(error => {

      console.log('error from server==>',error)

    })

  }

  const Aadhaar = ({item}) => {

    // let comp  = components.find((item)=> item.code == 'aadhaar');


    let code = components.find((item)=> item.code == 'aadhaar').id;

    console.log('code==>',code);

    let verified_comp = cases.find((item)=> item.component_id == code);

    console.log('verified_comp==>',cases);


    let deposition_status = cases.find((item)=> item.component_id == code)?.deposition_status;

    let comment = cases.find((item)=> item.component_id == code)?.comment;

    let reason = cases.find((item)=> item.component_id == code)?.reason;

    let colorcode = cases.find((item)=> item.component_id == code)?.colorcode;

    let color = colors[colorcode];

    console.log('aadhaar id', verified_comp);

      let component_fields =  getComponentFields({case_id: verified_comp.case_id, component_id: verified_comp.component_id, sub_component_id: null});

      console.log('component_fields==>',component_fields.then((res)=>{
        console.log('res==>',res);

        if(res.length > 0){

          let obj = {};
  
          res.map((item)=> {
  
            obj[item.field_id] = item.value;
  
          })
  
          item.corrected_fields = obj;
  
        }
      }
    
    ));



    return (
      <div>
        
        <Table className="table table-striped mb-0" striped  >

<thead >

  <tr>
    <th style={{width:'20%', fontSize:16, fontWeight:500}}>Aadhaar Card component details</th>
    <th style={{width:'30%', fontSize:16, fontWeight:500}}>Data Shared By Candidate</th>
    <th style={{width:'10%', fontSize:16, fontWeight:500}}></th>

    <th style={{width:'50%', fontSize:16, fontWeight:500}}>Verified Data</th>

  </tr>

  </thead>

<tbody >
  <tr>

    <td width={'20%'}>Aadhaar Card Number *</td>
    <td width={'20%'}>{item.aadhaar_number}</td>

    <td width={'10%'}>
  
    </td>
  
    <td width={'50%'}>
    <p>
    {item.corrected_fields?.aadhaar_number ? item.corrected_fields?.aadhaar_number: item.aadhaar_number}
    </p>
    </td>
  </tr>  


  <tr>

<td width={'20%'}>Full Name *</td>
<td width={'20%'}>{item.full_name}</td>

<td width={'10%'}>

</td>

<td width={'50%'}>
<p>
{item.corrected_fields?.full_name ? item.corrected_fields?.full_name: item.full_name}
</p>
</td>
</tr> 

<tr>

<td width={'20%'}>DOB *</td>
<td width={'20%'}>{item.dob}</td>

<td width={'10%'}>

</td>

<td width={'50%'}>

<p>
{item.corrected_fields?.dob ? item.corrected_fields?.dob: item.dob}
</p>
</td>
</tr> 


<tr>

<td width={'20%'}>Care Of *</td>
<td width={'20%'}>{item.care_of}</td>

<td width={'10%'}>


</td>

<td width={'50%'}>

<p>
{item.corrected_fields?.care_of ? item.corrected_fields?.care_of: item.care_of}
</p>
</td>
</tr> 

<tr>

<td width={'20%'}>Full address *</td>
<td width={'20%'}>{item.address}</td>

<td width={'10%'}>

</td>

<td width={'50%'}>
<p>
{item.corrected_fields?.address ? item.corrected_fields?.address: item.address}
</p>
</td>
</tr> 

<tr>

<td width={'20%'}>Attachment</td>
<td width={'20%'}>
  
<button 
onClick={()=>{
  window.open(item.uploaded_document, '_blank');

}
}
className="btn btn-primary btn-sm">View</button>
</td>

<td width={'10%'}>

</td>

<td width={'50%'}>
<p>
</p>
</td>
</tr>


<tr>

<td width={'20%'}>Verified On *</td>
<td width={'20%'}>{moment(item.timestamp).format('DD-MM-YYYY')}</td>

<td width={'10%'}>

</td>

<td width={'50%'}>

</td>
</tr> 

<tr>

<td width={'20%'}>Status</td>
<td width={'20%'} >
  
  <p style={{
  background: color,
  padding: 5,
  color:"#fff"
}}>Qc Complete</p>

</td>

<td width={'10%'}>

</td>

<td width={'50%'}>
</td>
</tr> 

<tr>

<td width={'20%'}>Disposition Status *</td>
<td width={'20%'}>
  <p>{deposition_status}</p>
</td>

<td width={'10%'}>
{/* <input style={styles.inputCheck} className="form-check-input" onClick={(e)=>{
                         
                        }} type="checkbox" id="upcomingtaskCheck01" /> */}
</td>

<td width={'50%'}>
  
</td>
</tr> 


<tr>

<td width={'20%'}>Disposition Remarks *</td>
<td width={'20%'}>

<p>{reason}</p>

</td>

<td width={'10%'}>
{/* <input style={styles.inputCheck} disabled className="form-check-input" onClick={(e)=>{
                         
                        }} type="checkbox" id="upcomingtaskCheck01" /> */}
</td>

<td width={'50%'}>
</td>
</tr> 


<tr>

<td width={'20%'}>Comments *</td>
<td width={'20%'}>

<p>{comment}</p>

</td>

<td width={'10%'}>
{/* <input style={styles.inputCheck} disabled className="form-check-input" onClick={(e)=>{
                         
                        }} type="checkbox" id="upcomingtaskCheck01" /> */}
</td>

<td width={'50%'}>
</td>
</tr> 
  
 


</tbody>
</Table>
      


   
              </div>
    )
  }


  const Employment = ({item}) => {

    let comp  = components.find((item)=> item.code == 'employment');

    let code = components.find((item)=> item.code == 'employment').id;


    let verified_comp = cases.find((item)=> item.component_id == comp.id);

    let deposition_status = cases.find((item)=> item.component_id == code)?.deposition_status;

    let comment = cases.find((item)=> item.component_id == code)?.comment;

    let reason = cases.find((item)=> item.component_id == code)?.reason;

    let colorcode = cases.find((item)=> item.component_id == code)?.colorcode;

    let color = colors[colorcode];

    let filtered = employment.filter((item)=> {

       let idx = cases.findIndex((i)=> i.sub_component_id == item.id);
        
        return idx > -1;
  
      
    });

    console.log('filtered==>',filtered);

    let promises = []

    filtered.map((item)=> {

      let promise = new Promise(async(resolve, reject) => {

      let component_fields = await getComponentFields({case_id: caseData.request_id, component_id: caseData.component_id, sub_component_id: item.id});

      console.log('component_fields==>',component_fields);

      let obj = {};

      component_fields.map((item)=> {

        obj[item.field_id] = item.value;

      })

      item.corrected_fields = obj;

      resolve('done');

    })

    promises.push(promise);

    })

    Promise.all(promises).then((res)=> {
        
        console.log('res==>',res);
  
      })


    return (
      <div className="m-2">

      



<Table className="table mb-0">
<thead className="table-dark">
<tr>
    <th>Employment Details</th>
    <th></th>
  </tr>
  </thead>
<tbody>
  </tbody>
</Table>

{
 filtered.length > 0 && filtered.map((item, index) => (


<Table className="table table-striped mb-0 mt-2" striped  >

<thead >

  <tr>

    <th style={{width:'20%', fontSize:16, fontWeight:500}}>Employment component details</th>

    <th style={{width:'30%', fontSize:16, fontWeight:500}}>Data Shared By Candidate</th>

    <th style={{width:'10%', fontSize:16, fontWeight:500}}></th>

    <th style={{width:'50%', fontSize:16, fontWeight:500}}>Verified Data</th>

  </tr>

  </thead>

<tbody >

  <tr>

    <td width={'20%'}>Company Name *</td>

    <td width={'20%'}>{item.company_name}</td>

    <td width={'10%'}>

    </td>

    <td width={'50%'}>

<p>
{item.corrected_fields?.company_name ? item.corrected_fields?.company_name: item.company_name}

</p>
     
    </td>

  </tr>

  <tr>

    <td width={'20%'}>Designation *</td>

    <td width={'20%'}>{item.designation}</td>

    <td width={'10%'}>


    </td>

    <td width={'50%'}>

  {/* <p>{item.corrected_fields?.designation}</p> */}

  <p>{item.corrected_fields?.designation ? item.corrected_fields?.designation: item.designation}</p>

    </td>

  </tr>

  <tr>

    <td width={'20%'}>Employee ID *</td>

    <td width={'20%'}>{item.emp_id}</td>

    <td width={'10%'}>

    </td>

    <td width={'50%'}>

<p>{item.corrected_fields?.emp_id ? item.corrected_fields?.emp_id: item.emp_id}</p>     
    </td>

  </tr>

  <tr>

    <td width={'20%'}>Reason for Leaving *</td>

    <td width={'20%'}>{item.reason_for_leaving}</td>

    <td width={'10%'}>

    </td>

    <td width={'50%'}>


    </td>

  </tr>



  <tr>

    <td width={'20%'}>Date of Joining *</td>

    <td width={'20%'}>{item.doj}</td>

    <td width={'10%'}>


    </td>

    <td width={'50%'}>

 <p>{item.corrected_fields?.doj ? item.corrected_fields?.doj: item.doj}</p>
     
    </td>

  </tr>

  <tr>

    <td width={'20%'}>Date of End *</td>

    <td width={'20%'}>{item.doe}</td>

    <td width={'10%'}>


    </td>

    <td width={'50%'}>


    <p>{item.corrected_fields?.doe ? item.corrected_fields?.doe: item.doe}</p>

    </td>

  </tr>

  <tr>

<td width={'20%'}>Status</td>
<td width={'20%'} >
  
  <p style={{
  background: color,
  padding: 5,
  color:"#fff"
}}>Qc Complete</p>

</td>

<td width={'10%'}>

</td>

<td width={'50%'}>
</td>
</tr> 

<tr>

<td width={'20%'}>Disposition Status *</td>
<td width={'20%'}>
  <p>{deposition_status}</p>
</td>

<td width={'10%'}>
{/* <input style={styles.inputCheck} className="form-check-input" onClick={(e)=>{
                         
                        }} type="checkbox" id="upcomingtaskCheck01" /> */}
</td>

<td width={'50%'}>
  
</td>
</tr> 


<tr>

<td width={'20%'}>Disposition Remarks *</td>
<td width={'20%'}>

<p>{reason}</p>

</td>

<td width={'10%'}>
{/* <input style={styles.inputCheck} disabled className="form-check-input" onClick={(e)=>{
                         
                        }} type="checkbox" id="upcomingtaskCheck01" /> */}
</td>

<td width={'50%'}>
</td>
</tr> 


<tr>

<td width={'20%'}>Comments *</td>
<td width={'20%'}>

<p>{comment}</p>

</td>

<td width={'10%'}>
{/* <input style={styles.inputCheck} disabled className="form-check-input" onClick={(e)=>{
                         
                        }} type="checkbox" id="upcomingtaskCheck01" /> */}
</td>

<td width={'50%'}>
</td>
</tr> 
  



</tbody>


</Table>


  ))
}
         

          
       
</div>
    )
  }

  const Education = ({item}) => {


    let comp  = components.find((item)=> item.code == 'education');

    let code = components.find((item)=> item.code == 'education').id;


    let verified_comp = cases.find((item)=> item.component_id == comp.id);

    let deposition_status = cases.find((item)=> item.component_id == code)?.deposition_status;

    let comment = cases.find((item)=> item.component_id == code)?.comment;

    let reason = cases.find((item)=> item.component_id == code)?.reason;

    let colorcode = cases.find((item)=> item.component_id == code)?.colorcode;

    let color = colors[colorcode];


    let filtered = education.filter((item)=> {

       let idx = cases.findIndex((i)=> i.sub_component_id == item.registration_number);
        
        return idx > -1;
  
      
    });

    console.log('education filtered==>',filtered, education);

    let promises = []

    filtered.map((item)=> {

      let promise = new Promise(async(resolve, reject) => {

      let component_fields = await getComponentFields({case_id: caseData.request_id, component_id: caseData.component_id, sub_component_id: item.id});

      let depo_status_idx = cases.findIndex((i)=> i.sub_component_id == item.registration_number);

      let depo_status = cases[depo_status_idx]?.deposition_status;

      let colorCode = cases[depo_status_idx]?.colorcode;

      let reason = cases[depo_status_idx]?.reason;
     
      console.log('component_fields==>',component_fields);

      let obj = {};

      component_fields.map((item)=> {

        obj[item.field_id] = item.value;

      })

      item.corrected_fields = obj;

      item.deposition_status = depo_status;

      item.colorcode = colorCode;

      item.reason = reason;

      resolve('done');

    })

    promises.push(promise);

    })

    Promise.all(promises).then((res)=> {
        
        console.log('res==>',res);
  
      })


    return (
      <div className="m-2">

      



<Table className="table mb-0">
<thead className="table-dark">
<tr>
    <th>Education details</th>
    <th></th>
  </tr>
  </thead>
<tbody>
  </tbody>
</Table>

{
 filtered.length > 0 && filtered.map((item, index) => (


<Table className="table table-striped mb-0" striped  >

<thead >

  <tr>

    <th style={{width:'20%', fontSize:16, fontWeight:500}}>Education component details</th>

    <th style={{width:'30%', fontSize:16, fontWeight:500}}>Data Shared By Candidate</th>

    <th style={{width:'10%', fontSize:16, fontWeight:500}}></th>

    <th style={{width:'50%', fontSize:16, fontWeight:500}}>Verified Data</th>

  </tr>

  </thead>

<tbody >

  <tr>

    <td width={'20%'}>University Name *</td>

    <td width={'20%'}>{item.university_name}</td>

    <td width={'10%'}>



    </td>

    <td width={'50%'}>

{/* <p>{item.corrected_fields?.university_name}</p> */}

<p>{item.corrected_fields?.university_name ? item.corrected_fields?.university_name: item.university_name}</p>


    </td>

  </tr>

  <tr>

    <td width={'20%'}>Type of Graduation *</td>

    <td width={'20%'}>{item.type_of_graduation}</td>

    <td width={'10%'}>



    </td>

    <td width={'50%'}>

<p>{item.corrected_fields?.type_of_graduation ? item.corrected_fields?.type_of_graduation: item.type_of_graduation}</p>
    </td>

  </tr>

  <tr>

    <td width={'20%'}>CGPA *</td>

    <td width={'20%'}>{item.CGPA}</td>

    <td width={'10%'}>



    </td>

    <td width={'50%'}>
<p>{item.corrected_fields?.CGPA ? item.corrected_fields?.CGPA: item.CGPA}</p>
    </td>

  </tr>

  <tr>

    <td width={'20%'}>Year of Graduation *</td>

    <td width={'20%'}>{item.year_of_graduation}</td>

    <td width={'10%'}>


    </td>

    <td width={'50%'}>

<p>{item.corrected_fields?.year_of_graduation ? item.corrected_fields?.year_of_graduation: item.year_of_graduation}</p>
    </td>

  </tr>

  <tr>

    <td width={'20%'}>Year of Start *</td>

    <td width={'20%'}>{item.year_of_start}</td>

    <td width={'10%'}>



    </td>

    <td width={'50%'}>

<p>{item.corrected_fields?.year_of_start ? item.corrected_fields?.year_of_start: item.year_of_start}</p>
    </td>

  </tr>

  <tr>

    <td width={'20%'}>Year of End *</td>

    <td width={'20%'}>{item.year_of_end}</td>

    <td width={'10%'}>


    </td>

    <td width={'50%'}>

<p>{item.corrected_fields?.year_of_end ? item.corrected_fields?.year_of_end: item.year_of_end}</p>
    </td>

  </tr>

  <tr>

    <td width={'20%'}>Final Status *</td>

    <td width={'20%'}>{item.final_status}</td>

    <td width={'10%'}>



    </td>

    <td width={'50%'}>

    </td>

  </tr>

  <tr>

    <td width={'20%'}>Upload Documents *</td>

    <td width={'20%'}>


    </td>

    <td width={'10%'}>

    </td>


    <td width={'50%'}>


    </td>

  </tr>

  <tr>

<td width={'20%'}>Status</td>
<td width={'20%'} >
  
  <p style={{
  background: colors[item.colorcode],
  padding: 5,
  color:"#fff"
}}>Qc Complete</p>

</td>

<td width={'10%'}>

</td>

<td width={'50%'}>
</td>
</tr> 

<tr>

<td width={'20%'}>Disposition Status *</td>
<td width={'20%'}>
  <p>{item.deposition_status}</p>
</td>

<td width={'10%'}>
{/* <input style={styles.inputCheck} className="form-check-input" onClick={(e)=>{
                         
                        }} type="checkbox" id="upcomingtaskCheck01" /> */}
</td>

<td width={'50%'}>
  
</td>
</tr> 


<tr>

<td width={'20%'}>Disposition Remarks *</td>
<td width={'20%'}>

<p>{item.reason}</p>

</td>

<td width={'10%'}>
{/* <input style={styles.inputCheck} disabled className="form-check-input" onClick={(e)=>{
                         
                        }} type="checkbox" id="upcomingtaskCheck01" /> */}
</td>

<td width={'50%'}>
</td>
</tr> 


<tr>

<td width={'20%'}>Comments *</td>
<td width={'20%'}>

<p>{comment}</p>

</td>

<td width={'10%'}>
{/* <input style={styles.inputCheck} disabled className="form-check-input" onClick={(e)=>{
                         
                        }} type="checkbox" id="upcomingtaskCheck01" /> */}
</td>

<td width={'50%'}>
</td>
</tr> 

</tbody>

</Table>


  ))
}
         

          
       
</div>
    )
  }

  const Pan = ({item}) => {

    let code = components.find((item)=> item.code == 'pan').id;

    let deposition_status = cases.find((item)=> item.component_id == code).deposition_status;

    let comment = cases.find((item)=> item.component_id == code).comment;

    let reason = cases.find((item)=> item.component_id == code).reason;

    let colorcode = cases.find((item)=> item.component_id == code).colorcode;

    let color = colors[colorcode];


    return (
      <div>
        
        <Table className="table table-striped mb-0" striped  >

<thead >

  <tr>
    <th style={{width:'20%', fontSize:16, fontWeight:500}}>Pan Card component details</th>
    <th style={{width:'30%', fontSize:16, fontWeight:500}}>Data Shared By Candidate</th>
    <th style={{width:'10%', fontSize:16, fontWeight:500}}></th>

    <th style={{width:'50%', fontSize:16, fontWeight:500}}>Verified Data</th>

  </tr>

  </thead>

<tbody >
  <tr>

    <td width={'20%'}>PAN Card Number *</td>
    <td width={'20%'}>{item.pan_number}</td>

    <td width={'10%'}>

    </td>
  
    <td width={'50%'} >
    <p> 

    {item.corrected_fields?.pan_number ? item.corrected_fields?.pan_number: item.pan_number}
    </p>

    </td>
  </tr>  


  <tr>

<td width={'20%'}>Full Name *</td>
<td width={'20%'}>{item.full_name}</td>

<td width={'10%'}>

</td>

<td width={'50%'}>
<p>{item.corrected_fields?.full_name ? item.corrected_fields?.full_name: item.full_name}</p>
</td>
</tr> 

<tr>

<td width={'20%'}>PAN Card Verification Status *</td>
<td width={'20%'}>Verified</td>

<td width={'10%'}>
{/* <input style={styles.inputCheck} className="form-check-input" onClick={(e)=>{
                         
                        }} type="checkbox" id="upcomingtaskCheck01" /> */}
</td>

<td width={'50%'}>
{/* <input type="text" className="form-control" value={''} /> */}
</td>
</tr> 

<tr>

<td width={'20%'}>Attachment</td>
<td width={'20%'}>
  
<button 
onClick={()=>{
  window.open(item.uploaded_document, '_blank');

}
}
className="btn btn-primary btn-sm">View</button>
</td>

<td width={'10%'}>

</td>

<td width={'50%'}>
<p>
</p>
</td>
</tr>

<tr>

<td width={'20%'}>Verified On *</td>
<td width={'20%'}>{moment(item.timestamp).format('DD-MM-YYYY')}</td>

<td width={'10%'}>
{/* <input style={styles.inputCheck}  className="form-check-input" onClick={(e)=>{
                         
                        }} type="checkbox" id="upcomingtaskCheck01" /> */}
</td>

<td width={'50%'}>
{/* <input type="text" disabled className="form-control" value={''} /> */}
</td>
</tr> 


<tr>

<td width={'20%'}>Status</td>
<td width={'20%'} > 


<p style={{
  background: color,
  padding: 5,
  color:"#fff"
}}>Qc Complete</p>

</td>

<td width={'10%'}>

</td>

<td width={'50%'}>
</td>
</tr> 

<tr>

<td width={'20%'}>Disposition Status *</td>
<td width={'20%'}>
  <p>{deposition_status}</p>
</td>

<td width={'10%'}>
{/* <input style={styles.inputCheck} className="form-check-input" onClick={(e)=>{
                         
                        }} type="checkbox" id="upcomingtaskCheck01" /> */}
</td>

<td width={'50%'}>
  
</td>
</tr> 


<tr>

<td width={'20%'}>Disposition Remarks *</td>
<td width={'20%'}>

<p>{reason}</p>

</td>

<td width={'10%'}>
{/* <input style={styles.inputCheck} disabled className="form-check-input" onClick={(e)=>{
                         
                        }} type="checkbox" id="upcomingtaskCheck01" /> */}
</td>

<td width={'50%'}>
</td>
</tr> 


<tr>

<td width={'20%'}>Comments *</td>
<td width={'20%'}>

<p>{comment}</p>

</td>

<td width={'10%'}>
{/* <input style={styles.inputCheck} disabled className="form-check-input" onClick={(e)=>{
                         
                        }} type="checkbox" id="upcomingtaskCheck01" /> */}
</td>

<td width={'50%'}>
</td>
</tr> 
  


</tbody>
</Table>
              </div>
    )
  }

  const VoterId = ({item}) => {

    let code = components.find((item)=> item.code == 'driving_licence').id;

    let deposition_status = cases.find((item)=> item.component_id == code)?.deposition_status;

    let comment = cases.find((item)=> item.component_id == code)?.comment;

    let reason = cases.find((item)=> item.component_id == code)?.reason;

    let colorcode = cases.find((item)=> item.component_id == code)?.colorcode;

    let color = colors[colorcode];

    return (
      <div>
        
        <Table className="table table-striped mb-0" striped  >

<thead >

  <tr>
    <th style={{width:'20%', fontSize:16, fontWeight:500}}>Voter ID component details</th>
    <th style={{width:'30%', fontSize:16, fontWeight:500}}>Data Shared By Candidate</th>
    <th style={{width:'10%', fontSize:16, fontWeight:500}}></th>

    <th style={{width:'50%', fontSize:16, fontWeight:500}}>Verified Data</th>

  </tr>

  </thead>

<tbody >
  <tr>

    <td width={'20%'}>Epic Number *</td>
    <td width={'20%'}>{item.epic_no}</td>

    <td width={'10%'}>

    </td>
  
    <td width={'50%'}>

    {item.corrected_fields?.epic_no ? item.corrected_fields?.epic_no: item.epic_no}
    </td>
  </tr>  


  <tr>

<td width={'20%'}>Full Name *</td>
<td width={'20%'}>{item.name}</td>

<td width={'10%'}>

</td>

<td width={'50%'}>
<p>{item.corrected_fields?.name ? item.corrected_fields?.name: item.name}</p>
</td>
</tr> 

<tr>

<td width={'20%'}>Gender *</td>
<td width={'20%'}>{item.gender}</td>

<td width={'10%'}>

</td>

<td width={'50%'}>

 
<p>{item.corrected_fields?.gender ? item.corrected_fields?.gender: item.gender}</p>


</td>
</tr> 

<tr>

<td width={'20%'}>Area *</td>
<td width={'20%'}>{item.area}</td>

<td width={'10%'}>

</td>

<td width={'50%'}>
<p>{item.corrected_fields?.area ? item.corrected_fields?.area: item.area}</p>
</td>
</tr> 

<tr>

<td width={'20%'}>Attachment</td>
<td width={'20%'}>
  
<button 
onClick={()=>{
  window.open(item.uploaded_document, '_blank');

}
}
className="btn btn-primary btn-sm">View</button>
</td>

<td width={'10%'}>

</td>

<td width={'50%'}>
<p>
</p>
</td>
</tr>

<tr>

<td width={'20%'}>Verified On *</td>
<td width={'20%'}>{moment(item.timestamp).format('DD-MM-YYYY')}</td>

<td width={'10%'}>
{/* <input style={styles.inputCheck}  className="form-check-input" onClick={(e)=>{
                         
                        }} type="checkbox" id="upcomingtaskCheck01" /> */}
</td>

<td width={'50%'}>
{/* <input type="text" disabled className="form-control" value={''} /> */}
</td>
</tr> 



<tr>

<td width={'20%'}>Status</td>
<td width={'20%'} >
  
  <p style={{
  background: color,
  padding: 5,
  color:"#fff"
}}>Qc Complete</p>

</td>

<td width={'10%'}>

</td>

<td width={'50%'}>
</td>
</tr> 

<tr>

<td width={'20%'}>Disposition Status *</td>
<td width={'20%'}>
  <p>{deposition_status}</p>
</td>

<td width={'10%'}>
{/* <input style={styles.inputCheck} className="form-check-input" onClick={(e)=>{
                         
                        }} type="checkbox" id="upcomingtaskCheck01" /> */}
</td>

<td width={'50%'}>
  
</td>
</tr> 


<tr>

<td width={'20%'}>Disposition Remarks *</td>
<td width={'20%'}>

<p>{reason}</p>

</td>

<td width={'10%'}>
{/* <input style={styles.inputCheck} disabled className="form-check-input" onClick={(e)=>{
                         
                        }} type="checkbox" id="upcomingtaskCheck01" /> */}
</td>

<td width={'50%'}>
</td>
</tr> 


<tr>

<td width={'20%'}>Comments *</td>
<td width={'20%'}>

<p>{comment}</p>

</td>

<td width={'10%'}>
{/* <input style={styles.inputCheck} disabled className="form-check-input" onClick={(e)=>{
                         
                        }} type="checkbox" id="upcomingtaskCheck01" /> */}
</td>

<td width={'50%'}>
</td>
</tr> 
  
  


</tbody>
</Table>

      
   
              </div>
    )
  }

  const DL = ({item}) => {

    let code = components.find((item)=> item.code == 'driving_licence').id;

    let deposition_status = cases.find((item)=> item.component_id == code)?.deposition_status;

    let comment = cases.find((item)=> item.component_id == code)?.comment;

    let reason = cases.find((item)=> item.component_id == code)?.reason;

    let colorcode = cases.find((item)=> item.component_id == code)?.colorcode;

    let color = colors[colorcode];

    return (
      <div>
        
        <Table className="table table-striped mb-0" striped  >

<thead >

  <tr>
    <th style={{width:'20%', fontSize:16, fontWeight:500}}>Driving License component details</th>
    <th style={{width:'30%', fontSize:16, fontWeight:500}}>Data Shared By Candidate</th>
    <th style={{width:'10%', fontSize:16, fontWeight:500}}></th>

    <th style={{width:'50%', fontSize:16, fontWeight:500}}>Verified Data</th>

  </tr>

  </thead>

<tbody >

  <tr>

    <td width={'20%'}>License Number *</td>

    <td width={'20%'}>{item.license_number}</td>

    <td width={'10%'}>

   

    </td>

    <td width={'50%'}>

    {item.corrected_fields?.license_number ? item.corrected_fields?.license_number: item.license_number}


    </td>

  </tr>

  <tr>

    <td width={'20%'}>Name *</td>
     
    <td width={'20%'}>{item.name}</td>

    <td width={'10%'}>



    </td>

    <td width={'50%'}>

    <p>{item.corrected_fields?.name ? item.corrected_fields?.name: item.name}</p>
    </td>

  </tr>

  <tr>

    <td width={'20%'}>DOB *</td>

    <td width={'20%'}>{item.dob}</td>

    <td width={'10%'}>



    </td>

    <td width={'50%'}>

    <p>{item.corrected_fields?.dob ? item.corrected_fields?.dob: item.dob}</p>
    </td>

  </tr>

  <tr>

<td width={'20%'}>Address *</td>

<td width={'20%'}>{item.permanent_address}</td>

<td width={'10%'}>








</td>

<td width={'50%'}>
<p>{item.corrected_fields?.permanent_address ? item.corrected_fields?.permanent_address: item.permanent_address}</p>

</td>

</tr>

<tr>

<td width={'20%'}>Status</td>
<td width={'20%'} >
  
  <p style={{
  background: color,
  padding: 5,
  color:"#fff"
}}>Qc Complete</p>

</td>

<td width={'10%'}>

</td>

<td width={'50%'}>
</td>
</tr> 

<tr>

<td width={'20%'}>Disposition Status *</td>
<td width={'20%'}>
  <p>{deposition_status}</p>
</td>

<td width={'10%'}>
{/* <input style={styles.inputCheck} className="form-check-input" onClick={(e)=>{
                         
                        }} type="checkbox" id="upcomingtaskCheck01" /> */}
</td>

<td width={'50%'}>
  
</td>
</tr> 


<tr>

<td width={'20%'}>Disposition Remarks *</td>
<td width={'20%'}>

<p>{reason}</p>

</td>

<td width={'10%'}>
{/* <input style={styles.inputCheck} disabled className="form-check-input" onClick={(e)=>{
                         
                        }} type="checkbox" id="upcomingtaskCheck01" /> */}
</td>

<td width={'50%'}>
</td>
</tr> 


<tr>

<td width={'20%'}>Comments *</td>
<td width={'20%'}>

<p>{comment}</p>

</td>

<td width={'10%'}>
{/* <input style={styles.inputCheck} disabled className="form-check-input" onClick={(e)=>{
                         
                        }} type="checkbox" id="upcomingtaskCheck01" /> */}
</td>

<td width={'50%'}>
</td>
</tr> 
  



</tbody>



</Table>
              </div>
    )
  }

  const Nurse = ({item}) => {

    let code = components.find((item)=> item.code == 'nurse').id;

    console.log('code==>',code);

    let verified_comp = cases.find((item)=> item.component_id == code);

    console.log('verified_comp==>',cases);


    let deposition_status = cases.find((item)=> item.component_id == code)?.deposition_status;

    let comment = cases.find((item)=> item.component_id == code)?.comment;

    let reason = cases.find((item)=> item.component_id == code)?.reason;

    let colorcode = cases.find((item)=> item.component_id == code)?.colorcode;

    let color = colors[colorcode];

    console.log('nurse id', verified_comp);

      let component_fields =  getComponentFields({case_id: verified_comp.case_id, component_id: verified_comp.component_id, sub_component_id: null});

      console.log('component_fields==>',component_fields.then((res)=>{
        console.log('res==>',res);

        if(res.length > 0){

          let obj = {};
  
          res.map((item)=> {
  
            obj[item.field_id] = item.value;
  
          })
  
          item.corrected_fields = obj;
  
        }
      }
    
    ));

    return (
      <div>
        
        <Table className="table table-striped mb-0" striped  >

<thead >

  <tr>
    <th style={{width:'20%', fontSize:16, fontWeight:500}}>Nurse component details</th>
    <th style={{width:'30%', fontSize:16, fontWeight:500}}>Data Shared By Candidate</th>
    <th style={{width:'10%', fontSize:16, fontWeight:500}}></th>

    <th style={{width:'50%', fontSize:16, fontWeight:500}}>Verified Data</th>

  </tr>

  </thead>

<tbody >

  <tr>

    <td width={'20%'}>Nurse ID *</td>
    <td width={'20%'}>{item.nurse_id}</td>

    <td width={'10%'}>

    </td>

    <td width={'50%'}>

      <p>{item.corrected_fields?.nurse_id}</p>

    </td>

  </tr>

  <tr>

    <td width={'20%'}>Nurse Name *</td>

    <td width={'20%'}>{item.nurse_name}</td>

    <td width={'10%'}>


    </td>

    <td width={'50%'}>

      <p>{item.corrected_fields?.nurse_name}</p>

    </td>

  </tr>

  <tr>

    <td width={'20%'}>Registered As *</td>

    <td width={'20%'}>{item.registered_as}</td>

    <td width={'10%'}>


    </td>

    <td width={'50%'}>


  <p>{item.corrected_fields?.registered_as}</p>

    </td>

  </tr>

  <tr>

    <td width={'20%'}>Status *</td>

    <td width={'20%'}>{item.status}</td>

    <td width={'10%'}>


    </td>

    <td width={'50%'}>


<p>{item.corrected_fields?.status}</p>


    </td>

  </tr>

  <tr>

    <td width={'20%'}>State Council *</td>

    <td width={'20%'}>{item.state_council}</td>

    <td width={'10%'}>


    </td>

    <td width={'50%'}>

<p>{item.corrected_fields?.state_council}</p>

    </td>

  </tr>


  <tr>

    <td width={'20%'}>Verified On *</td>

    <td width={'20%'}>{moment(item.timestamp).format('DD-MM-YYYY')}</td>

    <td width={'10%'}>



    </td>

    <td width={'50%'}>


    </td>

  </tr>

  <tr>

<td width={'20%'}>Status</td>
<td width={'20%'} >
  
  <p style={{
  background: color,
  padding: 5,
  color:"#fff"
}}>Qc Complete</p>

</td>

<td width={'10%'}>

</td>

<td width={'50%'}>
</td>
</tr> 

<tr>

<td width={'20%'}>Disposition Status *</td>
<td width={'20%'}>
  <p>{deposition_status}</p>
</td>

<td width={'10%'}>
{/* <input style={styles.inputCheck} className="form-check-input" onClick={(e)=>{
                         
                        }} type="checkbox" id="upcomingtaskCheck01" /> */}
</td>

<td width={'50%'}>
  
</td>
</tr> 


<tr>

<td width={'20%'}>Disposition Remarks *</td>
<td width={'20%'}>

<p>{reason}</p>

</td>

<td width={'10%'}>
{/* <input style={styles.inputCheck} disabled className="form-check-input" onClick={(e)=>{
                         
                        }} type="checkbox" id="upcomingtaskCheck01" /> */}
</td>

<td width={'50%'}>
</td>
</tr> 


<tr>

<td width={'20%'}>Comments *</td>
<td width={'20%'}>

<p>{comment}</p>

</td>

<td width={'10%'}>
{/* <input style={styles.inputCheck} disabled className="form-check-input" onClick={(e)=>{
                         
                        }} type="checkbox" id="upcomingtaskCheck01" /> */}
</td>

<td width={'50%'}>
</td>
</tr> 

</tbody>

</Table>
              </div>
    )
  }

  const Doctor = ({item}) => {

    
    let code = components.find((item)=> item.code == 'doctor').id;

    console.log('code==>',code);

    let verified_comp = cases.find((item)=> item.component_id == code);

    console.log('verified_comp==>',cases);


    let deposition_status = cases.find((item)=> item.component_id == code)?.deposition_status;

    let comment = cases.find((item)=> item.component_id == code)?.comment;

    let reason = cases.find((item)=> item.component_id == code)?.reason;

    let colorcode = cases.find((item)=> item.component_id == code)?.colorcode;

    let color = colors[colorcode];

    console.log('doctor id', verified_comp);

      let component_fields =  getComponentFields({case_id: verified_comp.case_id, component_id: verified_comp.component_id, sub_component_id: null});

      console.log('component_fields==>',component_fields.then((res)=>{
        console.log('res==>',res);

        if(res.length > 0){

          let obj = {};
  
          res.map((item)=> {
  
            obj[item.field_id] = item.value;
  
          })
  
          item.corrected_fields = obj;
  
        }
      }
    
    ));


    return (
      <div>
        
        <Table className="table table-striped mb-0" striped  >

<thead >

  <tr>
    <th style={{width:'20%', fontSize:16, fontWeight:500}}>Doctor component details</th>
    <th style={{width:'30%', fontSize:16, fontWeight:500}}>Data Shared By Candidate</th>
    <th style={{width:'10%', fontSize:16, fontWeight:500}}></th>

    <th style={{width:'50%', fontSize:16, fontWeight:500}}>Verified Data</th>

  </tr>

  </thead>

<tbody >

  <tr>

    <td width={'20%'}>Doctor ID *</td>
    <td width={'20%'}>{item.doctor_id}</td>
       
    <td width={'10%'}>


    </td>

    <td width={'50%'}>

      <p>{item.corrected_fields?.doctor_id}</p>


    </td>

  </tr>

  <tr>

    <td width={'20%'}>Full Name *</td>

    <td width={'20%'}>{item.full_name}</td>

    <td width={'10%'}>


    </td>

    <td width={'50%'}>

      <p>{item.corrected_fields?.full_name}</p>


    </td>

  </tr>

  <tr>

    <td width={'20%'}>Parent Name *</td>

    <td width={'20%'}>{item.parent_name}</td>

    <td width={'10%'}>



    </td>

    <td width={'50%'}>


      <p>{item.corrected_fields?.parent_name}</p>


    </td>

  </tr>

  <tr>

    <td width={'20%'}>Registration Number *</td>

    <td width={'20%'}>{item.registration_number}</td>

    <td width={'10%'}>



    </td>

    <td width={'50%'}>


      <p>{item.corrected_fields?.registration_number}</p>

    </td>

  </tr>

  <tr>

    <td width={'20%'}>State Medical Council *</td>

    <td width={'20%'}>{item.state_medical_council}</td>

    <td width={'10%'}>



    </td>

    <td width={'50%'}>


      <p>{item.corrected_fields?.state_medical_council}</p>

    </td>

  </tr>

  <tr>

    <td width={'20%'}>Qualification *</td>

    <td width={'20%'}>{item.qualification}</td>

    <td width={'10%'}>


    </td>

    <td width={'50%'}>



    </td>

  </tr>

  <tr>

    <td width={'20%'}>Registration Date *</td>

    <td width={'20%'}>{item.registration_date}</td>

    <td width={'10%'}>



    </td>

    <td width={'50%'}>


<p>{item.corrected_fields?.registration_date}</p>



    </td>

  </tr>

  <tr>

<td width={'20%'}>Attachment</td>
<td width={'20%'}>
  
<button 
onClick={()=>{
  window.open(item.uploaded_document, '_blank');

}
}
className="btn btn-primary btn-sm">View</button>
</td>

<td width={'10%'}>

</td>

<td width={'50%'}>
<p>
</p>
</td>
</tr>

  <tr>

    <td width={'20%'}>Verified On *</td>

    <td width={'20%'}>{moment(item.timestamp).format('DD-MM-YYYY')}</td>

    <td width={'10%'}>



    </td>

    <td width={'50%'}>


    </td>

  </tr>

  <tr>

<td width={'20%'}>Status</td>
<td width={'20%'} >
  
  <p style={{
  background: color,
  padding: 5,
  color:"#fff"
}}>Qc Complete</p>

</td>

<td width={'10%'}>

</td>

<td width={'50%'}>
</td>
</tr> 

<tr>

<td width={'20%'}>Disposition Status *</td>
<td width={'20%'}>
  <p>{deposition_status}</p>
</td>

<td width={'10%'}>
{/* <input style={styles.inputCheck} className="form-check-input" onClick={(e)=>{
                         
                        }} type="checkbox" id="upcomingtaskCheck01" /> */}
</td>

<td width={'50%'}>
  
</td>
</tr> 


<tr>

<td width={'20%'}>Disposition Remarks *</td>
<td width={'20%'}>

<p>{reason}</p>

</td>

<td width={'10%'}>
{/* <input style={styles.inputCheck} disabled className="form-check-input" onClick={(e)=>{
                         
                        }} type="checkbox" id="upcomingtaskCheck01" /> */}
</td>

<td width={'50%'}>
</td>
</tr> 


<tr>

<td width={'20%'}>Comments *</td>
<td width={'20%'}>

<p>{comment}</p>

</td>

<td width={'10%'}>
{/* <input style={styles.inputCheck} disabled className="form-check-input" onClick={(e)=>{
                         
                        }} type="checkbox" id="upcomingtaskCheck01" /> */}
</td>

<td width={'50%'}>
</td>
</tr> 
  
 

</tbody>







</Table>
      
    
              </div>
    )
  }



  const CA = ({item}) => {

    
    let code = components.find((item)=> item.code == 'icai').id;

    console.log('code==>',code);

    let verified_comp = cases.find((item)=> item.component_id == code);

    console.log('verified_comp==>',cases);


    let deposition_status = cases.find((item)=> item.component_id == code)?.deposition_status;

    let comment = cases.find((item)=> item.component_id == code)?.comment;

    let reason = cases.find((item)=> item.component_id == code)?.reason;

    let colorcode = cases.find((item)=> item.component_id == code)?.colorcode;

    let color = colors[colorcode];

    console.log('icai id', verified_comp);

      let component_fields =  getComponentFields({case_id: verified_comp.case_id, component_id: verified_comp.component_id, sub_component_id: null});

      console.log('component_fields==>',component_fields.then((res)=>{
        console.log('res==>',res);

        if(res.length > 0){

          let obj = {};
  
          res.map((item)=> {
  
            obj[item.field_id] = item.value;
  
          })
  
          item.corrected_fields = obj;
  
        }
      }
    
    ));

    return (
      <div>
        
        <Table className="table table-striped mb-0" striped  >

<thead >

  <tr>

    <th style={{width:'20%', fontSize:16, fontWeight:500}}>CA component details</th>

    <th style={{width:'30%', fontSize:16, fontWeight:500}}>Data Shared By Candidate</th>

    <th style={{width:'10%', fontSize:16, fontWeight:500}}></th>

    <th style={{width:'50%', fontSize:16, fontWeight:500}}>Verified Data</th>

  </tr>

  </thead>

<tbody >

  <tr>

    <td width={'20%'}>Membership Number *</td>

    <td width={'20%'}>{item.membership_no}</td>

    <td width={'10%'}>



    </td>

    <td width={'50%'}>

    <p>{item.corrected_fields?.membership_no}</p>

    </td>

  </tr>

  <tr>

    <td width={'20%'}>Member Name *</td>

    <td width={'20%'}>{item.member_name}</td>

    <td width={'10%'}>



    </td>

    <td width={'50%'}>

    <p>{item.corrected_fields?.member_name}</p>

    </td>

  </tr>

  <tr>

    <td width={'20%'}>Gender *</td>

    <td width={'20%'}>{item.gender}</td>

    <td width={'10%'}>



    </td>

    <td width={'50%'}>

      <p>{item.corrected_fields?.gender}</p>

    </td>

  </tr>

  <tr>

    <td width={'20%'}>Professional Address *</td>

    <td width={'20%'}>{item.professional_address}</td>

    <td width={'10%'}>


    </td>

    <td width={'50%'}>

    <p>{item.corrected_fields?.professional_address}</p>

    </td>

  </tr>

  <tr>

    <td width={'20%'}>Member Status *</td>

    <td width={'20%'}>{item.member_status}</td>

    <td width={'10%'}>


    </td>

    <td width={'50%'}>
    <p>{item.corrected_fields?.member_status}</p>


    </td>

  </tr>

  <tr>

    <td width={'20%'}>COP Status *</td>

    <td width={'20%'}>{item.cop_status}</td>

    <td width={'10%'}>



    </td>

    <td width={'50%'}>

    <p>{item.corrected_fields?.cop_status}</p>

    </td>

  </tr>

  <tr>

    <td width={'20%'}>Associate Or Fellow *</td>

    <td width={'20%'}>{item.associate_or_fellow}</td>

    <td width={'10%'}>



    </td>

    <td width={'50%'}>

    <p>{item.corrected_fields?.associate_or_fellow}</p>

    </td>

  </tr>

  <tr>

<td width={'20%'}>Attachment</td>
<td width={'20%'}>
  
<button 
onClick={()=>{
  window.open(item.uploaded_document, '_blank');

}
}
className="btn btn-primary btn-sm">View</button>
</td>

<td width={'10%'}>

</td>

<td width={'50%'}>
<p>
</p>
</td>
</tr>

<tr>

<td width={'20%'}>Status</td>
<td width={'20%'} >
  
  <p style={{
  background: color,
  padding: 5,
  color:"#fff"
}}>Qc Complete</p>

</td>

<td width={'10%'}>

</td>

<td width={'50%'}>
</td>
</tr> 

<tr>

<td width={'20%'}>Disposition Status *</td>
<td width={'20%'}>
  <p>{deposition_status}</p>
</td>

<td width={'10%'}>
{/* <input style={styles.inputCheck} className="form-check-input" onClick={(e)=>{
                         
                        }} type="checkbox" id="upcomingtaskCheck01" /> */}
</td>

<td width={'50%'}>
  
</td>
</tr> 


<tr>

<td width={'20%'}>Disposition Remarks *</td>
<td width={'20%'}>

<p>{reason}</p>

</td>

<td width={'10%'}>
{/* <input style={styles.inputCheck} disabled className="form-check-input" onClick={(e)=>{
                         
                        }} type="checkbox" id="upcomingtaskCheck01" /> */}
</td>

<td width={'50%'}>
</td>
</tr> 


<tr>

<td width={'20%'}>Comments *</td>
<td width={'20%'}>

<p>{comment}</p>

</td>

<td width={'10%'}>
{/* <input style={styles.inputCheck} disabled className="form-check-input" onClick={(e)=>{
                         
                        }} type="checkbox" id="upcomingtaskCheck01" /> */}
</td>

<td width={'50%'}>
</td>
</tr> 


</tbody>



  

</Table>
      
   
              </div>
    )
  }

  const renderData = (doc)=> {

    // console.log('doc==>',doc);


    if (doc && Object.keys(doc)[0] == 'aadhaar') {

      console.log('aadhaar doc found==>',doc);

      const item = doc.aadhaar;

      let code = components.find((item)=> item.code == 'aadhaar').id;

      console.log('code==>',code);
  
      let verified_comp = cases.find((item)=> item.component_id == code);

      if(verified_comp){

        return (
          <Aadhaar item={item}/>
         )

      }else{

        return null
      }

    
     
    }

    if (doc && Object.keys(doc)[0] == 'pan') {

      console.log('pan doc found==>',doc);

      const item = doc.pan;

      let code = components.find((item)=> item.code == 'pan').id;

      console.log('code==>',code);
  
      let verified_comp = cases.find((item)=> item.component_id == code);

      if(verified_comp){

        return (
          <Pan item={item}/>
         )
        

      }else{

        return null
      }

  
    }

    if (doc && Object.keys(doc)[0] == 'voter_id') {
        
        console.log('voter_id doc found==>',doc);
  
        const item = doc.voter_id;

        let code = components.find((item)=> item.code == 'voter_id').id;

        console.log('code==>',code);

    
        let verified_comp = cases.find((item)=> item.component_id == code);
  
        if(verified_comp){
  
          return (
            <VoterId item={item}/>
            )
          
          
  
        }else{
  
          return null
        }
  
   
      
      }

      if (doc && Object.keys(doc)[0] == 'driving_licence') {
          
          console.log('driving_licence doc found==>',doc);
    
          const item = doc.driving_licence;

          let code = components.find((item)=> item.code == 'driving_licence').id;

          console.log('code==>',code);
      
          let verified_comp = cases.find((item)=> item.component_id == code);

        
    
          if(verified_comp){
    
            return (
              <DL item={item}/>
              )
    
          }else{
    
            return null
          }
    
    
      
        
        }

        if (doc && Object.keys(doc)[0] == 'icai') {

          console.log('ca doc found==>',doc);
    
          const item = doc.icai;

          let code = components.find((item)=> item.code == 'icai').id;

          console.log('code==>',code);
      
          let verified_comp = cases.find((item)=> item.component_id == code);
    
          if(verified_comp){
    
            return (
              <CA item={item}/>
              )
    
          }else{
    
            return null
          }
    
    
    
         
        }

        if (doc && Object.keys(doc)[0] == 'nurse') {
            
            console.log('nurse doc found==>',doc, cases);
      
            const item = doc.nurse;

            let code = components.find((item)=> item.code == 'nurse').id;

            console.log('code==>',code);

        
            let verified_comp = cases.find((item)=> item.component_id == code);

      
            if(verified_comp){
      
          
              return (
                <Nurse item={item}/>
                )
              
      
            }else{
      
              return null
            }
      
      
          }

          if (doc && Object.keys(doc)[0] == 'doctor') {

            console.log('doctor doc found==>',doc);
      
            const item = doc.doctor;

            let code = components.find((item)=> item.code == 'doctor').id;

            console.log('code==>',code);
        
            let verified_comp = cases.find((item)=> item.component_id == code);
      
            if(verified_comp){
      
          
              return (
                <Doctor item={item}/>
                )
      
            }else{
      
              return null
            }
      
        
           
          }

          if(doc && Object.keys(doc)[0] == 'employment'){

            console.log('employment doc found==>',doc);

            const item = doc.employment;

            return (
              <Employment item={item}/>
            )

          }

          if(doc && Object.keys(doc)[0] == 'education'){

            console.log('education doc found==>',doc);

            const item = doc.education;

            return (
              <Education item={item}/>
            )

          }

          

  }


  const viewReport = async(report_type) => {

    console.log('selected option ===>',options);

    let promises = []
    

    options.map((option)=> {

      let promise = new Promise((resolve, reject) => {

        let isOptionExist = selectedOption.findIndex((item)=> item.value == option.value);

        if(isOptionExist > -1){

      const comp_id = components.find((item)=> item.code == option.value).id;

      const assigned_id = cases.find((item)=> item.component_id == comp_id).assigned_id;

      console.log('assigned id==>',assigned_id);

      update_report(assigned_id, report_type);

      resolve('done');

        }else{

          const comp_id = components.find((item)=> item.code == option.value).id;

          const assigned_id = cases.find((item)=> item.component_id == comp_id).assigned_id;

          update_report(assigned_id, "");
            
            resolve('done');  
        }

    })

    promises.push(promise);

  })

  Promise.all(promises).then((values) => {

    console.log('all promises done==>',values);

     update_case_report({
      report: report_type,
      org_client_id: caseData.org_client_id,
      
     });

        window.open(`${process.env.REACT_APP_URL}/client/view-report/${caseData.request_id}`, '_blank');


  })


    // window.open(`${process.env.REACT_APP_URL}/admin/view-report/${caseData.request_id}`, '_blank');


  }

  const update_report  = async(id, report) => {


    const config = {
      headers: {
        'Content-Type': 'application/json'
      }
    };

    const body = {

      id,
      report
    }

    console.log('update report body==>',body);

    axios.post(`${process.env.REACT_APP_BASE_URL}/employer/admin/generate_report`, body, config)
    .then(response => {
      console.log('report updated',response);

    })
    .catch(error => {
  
      
  
      console.log('update report error==>',error);
    });


  }


  const get_request_identity_org = async () => {

    const org_client_id = caseData?.org_client_id;

    console.log('org_client_id',org_client_id);

    const config = {
      headers: {
        'Content-Type': 'application/json'
      }
    };

    axios.get(`${process.env.REACT_APP_BASE_URL}/service/request_identity_org/${org_client_id}`, {}, config)
    .then(response => {
      console.log('requested identity org response',response);

      let data = response.data.data[0];

      console.log('request identity data==>',data);

      let req_data = JSON.parse(data.req_data);
      console.log('req_data==>',req_data);


      let arr = [];

      let newArr = []

      if(data.identity){

        let identity = req_data.identity;

        let obj =    {
          name: "Identity",
          isSubContent: true,
          code: 'identity',
          components:[],
          
          isOpen: false,
        }

        identity.map((item, index) => {

          obj.components.push({
            name:item,
            isOpen:false  
          })

          newArr.push({
            name:item,
            isOpen:false
          })

        })

        if(identity.length > 0){

         arr.push(obj); 

        }
   
       

      }

      if(data.professional_identity){



        let professional_identity = req_data.professional_identity;

       let obj =   {
        name: "Professional Identity",
        isSubContent: true,
        code: 'professional_identity',
        components:[],
        isOpen: false,
      }

        professional_identity.map((item, index) => {

          obj.components.push({
            name:item,
            isOpen:false  
          })

          newArr.push({
            name:item,
            isOpen:false
          })

        })

        if(professional_identity.length > 0){
          arr.push(obj);

        }




      }

      if(data.employment){
          
          let employment = req_data.employment;
  
          let obj =   {
            name: "Employment",
            isSubContent: false,
            code: 'employment',
            components:[],
            isOpen: false,
          }
  
     
            obj.components.push({

              name: 'employment',

              isOpen:false

            })

        
  
            arr.push(obj);

            newArr.push({
              name: 'employment',
              isOpen:false
            })
  
          
      }

      if(data.education){
          
        let education = req_data.education;

        let obj =   {
          name: "Education",
          isSubContent: false,
          code: 'education',
          components:[],
          isOpen: false,
        }

   
          obj.components.push({

            name: 'education',

            isOpen:false

          })

      

          arr.push(obj);

          newArr.push({
            name: 'education',
            isOpen:false
          })


        
    }

    if(data.credit_score){


      let obj =   {
        name: "Credit Score",
        isSubContent: false,
        code: 'credit_score',
        components:[],
        isOpen: false,
      }

 
        obj.components.push({

          name: 'credit_score',

          isOpen:false

        })

    

        arr.push(obj);

        newArr.push({
          name: 'credit_score',
          isOpen:false
        })

    }

      console.log('arr==>',arr);

      setSection(newArr);

      

      let edu  = components.find((item)=> item.code == 'education');

      let emp = components.find((item)=> item.code == 'employment');

      // console.log('comp==>',comp, cases);

      let edu_verified = cases.findIndex((item)=> item.component_id == edu.id);

      let emp_verified = cases.findIndex((item)=> item.component_id == emp.id);

      console.log('edu_verified==>',edu_verified, emp_verified);

      let showEdu = false;

      let showEmp = false;

      if(edu_verified > -1 && parseInt(data.education)){

        showEdu = true;

        

      }   

      if(emp_verified > -1 && parseInt(data.employment)){

        showEmp = true;

      }

      console.log('showEdu==>',showEdu, showEmp);


      setShowDoc({
        ...showDoc,
        identity: parseInt(data.identity)?true:false,
        professional_identity: parseInt(data.professional_identity)?true:false,
        epfo: req_data.education.epfo?true:false,
        tds: req_data.education.tds?true:false,
        education:  parseInt(data.education)?true:false,
        employment: parseInt(data.employment)?true:false,
        credit_score: parseInt(data.credit_score)?true:false,

      })



    })
    .catch(error => {
  
      
  
      console.log('error==>',error);
    });

  }



  const activity_logs = async () => {

    const config = {
      headers: {
        'Content-Type': 'application/json'
      }
    };

    const {org_client_id, request_id} = caseData;

    const body = {

      org_client_id: org_client_id,
      case_id: request_id

    }

  
    axios.post(`${process.env.REACT_APP_BASE_URL}/service/activity_logs`, body, config)
    .then(response => {
      console.log('activity logs',response);

     setActivityLogs(response.data);


    })
    .catch(error => {
  
      
  
      console.log('error==>',error);
    });

  }

  const get_wallet = async (value) => {

    const config = {
      headers: {
        'Content-Type': 'application/json'
      }
    };

  
    axios.get(`${process.env.REACT_APP_BASE_URL}/service/wallet/${value}`, {}, config)
    .then(response => {
      console.log('wallet data',response.data);

      let data = response.data;

      console.log('data==>',data);

      setUser(data.user);



    })
    .catch(error => {
  
      
  
      console.log('error==>',error);
    });

  }

  const get_case = async () => {

   
    const data = location.state;

    console.log('param data',data)

    if (data) {
      setCaseData(data);
      get_wallet(data.walletID);
    }



  }




  const toggle = () => {
    if (modal) {
      setModal(false);
      setOrder(null);
    } else {
      setModal(true);
    }
  };


  //delete order
  const [deleteModal, setDeleteModal] = useState(false);


  const update_case = async(status) => {

    console.log('status==>',status);

    const config = {
      headers: {
        'Content-Type': 'application/json'
      }
    };

    const {notification_id} = caseData;

    const body = {

      notification_id: notification_id,
      verification_status: status

    }

    console.log('update case body==>',body);

  
    axios.put(`${process.env.REACT_APP_BASE_URL}/service/case_status`, body, config)
    .then(response => {
      console.log('update case res',response);


      navigate('/on-going-cases');



    })
    .catch(error => {
  
      
  
      console.log('update case error==>',error);
    });

  }

  const update_log_call = async() => {


    const user = JSON.parse(Cookies.get('authUser'))


    const config = {
      headers: {
        'Content-Type': 'application/json'
      }
    };


    const body = {


   
      org_emp_id: user.user_id,
      case_id: caseData.request_id,
      org_client_id: caseData.org_client_id,
      activity: log_activity,


    }

    console.log('update case body==>',body);

  
    axios.post(`${process.env.REACT_APP_BASE_URL}/service/admin/log_calls`, body, config)
    .then(response => {
      console.log('update log call res',response);

     

    })
    .catch(error => {
  
      
  
      console.log('update log call error==>',error);
    });

  }

  useEffect(() => {

    if(components.length>0 && Object.keys(requestedData).length > 0 && component_to_verify == null){

    
      let codeIdx = components.findIndex((item)=> item.id == caseData.component_id);


      if(codeIdx > -1){

      let idx = Object.keys(requestedData).findIndex((item)=> item ==  components[codeIdx].code);

      console.log('select component idx==>',idx, caseData.component_id, requestedData, components);


      if (idx > -1) {

       


        setComponentToVerify(components[codeIdx].code);


      }

    }
      
    }

  }, [components, requestedData]);

  const assignToQC = () => {

    const user = JSON.parse(Cookies.get('authUser'))


    const config = {
      headers: {
        'Content-Type': 'application/json'
      }
    };

 

    const body = {
      case_id: caseData.request_id,
      org_emp_id: user.user_id,
      org_client_id: caseData.org_client_id,
      team: 'QC Case Manager',
      deposition_status: depositionStatus,
      component_id: null,
      colorcode: depStatusColor,
      case_status: 'Complete'
     
    }

    console.log('id==>', body)

    axios.post(`${process.env.REACT_APP_BASE_URL}/employer/admin/assignCase`, body, config)

    .then(response => {

      console.log('assign case==>',response)

      setEnableGenerateReport(true);

      // navigate('/admin/qc-case-level')
  
    })

    .catch(error => {

      console.log('error from server==>',error)

    })

   
  
  };


  const updateAssignCase = async () => {

    const config = {
      headers: {
        'Content-Type': 'application/json'
      }
    };

    const body = {
      case_id: caseData.request_id,
      assigned_id: caseData.assigned_id,
      org_client_id: caseData.org_client_id,
      description: 'Case Assigned to QC Case Manager',
      case_status: 'complete'
     
    }

    console.log('update case==>', body)

    axios.put(`${process.env.REACT_APP_BASE_URL}/employer/admin/assignCase`, body, config)

    .then(response => {

      console.log('update assign case==>',response)

      alert('Case Assigned to QC Case Manager')

      // navigate('/admin/my-cases')
   

    })

    .catch(error => {

      console.log('error from server==>',error)

    })
  }


  useEffect(() => {

    if(section.length > 0 && components.length > 0){

      console.log('section==>',section);

      let arr = [];

      section.map((item, index) => {

       arr.push(item)

      })

      console.log('section arr==>',arr)
      // const selected_components = arr.flat();

      let arr2 =  arr.filter((component, index) => {

        let comp = components.find((item)=> item.code == component.name);

        console.log('section comp', comp)

        let verified_comp = cases.findIndex((item)=> item.component_id == comp.id);

        console.log('verified_comp==>',verified_comp);

        return verified_comp > -1;
       


      })

      console.log('selected_components==>',arr2);

      let setNewOption = arr2.map((item, index) => {

        return {
          value: item.name,
          label: item.name
        }

      })


      setOptions(setNewOption);


    }

  }, [section, components]);

  const getSelectedOption = (a) => {

    console.log('selected option==>',a);

   setSelectedOption(a);

  }

  const getUploadedDocuments = async () => {

    let obj = {
      case_id: caseData.request_id
    }

    const config = {
      headers: {
        'Content-Type': 'application/json'
      }
    };

    console.log('get uploaded documents==>', obj);

    axios.post(`${process.env.REACT_APP_BASE_URL}/employer/admin/uploaded_documents`, obj, config)
    .then(response => {
      console.log('uploaded documents',response);

      let data = response.data.data;

      // let filtered_data = data.filter(item => item.uploaded_by == 'admin')

      let mapData = data.map((item, index) => {

        return {
          ...item,
          selected:false}

      })

      setUploadedDocuments(mapData);

    })
    .catch(error => {
  
      
  
      console.log('get uploaded documents error==>',error);
    });

  }


  const getColorCodeReason = async () => {

    const config = {
      headers: {
        'Content-Type': 'application/json'
      }
    };
   
    axios.get(`${process.env.REACT_APP_BASE_URL}/employer/admin/colorcodematrix`, {}, config)

    .then(response => {

      console.log('color code cases==>',response.data.data, component_to_verify)

      let data = response.data.data;

      let doc = 
        {
          aadhaar: "Identity",
          pan: "Identity",
          voter_id: "Identity",
          driving_licence: "Identity",
          doctor: "Professional Identity",
          nurse: "Professional Identity",
          icai: "Professional Identity",
          education: "Education",
          employment: "Employment",


        }
      
 // find component_to_verify in the doc object and return the value

      let type = doc[component_to_verify];
       

 let filtered  = data.filter((item)=> item.Parameter === type);

 console.log('colorcode filtered==>',filtered);
      

      setColorCodeMatrix(data);


    })

    .catch(error => {

      console.log('error from server==>',error)

    })


  }

  const getDepStatusOptions = async () => {

    const config = {
      headers: {
        'Content-Type': 'application/json'
      }
    };


   
    axios.get(`${process.env.REACT_APP_BASE_URL}/employer/admin/deposition_status_options`, {}, config)

    .then(response => {


      let data = response.data.data;

      console.log('deposition status options==>',data);

 
      setDepStatusOption(data);


    })

    .catch(error => {

      console.log('deposition error from server==>',error)

    })


  }

  useEffect(() => {

    

    getDepStatusOptions();  

     getColorCodeReason();

    

  }, []);

  
  const rework = async () => {

    const config = {
      headers: {
        'Content-Type': 'application/json'
      }
    };

    const body = {
      case_id: caseData.request_id
    }

    console.log('rework',body)
   
    axios.post(`${process.env.REACT_APP_BASE_URL}/employer/admin/rework_process_leader`, body, config)

    .then(response => {

      navigate('/admin/leader-cases')

    })

    .catch(error => {

      console.log('rework error from server==>',error)

    })


  }




  return (
    <React.Fragment>

      <div className="page-content">
        <div className="container-fluid">
     
          <Row>
            <Col lg="12">
              <div className="d-flex mb-4" style={{justifyContent:'space-between'}}>
                <h2 className="page-title">{caseData?.candidate_name}</h2>

                <button className="btn btn-primary"  onClick={()=>navigate('/on-going-cases')}>back</button>

              </div>
            <div className="table-responsive">
                    <Table className="table mb-3">
                      <thead className="table-dark" >
                        <tr>
                          <th>Case Ref.#</th>

                          <th>Client Name</th>

                          <th>Location</th>

                          <th>Case In Date</th>
                          <th>Case Due Date</th>

                          <th>Father's Name</th>
                          <th>Dob</th>
                          <th>Client Employee ID</th>

                        </tr>
                      </thead>
                      <tbody>
                        {caseData &&<tr className="table-light">
                          <th >{caseData?.request_id}</th>
                          <td></td>
                          <td>{caseData?.location}</td>
                          <td>{moment(caseData?.timestamp).format('DD-MM-YYYY')}</td>
                          <td></td>

                          <td>{caseData?.candidate_father_name}</td>
                          <td>{caseData?.candidate_dob}</td>
                          <td></td>
                        </tr>}
                    
                      </tbody>
                    </Table>
                  </div>


              </Col>
          </Row>

          <Row>
          
            <Col lg="12">


              <Card>
                  <CardBody>
                    {
                      enable_generate_report &&
                      <div className="d-flex mb-5 px-2" style={{alignItems:'flex-end'}}>
                  <div className="me-3" style={{justifyContent:'space-between', alignItems:'center'}}>
                        <Label className="form-label">Select Reports components:</Label>

<MySelect options={options} selectedOption={getSelectedOption} />

      </div>


      <Dropdown isOpen={dropdownOpen}  toggle={toggleDropdown}>

        <DropdownToggle className="bg-primary" caret>
          Generate Report
        </DropdownToggle>
        <DropdownMenu>
          <DropdownItem onClick={()=>{viewReport('IR')}}>Interim Report</DropdownItem>
      
         
          </DropdownMenu>
      </Dropdown>


      </div>
      }
                  <div className="accordion" id="accordion">
    
   
{/* Identity */}
  <div className="accordion-item m-2">
      <h2 className="accordion-header" id="headingOne" >
        <button
          className={classnames(
            "accordion-button",
            "fw-medium",
            { collapsed: !col2 }
          )}
          style={{
            cursor:'pointer',
            fontWeight:'200',
            fontSize:18
          }}
          type="button"
          onClick={t_col2}
        >
        Verification Components
        </button>
      </h2>

      

      <Collapse isOpen={col2} className="accordion-collapse">

<div className="accordion p-3" id="accordion3">
  {
    section.map((item, index) => {

      let comp1;
       let verified_comp1;
       let docData2;
       let renderedData2;
      
      //  if(!item.isSubContent){

      //    docData2 = requestedData.find((doc) =>  Object.keys(doc)[0] === item.code);


      //    comp1  = components.find((c)=> c.code == item.code);

      //   console.log('comp==>',comp1, cases);

      //    verified_comp1 = cases.findIndex((item)=> item.component_id == comp1.id);

      //   console.log('verified_comp==>',verified_comp1);

      //    renderedData2 = renderData(docData2);
      //  }

      const docData = requestedData.find((doc) =>  Object.keys(doc)[0] === item.name);

      console.log('docData==>',docData);

        let comp  = components.find((c)=> c.code == item.name);

        console.log('comp==>',comp, cases);

        let verified_comp = cases.findIndex((item)=> item.component_id == comp.id);

        console.log('verified_comp==>',verified_comp);

      const renderedData = renderData(docData);
      
      return (
      <div className="accordion-item m-2" key={index}>
        <h2 className="accordion-header" id={`heading${index}`}>
          <button
            className={classnames("accordion-button", { collapsed: !item.isOpen })}
            type="button"
           
            onClick={() => {
              const newItems = section.map((sectionItem, sectionIndex) => {
                if (sectionIndex === index) {
                  return {
                    ...sectionItem,
                    isOpen: !sectionItem.isOpen
                  };
                }
                return {
                  ...sectionItem,
                  
                };
              });
              setSection(newItems);
            }}
            style={{ cursor: "pointer",
            fontWeight:'200',
            
            fontSize:16
          
          }}
          >
          {item.name}


          </button>
        </h2>
        <Collapse isOpen={item.isOpen}  className="accordion-collapse">


  {
    renderedData
  }
       
       

   
        </Collapse>
      </div>
    )})
  }
  </div>


      </Collapse>
    </div>



  
{/* Employment */}
    {/* {showDoc.employment && <div className="accordion-item m-2">
      <h2 className="accordion-header" id="headingOne">
        <button
          className={classnames(
            "accordion-button",
            "fw-medium",
            { collapsed: !col3 }
          )}
          type="button"
          onClick={t_col3}
          style={{ cursor: "pointer",
          fontWeight:'200',
          fontSize:18
        
        }}
        >
        Employment
        </button>
      </h2>

      

      <Collapse isOpen={col3} className="accordion-collapse">


<div className="accordion-body">
        <div className="text-muted">

<Table className="table mb-0">
<thead className="table-dark">
<tr>
    <th>Employment Details</th>
    <th></th>
  </tr>
  </thead>
<tbody>
  </tbody>
</Table>

{
 employment.length > 0 && employment.map((item, index) => (
<Table className="table mb-0 mt-3">
<thead className="table-dark">


</thead>
<tbody>
<tr>
    <td className="table-light w-25">Company name</td>
    <td className="table-light w-75">{item.company_name}</td>

</tr>

<tr>
    <td className="table-light w-25">Designation</td>
    <td className="table-light w-75">{item.designation}</td>

</tr>

<tr>
    <td className="table-light w-25">Employee ID</td>
    <td className="table-light w-75">{item.emp_id}</td>

</tr>

<tr>
    <td className="table-light w-25">Reason for Leaving</td>
    <td className="table-light w-75"></td>

</tr>

<tr>
    <td className="table-light w-25">Attachment</td>
    <td className="table-light w-75">

      <div className="d-flex w-50" style={{justifyContent:'space-between'}}>
      <Link to={`${item?.offer_letter_path}`} target="_blank">
     Offer Letter
    </Link>
    <Link to={`${item?.payslip_path}`} target="_blank">
     Payslip path
    </Link>
    <Link to={`${item?.exp_letter_path}`} target="_blank">
     Experience Letter
    </Link>
      </div>

    </td>

</tr>

<tr>
    <td className="table-light">Date of joining</td>
    <td className="table-light">{item.doj}</td>

</tr>

<tr>
    <td className="table-light">Date of relieving</td>
    <td className="table-light">{item.doe}</td>

</tr>


<tr>
    <td className="table-light">Final Status</td>
    <td className="table-light"></td>

</tr>
</tbody>
</Table>

  ))
}
         
        </div>
      </div>
      </Collapse>
 
          
       
</div>} */}

{/* Epfo */}
{showDoc.epfo && <div className="accordion-item m-2">
      <h2 className="accordion-header" id="headingOneEpfo">
        <button
          className={classnames(
            "accordion-button",
            "fw-medium",
            { collapsed: !col6 }
          )}
          type="button"
          onClick={t_col6}
          style={{ cursor: "pointer",
          fontWeight:'200',
          fontSize:18
        
        }}
        >
        Epfo
        </button>
      </h2>

      

      <Collapse isOpen={col6} className="accordion-collapse">



<div className="accordion-body">
        <div className="text-muted">

        <p style={{fontSize:18}}>Epfo Details</p>

        <div  >
                      

                          {
epfo_data && 

<div className="table-responsive pb-3" >
<p style={{fontSize:16, textAlign:'center'}} className="mb-4">Employee Verification by UAN Number</p>

<Row>
 <Col className="col-4">

   <div style={{}}>

     <div className="d-flex">
     <p style={{fontSize:13}}>Name:</p>
   <p style={{fontSize:13}}>{epfo_data?.full_name}</p>
     </div>

     <p style={{fontSize:13}}>
       Date of Birth: <span>{epfo_data?.dob}</span>
     </p >


   </div>
 </Col>
 <Col className="col-4">
 <div style={{display:'flex'}}>
   <p style={{fontSize:13}}>Father Name:</p>
   <p style={{fontSize:13}}>{epfo_data?.father_name}</p>

   </div>
 </Col>
 <Col className="col-4">
 <div style={{display:'flex'}}>
   <p style={{fontSize:13}}>UAN Number:</p>
   <p>{epfo_data?.pf_uan}</p>

   </div>
 </Col>
</Row>
<Row>
 <Col className="col-12">
  
  {
    epfo_companies && 

    epfo_companies.map((item)=>{
      return (
        <Accordion header={
          <div className="container-fluid">
          
          <Row>
          <Col className="col-11">
          <Row className="pt-3 text-center">
          <Col className="col-6 text-center">
          <p style={{fontSize:13}}>{item?.company_name}</p>
          
          </Col>
          <Col className="col-6 text-center">
          <p style={{fontSize:13}}>{item?.establishment_id}</p>
          
          </Col>
          
          </Row>
          </Col>
          <Col className="col-1 d-flex" style={{alignItems:"center", justifyContent:'end'}}>
          {/* <FeatherIcon icon="chevron-down" style={{marginRight:10}}  /> */}

          <i class="fas fa-chevron-down" style={{marginRight:10}}></i>
          
          </Col>
          </Row>
          
          
          
          </div>
          }>
          <div className="table-responsive">
          
     
          <PassbookDetail eid={item?.establishment_id} data={epfo_passbook}/>
          
          </div>
          </Accordion>
      )
    })

  }

 </Col>
</Row>
</div>
                          }
                   

                       
                      </div>


         
        </div>
      </div>
      </Collapse>
    </div>}

{/* TDS */}
   {showDoc.tds && <div className="accordion-item m-2">
      <h2 className="accordion-header" id="headingOneTds">
        <button
          className={classnames(
            "accordion-button",
            "fw-medium",
            { collapsed: !col7 }
          )}
          type="button"
          onClick={t_col7}
          style={{ cursor: "pointer",
          fontWeight:'200',
          fontSize:18
        
        }}
        >
        TDS
        </button>
      </h2>

      

      <Collapse isOpen={col7} className="accordion-collapse">

      <div className="accordion-body">
        <div className="text-muted">

        <p style={{fontSize:18}}>TDS Details</p>

        <div  >
                      

                          {
itr && 

<div className="table-responsive pb-3" >
<p style={{fontSize:16, textAlign:'center'}} className="mb-4">Employee Verification by UAN Number</p>

<Row>
 <Col className="col-12">

   <div style={{}}>

     <div className="d-flex">
     <p style={{fontSize:13}}>PAN:</p>
   <p style={{fontSize:13}}>{itr?.pan_no}</p>
     </div>




   </div>
 </Col>

</Row>
<Row>
 <Col className="col-12">
  
  {
    itr && 

    tds.map((item)=>{
      return (
        <Accordion header={
          <div className="container-fluid">
          
          <Row>
          <Col className="col-11">
          <Row className="pt-3 text-center">
          <Col className="col-6 text-center">
          <p style={{fontSize:13}}>{item?.assessment_year}</p>
          
          </Col>
          <Col className="col-6 text-center">
          <p style={{fontSize:13}}>{item?.pan_no}</p>
          
          </Col>
          
          </Row>
          </Col>
          <Col className="col-1 d-flex" style={{alignItems:"center", justifyContent:'end'}}>
          {/* <FeatherIcon icon="chevron-down" style={{marginRight:10}}  /> */}

          <i class="fas fa-chevron-down" style={{marginRight:10}}></i>
          
          </Col>
          </Row>
          
          
          
          </div>
          }>
          <div className="table-responsive">
          
     
          <TDSDATA assessment_year={item.assessment_year}   data={tds_data}/>
          
          </div>
          </Accordion>
      )
    })

  }

 </Col>
</Row>
</div>
                          }
                   

                       
                      </div>


         
        </div>
      </div>
      </Collapse>
    </div>}

    {/* Physical verification */}
   {showDoc.physical_verification && <div className="accordion-item m-2">
      <h2 className="accordion-header" id="headingEight">
        <button
          className={classnames(
            "accordion-button",
            "fw-medium",
            { collapsed: !col8 }
          )}
          type="button"
          onClick={t_col8}
          style={{ cursor: "pointer",
          fontWeight:'200',
          fontSize:18
        
        }}
        >
        Physical Verification
        </button>
      </h2>

      

      <Collapse isOpen={col8} className="accordion-collapse">



<div className="accordion-body">
        <div className="text-muted">

        <Table className="table mb-0">
<thead className="table-dark">
  <tr>
    <th>Address Details</th>
    <th></th>
  </tr>

</thead>
<tbody>
<tr>
    <td className="table-light">Permanent Address</td>
    <td className="table-light"></td>

</tr>

<tr>
    <td className="table-light">State</td>
    <td className="table-light"></td>

</tr>


<tr>
    <td className="table-light">City</td>
    <td className="table-light"></td>

</tr>

<tr>
    <td className="table-light">Pincode</td>
    <td className="table-light"></td>

</tr>

<tr>
    <td className="table-light">Refrence name</td>
    <td className="table-light"></td>

</tr>

<tr>
    <td className="table-light">Refrence relation</td>
    <td className="table-light"></td>

</tr>

<tr>
    <td className="table-light">Reference mobile</td>
    <td className="table-light"></td>

</tr>

<tr>
    <td className="table-light">Candidate residence since</td>
    <td className="table-light"></td>

</tr>

<tr>
    <td className="table-light">Candidate residence until</td>
    <td className="table-light"></td>

</tr>

<tr>
    <td className="table-light">House Type</td>
    <td className="table-light"></td>

</tr>


<tr>
    <td className="table-light">Latitude/Longitude</td>
    <td className="table-light"></td>

</tr>
</tbody>
</Table>
         
        </div>
      </div>
      </Collapse>
    </div>}

{/* court records */}
    {showDoc.court && <div className="accordion-item m-2">
      <h2 className="accordion-header" id="headingNine">
        <button
          className={classnames(
            "accordion-button",
            "fw-medium",
            { collapsed: !col9 }
          )}
          type="button"
          onClick={t_col9}
          style={{ cursor: "pointer",
          fontWeight:'200',
          fontSize:18
        
        }}
        >
        Court Records
        </button>
      </h2>

      

      <Collapse isOpen={col9} className="accordion-collapse">



<div className="accordion-body">
        <div className="text-muted">

        <Table className="table mb-0">
<thead className="table-dark">
  <tr>
    <th>Court verification</th>
    <th></th>
  </tr>

</thead>

<tbody>
<tr>
    <td className="table-light">Personal assistant</td>
    <td className="table-light"></td>
    </tr>
    <tr>
    <td className="table-light">Permanent Address</td>
    <td className="table-light"></td>
    </tr>
    <tr>
    <td className="table-light">District and state</td>
    <td className="table-light"></td>
    </tr>
    <tr>
    <td className="table-light">Final status</td>
    <td className="table-light"></td>
    </tr>
  </tbody>  
</Table>
         
        </div>
      </div>
      </Collapse>
    </div>}

{/* Credit Report */}
    {showDoc.credit_score &&<div className="accordion-item m-2">
      <h2 className="accordion-header" id="headingTen">
        <button
          className={classnames(
            "accordion-button",
            "fw-medium",
            { collapsed: !col10 }
          )}
          type="button"
          onClick={t_col10}
          style={{ cursor: "pointer",
          fontWeight:'200',
          fontSize:18
        
        }}
        >
        Credit Report
        </button>
      </h2>

      

      <Collapse isOpen={col10} className="accordion-collapse">



<div className="accordion-body">
        <div className="text-muted">

      <Table className="table mb-0">
<thead className="table-dark">
  <tr>
    <th>Credit Details</th>
    <th></th>
  </tr>

</thead>

<tbody>



    <tr>
    <td className="table-light">Pan</td>
    <td className="table-light">{credit_score.pan}</td>
    </tr>
    <tr>
    <td className="table-light">Name</td>
    <td className="table-light">{credit_score.name}</td>
    </tr>
    <tr>
    <td className="table-light">Mobile</td>
    <td className="table-light">{credit_score.mobile}</td>
    </tr>
    <tr>
    <td className="table-light">Credit score</td>
    <td className="table-light">{credit_score.credit_score}</td>
    </tr>
    <tr>
    <td className="table-light">Report</td>
    <td className="table-light">
      <Link to={`${credit_score.credit_report_link}`} target="_blank">View Report</Link>
    </td>
    </tr>
  </tbody>  
</Table>
      
        </div>
      </div>
      </Collapse>
    </div>}

{/* Drug Test */}
    {showDoc.drug && <div className="accordion-item m-2">
      <h2 className="accordion-header" id="headingEleven">
        <button
          className={classnames(
            "accordion-button",
            "fw-medium",
            { collapsed: !col11 }
          )}
          type="button"
          onClick={t_col11}
          style={{ cursor: "pointer",
          fontWeight:'200',
          fontSize:18
        
        }}
        >
        Drug Test
        </button>
      </h2>

      

      <Collapse isOpen={col11} className="accordion-collapse">



<div className="accordion-body">
        <div className="text-muted">

        {/* <p style={{fontSize:18}}>Education</p> */}


         
        </div>
      </div>
      </Collapse>
    </div>}
  </div>

                    </CardBody>
                </Card>

{/*  
  {
    (Object.keys(requestedData).length >0 && component_to_verify!==null) && renderData()
  } */}
          
          <Card>
                  <CardBody>
                  <p style={{fontSize:15, fontWeight:500}} className="mb-3">Uploaded Documents</p>

                  <Table bordered  style={{
                      borderWidth:1,
                    }}>

          <thead>
            <tr >
              <th>Show in report</th>
              <th>File Name</th>
              <th>Description</th>
              <th>Component</th>
              <th>Uploaded By</th>
              <th>Uploaded On</th>
              <th>Action</th>


            </tr>

          </thead>

          <tbody >


          {
            uploaded_documents.length > 0 && uploaded_documents.map((item, index) => {
                
                return <tr key={index}>
                  <td>

                  <Input className="form-check-input" type="checkbox" onClick={()=> {
           
            let data = uploaded_documents;

            data[index].selected = !data[index].selected;

            setUploadedDocuments([...data]);


  
                    }} 

            
            checked={item.selected}   />
                  </td>
                <td>{item.file_name}</td>
                <td>{item.description}</td>
                <td></td>
                <td>{item.uploaded_by}</td>
                <td></td>
                <td><button className="btn btn-primary btn-sm">View</button></td>
              </tr>
            })
          }
   
           </tbody>

                      </Table>
                  
               <button className="btn btn-primary" onClick={updateDocument}>Save</button>
                  </CardBody>
                </Card>    
            


               
            </Col>
          </Row>

          <Row>
            <Col lg="5">
            <Card>
                  <CardBody>

                    <div className="p-2">

                    <p style={{fontSize:16, fontWeight:500}}>Case History</p>


    <div className="mb-3 d-flex" style={{justifyContent:'space-between', alignItems:'center'}}>
                        <Label className="form-label">Status:</Label>
    <select className="form-select w-75"
    
    onChange={(e)=> {
  
      let value = e.target.value;
      console.log('value==>',value)

    
      setSelectedStatus(value);
   

}}>

<option value="">Select status to update</option>

<option value="send_report_to_client">Complete - Send Order Report To Client</option>

<option value="rework">Rework - Send to process leader</option>

</select>
      </div>

      {selectedStatus == 'send_report_to_client' && <div className="mb-3 d-flex" style={{justifyContent:'space-between', alignItems:'center'}}>
                        <Label className="form-label">Deposition Status:</Label>
    <select className="form-select w-75"
    
    onChange={(e)=> {
  
      let value = e.target.value;
      console.log('value==>',value)


      setDepStatusColor(depStatusOption[value].ColorCode);

       
      setDepositionStatus(depStatusOption[value].status);

      setColorCode('')
   

}}>
    <option value="">Select Deposition status</option>

    {

depStatusOption.map((item, index) => {
        
        return <option value={index}>{item.status}</option> 

    })

    }


</select>
      </div>}

      {/* {selectedStatus == 'send_report_to_client' && <div className="mb-3 d-flex" style={{justifyContent:'space-between', alignItems:'center'}}>
                        <Label className="form-label">Reason:</Label>
    <select className="form-select w-75"

     style={{
      borderWidth: colorCode ? 4:1,
      borderColor: colorCode ? colors[colorCode]  : 'black',
     }}
    onChange={(e)=> {

  
      let value = e.target.value;
      console.log('value==>',value)

      let colorcode = colorcodematrix.find((item)=> item.ID == value);

      console.log('colorcode==>',colorcode);


      setColorCode(colorcode.ColorCode);
      
      setReason(value);
   

}}>
    <option value="">Select Reason</option>
{
  colorcodematrix.map((item, index) => {
      
      return <option value={item.ID}>{item.Reason}</option>
  })
}

</select>
      </div>} */}

      <div className="mb-3 d-flex" style={{justifyContent:'space-between', alignItems:'center'}}>
                                  <Label htmlFor="commentmessage-input">
                                    Activity
                                  </Label>
                                  <textarea
                                    className="form-control w-75"
                                    id="commentmessage-input"
                                    placeholder="Your message..."
                                    rows="3"
                                  ></textarea>
                                </div>


                                <button
                        type="button"
                        onClick={()=>{

                          if(selectedStatus == '') {
                            alert('Please select status to update');
                            return
                          }

                          if(selectedStatus == 'send_report_to_client' && depositionStatus == '') {

                            alert('Please select deposition status to update');

                            return
                          }

                          if(selectedStatus == 'send_report_to_client') {

                           assignToQC()

                          }

                          if(selectedStatus == 'rework') {

                            rework()
 
                           }
                         
                        }}
                        className="btn btn-primary me-2"
                      >
                        Update
                      </button>
                    </div>
          
                    </CardBody>
                </Card>
              </Col>

              <Col lg="4">
              <Card>
                  <CardBody>
                  <p style={{fontSize:16, fontWeight:500}}>Log Call</p>

                    <div className="p-2">


                    <div className="d-flex mb-3" style={{justifyContent:'space-between', width:'70%'}}>
                        <Label for="basicpill-firstname-input1"  className="control-label me-3" >
                        Consider as An Attempt:
                                </Label>

                                <div className="form-check me-3 d-flex">
<div className="form-check me-3">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="exampleRadios90"
                            id="exampleRadios90"
                            value="yes"
                            checked={log_call_attempt === 'yes' ? true : false}
                            defaultChecked
                            onChange={(e)=>{
                                setLogCallAttempt('yes');
                                update_log_call();
                            }
                          }
                            
                          />
                          <label
                            className="form-check-label"
                            htmlFor="exampleRadios90"
                          >
                            Yes
                          </label>
                        </div>

                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="exampleRadios91"
                            checked={log_call_attempt === 'no' ? true : false}
                            id="exampleRadios91"
                            value="no"
                            onChange={(e)=>{
                                
                                setLogCallAttempt('no');
                            }
                          }
                          />
                          <label
                            className="form-check-label"
                            htmlFor="exampleRadios91"
                          >
                            No
                          </label>

              

                        
                        </div>
                        </div>
                   
                     
</div>


      <div className="mb-3 d-flex" style={{justifyContent:'space-between', alignItems:'center'}}>
                                  <Label htmlFor="commentmessage-input">
                                    Activity
                                  </Label>
                                  <textarea
                                    className="form-control w-75"
                                    id="commentmessage-input"
                                    placeholder="Your message..."
                                    onChange={(e)=>setLogActivity(e.target.value)}
 
                                    rows="3"
                                  >
                                    {log_activity}
                                  </textarea>
                                </div>


                                <button
                        type="button"
                        onClick={update_log_call}
                        className="btn btn-primary me-2"
                      >
                        Update
                      </button>
                    </div>
          
                    </CardBody>
                </Card>
              </Col>


              <Col lg="3">
            <Card>
                  <CardBody>
                  <p style={{fontSize:16, fontWeight:500}}>External Message Log</p>

                    <div className="p-2">



 


      <div className="mb-3" style={{justifyContent:'space-between', alignItems:'center'}}>
                                  <Label htmlFor="commentmessage-input">
                                    External Message Log
                                  </Label>
                                  <textarea
                                    className="form-control"
                                    id="commentmessage-input"
                                    placeholder="Your message..."
                                    rows="3"
                                  ></textarea>
                                </div>


                                <button
                        type="button"
                        onClick={()=>{
                         
                        }}
                        className="btn btn-primary me-2"
                      >
                        Update
                      </button>
                    </div>
          
                    </CardBody>
                </Card>
              </Col>
          </Row>
      
          <Row>
  <Col lg="4">
  <Card>
                  <CardBody>

                    <div className="p-2 bg-light">
                    <p style={{fontSize:16}} className="mb-4 px-3">Activity Log</p>


{
  activityLogs.map((item, index)=>(
    <div className="row pr-2 p-3  pt-0 pb-0">
    <ul  class="activity-content">
      <li  class="box app-arrow-left-top" >
                          <span >
                          {item.status==null?'New':item.status} - on {moment(item.updated_at).format('dddd, DD-MMM-YY hh:mm:ss')} {"<"}  By - {(item.updated_by == null || item.updated_by == "")?"System Scheduler": item.updated_by} {">"}</span>
                         
                          
                          
                          </li>



                     
                    </ul>
    </div>
  ))
}
          
                    </div>
          
                    </CardBody>
                </Card>
  </Col>
</Row>
        </div>
      </div>

    </React.Fragment>
  );
}
QcCaseDetail.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
};

export default QcCaseDetail;
