


import React, { useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { get, isEmpty, set } from "lodash";
import "bootstrap/dist/css/bootstrap.min.css";
import * as Yup from "yup";
import { useFormik } from "formik";
import axios from "axios";
import classnames from "classnames";
// import {Buffer} from 'buffer';
// import { PDFDownloadLink, pdf, usePDF, BlobProvider } from '@react-pdf/renderer';
import Accordion from "../../../components/Accordian";
import { TDSDATA, PassbookDetail, EPFO } from "../../../components/IdentityInfo";

import Cookies from "js-cookie";


import {
  Button,
  Col,
  Row,
  UncontrolledTooltip,
  Modal,
  ModalHeader,
  ModalBody,
  Collapse,
  Form,
  Input,
  FormFeedback,
  Label,
  Card,
  CardBody,
  Table,
} from "reactstrap";
import moment from "moment";

//Import Flatepicker
import "flatpickr/dist/themes/material_blue.css";
import Flatpickr from "react-flatpickr";
// import PdfDocViewer from "../components/PdfDocViewer";
// import FeatherIcon from "feather-icons-react";

function QcCompDetail() {
  //meta title
  document.title = "TheRollNumber";

  const navigate = useNavigate();

  // const [instance, updateInstance] = usePDF({ document: PdfDocViewer });

  const [componentData, setComponentData] = useState(null);

  const location = useLocation();

  const [colorCode, setColorCode] = useState('');


  const [isOpen, setIsOpen] = useState({});

  const [caseData, setCaseData] = useState({});



  const [log_call_attempt, setLogCallAttempt] = useState('no');


  const [user, setUser] = useState({});

  const [report_status, setReportStatus] = useState('');

  const [activityLogs, setActivityLogs] = useState([]);

  const [requestedDocs, setRequestedDocs] = useState([]);

  const [requestedData, setRequestedData] = useState([]);

const [credit_score, setCreditScore] = useState({});

const [selectedStatus , setSelectedStatus] = useState('');

const [depositionStatus, setDepositionStatus] = useState('');

const [uploaded_documents, setUploadedDocuments] = useState([]);

const [depStatusOption, setDepStatusOption] = useState([]);

const [depStatusColor, setDepStatusColor] = useState('');

const [comment, setComment] = useState('');

const [log_activity, setLogActivity] = useState('');

const [panState, setPanState] = useState({

  pan_number: "",
  enable_pan_number: false,
  full_name: "",
  enable_full_name: false,
  verified_data: "",
  
  verified_on: "",
  disposition_code: "",
  disposition_remarks: "",
  uploaded_document: ""

});

const [upload_doc_object, setUploadDocObject] = useState({
  description: "",
  file:null
});

const [reason, setReason] = useState('');

const [colorcodematrix, setColorCodeMatrix] = useState([]);



const [state, setState] = useState({
  client_status: "no",
});

  const [epfo_data, setEpfoData] = useState({});
  const [epfo_companies, setEpfoCompanies] = useState([]);
  const [epfo_passbook, setEpfoPassbook] = useState([]);

  const [itr, setItr] = useState({});

  const [tds, setTds] = useState([]);


  const [tds_data, setTdsData] = useState([]);

  const [modalConfirm, setModalConfirm] = useState(false);

  const [modalConfirm2, setModalConfirm2] = useState(false);

  const toggleConfirm = () => setModalConfirm(!modalConfirm);

  const toggleConfirm2 = () => setModalConfirm2(!modalConfirm2);

  const [component_to_verify, setComponentToVerify] = useState(null);

  const [components, setComponents] = useState([]);

// const [setQCM, setQCM] = useState([]);




  const [docNames, setDocNames] = useState({
    aadhaar: "Aadhaar Card",
    pan: "Pan Card",
    voter_id: "Voter Id",
    driving_licence: "Driving License",
   
  });
 

  // Toggle function to open or close an accordion item
  const toggle2 = (index) => {
    setIsOpen((prevState) => ({
      ...prevState,
      [index]: !prevState[index]
    }));
  };


  const [section, setSection] = useState([

    
  ]);


  const [modal, setModal] = useState(false);
  const [modal1, setModal1] = useState(false);
  const [isEdit, setIsEdit] = useState(false);

  const [component_fields, setComponentFields] = useState([]);

  const [orderList, setOrderList] = useState([]);
  const [order, setOrder] = useState(null);
  const [col1, setcol1] = useState(true);
  const [col2, setcol2] = useState(false);
  const [col3, setcol3] = useState(false);
  const [col4, setcol4] = useState(false);
  const [col5, setcol5] = useState(false);
  const [col6, setcol6] = useState(false);
  const [col7, setcol7] = useState(false);
  const [col8, setcol8] = useState(false);
  const [col9, setcol9] = useState(false);
  const [col10, setcol10] = useState(false);
  const [col11, setcol11] = useState(false);
  const [col12, setcol12] = useState(false);
  const [col13, setcol13] = useState(false);
  const [col14, setcol14] = useState(false);
  const [col15, setcol15] = useState(false);

  

  const t_col1 = () => {
    setcol1(!col1);

  };

  const t_col2 = () => {
    setcol2(!col2);


  };

  const t_col3 = () => {
    setcol3(!col3);


  };

  const t_col4 = () => {
    setcol4(!col4);
    setcol1(false);
    setcol2(false);
    setcol3(false)
    setcol5(false)

  };

  const t_col5 = () => {
    setcol5(!col5);
    setcol1(false);
    setcol2(false);
    setcol3(false)
    setcol4(false)

  };

  const t_col6 = () => {
    setcol6(!col6);


  };

  const t_col7 = () => {
    setcol7(!col7);

  }

  const t_col8 = () => {
    setcol8(!col8);

  }

  const t_col9 = () => {
    setcol9(!col9);

  }

  const t_col10 = () => {

    setcol10(!col10);

  }

  const t_col11 = () => {

    setcol11(!col11);

  }

  const t_col12 = () => {

    setcol12(!col12);

  }


  // validation

  const toggleViewModal = () => setModal1(!modal1);

  useEffect(() => {

    getAdminUsers();

    get_case();

    get_components();

  }, []);


  const getAdminUsers = async () => {

    const config = {
      headers: {
        'Content-Type': 'application/json'
      }
    };
   
    axios.get(`${process.env.REACT_APP_BASE_URL}/employer/admin/users`, {}, config)

    .then(response => {

      console.log('admin response cases==>',response.data.data)

      const data = response.data.data.filter((item)=> (item.roles.includes('QC Component Manager') || item.roles.includes('Administrator')) );

        console.log('new case users data==>',data)

        // setPTL(data)


    })

    .catch(error => {

      console.log('error from server==>',error)

    })


  }


  const get_components = async () => {

    const config = {
      headers: {
        'Content-Type': 'application/json'
      }
    };
   
    axios.get(`${process.env.REACT_APP_BASE_URL}/service/components`, {}, config)

    .then(response => {

      console.log('response components==>',response)

      const data = response.data;

     console.log('data==>',data)

     let arr = [];

      data.map((item)=> {

        arr.push({
          component: item.name,
          code: item.code,
          checked: false,
          id: item.id
        })

      })


      setComponents(arr)




    })

    .catch(error => {

      console.log('error from server==>',error)

    })


  }


  const get_requested_data = async () => {

    console.log('caseData',caseData);

    const config = {
      headers: {
        'Content-Type': 'application/json',

      }
    };

    const body = {

      org_user_client_id: caseData.org_client_id,
    
    }

    console.log('body requested ==>',body);

    axios.post(`${process.env.REACT_APP_BASE_URL}/service/requestedData/${caseData.walletID}`, body, config)
    .then(response => {
      console.log('get requested data',response);

      let data = response.data.data;

      console.log('data==>',data);

      if(data.length > 0){

        setRequestedData(data);

      }


 

    })
    .catch(error => {
  
      
  
      console.log('error==>',error);
    });

 
  }

  useEffect(() => {

    if (Object.keys(caseData).length > 0){
      get_request_identity_org();
      activity_logs();
      get_requested_data();
    }

  }, [caseData]);


  const Aadhaar = ({item, onSave}) => {

    const [state, setState] = useState({
      aadhaar_number: "",
      enable_aadhaar_number: false,
      full_name: "",
      enable_full_name: false,
      dob: "",
      enable_dob: false,
      care_of: "",
      enable_care_of: false,
      address: "",
      enable_address: false,
      verified_data: "",
      verified_on: "",
      client_status: "no",
      disposition_code: "",
      disposition_remarks: "",
      uploaded_document: ""
    });

    const [upload_doc, setUploadDoc] = useState({
      file: null,
      description: "",
    
    });

    useEffect(() => {


      if(component_fields.length > 0){

        let obj = {};

        component_fields.map((item)=> {

          obj[item.field_id] = item.value;

        })

        setState({
          ...state,
          ...obj
        
        })

      }


    }, []);

    const inputHandler = (e) => {

      const {name, value} = e.target;
  
      setState({...state, [name]: value})
  
     }


     const save = () => {

      console.log('state',state);
  
      let obj = {}
  
      Object.keys(state).map((key)=> {
  
        // return only the keys that are enabled
  
        if(state[`enable_${key}`]){
  
          obj[key] = state[key]
  
  
  
        }
        
  
     
  
      })
  
      let upload_obj = null;
  
      if(upload_doc.file){
  
        upload_obj = {
  
          file: upload_doc.file,
          description: upload_doc.description
  
        }
  
      }
  
      onSave(obj, upload_obj);
    }
    return (
      <div>
        
 
      <div className="table-responsive">
      <Table className="table table-striped mb-0" striped  >

        <thead >

          <tr>
            <th style={{width:'20%', fontSize:16, fontWeight:500}}>Aadhaar Card component details</th>
            <th style={{width:'30%', fontSize:16, fontWeight:500}}>Data Shared By Candidate</th>
            <th style={{width:'10%', fontSize:16, fontWeight:500}}></th>

            <th style={{width:'50%', fontSize:16, fontWeight:500}}>Verified Data</th>

          </tr>

          </thead>
  
        <tbody >
          <tr>
    
            <td width={'20%'}>Aadhaar Card Number *</td>
            <td width={'20%'}>{item.aadhaar_number}</td>

            <td width={'10%'}>
            <input  style={styles.inputCheck} checked={panState.enable_aadhaar_number} className="form-check-input" onChange={(e)=>{
                                         
                                         setState({...state, enable_aadhaar_number: !panState.enable_aadhaar_number})


                                        }} type="checkbox" />
            </td>
          
            <td width={'50%'}>
            <Input
                                    type="text"
                                    className="form-control me-3"
                                    value={state.aadhaar_number}
                                    disabled={!state.enable_aadhaar_number}
                                   onChange={inputHandler}
                                    style={styles.boxInput}
                                    name="aadhaar_number"
                                    placeholder="Aadhaar number"
                                />
            </td>
          </tr>  

   
          <tr>
    
    <td width={'20%'}>Full Name *</td>
    <td width={'20%'}>{item.full_name}</td>

    <td width={'10%'}>
    <input style={styles.inputCheck} name="full_name" className="form-check-input" onClick={(e)=>{

      setState({...state, enable_full_name: !state.enable_full_name})
                                 
                                }} type="checkbox" id="upcomingtaskCheck01" />
    </td>
  
    <td width={'50%'}>
      <input type="text" disabled={!state.enable_full_name} name="full_name"
 className="form-control" placeholder="Full name" onChange={inputHandler} value={state.full_name} />
    </td>
  </tr> 

<tr>
    
    <td width={'20%'}>DOB *</td>
    <td width={'20%'}>{item.dob}</td>

    <td width={'10%'}>
    <input style={styles.inputCheck} className="form-check-input" onClick={(e)=>{

      setState({...state, enable_dob: !state.enable_dob})
                                 
                                


    }} type="checkbox" id="upcomingtaskCheck01" />
    </td>
  
    <td width={'50%'}>
      <input type="text" disabled={!state.enable_dob} onChange={inputHandler} name="dob" className="form-control" value={state.dob} />
    </td>
  </tr> 


  <tr>
    
    <td width={'20%'}>Care Of *</td>
    <td width={'20%'}>{item.care_of}</td>

    <td width={'10%'}>
    <input style={styles.inputCheck} className="form-check-input" onClick={(e)=>{

      setState({...state, enable_care_of: !state.enable_care_of})
                                 
                                


    }} type="checkbox" id="upcomingtaskCheck01" />
    </td>
  
    <td width={'50%'}>
      <input type="text" disabled={!state.enable_care_of} onChange={inputHandler} name="care_of" className="form-control" value={state.care_of} />
    </td>
  </tr> 

  <tr>
    
    <td width={'20%'}>Full address *</td>
    <td width={'20%'}>{item.address}</td>

    <td width={'10%'}>
    <input style={styles.inputCheck} className="form-check-input" onClick={(e)=>{

      setState({...state, enable_address: !state.enable_address})
                                 
                                


    }} type="checkbox" id="upcomingtaskCheck01" />
    </td>
  
    <td width={'50%'}>
      <textarea type="text" disabled={!state.enable_address} onChange={inputHandler} name="address" className="form-control" value={state.address} />
    </td>
  </tr> 



  <tr>
    
    <td width={'20%'}>Verified On *</td>
    <td width={'20%'}>{moment(item.timestamp).format('DD-MM-YYYY')}</td>

    <td width={'10%'}>
    {/* <input style={styles.inputCheck}  className="form-check-input" onClick={(e)=>{
                                 
                                }} type="checkbox" id="upcomingtaskCheck01" /> */}
    </td>
  
    <td width={'50%'}>
      {/* <input type="text" disabled className="form-control" value={''} /> */}
    </td>
  </tr> 

     

<tr>
    
    <td width={'20%'}>Disposition Code *</td>
    <td width={'20%'}></td>

    <td width={'10%'}>
    {/* <input style={styles.inputCheck} className="form-check-input" onClick={(e)=>{
                                 
                                }} type="checkbox" id="upcomingtaskCheck01" /> */}
    </td>
  
    <td width={'50%'}>
      {/* <input type="text" disabled className="form-control" value={''} /> */}
    </td>
  </tr> 


  <tr>
    
    <td width={'20%'}>Disposition Remarks *</td>
    <td width={'20%'}></td>

    <td width={'10%'}>
    {/* <input style={styles.inputCheck} disabled className="form-check-input" onClick={(e)=>{
                                 
                                }} type="checkbox" id="upcomingtaskCheck01" /> */}
    </td>
  
    <td width={'50%'}>
      {/* <input type="text" disabled className="form-control" value={''} /> */}
    </td>
  </tr> 


<tr>

<td>Upload Documents *</td>

<td
>
<input type="text" onChange={
  (e)=> {
      
  
      setUploadDoc({...upload_doc, description: e.target.value})
  
  }
} placeholder="Description" className="form-control" value={upload_doc.description} />


  </td>

<td>

</td>

<td >
<input type="file" className="form-control" onChange={
  (e)=> {
    
    console.log('file',e.target.files[0]);

    setUploadDoc({...upload_doc, file: e.target.files[0]})
  }

}

/>

</td>

</tr>

          
     
      
        </tbody>
      </Table>

      <div className="text-center">
      <Button color="primary" size="md" className="my-3" onClick={save}>Save</Button>
      </div>
    </div>

              </div>
    )
  }

  
  const Credit = ({item}) => {


 
    return (
     
      <div className="p-2">
 
      <div className="table-responsive">
      <Table className="table table-striped mb-0" striped  >

        <thead >

          <tr>
            <th style={{width:'20%', fontSize:16, fontWeight:500}}>Credit Score component details</th>
            <th style={{width:'30%', fontSize:16, fontWeight:500}}>Data Shared By Candidate</th>
            <th style={{width:'10%', fontSize:16, fontWeight:500}}></th>

            <th style={{width:'50%', fontSize:16, fontWeight:500}}></th>

          </tr>

          </thead>
  
        <tbody >
          <tr>
    
            <td width={'20%'}>PAN Number</td>
            <td width={'20%'}>{item[0].pan}</td>

            <td width={'10%'}>

            </td>
          
            <td width={'50%'}>
       
            </td>
          </tr>  

   
          <tr>
    
    <td width={'20%'}>Full Name</td>
    <td width={'20%'}>{item[0].name}</td>

    <td width={'10%'}>

    </td>
  
    <td width={'50%'}>
     
    </td>
  </tr> 

<tr>
    
    <td width={'20%'}>Mobile</td>
    <td width={'20%'}>{item[0].mobile}</td>

    <td width={'10%'}>

    </td>
  
    <td width={'50%'}>
    </td>
  </tr> 

  <tr>
    
    <td width={'20%'}>Credit Score</td>
    <td width={'20%'}>{item[0].credit_score}</td>

    <td width={'10%'}>

    </td>
  
    <td width={'50%'}>
    </td>
  </tr> 

  <tr>
    
    <td width={'20%'}>Verified On</td>
    <td width={'20%'}>{moment(item.timestamp).format('DD-MM-YYYY')}</td>

    <td width={'10%'}>

    </td>
  
    <td width={'50%'}>
    </td>
  </tr> 

     


  <tr>
    
    <td width={'20%'}>Report</td>
    <td width={'20%'}>
     <button onClick={()=>{
        
       window.open(item[0].credit_report_link, '_blank')
     }} className="btn btn-primary btn-sm">View</button>
    </td>

    <td width={'10%'}>

    </td>
  
    <td width={'50%'}>
    </td>
  </tr> 

  {/* <tr>
    
    <td width={'20%'}>Reporting Agency</td>
    <td width={'20%'}>Equifax</td>

    <td width={'10%'}>

    </td>
  
    <td width={'50%'}>
    </td>
  </tr>    */}
     
      
        </tbody>
      </Table>

   
    </div>
        </div>
    )
  }

  const Education = ({item, onSave}) => {

    const [state, setState] = useState({
  
      university_name: "",
      enable_university_name: false,
      type_of_graduation: "",
      enable_type_of_graduation: false,
      CGPA: "",
      enable_CGPA: false,
      year_of_graduation: "",
      enable_year_of_graduation: false,
      year_of_start: "",
      enable_year_of_start: false,
      year_of_end: "",
      enable_year_of_end: false,
      final_status: "",
      enable_final_status: false,
  
    });
  
    useEffect(() => {
  
  
      if(component_fields.length > 0){
  
        let obj = {};
  
        component_fields.map((item)=> {
  
          obj[item.field_id] = item.value;
  
        })
  
        setState({
          ...state,
          ...obj
        
        })
  
      }
  
  
    }, []);
  
  
    const inputHandler = (e) => {
  
      const {name, value} = e.target;
  
      setState({...state, [name]: value})
  
      }
      const save = () => {
  
        console.log('state',state);
  
        let obj = {}
  
        Object.keys(state).map((key)=> {
  
          // return only the keys that are enabled
  
          if(state[`enable_${key}`]){
  
            obj[key] = state[key]
  
  
  
          }
          
  
       
  
        })
  
  
  
        onSave(obj);
  
      }
  
  
  
    return (
      <div>
  
        <div className="table-responsive">
       
  
  <Table className="table table-striped mb-0" striped  >
  
  <thead >
  
    <tr>
  
      <th style={{width:'20%', fontSize:16, fontWeight:500}}>Education component details</th>
  
      <th style={{width:'30%', fontSize:16, fontWeight:500}}>Data Shared By Candidate</th>
  
      <th style={{width:'10%', fontSize:16, fontWeight:500}}></th>
  
      <th style={{width:'50%', fontSize:16, fontWeight:500}}>Verified Data</th>
  
    </tr>
  
    </thead>
  
  <tbody >
  
    <tr>
  
      <td width={'20%'}>University Name *</td>
  
      <td width={'20%'}>{item.university_name}</td>
  
      <td width={'10%'}>
  
      <input  style={styles.inputCheck} checked={state.enable_university_name} className="form-check-input" onChange={(e)=>{
  
        setState({...state, enable_university_name: !state.enable_university_name})
  
      }} type="checkbox"/>
  
      </td>
  
      <td width={'50%'}>
  
      <Input type="text" className="form-control me-3" value={state.university_name} disabled={!state.enable_university_name} onChange={inputHandler} style={styles.boxInput} name="university_name" placeholder="University name" />
  
      </td>
  
    </tr>
  
    <tr>
  
      <td width={'20%'}>Type of Graduation *</td>
  
      <td width={'20%'}>{item.type_of_graduation}</td>
  
      <td width={'10%'}>
  
      <input style={styles.inputCheck} name="type_of_graduation" className="form-check-input" onClick={(e)=>{
        setState({...state, enable_type_of_graduation: !state.enable_type_of_graduation})
  
      }} type="checkbox" id="upcomingtaskCheck01" />
  
      </td>
  
      <td width={'50%'}>
  
      <input type="text" disabled={!state.enable_type_of_graduation} name="type_of_graduation" className="form-control" placeholder="Type of graduation" onChange={inputHandler} value={state.type_of_graduation} />
  
      </td>
  
    </tr>
  
    <tr>
  
      <td width={'20%'}>CGPA *</td>
  
      <td width={'20%'}>{item.CGPA}</td>
  
      <td width={'10%'}>
  
      <input style={styles.inputCheck} name="CGPA" className="form-check-input" onClick={(e)=>{
  
        setState({...state, enable_CGPA: !state.enable_CGPA})
  
      }} type="checkbox" id="upcomingtaskCheck01" />
  
      </td>
  
      <td width={'50%'}>
  
      <input type="text" disabled={!state.enable_CGPA} name="CGPA" className="form-control" placeholder="CGPA" onChange={inputHandler} value={state.CGPA} />
  
      </td>
  
    </tr>
  
    <tr>
  
      <td width={'20%'}>Year of Graduation *</td>
  
      <td width={'20%'}>{item.year_of_graduation}</td>
  
      <td width={'10%'}>
  
      <input style={styles.inputCheck} name="year_of_graduation" className="form-check-input" onClick={(e)=>{
        setState({...state, enable_year_of_graduation: !state.enable_year_of_graduation})
  
      }} type="checkbox" id="upcomingtaskCheck01" />
  
      </td>
  
      <td width={'50%'}>
  
      <input type="text" disabled={!state.enable_year_of_graduation} name="year_of_graduation" className="form-control" placeholder="Year of graduation" onChange={inputHandler} value={state.year_of_graduation} />
  
      </td>
  
    </tr>
  
    <tr>
  
      <td width={'20%'}>Year of Start *</td>
  
      <td width={'20%'}>{item.year_of_start}</td>
  
      <td width={'10%'}>
  
      <input style={styles.inputCheck} name="year_of_start" className="form-check-input" onClick={(e)=>{
  
        setState({...state, enable_year_of_start: !state.enable_year_of_start})
  
      }} type="checkbox" id="upcomingtaskCheck01" />
  
      </td>
  
      <td width={'50%'}>
  
      <input type="text" disabled={!state.enable_year_of_start} name="year_of_start" className="form-control" placeholder="Year of start" onChange={inputHandler} value={state.year_of_start} />
  
      </td>
  
    </tr>
  
    <tr>
  
      <td width={'20%'}>Year of End *</td>
  
      <td width={'20%'}>{item.year_of_end}</td>
  
      <td width={'10%'}>
  
      <input style={styles.inputCheck} name="year_of_end" className="form-check-input" onClick={(e)=>{
        setState({...state, enable_year_of_end: !state.enable_year_of_end})
  
      }} type="checkbox" id="upcomingtaskCheck01" />
  
      </td>
  
      <td width={'50%'}>
  
      <input type="text" disabled={!state.enable_year_of_end} name="year_of_end" className="form-control" placeholder="Year of end" onChange={inputHandler} value={state.year_of_end} />
  
      </td>
  
    </tr>
  
    <tr>
  
      <td width={'20%'}>Final Status *</td>
  
      <td width={'20%'}>{item.final_status}</td>
  
      <td width={'10%'}>
  
      <input style={styles.inputCheck} name="final_status" className="form-check-input" onClick={(e)=>{
  
        setState({...state, enable_final_status: !state.enable_final_status})
  
      }} type="checkbox" id="upcomingtaskCheck01" />
  
      </td>
  
      <td width={'50%'}>
  
      <input type="text" disabled={!state.enable_final_status} name="final_status" className="form-control" placeholder="Final status" onChange={inputHandler} value={state.final_status} />
  
      </td>
  
    </tr>
  
    <tr>
  
      <td width={'20%'}>Upload Documents *</td>
  
      <td width={'20%'}>
  
      <input type="text" placeholder="Description" className="form-control" value={''} />
  
      </td>
  
      <td width={'10%'}>
  
      </td>
  
  
      <td width={'50%'}>
  
      <input type="file" className="form-control" value={''} />
  
      </td>
  
    </tr>
  
  </tbody>
  
  </Table>
  
  <div className="text-center">
        <Button color="primary" size="md" className="my-3" onClick={save}>Save</Button>
        </div>
  
  </div>
        </div>
  
    )
  
  }
  
  const Employment = ({item, onSave}) => {
  
    const [state, setState] = useState({
  
      company_name: "",
      enable_company_name: false,
      designation: "",
      enable_designation: false,
      emp_id: "",
      enable_emp_id: false,
      reason_for_leaving: "",
      enable_reason_for_leaving: false,
      remarks: "",
      enable_remarks: true,
      attachment: "",
      enable_attachment: false,
      doj: "",
      enable_doj: false,
      doe: "",
      enable_doe: false,
      final_status: "",
      enable_final_status: false,
  
    });
  
    const [upload_doc, setUploadDoc] = useState({
      file: null,
      description: "",
    
    });
  
    useEffect(() => {
  
  
      if(component_fields.length > 0){
  
        let obj = {};
  
        component_fields.map((item)=> {
  
          obj[item.field_id] = item.value;
  
        })
  
        setState({
          ...state,
          ...obj
        
        })
  
      }
  
  
    }, []);
  
    const inputHandler = (e) => {
  
      const {name, value} = e.target;
  
      setState({...state, [name]: value})
  
      }
  
      const save = () => {
  
        console.log('state',state);
  
        let obj = {}
  
        Object.keys(state).map((key)=> {
  
          // return only the keys that are enabled
  
          if(state[`enable_${key}`]){
  
            obj[key] = state[key]
  
  
  
          }
          
  
       
  
        })
  
        let upload_obj = null;
  
        if(upload_doc.file){
  
          upload_obj = {
  
            file: upload_doc.file,
            description: upload_doc.description
  
          }
  
        }
  
        onSave(obj, upload_obj);
  
      }
  
  
    return (
      <div>
        <div className="table-responsive">
    
  
  <Table className="table table-striped mb-0" striped  >
  
  <thead >
  
    <tr>
  
      <th style={{width:'20%', fontSize:16, fontWeight:500}}>Employment component details</th>
  
      <th style={{width:'30%', fontSize:16, fontWeight:500}}>Data Shared By Candidate</th>
  
      <th style={{width:'10%', fontSize:16, fontWeight:500}}></th>
  
      <th style={{width:'50%', fontSize:16, fontWeight:500}}>Verified Data</th>
  
    </tr>
  
    </thead>
  
  <tbody >
  
    <tr>
  
      <td width={'20%'}>Company Name *</td>
  
      <td width={'20%'}>{item.company_name}</td>
  
      <td width={'10%'}>
  
      <input  style={styles.inputCheck} checked={state.enable_company_name} className="form-check-input" onChange={(e)=>{
  
        setState({...state, enable_company_name: !state.enable_company_name})
  
      }} type="checkbox"/>
  
      </td>
  
      <td width={'50%'}>
  
      <Input type="text" className="form-control me-3" value={state.company_name} disabled={!state.enable_company_name} onChange={inputHandler} style={styles.boxInput} name="company_name" placeholder="Company name" />
       
      </td>
  
    </tr>
  
    <tr>
  
      <td width={'20%'}>Designation *</td>
  
      <td width={'20%'}>{item.designation}</td>
  
      <td width={'10%'}>
  
      <input style={styles.inputCheck} name="designation" className="form-check-input" onClick={(e)=>{
        setState({...state, enable_designation: !state.enable_designation})
  
      }} type="checkbox" id="upcomingtaskCheck01" />
  
      </td>
  
      <td width={'50%'}>
  
      <input type="text" disabled={!state.enable_designation} name="designation" className="form-control" placeholder="Designation" onChange={inputHandler} value={state.designation} />
  
      </td>
  
    </tr>
  
    <tr>
  
      <td width={'20%'}>Employee ID *</td>
  
      <td width={'20%'}>{item.emp_id}</td>
  
      <td width={'10%'}>
  
      <input style={styles.inputCheck} name="emp_id" className="form-check-input" onClick={(e)=>{
  
        setState({...state, enable_emp_id: !state.enable_emp_id})
  
      }} type="checkbox" id="upcomingtaskCheck01" />
  
      </td>
  
      <td width={'50%'}>
  
      <input type="text" disabled={!state.enable_emp_id} name="emp_id" className="form-control" placeholder="Employee ID" onChange={inputHandler} value={state.emp_id} />
       
      </td>
  
    </tr>
  
    <tr>
  
      <td width={'20%'}>Reason for Leaving *</td>
  
      <td width={'20%'}>{item.reason_for_leaving}</td>
  
      <td width={'10%'}>
  
      <input style={styles.inputCheck} name="reason_for_leaving" className="form-check-input" onClick={(e)=>{
  
        setState({...state, enable_reason_for_leaving: !state.enable_reason_for_leaving})
  
      }} type="checkbox" id="upcomingtaskCheck01" />
  
      </td>
  
      <td width={'50%'}>
  
      <input type="text" disabled={!state.enable_reason_for_leaving} name="reason_for_leaving" className="form-control" placeholder="Reason for leaving" onChange={inputHandler} value={state.reason_for_leaving} />
  
      </td>
  
    </tr>
  
  
  
    <tr>
  
      <td width={'20%'}>Date of Joining *</td>
  
      <td width={'20%'}>{moment(item.doj).format('DD-MM-YYYY')}</td>
  
      <td width={'10%'}>
      <input style={styles.inputCheck} name="doj" className="form-check-input" onClick={(e)=>{
  
        setState({...state, enable_doj: !state.enable_doj})
  
      }} type="checkbox" id="upcomingtaskCheck01" />
  
      </td>
  
      <td width={'50%'}>
  
      <input type="text" disabled={!state.enable_doj} name="doj" className="form-control" placeholder="Date of joining" onChange={inputHandler} value={state.doj} />
       
      </td>
  
    </tr>
  
    <tr>
  
      <td width={'20%'}>Date of End *</td>
  
      <td width={'20%'}>{moment(item.doe).format('DD-MM-YYYY')}</td>
  
      <td width={'10%'}>
  
      <input style={styles.inputCheck} name="doe" className="form-check-input" onClick={(e)=>{
  
        setState({...state, enable_doe: !state.enable_doe})
  
      }} type="checkbox" id="upcomingtaskCheck01" />
  
      </td>
  
      <td width={'50%'}>
  
  
      <input type="text" disabled={!state.enable_doe} name="doe" className="form-control" placeholder="Date of end" onChange={inputHandler} value={state.doe} />
  
      </td>
  
    </tr>
  
  { item.tds && <tr>
  
      <td width={'20%'}>TDS</td>
  
      <td width={'20%'}>
  
        <button className="btn btn-primary btn-sm"
        onClick={()=> {
          window.open(`${process.env.REACT_APP_URL}/admin/view-tds/${caseData.org_client_id}/${caseData.walletID}`, '_blank');
        
        }}
        >View</button>
      </td>
  
      <td width={'10%'}>
  
  
  
      </td>
  
      <td width={'50%'}>
  
  
      <input type="text"  name="doe" className="form-control" placeholder="Remarks" onChange={inputHandler} value={state.doe} />
  
      </td>
  
    </tr>}
  
    { item?.epfo && <tr>
  
  <td width={'20%'}>EPFO</td>
  
  <td width={'20%'}>
  
    <button
         onClick={()=> {
          window.open(`${process.env.REACT_APP_URL}/admin/view-epfo/${caseData.org_client_id}/${caseData.walletID}`, '_blank');
        
        }}
    className="btn btn-primary btn-sm">View</button>
  </td>
  
  <td width={'10%'}>
  
  
  
  </td>
  
  <td width={'50%'}>
  
  
  <input type="text"  name="remarks" className="form-control" placeholder="Remarks" onChange={inputHandler} value={state.remarks} />
  
  </td>
  
  </tr>}
  
    <tr>
  
  <td>Upload Documents *</td>
  
  <td
  >
  <input type="text" onChange={
    (e)=> {
        
    
        setUploadDoc({...upload_doc, description: e.target.value})
    
    }
  } placeholder="Description" className="form-control" value={upload_doc.description} />
  
    </td>
  
  <td>
  
  </td>
  
  <td >
  <input type="file"  className="form-control" 
  
  onChange={
    (e)=> {
  
      console.log('file',e.target.files[0]);
  
      setUploadDoc({...upload_doc, file: e.target.files[0]})
  
    }
  }
  />
  
  </td>
  
  </tr>
  
  
  </tbody>
  
  
  </Table>
  
  <div className="text-center">
        <Button color="primary" size="md" className="my-3" onClick={save}>Save</Button>
        </div>
  
          </div>
      </div>
    )
  
  }
  


  const Pan = ({item, onSave}) => {

    const [state, setState] = useState({
 
     pan_number: "",
     enable_pan_number: false,
     full_name: "",
     enable_full_name: false,
     verified_data: "",
     verified_on: "",
     disposition_code: "",
     disposition_remarks: "",
     uploaded_document: ""
    });
 
    const [upload_doc, setUploadDoc] = useState({
     file: null,
     description: "",
   
   });
 
    const inputHandler = (e) => {
 
     const {name, value} = e.target;
 
     setState({...state, [name]: value})
 
    }
 
    useEffect(() => {
 
 
     if(component_fields.length > 0){
 
       let obj = {};
 
       component_fields.map((item)=> {
 
         obj[item.field_id] = item.value;
 
       })
 
       setState({
         ...state,
         ...obj
       
       })
 
     }
 
 
   }, []);
 
   
 
   const save = () => {
 
     console.log('state',state);
 
     let obj = {}
 
     Object.keys(state).map((key)=> {
 
       // return only the keys that are enabled
 
       if(state[`enable_${key}`]){
 
         obj[key] = state[key]
 
 
 
       }
       
 
    
 
     })
 
     let upload_obj = null;
 
     if(upload_doc.file){
 
       upload_obj = {
 
         file: upload_doc.file,
         description: upload_doc.description
 
       }
 
     }
 
     onSave(obj, upload_obj);
   }
 
     return (
      
       <div className="p-2">
 
       {/* <p style={{fontSize:16, fontWeight:500}}>Pan Card Component Details</p> */}
 
       <div className="table-responsive">
       <Table className="table table-striped mb-0" striped  >
 
         <thead >
 
           <tr>
             <th style={{width:'20%', fontSize:16, fontWeight:500}}>Pan Card component details</th>
             <th style={{width:'30%', fontSize:16, fontWeight:500}}>Data Shared By Candidate</th>
             <th style={{width:'10%', fontSize:16, fontWeight:500}}></th>
 
             <th style={{width:'50%', fontSize:16, fontWeight:500}}>Verified Data</th>
 
           </tr>
 
           </thead>
   
         <tbody >
           <tr>
     
             <td width={'20%'}>PAN Card Number *</td>
             <td width={'20%'}>{item.pan_number}</td>
 
             <td width={'10%'}>
             <input  style={styles.inputCheck} checked={state.enable_pan_number} className="form-check-input" onChange={(e)=>{
                                          
                                          setState({...state, enable_pan_number: !state.enable_pan_number})
 
 
                                         }} type="checkbox" id="pan_number" />
             </td>
           
             <td width={'50%'}>
             <Input
                                     type="text"
                                     className="form-control me-3"
                                     value={state.pan_number}
                                     disabled={!state.enable_pan_number}
                                    onChange={inputHandler}
                                     style={styles.boxInput}
                                     name="pan_number"
                                     placeholder="Pan number"
                                 />
             </td>
           </tr>  
 
    
           <tr>
     
     <td width={'20%'}>Full Name *</td>
     <td width={'20%'}>{item.full_name}</td>
 
     <td width={'10%'}>
     <input style={styles.inputCheck} name="full_name" className="form-check-input" onClick={(e)=>{
 
       setState({...state, enable_full_name: !state.enable_full_name})
                                  
                                 }} type="checkbox" id="upcomingtaskCheck01" />
     </td>
   
     <td width={'50%'}>
       <input type="text" disabled={!state.enable_full_name} name="full_name"
  className="form-control" placeholder="Full name" onChange={inputHandler} value={state.full_name} />
     </td>
   </tr> 
 
 <tr>
     
     <td width={'20%'}>PAN Card Verification Status *</td>
     <td width={'20%'}>Verified</td>
 
     <td width={'10%'}>
     {/* <input style={styles.inputCheck} className="form-check-input" onClick={(e)=>{
                                  
                                 }} type="checkbox" id="upcomingtaskCheck01" /> */}
     </td>
   
     <td width={'50%'}>
       {/* <input type="text" className="form-control" value={''} /> */}
     </td>
   </tr> 
 
 
 
   <tr>
     
     <td width={'20%'}>Verified On *</td>
     <td width={'20%'}>{moment(item.timestamp).format('DD-MM-YYYY')}</td>
 
     <td width={'10%'}>
     {/* <input style={styles.inputCheck}  className="form-check-input" onClick={(e)=>{
                                  
                                 }} type="checkbox" id="upcomingtaskCheck01" /> */}
     </td>
   
     <td width={'50%'}>
       {/* <input type="text" disabled className="form-control" value={''} /> */}
     </td>
   </tr> 
 
      
 
 <tr>
     
     <td width={'20%'}>Disposition Code *</td>
     <td width={'20%'}></td>
 
     <td width={'10%'}>
     {/* <input style={styles.inputCheck} className="form-check-input" onClick={(e)=>{
                                  
                                 }} type="checkbox" id="upcomingtaskCheck01" /> */}
     </td>
   
     <td width={'50%'}>
       {/* <input type="text" disabled className="form-control" value={''} /> */}
     </td>
   </tr> 
 
 
   <tr>
     
     <td width={'20%'}>Disposition Remarks *</td>
     <td width={'20%'}></td>
 
     <td width={'10%'}>
     {/* <input style={styles.inputCheck} disabled className="form-check-input" onClick={(e)=>{
                                  
                                 }} type="checkbox" id="upcomingtaskCheck01" /> */}
     </td>
   
     <td width={'50%'}>
       {/* <input type="text" disabled className="form-control" value={''} /> */}
     </td>
   </tr> 
 
 
 <tr>
 
 <td>Upload Documents *</td>
 
 <td
 >
 <input type="text" onChange={
   (e)=> {
       
   
       setUploadDoc({...upload_doc, description: e.target.value})
   
   }
 } placeholder="Description" className="form-control" value={upload_doc.description} />
 
   </td>
 
 <td>
 
 </td>
 
 <td >
 <input type="file" className="form-control" onChange={
   (e)=> {
 
     console.log('file',e.target.files[0]);
 
     setUploadDoc({...upload_doc, file: e.target.files[0]})
 
   }
 } />
 
 </td>
 
 </tr>
 
           
      
       
         </tbody>
       </Table>
 
       <div className="text-center">
       <Button color="primary" size="md" className="my-3" onClick={save}>Save</Button>
       </div>
     </div>
         </div>
     )
   }
 
   const VoterId = ({item, onSave}) => {

    const [state, setState] = useState({

      epic_no: "",
      enable_epic_no: false,
      full_name: "",
      enable_full_name: false,
      gender: "",
      enable_gender: false,
      area: "",
      enable_area: false,

    });

    useEffect(() => {


      if(component_fields.length > 0){

        let obj = {};

        component_fields.map((item)=> {

          obj[item.field_id] = item.value;

        })

        setState({
          ...state,
          ...obj
        
        })

      }


    }, []);

    const inputHandler = (e) => {

      const {name, value} = e.target;
  
      setState({...state, [name]: value})
  
     }

     const save = () => {

      console.log('state',state);

      let obj = {}

      Object.keys(state).map((key)=> {

        // return only the keys that are enabled

        if(state[`enable_${key}`]){

          obj[key] = state[key]

        }
        
      })



      onSave(obj);

    }

    return (
      <div>
        

      
      <div className="table-responsive">
      <Table className="table table-striped mb-0" striped  >

        <thead >

          <tr>
            <th style={{width:'20%', fontSize:16, fontWeight:500}}>Voter ID component details</th>
            <th style={{width:'30%', fontSize:16, fontWeight:500}}>Data Shared By Candidate</th>
            <th style={{width:'10%', fontSize:16, fontWeight:500}}></th>

            <th style={{width:'50%', fontSize:16, fontWeight:500}}>Verified Data</th>

          </tr>

          </thead>
  
        <tbody >
          <tr>
    
            <td width={'20%'}>Epic Number *</td>
            <td width={'20%'}>{item.epic_no}</td>

            <td width={'10%'}>
            <input  style={styles.inputCheck} checked={state.enable_epic_no} className="form-check-input" onChange={(e)=>{
                                         
                                         setState({...state, enable_epic_no: !state.enable_epic_no})


                                        }} type="checkbox"/>
            </td>
          
            <td width={'50%'}>
            <Input
                                    type="text"
                                    className="form-control me-3"
                                    value={state.epic_no}
                                    disabled={!state.enable_epic_no}
                                   onChange={inputHandler}
                                    style={styles.boxInput}
                                    name="epic_no"
                                    placeholder="Epic number"
                                />
            </td>
          </tr>  

   
          <tr>
    
    <td width={'20%'}>Full Name *</td>
    <td width={'20%'}>{item.name}</td>

    <td width={'10%'}>
    <input style={styles.inputCheck} name="full_name" className="form-check-input" onClick={(e)=>{

      setState({...state, enable_full_name: !state.enable_full_name})
                                 
                                }} type="checkbox" id="upcomingtaskCheck01" />
    </td>
  
    <td width={'50%'}>
      <input type="text" disabled={!state.enable_full_name} name="full_name"
 className="form-control" placeholder="Full name" onChange={inputHandler} value={state.full_name} />
    </td>
  </tr> 

<tr>
    
    <td width={'20%'}>Gender *</td>
    <td width={'20%'}>{item.gender}</td>

    <td width={'10%'}>
    <input style={styles.inputCheck} name="gender" className="form-check-input" onClick={(e)=>{

setState({...state, enable_gender: !state.enable_gender})
                           
                          }} type="checkbox" id="upcomingtaskCheck01" />
    </td>
  
    <td width={'50%'}>
    <input type="text" disabled={!state.enable_gender} name="gender"
 className="form-control" placeholder="Gender" onChange={inputHandler} value={state.gender} />
    </td>
  </tr> 

  <tr>
    
    <td width={'20%'}>Area *</td>
    <td width={'20%'}>{item.area}</td>

    <td width={'10%'}>
    <input style={styles.inputCheck} name="area" className="form-check-input" onClick={(e)=>{

setState({...state, enable_area: !state.enable_area})
                           
                          }} type="checkbox" id="upcomingtaskCheck01" />
    </td>
  
    <td width={'50%'}>
    <input type="text" disabled={!state.enable_area} name="area"
 className="form-control" placeholder="area" onChange={inputHandler} value={state.area} />
    </td>
  </tr> 

  <tr>
    
    <td width={'20%'}>Verified On *</td>
    <td width={'20%'}>{moment(item.timestamp).format('DD-MM-YYYY')}</td>

    <td width={'10%'}>
    {/* <input style={styles.inputCheck}  className="form-check-input" onClick={(e)=>{
                                 
                                }} type="checkbox" id="upcomingtaskCheck01" /> */}
    </td>
  
    <td width={'50%'}>
      {/* <input type="text" disabled className="form-control" value={''} /> */}
    </td>
  </tr> 

     

<tr>
    
    <td width={'20%'}>Disposition Code *</td>
    <td width={'20%'}></td>

    <td width={'10%'}>
    {/* <input style={styles.inputCheck} className="form-check-input" onClick={(e)=>{
                                 
                                }} type="checkbox" id="upcomingtaskCheck01" /> */}
    </td>
  
    <td width={'50%'}>
      {/* <input type="text" disabled className="form-control" value={''} /> */}
    </td>
  </tr> 


  <tr>
    
    <td width={'20%'}>Disposition Remarks *</td>
    <td width={'20%'}></td>

    <td width={'10%'}>
    {/* <input style={styles.inputCheck} disabled className="form-check-input" onClick={(e)=>{
                                 
                                }} type="checkbox" id="upcomingtaskCheck01" /> */}
    </td>
  
    <td width={'50%'}>
      {/* <input type="text" disabled className="form-control" value={''} /> */}
    </td>
  </tr> 


<tr>

<td>Upload Documents *</td>

<td
>
<input type="text" placeholder="Description" className="form-control" value={''} />

  </td>

<td>

</td>

<td >
<input type="file" className="form-control" value={''} />

</td>

</tr>

          
     
      
        </tbody>
      </Table>

      <div className="text-center">
      <Button color="primary" size="md" className="my-3" onClick={save}>Save</Button>
      </div>
    </div>
              </div>
    )
  }

  const DL = ({item, onSave}) => {

    const [state, setState] = useState({
      license_number: "",
      enable_license_number: false,
      name: "",
      enable_name: false,
      dob: "",
      enable_dob: false,
      gender: "",
      enable_gender: false, 
      permanent_address: "",
      enable_permanent_address: false,
    

    
    });

    const [upload_doc, setUploadDoc] = useState({
      file: null,
      description: "",
    
    });

    useEffect(() => {


      if(component_fields.length > 0){

        let obj = {};

        component_fields.map((item)=> {

          obj[item.field_id] = item.value;

        })

        setState({
          ...state,
          ...obj
        
        })

      }


    }, []);

    const inputHandler = (e) => {

      const {name, value} = e.target;
  
      setState({...state, [name]: value})
  
     }

      const save = () => {

        console.log('state',state);
    
        let obj = {}
    
        Object.keys(state).map((key)=> {
    
          // return only the keys that are enabled
    
          if(state[`enable_${key}`]){
    
            obj[key] = state[key]
    
    
    
          }
          
    
       
    
        })
    
        let upload_obj = null;
    
        if(upload_doc.file){
    
          upload_obj = {
    
            file: upload_doc.file,
            description: upload_doc.description
    
          }
    
        }
    
        onSave(obj, upload_obj);
      }
    

    return (
      <div>
        
  
      
      <div className="table-responsive">
      <Table className="table table-striped mb-0" striped  >

        <thead >

          <tr>
            <th style={{width:'20%', fontSize:16, fontWeight:500}}>Driving License component details</th>
            <th style={{width:'30%', fontSize:16, fontWeight:500}}>Data Shared By Candidate</th>
            <th style={{width:'10%', fontSize:16, fontWeight:500}}></th>

            <th style={{width:'50%', fontSize:16, fontWeight:500}}>Verified Data</th>

          </tr>

          </thead>

        <tbody >

          <tr>

            <td width={'20%'}>License Number *</td>

            <td width={'20%'}>{item.license_number}</td>

            <td width={'10%'}>

            <input  style={styles.inputCheck} checked={state.enable_license_number} className="form-check-input" onChange={(e)=>{

              setState({...state, enable_license_number: !state.enable_license_number})

            }} type="checkbox"/>

            </td>

            <td width={'50%'}>

            <Input type="text" className="form-control me-3" value={state.license_number} disabled={!state.enable_license_number} onChange={(e)=>{
              setState({...state, license_number: e.target.value})
            }} style={styles.boxInput} name="license_number" placeholder="License number" />

            </td>

          </tr>

          <tr>

            <td width={'20%'}>Name *</td>
             
            <td width={'20%'}>{item.name}</td>

            <td width={'10%'}>

            <input style={styles.inputCheck} name="name" className="form-check-input" onClick={(e)=>{
              setState({...state, enable_name: !state.enable_name})
            }} type="checkbox" id="upcomingtaskCheck01" />

            </td>

            <td width={'50%'}>

            <input type="text" disabled={!state.enable_name} name="name" className="form-control" placeholder="Name" onChange={(e)=>{

              setState({...state, name: e.target.value})

            }} value={state.name} />

            </td>

          </tr>

          <tr>

            <td width={'20%'}>DOB *</td>

            <td width={'20%'}>{item.dob}</td>

            <td width={'10%'}>

            <input style={styles.inputCheck} name="dob" className="form-check-input" onClick={(e)=>{

              setState({...state, enable_dob: !state.enable_dob})

            }} type="checkbox" id="upcomingtaskCheck01" />

            </td>

            <td width={'50%'}>

            <input type="text" disabled={!state.enable_dob} name="dob" className="form-control" placeholder="DOB" onChange={(e)=>{

              setState({...state, dob: e.target.value})

            }} value={state.dob} />

            </td>

          </tr>

          <tr>

<td width={'20%'}>Address *</td>

<td width={'20%'}>{item.permanent_address}</td>

<td width={'10%'}>

<input style={styles.inputCheck} name="permanent_address" className="form-check-input" onClick={(e)=>{

setState({...state, enable_permanent_address: !state.enable_permanent_address})

}} type="checkbox" id="upcomingtaskCheck01" />






</td>

<td width={'50%'}>

<input type="text" disabled={!state.enable_permanent_address} name="permanent_address" className="form-control" placeholder="Address" onChange={(e)=>{
setState({...state, permanent_address: e.target.value})
}} value={state.permanent_address} />


</td>

</tr>

<tr>

<td>Upload Documents *</td>

<td
>
<input type="text" onChange={
  (e)=> {
      
  
      setUploadDoc({...upload_doc, description: e.target.value})
  
  }
} placeholder="Description" className="form-control" value={upload_doc.description} />

  </td>

<td>

</td>

<td >
<input type="file" className="form-control" onChange={
  (e)=> {

    console.log('file',e.target.files[0]);

    setUploadDoc({...upload_doc, file: e.target.files[0]})

  }
} />

</td>

</tr>

</tbody>



        </Table>

        <div className="text-center">
      <Button color="primary" size="md" className="my-3" onClick={save}>Save</Button>
      </div>
        </div>

              </div>
    )
  }

  const Nurse = ({item, onSave}) => {

    const [state, setState] = useState({

      nurse_id: "",
      enable_nurse_id: false,
      nurse_name: "",
      enable_nurse_name: false,
      registered_as: "",
      enable_registered_as: false,
      status: "",
      enable_status: false,
      state_council: "",
      enable_state_council: false,
      verified_data: "",
      verified_on: "",
    });

    const [upload_doc, setUploadDoc] = useState({
      file: null,
      description: "",
    
    });
  

    const inputHandler = (e) => {

      const {name, value} = e.target;

      setState({...state, [name]: value})

      }

      const save = () => {

        console.log('state',state);
    
        let obj = {}
    
        Object.keys(state).map((key)=> {
    
          // return only the keys that are enabled
    
          if(state[`enable_${key}`]){
    
            obj[key] = state[key]
    
    
    
          }
          
    
       
    
        })
    
        let upload_obj = null;
    
        if(upload_doc.file){
    
          upload_obj = {
    
            file: upload_doc.file,
            description: upload_doc.description
    
          }
    
        }
    
        onSave(obj, upload_obj);
      }
    

      useEffect(() => {


        if(component_fields.length > 0){
  
          let obj = {};
  
          component_fields.map((item)=> {
  
            obj[item.field_id] = item.value;
  
          })
  
          setState({
            ...state,
            ...obj
          
          })
  
        }
  
  
      }, []);


    return (
      <div>
        
      {/* <Table className="table mb-0">
        <thead className="table-dark">
          <tr>
            <th style={{width:'50%'}}>Verification Component</th>
            <th style={{width:'50%'}}>Details Provided By The Candidate</th>
      
      
          </tr>
      
        </thead>
        <tbody>

        <tr>
            <td className="table-light">Id Number</td>
            <td className="table-light">{item?.id_number}</td>
      
          </tr>
      
         <tr>
            <td className="table-light">Candidate Name</td>
            <td className="table-light">{item?.nurse_name}</td>
      
          </tr>
      
          <tr>
            <td className="table-light">Registered As</td>
            <td className="table-light">{item?.registered_as}</td>
      
          </tr>
      
      
          <tr>
            <td className="table-light">Status</td>
            <td className="table-light">{item?.status}</td>
      
          </tr>

          <tr>
            <td className="table-light">State Council</td>
            <td className="table-light">{item?.state_council}</td>
      
          </tr>
      
          <tr>
            <td className="table-light">Verified On</td>
            <td className="table-light">{moment(item.timestamp).format('DD-MM-YYYY')}</td>
      
          </tr>

          <tr>
            <td className="table-light">Attachment (Candidate)</td>
            <td className="table-light">
              <Link to={`${item?.uploaded_document}`} target="_blank">
              Click here
              </Link>
            </td>
      
          </tr>
          <tr>
            <td className="table-light">Attachment (By TheRollNumber)</td>
            <td className="table-light">
            </td>
      
          </tr>
      
      
        </tbody>
      </Table> */}

      <div className="table-responsive">
      <Table className="table table-striped mb-0" striped  >

        <thead >

          <tr>
            <th style={{width:'20%', fontSize:16, fontWeight:500}}>Nurse component details</th>
            <th style={{width:'30%', fontSize:16, fontWeight:500}}>Data Shared By Candidate</th>
            <th style={{width:'10%', fontSize:16, fontWeight:500}}></th>

            <th style={{width:'50%', fontSize:16, fontWeight:500}}>Verified Data</th>

          </tr>

          </thead>

        <tbody >

          <tr>

            <td width={'20%'}>Nurse ID *</td>
            <td width={'20%'}>{item.nurse_id}</td>

            <td width={'10%'}>

            <input  style={styles.inputCheck} checked={state.enable_nurse_id} className="form-check-input" onChange={(e)=>{

              setState({...state, enable_nurse_id: !state.enable_nurse_id})

            }} type="checkbox"/>

            </td>

            <td width={'50%'}>

            <Input
                                    type="text"
                                    className="form-control me-3"
                                    value={state.nurse_id}
                                    disabled={!state.enable_nurse_id}
                                   onChange={inputHandler}
                                    style={styles.boxInput}
                                    name="nurse_id"
                                    placeholder="Nurse ID"
                                />

            </td>

          </tr>

          <tr>

            <td width={'20%'}>Nurse Name *</td>

            <td width={'20%'}>{item.nurse_name}</td>

            <td width={'10%'}>

            <input style={styles.inputCheck} name="nurse_name" className="form-check-input" onClick={(e)=>{

              setState({...state, enable_nurse_name: !state.enable_nurse_name})

            }} type="checkbox" id="upcomingtaskCheck01" />

            </td>

            <td width={'50%'}>

            <input type="text" disabled={!state.enable_nurse_name} name="nurse_name"

            className="form-control" placeholder="Nurse name" onChange={inputHandler} value={state.nurse_name} />

            </td>

          </tr>

          <tr>

            <td width={'20%'}>Registered As *</td>

            <td width={'20%'}>{item.registered_as}</td>

            <td width={'10%'}>

            <input style={styles.inputCheck} name="registered_as" className="form-check-input" onClick={(e)=>{


              setState({...state, enable_registered_as: !state.enable_registered_as})

            }} type="checkbox" id="upcomingtaskCheck01" />

            </td>

            <td width={'50%'}>


            <input type="text" disabled={!state.enable_registered_as} name="registered_as"

            className="form-control" placeholder="Registered as" onChange={inputHandler} value={state.registered_as} />

            </td>

          </tr>

          <tr>

            <td width={'20%'}>Status *</td>

            <td width={'20%'}>{item.status}</td>

            <td width={'10%'}>

            <input style={styles.inputCheck} name="status" className="form-check-input" onClick={(e)=>{


              setState({...state, enable_status: !state.enable_status})

            }} type="checkbox" id="upcomingtaskCheck01" />

            </td>

            <td width={'50%'}>

            <input type="text" disabled={!state.enable_status} name="status"

            className="form-control" placeholder="Status" onChange={inputHandler} value={state.status} />

            </td>

          </tr>

          <tr>

            <td width={'20%'}>State Council *</td>

            <td width={'20%'}>{item.state_council}</td>

            <td width={'10%'}>

            <input style={styles.inputCheck} name="state_council" className="form-check-input" onClick={(e)=>{

     
              setState({...state, enable_state_council: !state.enable_state_council})

            }} type="checkbox" id="upcomingtaskCheck01" />

            </td>

            <td width={'50%'}>

            <input type="text" disabled={!state.enable_state_council} name="state_council"

            className="form-control" placeholder="State council" onChange={inputHandler} value={state.state_council} />

            </td>

          </tr>


          <tr>

            <td width={'20%'}>Verified On *</td>

            <td width={'20%'}>{moment(item.timestamp).format('DD-MM-YYYY')}</td>

            <td width={'10%'}>

            {/* <input style={styles.inputCheck}  className="form-check-input" onClick={(e)=>{

            }} type="checkbox" id="upcomingtaskCheck01" /> */}

            </td>

            <td width={'50%'}>

            {/* <input type="text" disabled className="form-control" value={''} /> */}

            </td>

          </tr>

          <tr>

            <td width={'20%'}>Disposition Code *</td>

            <td width={'20%'}></td>

            <td width={'10%'}>

            {/* <input style={styles.inputCheck} className="form-check-input" onClick={(e)=>{

            }} type="checkbox" id="upcomingtaskCheck01" /> */}

            </td>

            <td width={'50%'}>

            {/* <input type="text" disabled className="form-control" value={''} /> */}

            </td>

          </tr>

          <tr>

            <td width={'20%'}>Disposition Remarks *</td>

            <td width={'20%'}></td>

            <td width={'10%'}>

            {/* <input style={styles.inputCheck} disabled className="form-check-input" onClick={(e)=>{

            }} type="checkbox" id="upcomingtaskCheck01" /> */}

            </td>

            <td width={'50%'}>

            {/* <input type="text" disabled className="form-control" value={''} /> */}

            </td>

          </tr>

          <tr>

            <td width={'20%'}>Upload Documents *</td>

            <td width={'20%'}>

            <input type="text" onChange={
  (e)=> {
      
  
      setUploadDoc({...upload_doc, description: e.target.value})
  
  }
} placeholder="Description" className="form-control" value={upload_doc.description} />

            </td>

            <td width={'10%'}>

            </td>

            <td width={'50%'}>

            <input type="file" className="form-control" onChange={
  (e)=> {

    console.log('file',e.target.files[0]);

    setUploadDoc({...upload_doc, file: e.target.files[0]})

  }
} />
            </td>

          </tr>

        </tbody>

        </Table>

        <div className="text-center">
      <Button color="primary" size="md" className="my-3" onClick={save}>Save</Button>
      </div>
        </div>
 
              </div>
    )
  }

  const Doctor = ({item, onSave}) => {

    const [upload_doc, setUploadDoc] = useState({
      file: null,
      description: "",
    
    });
  
    
  const save = () => {

    console.log('state',state);


    let upload_obj = null;

    if(upload_doc.file){

      upload_obj = {

        file: upload_doc.file,
        description: upload_doc.description

      }

    }

    onSave(upload_obj);
  }

    return (
      <div>
        
      <Table className="table mb-0">
        <thead className="table-dark">
          <tr>
            <th style={{width:'50%'}}>Verification Component</th>
            <th style={{width:'50%'}}>Details Provided By The Candidate</th>
      
      
          </tr>
      
        </thead>
        <tbody>

        <tr>
            <td className="table-light">Doctor ID</td>
            <td className="table-light">{item?.doctor_id}</td>
      
          </tr>
      
         <tr>
            <td className="table-light">Candidate Name</td>
            <td className="table-light">{item?.full_name}</td>
      
          </tr>
      
          <tr>
            <td className="table-light">Parent Name</td>
            <td className="table-light">{item?.parent_name}</td>
      
          </tr>
      
      
          <tr>
            <td className="table-light">Registration Number</td>
            <td className="table-light">{item?.registration_number}</td>
      
          </tr>

          <tr>
            <td className="table-light">State Medical Council</td>
            <td className="table-light">{item?.state_medical_council}</td>
      
          </tr>

          <tr>
            <td className="table-light">Qualification</td>
            <td className="table-light">{item?.qualification}</td>
      
          </tr>
      
          <tr>
            <td className="table-light">Registration Date</td>
            <td className="table-light">{item?.registration_date}</td>
      
          </tr>
          <tr>
            <td className="table-light">Verified On</td>
            <td className="table-light">{moment(item.timestamp).format('DD-MM-YYYY')}</td>
      
          </tr>

          <tr>
            <td className="table-light">Attachment (Candidate)</td>
            <td className="table-light">
              <Link to={`${item?.uploaded_document}`} target="_blank">
              Click here
              </Link>
            </td>
      
          </tr>
          <tr>
            <td className="table-light">Attachment (By TheRollNumber)</td>
            <td className="table-light">
              {/* <Link to={`${item?.uploaded_document}`} target="_blank">
              Click here
              </Link> */}
            </td>
      
          </tr>
          <tr>

<td>Upload Documents *</td>

<td
>
<input type="text" onChange={
  (e)=> {
      
  
      setUploadDoc({...upload_doc, description: e.target.value})
  
  }
} placeholder="Description" className="form-control" value={upload_doc.description} />

  </td>

<td>

</td>

<td >
<input type="file" className="form-control" onChange={
  (e)=> {

    console.log('file',e.target.files[0]);

    setUploadDoc({...upload_doc, file: e.target.files[0]})

  }
} />

</td>

</tr>
      
        </tbody>
      </Table>
      
      <Table className="table mb-0 mt-3">
      <thead className="table-dark">
          <tr>
            <th className="text-center" style={{width:'100%'}}>Comment</th>
            
      
      
          </tr>
      
        </thead>
        <tbody>
          <tr>
            <td className="table-light" style={{height:100}}></td>
       
      
          </tr>
          </tbody>
        </Table>
              </div>
    )
  }


  const CA = ({item, onSave}) => {


    const [state, setState] = useState({
        
        membership_no: "",
        enable_membership_no: false,
        member_name: "",
        enable_member_name: false,
        gender: "",
        enable_gender: false,
        professional_address: "",
        enable_professional_address: false,
        member_status: "",
        enable_member_status: false,
        cop_status: "",
        enable_cop_status: false,
        associate_or_fellow: "",
        enable_associate_or_fellow: false,
        uploaded_document: "",
        enable_uploaded_document: false,
    



    });


    const inputHandler = (e) => {

      const {name, value} = e.target;

      setState({...state, [name]: value})

      }

      useEffect(() => {


        if(component_fields.length > 0){
    
          let obj = {};
    
          component_fields.map((item)=> {
    
            obj[item.field_id] = item.value;
    
          })
    
          setState({
            ...state,
            ...obj
          
          })
    
        }
    
    
      }, []);
    
      const save = () => {
    
        console.log('state',state);
    
        let obj = {}
    
        Object.keys(state).map((key)=> {
    
          // return only the keys that are enabled
    
          if(state[`enable_${key}`]){
    
            obj[key] = state[key]
    
    
    
          }
          
    
       
    
        })
    
    
    
        onSave(obj);
    
      }


    return (
      <div>
        
     

        <div className="table-responsive">
      <Table className="table table-striped mb-0" striped  >

        <thead >

          <tr>

            <th style={{width:'20%', fontSize:16, fontWeight:500}}>CA component details</th>

            <th style={{width:'30%', fontSize:16, fontWeight:500}}>Data Shared By Candidate</th>

            <th style={{width:'10%', fontSize:16, fontWeight:500}}></th>

            <th style={{width:'50%', fontSize:16, fontWeight:500}}>Verified Data</th>

          </tr>

          </thead>

        <tbody >

          <tr>

            <td width={'20%'}>Membership Number *</td>

            <td width={'20%'}>{item.membership_no}</td>

            <td width={'10%'}>

            <input  style={styles.inputCheck} checked={state.enable_membership_no} className="form-check-input" onChange={(e)=>{

              setState({...state, enable_membership_no: !state.enable_membership_no})

            }} type="checkbox"/>

            </td>

            <td width={'50%'}>

            <Input

            type="text"

            className="form-control me-3"

            value={state.membership_no}

            disabled={!state.enable_membership_no}

            onChange={inputHandler}

            style={styles.boxInput}

            name="membership_no"

            placeholder="Membership number"

            />

            </td>

          </tr>

          <tr>

            <td width={'20%'}>Member Name *</td>

            <td width={'20%'}>{item.member_name}</td>

            <td width={'10%'}>

            <input style={styles.inputCheck} name="member_name" className="form-check-input" onClick={(e)=>{

              setState({...state, enable_member_name: !state.enable_member_name})

            }} type="checkbox" id="upcomingtaskCheck01" />

            </td>

            <td width={'50%'}>

            <input type="text" disabled={!state.enable_member_name} name="member_name"

            className="form-control" placeholder="Member name" onChange={inputHandler} value={state.member_name} />

            </td>

          </tr>

          <tr>

            <td width={'20%'}>Gender *</td>

            <td width={'20%'}>{item.gender}</td>

            <td width={'10%'}>

            <input style={styles.inputCheck} name="member_name" className="form-check-input" onClick={(e)=>{

              setState({...state, enable_gender: !state.enable_gender})

            }} type="checkbox" id="upcomingtaskCheck01" />

            </td>

            <td width={'50%'}>

            <input type="text" disabled={!state.enable_gender} name="gender"

            className="form-control" placeholder="Gender" onChange={inputHandler} value={state.gender} />

            </td>

          </tr>

          <tr>

            <td width={'20%'}>Professional Address *</td>

            <td width={'20%'}>{item.professional_address}</td>

            <td width={'10%'}>


            <input style={styles.inputCheck} name="professional_address" className="form-check-input" onClick={(e)=>{
              setState({...state, enable_professional_address: !state.enable_professional_address})

            }} type="checkbox" id="upcomingtaskCheck01" />

            </td>

            <td width={'50%'}>

            <input type="text" disabled={!state.enable_professional_address} name="professional_address"

            className="form-control" placeholder="Professional address" onChange={inputHandler} value={state.professional_address} />

            </td>

          </tr>

          <tr>

            <td width={'20%'}>Member Status *</td>

            <td width={'20%'}>{item.member_status}</td>

            <td width={'10%'}>

            <input style={styles.inputCheck} name="member_status" className="form-check-input" onClick={(e)=>{

              setState({...state, enable_member_status: !state.enable_member_status})

            }} type="checkbox" id="upcomingtaskCheck01" />

            </td>

            <td width={'50%'}>

            <input type="text" disabled={!state.enable_member_status} name="member_status"

            className="form-control" placeholder="Member status" onChange={inputHandler} value={state.member_status} />


            </td>

          </tr>

          <tr>

            <td width={'20%'}>COP Status *</td>

            <td width={'20%'}>{item.cop_status}</td>

            <td width={'10%'}>

            <input style={styles.inputCheck} name="cop_status" className="form-check-input" onClick={(e)=>{

              setState({...state, enable_cop_status: !state.enable_cop_status})

            }} type="checkbox" id="upcomingtaskCheck01" />

            </td>

            <td width={'50%'}>

            <input type="text" disabled={!state.enable_cop_status} name="cop_status"

            className="form-control" placeholder="COP status" onChange={inputHandler} value={state.cop_status} />

            </td>

          </tr>

          <tr>

            <td width={'20%'}>Associate Or Fellow *</td>

            <td width={'20%'}>{item.associate_or_fellow}</td>

            <td width={'10%'}>

            <input style={styles.inputCheck} name="associate_or_fellow" className="form-check-input" onClick={(e)=>{

              setState({...state, enable_associate_or_fellow: !state.enable_associate_or_fellow})

            }} type="checkbox" id="upcomingtaskCheck01" />

            </td>

            <td width={'50%'}>

            <input type="text" disabled={!state.enable_associate_or_fellow} name="associate_or_fellow"


            className="form-control" placeholder="Associate or fellow" onChange={inputHandler} value={state.associate_or_fellow} />

            </td>

          </tr>

          <tr>

            <td width={'20%'}>Uploaded Document *</td>

            <td width={'20%'}>

            <input type="text" placeholder="Description" className="form-control" value={''} />

            </td>

            <td width={'10%'}>

            </td>

            <td width={'50%'}>

            <input type="file" className="form-control" value={''} />

            </td>

          </tr>


</tbody>



          

        </Table>
        <div className="text-center">
      <Button color="primary" size="md" className="my-3" onClick={save}>Save</Button>
      </div>
        </div>

              </div>
    )
  }

  const renderData = ()=> {


    console.log('doc==>',requestedData, component_to_verify);

if(Object.keys(requestedData).length > 0 && component_to_verify !== null){



    if (component_to_verify == 'aadhaar') {


      const item = requestedData['aadhaar'];

      return (
       <Aadhaar item={item}/>
      )
     
    }

    if (component_to_verify == 'pan') {


      const item = requestedData['pan'];

      console.log('pan item==>',item);

      return (
       <Pan item={item}/>
      )
     
    }

    if (component_to_verify == 'voter_id') {
        
  
        const item = requestedData['voter_id'];
  
        return (
        <VoterId item={item}/>
        )
      
      }

      if (component_to_verify == 'driving_licence') {
          
    
          const item = requestedData['driving_licence'];
    
          return (
          <DL item={item}/>
          )
        
        }

        if (component_to_verify == 'icai') {

    
          const item = requestedData['icai'];
    
          return (
          <CA item={item}/>
          )
         
        }

        if (component_to_verify == 'nurse') {
            
      
            const item = requestedData['nurse'];
      
            return (
            <Nurse item={item}/>
            )
          
          }

          if (component_to_verify == 'doctor') {

      
            const item = requestedData['doctor'];
      
            return (
            <Doctor item={item}/>
            )
           
          }

        }

  }


  const get_request_identity_org = async () => {

    const org_client_id = caseData?.org_client_id;

    console.log('org_client_id',org_client_id);

    const config = {
      headers: {
        'Content-Type': 'application/json'
      }
    };


    
    axios.get(`${process.env.REACT_APP_BASE_URL}/service/request_identity_org/${org_client_id}`, {}, config)
    .then(response => {
      console.log('requested identity org response',response);

      let data = response.data.data[0];

      console.log('request identity data==>',data);

      let req_data = JSON.parse(data.req_data);
      console.log('req_data==>',req_data);

      let newSection = [
        {
          name: "Identity",
          components:[],
          isOpen: false,
        },
        {
          name: "Professional Identity",
          components:[],
          isOpen: false,
        },
    
        
      ];

      let arr = [];

      if(data.identity){

        let identity = req_data.identity;

        let obj =    {
          name: "Identity",
          components:[],
          isOpen: false,
        }

        identity.map((item, index) => {

          obj.components.push({
            name:item,
            isOpen:false  
          })

        })

        if(identity.length > 0){

         arr.push(obj); 

        }
        // setSection([...section,
        
        //   obj
        // ]);

       

      }

      if(data.professional_identity){



        let professional_identity = req_data.professional_identity;

       let obj =   {
        name: "Professional Identity",
        components:[],
        isOpen: false,
      }

        professional_identity.map((item, index) => {

          obj.components.push({
            name:item,
            isOpen:false  
          })

        })

        if(professional_identity.length > 0){
          arr.push(obj);

        }


        // setSection([...section,
        //   obj
   
      
        // ]);

      }

      setSection(arr);



    })
    .catch(error => {
  
      
  
      console.log('error==>',error);
    });

  }

  const activity_logs = async () => {

    const config = {
      headers: {
        'Content-Type': 'application/json'
      }
    };

    const {org_client_id, request_id} = caseData;

    const body = {

      org_client_id: org_client_id,
      case_id: request_id

    }

  
    axios.post(`${process.env.REACT_APP_BASE_URL}/service/activity_logs`, body, config)
    .then(response => {
      console.log('activity logs',response);

     setActivityLogs(response.data);


    })
    .catch(error => {
  
      
  
      console.log('error==>',error);
    });

  }

  const get_wallet = async (value) => {

    const config = {
      headers: {
        'Content-Type': 'application/json'
      }
    };

  
    axios.get(`${process.env.REACT_APP_BASE_URL}/service/wallet/${value}`, {}, config)
    .then(response => {
      console.log('wallet data',response.data);

      let data = response.data;

      console.log('data==>',data);

      setUser(data.user);



    })
    .catch(error => {
  
      
  
      console.log('error==>',error);
    });

  }

  const get_case = async () => {

   
    const data = location.state;

    console.log('param data',data)

    if (data) {
      setCaseData(data);
      get_wallet(data.walletID);
    }



  }




  const toggle = () => {
    if (modal) {
      setModal(false);
      setOrder(null);
    } else {
      setModal(true);
    }
  };


  //delete order
  const [deleteModal, setDeleteModal] = useState(false);


  const update_case = async(status) => {

    console.log('status==>',status);

    const config = {
      headers: {
        'Content-Type': 'application/json'
      }
    };

    const {notification_id} = caseData;

    const body = {

      notification_id: notification_id,
      verification_status: status

    }

    console.log('update case body==>',body);

  
    axios.put(`${process.env.REACT_APP_BASE_URL}/service/case_status`, body, config)
    .then(response => {
      console.log('update case res',response);


      navigate('/on-going-cases');



    })
    .catch(error => {
  
      
  
      console.log('update case error==>',error);
    });

  }

  useEffect(() => {

    if(components.length>0 && Object.keys(requestedData).length > 0){


      let codeIdx = components.findIndex((item)=> item.id == caseData.component_id);

      console.log('codeIdx==>',codeIdx, components, requestedData);

      // console.log('component to verify', components)

      if(codeIdx > -1){

      let idx = Object.keys(requestedData).findIndex((item)=> item ==  components[codeIdx].code);

      console.log('select component idx==>',idx, caseData.component_id, requestedData, components);

      if (idx > -1) {


        setComponentToVerify(components[codeIdx].code);


      }


      
    }

    }

  }, [components, requestedData]);

  const assignToQC = () => {


    const config = {
      headers: {
        'Content-Type': 'application/json'
      }
    };

 

    const body = {
      case_id: caseData.request_id,
      org_emp_id: "",
      team: 'QC Case Manager',
      deposition_status: "",
      component_id: null,
      
      case_status: 'Assigned'
     
    }

    console.log('id==>', body)

    axios.post(`${process.env.REACT_APP_BASE_URL}/employer/admin/assignCase`, body, config)

    .then(response => {

      console.log('assign case==>',response)

      updateAssignCase()

  
    })

    .catch(error => {

      console.log('error from server==>',error)

    })

   
  
  };


  const updateAssignCase = async () => {

    const config = {
      headers: {
        'Content-Type': 'application/json'
      }
    };

    const body = {
      case_id: caseData.request_id,
      assigned_id: caseData.assigned_id,
      deposition_status: caseData.deposition_status,
      description: 'QC Component Manager completed the case',
      case_status: 'complete',
      org_client_id: caseData.org_client_id,
      comment:comment,
      colorcode: caseData.colorcode,
      reason: caseData.reason,
     
    }

    console.log('update case==>', body, caseData)

    axios.put(`${process.env.REACT_APP_BASE_URL}/employer/admin/assignCase`, body, config)

    .then(response => {

      console.log('update assign case==>',response)

      // update_document(componentData.documents)

      alert('QC Component Manager complete')

      navigate('/admin/qc-component-level')
   

    })

    .catch(error => {

      console.log('error from server==>',error)

    })
  }

  useEffect(() => {

    if(components.length>0 && requestedData.length > 0 && component_to_verify == null){

      
 
  
      let codeIdx = components.findIndex((item)=> item.id == caseData.component_id);

        console.log('requestedData==>', requestedData, components[codeIdx].code);

      let idx = requestedData.findIndex((item)=> Object.keys(item)[0] ==  components[codeIdx].code);

      console.log('select component idx==>',idx, caseData.component_id, requestedData, components);

      getComponentFields({case_id: caseData.request_id, component_id: caseData.component_id, sub_component_id: caseData.sub_component_id});

      getUploadedDocuments({case_id: caseData.request_id, component_id: caseData.component_id, sub_component_id: caseData.sub_component_id});


      if (idx > -1) {


        setComponentToVerify(components[codeIdx].code);


        if(components[codeIdx].code == 'aadhaar'){


          setComponentData(requestedData[idx]['aadhaar']);

        }

        if(components[codeIdx].code == 'pan'){

          setComponentData(requestedData[idx]['pan']);

        }

        if(components[codeIdx].code == 'voter_id'){

          setComponentData(requestedData[idx]['voter_id']);

        }

        if(components[codeIdx].code == 'driving_licence'){

          setComponentData(requestedData[idx]['driving_licence']);

        }

        if(components[codeIdx].code == 'icai'){

          setComponentData(requestedData[idx]['icai']);

        }

        if(components[codeIdx].code == 'credit_score'){

          console.log('credit score', requestedData[idx]['credit_score'])

          setComponentData(requestedData[idx]['credit_score']);

        }

        if(components[codeIdx].code == 'nurse'){
          setComponentData(requestedData[idx]['nurse']);
        }

        if(components[codeIdx].code == 'doctor'){

          setComponentData(requestedData[idx]['doctor']);
        }

        if(components[codeIdx].code == 'ca'){


          setComponentData(requestedData[idx]['ca']);
        }

        if(components[codeIdx].code == 'education'){

          console.log('education component data==>',requestedData[idx]['education'], requestedData);

          setComponentData(requestedData[idx]['education'][0]);
        }

        if(components[codeIdx].code == 'employment'){

          // console.log('employment component data==>', requestedData[idx]['employment'], requestedData[idx]['employment'].findIndex((item)=> item.id == caseData.sub_component_id));

          let emp_idx = requestedData[idx]['employment'].findIndex((item)=> item.id == caseData.sub_component_id);

          let emp_data = requestedData[idx]['employment'][emp_idx];

          let tds_idx = requestedData.findIndex((item)=> Object.keys(item)[0] == 'tds')

          let epfo_idx = requestedData.findIndex((item)=> Object.keys(item)[0] == 'epfo') 

          if(tds_idx > -1){

          

            emp_data['tds'] = true;
          }else{
            emp_data['tds'] = false;
          }

          if(epfo_idx > -1){

            emp_data['epfo'] = true;

          }else{

            emp_data['epfo'] = false;

          }

       

          setComponentData(emp_data);

        }

      }

    }

  }, [components, requestedData]);

  const getComponentFields = async (obj) => {


    const config = {
      headers: {
        'Content-Type': 'application/json'
      }
    };

    console.log('get component fields==>', obj) 


    axios.post(`${process.env.REACT_APP_BASE_URL}/employer/admin/component_fields`, obj, config)

    .then(response => {

      console.log('get component field res ==>',response)

      setComponentFields(response.data.data);

    })

    .catch(error => {

      console.log('error from server==>',error)

    })
  }

  const getUploadedDocuments = async (obj) => {

    const config = {
      headers: {
        'Content-Type': 'application/json'
      }
    };

    console.log('get uploaded documents==>', obj);

    axios.post(`${process.env.REACT_APP_BASE_URL}/employer/admin/uploaded_documents`, obj, config)
    .then(response => {
      console.log('uploaded documents',response);

      let data = response.data.data;

      let filtered_data = data.filter(item => item.uploaded_by == 'admin')


      setUploadedDocuments(filtered_data);

    })
    .catch(error => {
  
      
  
      console.log('get uploaded documents error==>',error);
    });

  }

  const upload_file = async (obj) => {

    const config = {
      headers: {
        'Content-Type': 'multipart/form-data',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'POST, GET, OPTIONS, DELETE, PUT',
        
      }
    };

    const newObj = {};

    newObj['component_id'] = caseData.component_id;
    newObj['sub_component_id'] = caseData.sub_component_id;
    newObj['description'] = obj.description;
    newObj['case_id'] = caseData.request_id;
    newObj['assigned_id'] = caseData.assigned_id;
    newObj['uploaded_by'] = 'admin';
   
    const formData = new FormData();



    console.log('upload document obj==>',newObj, obj.file);

    formData.append('image', obj.file);

    formData.append('myData', JSON.stringify(newObj))


    axios.post(`${process.env.REACT_APP_BASE_URL}/employer/admin/upload_comp_document`, formData, config)
    .then(response => {
      console.log('uploaded',response);


    })
    .catch(error => {
  
    
  
      console.log('error==>',error);
    });

   }

  const save = (data, upload_doc) => {

    console.log('data==>',data, caseData.sub_component_id);

    if(upload_doc!==null){

      console.log('upload doc==>',upload_doc);

    upload_file(upload_doc);

    }

    Object.keys(data).map((item, index) => {

      const body = {
        case_id: caseData.request_id,
        component_id: caseData.component_id,
        sub_component_id: caseData.sub_component_id,
        field_id: item,
        value: data[item],
      }

      console.log('body==>',body);

      addComponentField(body);

    })

  }

  const addComponentField = async (obj) => {

    const config = {
      headers: {
        'Content-Type': 'application/json'
      }
    };

    console.log('inserting component fields==>', obj)

   await axios.post(`${process.env.REACT_APP_BASE_URL}/employer/admin/add_component_field`, obj, config)

    .then(response => {

      console.log('add component field==>',response)

    })

    .catch(error => {

      console.log('error from server==>',error)

    })
  }

  const getColorCodeReason = async () => {

    const config = {
      headers: {
        'Content-Type': 'application/json'
      }
    };
   
    axios.get(`${process.env.REACT_APP_BASE_URL}/employer/admin/colorcodematrix`, {}, config)

    .then(response => {

      console.log('color code cases==>',response.data.data, component_to_verify)

      let data = response.data.data;

      let doc = 
        {
          aadhaar: "Identity",
          pan: "Identity",
          voter_id: "Identity",
          driving_licence: "Identity",
          doctor: "Professional Identity",
          nurse: "Professional Identity",
          icai: "Professional Identity",
          education: "Education",
          employment: "Employment",
          credit_score: "Credit",


        }
      
 // find component_to_verify in the doc object and return the value

      let type = doc[component_to_verify];
       

 let filtered  = data.filter((item)=> item.Parameter === type);

 console.log('colorcode filtered==>',filtered);
      

      setColorCodeMatrix(filtered);


    })

    .catch(error => {

      console.log('error from server==>',error)

    })


  }

  const getDepStatusOptions = async () => {

    const config = {
      headers: {
        'Content-Type': 'application/json'
      }
    };
   
    axios.get(`${process.env.REACT_APP_BASE_URL}/employer/admin/deposition_status_options`, {}, config)

    .then(response => {


      let data = response.data.data;

      console.log('deposition status options==>',data);
 
      setDepStatusOption(data);


    })

    .catch(error => {

      console.log('deposition error from server==>',error)

    })


  }

  const update_document = async (docs) => {

    console.log('update docs body==>',docs);

    const config = {
      headers: {
        'Content-Type': 'application/json'
      }
    };

    const body = {
      documents: docs,
    }

    console.log('update document body==>',body);

    await axios.post(`${process.env.REACT_APP_BASE_URL}/employer/admin/update_uploaded_document`, body, config)

    .then(response => {
        
        console.log('update document==>',response)
  
      }
    )

    .catch(error => {

      console.log('error from server==>',error)

    })

  }


  useEffect(() => {

    if(component_to_verify !== null){

    getDepStatusOptions();  

     getColorCodeReason();

    }

  }, [component_to_verify]);


  useEffect(() => {

    if(component_to_verify !== null){

      let documents = []

      Object.keys(componentData).map((key)=> {

          
          if(key.includes('payslip_path') || key.includes('offer_letter_path') || key.includes('exp_letter_path')){

            let obj = {
              payslip_path: "Payslip",
              offer_letter_path: "Offer Letter",
              exp_letter_path: "Experience Letter"
            }

            if(componentData[key] != null && componentData[key] != ''){


            documents.push({
              name: obj[key],
              value: componentData[key],
              case_id: caseData.request_id,
              component_id: caseData.component_id,
              sub_component_id: caseData.sub_component_id,
              assigned_id: caseData.assigned_id,
              selected: false,
              description: '',
              uploaded_by: 'candidate'


            });

          }

          }else if(key.includes('uploaded_document')){


            if(componentData[key] != null && componentData[key] != ''){

              let obj ={
                pan: "pan",
                voter_id: "Voter ID",
                driving_licence: "Driving Licence",
                nurse: "Nurse",
                doctor: "Doctor",
                icai: "ICAI",
                aadhaar: "Aadhaar"

              }

            documents.push({

              name: obj[component_to_verify],
              selected: false,
              value: componentData[key],
              case_id: caseData.request_id,
              component_id: caseData.component_id,
              sub_component_id: caseData.sub_component_id,
              assigned_id:caseData.assigned_id,
              description: '',
              uploaded_by: 'candidate'

            });

          } 
            
            }   

        
      })

      let new_documents = [...uploaded_documents, ...documents];


      setComponentData({...componentData, documents: new_documents});
    }


  }, [component_to_verify, uploaded_documents]);

  const rework = async () => {

    const config = {
      headers: {
        'Content-Type': 'application/json'
      }
    };

    const body = {
      case_id: caseData.request_id,
      component_id: caseData.component_id,
      sub_component_id: caseData.sub_component_id,
      assigned_id: caseData.assigned_id
    }

    console.log('rework',body)
   
    axios.post(`${process.env.REACT_APP_BASE_URL}/employer/admin/rework`, body, config)

    .then(response => {

      navigate('/admin/leader-cases')

    })

    .catch(error => {

      console.log('rework error from server==>',error)

    })


  }

  const update_log_call = async() => {


    const user = JSON.parse(Cookies.get('authUser'))


    const config = {
      headers: {
        'Content-Type': 'application/json'
      }
    };


    const body = {


   
      org_emp_id: user.user_id,
      case_id: caseData.request_id,
      org_client_id: caseData.org_client_id,
      activity: log_activity,


    }

    console.log('update case body==>',body);

  
    axios.post(`${process.env.REACT_APP_BASE_URL}/service/admin/log_calls`, body, config)
    .then(response => {
      console.log('update log call res',response);

     

    })
    .catch(error => {
  
      
  
      console.log('update log call error==>',error);
    });

  }

  return (
    <React.Fragment>

      <div className="page-content">
        <div className="container-fluid">
     
   
          <Row>
            <Col lg="12">
              <div className="d-flex mb-4" style={{justifyContent:'space-between'}}>
                <h2 className="page-title">{caseData?.candidate_name}</h2>

                <button className="btn btn-primary"  onClick={()=>navigate('/on-going-cases')}>back</button>

              </div>
            <div className="table-responsive">
                    <Table className="table mb-3">
                      <thead className="table-dark" >
                        <tr>
                          <th>Case Ref.#</th>

                          <th>Client Name</th>

                          <th>Location</th>

                          <th>Case In Date</th>
                          <th>Case Due Date</th>

                          <th>Father's Name</th>
                          <th>Dob</th>
                          <th>Client Employee ID</th>

                        </tr>
                      </thead>
                      <tbody>
                        {caseData &&<tr className="table-light">
                          <th >{caseData?.request_id}</th>
                          <td></td>
                          <td>{caseData?.location}</td>
                          <td>{moment(caseData?.timestamp).format('DD-MM-YYYY')}</td>
                          <td></td>

                          <td>{caseData?.candidate_father_name}</td>
                          <td>{caseData?.candidate_dob}</td>
                          <td></td>
                        </tr>}
                    
                      </tbody>
                    </Table>
                  </div>


              </Col>
          </Row>

          <Row>
          
            <Col lg="12">

            {
    component_to_verify == 'pan' && <Pan item={componentData} onSave={save} />
  }

  {
    component_to_verify == 'voter_id' && <VoterId item={componentData} onSave={save}/>

  }

  {

    component_to_verify == 'driving_licence' && <DL item={componentData} onSave={save}/>

  }

  {

    component_to_verify == 'nurse' && <Nurse item={componentData} onSave={save}/>

  }

  {

    component_to_verify == 'doctor' && <Doctor item={componentData} onSave={save}/>

  }

  {

    component_to_verify == 'icai' && <CA item={componentData} onSave={save}/>

  }

  {

    component_to_verify == 'aadhaar' && <Aadhaar item={componentData} onSave={save}/>

  }

{

component_to_verify == 'credit_score' && <Credit item={componentData} />

}

  {
     component_to_verify == 'education' && <Education onSave={save} item={componentData}/>
  }

  {
    component_to_verify == 'employment' && <Employment onSave={save}   item={componentData}/>

  }

  {/* {
    (Object.keys(requestedData).length >0 && component_to_verify!==null) && renderData()
  } */}
          
          <Card>
                  <CardBody>
                  <p style={{fontSize:15, fontWeight:500}} className="mb-3">Uploaded Documents</p>

                  <Table bordered  style={{
                      borderWidth:1,
                    }}>

          <thead>
            <tr >
              {/* <th>Show in report</th> */}
              <th>File Name</th>
              <th>Description</th>
              {/* <th>Component</th> */}
              <th>Uploaded By</th>
              <th>Uploaded On</th>
              <th>Action</th>


            </tr>

          </thead>

          <tbody >


          {
            componentData?.documents && componentData.documents.map((item, index) => {
                
                return <tr key={index}>
                  {/* <td>

                  <Input className="form-check-input" type="checkbox" onClick={()=> {
           
                      let obj = {...componentData}

                      console.log('select component data', componentData)

                       let newDocs = obj.documents

                       newDocs[index].selected = !newDocs[index].selected;

  
                      setComponentData(obj)
  
                    }} 

            
            checked={item.selected}   />
                  </td> */}
                <td>{item.name}</td>
                <td>{item.description}</td>
                {/* <td></td> */}
                <td>{item.uploaded_by}</td>
                <td></td>
                <td><button
                    onClick={()=>{
                      window.open(item.value, '_blank')
    
                    }}
                className="btn btn-primary btn-sm">View</button></td>
              </tr>
            })
          }
   
           </tbody>

                      </Table>
               
                  </CardBody>
                </Card>    
            


               
            </Col>
          </Row>

          <Row>
            <Col lg="4">
            <Card>
                  <CardBody>

                    <div className="p-2">

                    <p style={{fontSize:16, fontWeight:500}}>Case History</p>


    <div className="mb-3 d-flex" style={{justifyContent:'space-between', alignItems:'center'}}>
                        <Label className="form-label">Status:</Label>
    <select className="form-select w-75"
    
    onChange={(e)=> {
  
      let value = e.target.value;
      console.log('value==>',value)

    
      setSelectedStatus(value);
   

}}>

<option value="">Select status</option>


<option value="qc_complete">QC Complete</option>

<option value="send_to_qc">Complete - Send to QC</option>
<option value="rework">Rework</option>


</select>
      </div>

      {selectedStatus == 'send_to_qc' && <div className="mb-3 d-flex" style={{justifyContent:'space-between', alignItems:'center'}}>
                        <Label className="form-label">Deposition Status:</Label>
    <select className="form-select w-75"
    
    onChange={(e)=> {
  
      let value = e.target.value;
      console.log('value==>',value)
    
      setDepositionStatus(value);
   

}}>
    <option value="">Select Deposition status</option>

  <option value="discrepancy">Discrepancy</option>
  <option value="clear">Clear</option>
  <option value="closed_as_insufficiency">Closed as Insufficiency</option>
  <option value="unable_to_verify">Unable to verify</option>
  <option value="stop_check">Stop check</option>


</select>
      </div>}

      <div className="mb-3 d-flex" style={{justifyContent:'space-between', alignItems:'center'}}>
                                  <Label htmlFor="commentmessage-input">
                                    Activity
                                  </Label>
                                  <textarea
                                    className="form-control w-75"
                                    id="commentmessage-input"
                                    placeholder="Your message..."
                                    value={comment}
                                    onChange={(e)=>{

                                      let value = e.target.value;

                                      setComment(value);

                                    }}
                                    rows="3"
                                  ></textarea>
                                </div>


                                <button
                        type="button"
                        onClick={()=>{

                          if(selectedStatus == '') {
                            alert('Please select status to update');
                            return
                          }

                          if(selectedStatus == 'send_to_qc' && depositionStatus == '') {

                            alert('Please select deposition status to update');

                            return
                          }

                          if(selectedStatus == 'qc_complete') {

                          //  assignToQC()

                          // update_document(componentData.documents)

                          updateAssignCase()

                          }

                          if(selectedStatus == 'rework'){

                            rework();

                          }
                         
                        }}
                        className="btn btn-primary me-2"
                      >
                        Update
                      </button>
                    </div>
          
                    </CardBody>
                </Card>
              </Col>

              <Col lg="4">
              <Card>
                  <CardBody>
                  <p style={{fontSize:16, fontWeight:500}}>Log Call</p>

                    <div className="p-2">


                    <div className="d-flex mb-3" style={{justifyContent:'space-between', width:'70%'}}>
                        <Label for="basicpill-firstname-input1"  className="control-label me-3" >
                        Consider as An Attempt:
                                </Label>

                                <div className="form-check me-3 d-flex">
<div className="form-check me-3">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="exampleRadios90"
                            id="exampleRadios90"
                            value="yes"
                            checked={log_call_attempt === 'yes' ? true : false}
                            defaultChecked
                            onChange={(e)=>{
                                setLogCallAttempt('yes');
                                update_log_call();
                            }
                          }
                            
                          />
                          <label
                            className="form-check-label"
                            htmlFor="exampleRadios90"
                          >
                            Yes
                          </label>
                        </div>

                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="exampleRadios91"
                            checked={log_call_attempt === 'no' ? true : false}
                            id="exampleRadios91"
                            value="no"
                            onChange={(e)=>{
                                
                                setLogCallAttempt('no');
                            }
                          }
                          />
                          <label
                            className="form-check-label"
                            htmlFor="exampleRadios91"
                          >
                            No
                          </label>

              

                        
                        </div>
                        </div>
                   
                     
</div>


      <div className="mb-3 d-flex" style={{justifyContent:'space-between', alignItems:'center'}}>
                                  <Label htmlFor="commentmessage-input">
                                    Activity
                                  </Label>
                                  <textarea
                                    className="form-control w-75"
                                    id="commentmessage-input"
                                    placeholder="Your message..."
                                    onChange={(e)=>setLogActivity(e.target.value)}
 
                                    rows="3"
                                  >
                                    {log_activity}
                                  </textarea>
                                </div>


                                <button
                        type="button"
                        onClick={update_log_call}
                        className="btn btn-primary me-2"
                      >
                        Update
                      </button>
                    </div>
          
                    </CardBody>
                </Card>
              </Col>


              <Col lg="4">
            <Card>
                  <CardBody>
                  <p style={{fontSize:16, fontWeight:500}}>External Message Log</p>

                    <div className="p-2">



 


      <div className="mb-3 d-flex" style={{justifyContent:'space-between', alignItems:'center'}}>
                                  <Label htmlFor="commentmessage-input">
                                    External Message Log
                                  </Label>
                                  <textarea
                                    className="form-control w-75"
                                    id="commentmessage-input"
                                    placeholder="Your message..."
                                    rows="3"
                                  ></textarea>
                                </div>


                                <button
                        type="button"
                        onClick={()=>{
                         
                        }}
                        className="btn btn-primary me-2"
                      >
                        Update
                      </button>
                    </div>
          
                    </CardBody>
                </Card>
              </Col>
          </Row>
      
<Row>
  <Col lg="4">
  <Card>
                  <CardBody>

                    <div className="p-2 bg-light">
                    <p style={{fontSize:16}} className="mb-4 px-3">Activity Log</p>


{
  activityLogs.map((item, index)=>(
    <div className="row pr-2 p-3  pt-0 pb-0">
    <ul  class="activity-content">
      <li  class="box app-arrow-left-top" >
                          <span >
                          {item.status==null?'New':item.status} - on {moment(item.updated_at).format('dddd, DD-MMM-YY hh:mm:ss')} {"<"}  By - {(item.updated_by == null || item.updated_by == "")?"System Scheduler": item.updated_by} {">"}
                          {"\n"}{item.description}
                          </span>
                          
                          
                          </li>

                     

                     
                    </ul>
    </div>
  ))
}
          
                    </div>
          
                    </CardBody>
                </Card>
  </Col>
</Row>
        </div>
      </div>

    </React.Fragment>
  );
}
QcCompDetail.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
};

export default QcCompDetail;



const styles = {

  inputCheck: {
    border: '1px solid grey',
    width: 15,
    height: 15,
  }

}