import React, { useEffect } from "react";
import ReactEcharts from "echarts-for-react";
import getChartColorsArray from "../../components/Common/ChartsDynamicColor";

const Doughnut = ({ dataColors, data }) => {
  const doughnutEChartColors = getChartColorsArray(dataColors);


  const options = {
    toolbox: {
      show: false,
    },
    tooltip: {
      trigger: "item",
      formatter: "{a} <br/>{b}: {c}",
    },
    legend: {
      orient: "vertical",
      x: "left",
      data: ["Open Cases", "Closed Cases", "Cancelled Cases", "Expired Cases"],
      textStyle: {
        color: ["#8791af"],
      },
    },
    color: doughnutEChartColors,
    series: [
      {
        name: "",
        type: "pie",
        radius: ["50%", "70%"],
        avoidLabelOverlap: false,
        label: {
          normal: {
            show: false,
            position: "center",
          },
          emphasis: {
            show: true,
            textStyle: {
              fontSize: "20",
              fontWeight: "bold",
            },
          },
        },
        labelLine: {
          normal: {
            show: false,
          },
        },
        data: [
          { value: 0, name: "Open Cases" },
          { value: 0, name: "Closed Cases" },
          { value: 0, name: "Cancelled Cases" },
          { value: 0, name: "Expired Cases" }
      
        ],
      },
    ],
  };

  const [state, setState] = React.useState(options);


  useEffect(() => {

    if(data.length > 0){

      const arr = [...options.series[0].data];

      data.map((item, index) => {

        if(item.verification_status !== 'complete'){

          arr[0].value = arr[0].value + 1;

        }

        if(item.verification_status === 'complete'){

          arr[1].value = arr[1].value + 1;

        }

        if(item.verification_status === 'cancelled'){

          arr[2].value = arr[2].value + 1;

        }

        if(item.verification_status === 'expired'){

          arr[3].value = arr[3].value + 1;

     
      }
    }


      )
      console.log(arr, 'arr')

      setState({
        ...state,
        series: [
          {
            name: "",
            type: "pie",
            radius: ["50%", "70%"],
            avoidLabelOverlap: false,
            label: {
              normal: {
                show: false,
                position: "center",
              },
              emphasis: {
                show: true,
                textStyle: {
                  fontSize: "20",
                  fontWeight: "bold",
                },
              },
            },
            labelLine: {
              normal: {
                show: false,
              },
            },
            data: arr,
          },
        ],
      });


    }

    

  }, [data]);

  return (
    <React.Fragment>
      <ReactEcharts style={{ height: "350px" }} option={state} />
    </React.Fragment>
  );
};
export default Doughnut;
