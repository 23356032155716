import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import withRouter from '../components/Common/withRouter';
import Header from '../components/Header/index';

const Layout = (props) => {


  const toggleMenuCallback = () => {
  };

  return (
    <React.Fragment>
          <div id="layout-wrapper">
        <Header location={props.router.location.pathname} toggleMenuCallback={toggleMenuCallback} />
     
        <div >{props.children}</div>
        {/* <Footer /> */}
      </div>
      {/* {props.children} */}
      
      </React.Fragment>
  );
};

Layout.propTypes = {
  children: PropTypes.any,
  location: PropTypes.object
};

export default withRouter(Layout);
