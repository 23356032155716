import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Modal,
  ModalBody,
  CardBody,
  Table,
  CardSubtitle,
  Card,
Button,
  Container,
} from "reactstrap";


import axios from 'axios';
import moment from 'moment';

import { Link, useLocation, useNavigate } from "react-router-dom";
import Header from "../components/Header";






const Credential = () => {

  //meta title
  document.title = "TheRollNumber";


  const location = useLocation();
  const navigate = useNavigate();

  const [data, setData] = useState([]);
  const [imageSrc, setImageSrc] = useState(null);
  const [zoom, setZoom] = useState(false);
  const [show, setShow] = useState(false);
  const [state, setState] = useState({
    certificate_no: '',
    certificate_issue_date: '',
    certificate_status: '',
    degree_type: '',
    certificate_path: '',
    name: '',
    registration_no: '',
    year_of_start: '',
    year_of_graduation: '',
    blockchain_id: '',
  });
  const onCloseClick = () => {
     setShow(!show);
   };

  useEffect(() => {

    // get params from url

    const id = location.pathname.split('/')[3];
    const univ = location.pathname.split('/')[2];



    getCredentials(id, univ);

  }, []);


   const getCredentials = async (id, univ) => {

    // const id = location.pathname.split('/')[2];

    console.log('credential view id',id, univ);

    const config = {
      headers: {
        'Content-Type': 'application/json'
      }
    };

    const body = {
      univ,
      id
    }
  
    axios.get(`${process.env.REACT_APP_BASE_URL}/university/credential_view_cert/${univ}/${id}`, body, config)
    .then(response => {
      console.log('credential view',response.data.data);

      let data = response.data.data[0];

      console.log('credential view cert',data);


      setState({
        certificate_no: data.cert_id,
        certificate_issue_date:  moment(data.timestamp).format('DD-MM-YYYY'),
        // certificate_status: data.certificate_status,
        // degree_type: data.degree_type,
        issuing_authority: data.univ_abbr,
        degree_type: data.degree_type,
        name: data.student_name,
        certificate_path: data.cert_path,
        registration_no: data.regn_no,
        year_of_start: data.yos,
        year_of_graduation: data.yog,
        blockchain_id: '',
      })




    })
    .catch(error => {
  
  
      console.log('credential view cert error==>',error);
    });

  }

 
  return (


    <React.Fragment>
    


      <div className="page-content px-0" style={{background:'rgb(231, 231, 231)'}}>
 
  <Header/>

        <Container fluid={true}>

<Row>
  <Col className="col-12">
{ zoom && <div  style={{position:'absolute', width:'100%',  flex:1,left:0,  background:'rgb(231, 231, 231)', zIndex:99}}>
<div className="py-5 text-center">
              <img className="img-fluid w-75 " src={'https://d2baq4x2h5s2u2.cloudfront.net/univ_certs_local/10.png'} style={{ objectFit:'contain',alignSelf:'center',  borderRadius:8}}/>


              </div>

              <button className="btn btn-secondary btn-xl" style={{position:'fixed',background:"#fff", color:"#444", margin:'0 auto',borderRadius:20, width:200, bottom:10, left:0, right:0}} onClick={()=>setZoom(false)}>
  Minimize
</button>
</div>}
    </Col>
</Row>
 
          <Row>

          <Col className="col-8">
         
      
            <div className="shadow-none border card" style={{
              border: '1px solid #dadada',
              background:'rgb(248, 248, 248)',
              borderRadius:10
            }}>

              <div className="d-flex" style={{justifyContent:'space-between',padding:'2% 2em 0.5em 3%',
              background:'#fff',
              borderStartStartRadius:10,
              borderStartEndRadius:10,
              borderBottom:'1px solid rgb(218, 218, 218)'}}>
                <h5 style={{
                  fontSize:16,
                letterSpacing:0.5,
                  fontWeight:600,
                  lineHeight:1.5,
               
                  color:'#444'
                }}>Course Certificate</h5>

                <div className="d-flex justify-content-between">
                <h5 
                
                onClick={()=>setZoom(!zoom)}
                
                style={{
                  fontSize:16,
                letterSpacing:0.5,
                  fontWeight:600,
                  lineHeight:1.5,
               
                  color:'#444'
                }}>
                            <i className="bx bx-fullscreen me-2"  style={{fontSize:16}}/>

                  Full Screen</h5>
                  </div>
              </div>

              <div className="py-5 text-center">
              <img className="img-fluid w-50 " src={state.certificate_path} style={{ objectFit:'contain',alignSelf:'center',  borderRadius:8}}/>

              </div>

            </div>
        

</Col>
            <Col className="col-4">

            <div className="shadow-none border card p-4" style={{
              border: '1px solid #dadada',
              borderRadius:10
            }}>
   <h5 style={{
                  fontSize:16,
                letterSpacing:0.5,
                  fontWeight:600,
                  lineHeight:1.5,
               
                  color:'#444'
                }}>credential Details</h5>

<div className="d-flex" style={{flexWrap:'wrap', }}>

  <div  className="w-50 mb-4 mt-4">

  <p className="m-0" style={style.subHeading}>Record:</p>

<div className="d-flex" style={{alignItems:'center'}}>
<h4 className="m-0" style={style.heading}> 
 Matched</h4>
 <i className="mdi mdi-check-circle-outline text-success display-5 me-2" style={{fontSize:16}} />

</div>


  </div>

  <div className="w-50 mt-3">

<p className="m-0" style={style.subHeading}>Issuing Authority:</p>
<h4 className="m-0" style={style.heading}>{state.issuing_authority}</h4>

</div>

  <div className="w-50">

<p className="m-0" style={style.subHeading}>Certificate Number:</p>
<h4 className="m-0" style={style.heading}>{state.certificate_no}</h4>

</div>

<div className="w-50">

<p className="m-0" style={style.subHeading}>Certificate Issue Date:</p>
<h4 className="m-0" style={style.heading}>{state.certificate_issue_date}</h4>

</div>

<div className="w-50 mb-4 mt-4">

<p className="m-0" style={style.subHeading}>Name:</p>
<h4 className="m-0" style={style.heading}>{state.name}</h4>

</div>

<div className="w-50 mb-4 mt-4">

<p className="m-0" style={style.subHeading}>Degree Type:</p>
<h4 className="m-0" style={style.heading}>{state.degree_type}</h4>

</div>

<div className="w-50 mt-4">

<p className="m-0" style={style.subHeading}>Registration Number:</p>
<h4 className="m-0" style={style.heading}>{state.registration_no}</h4>

</div>

<div className="w-50 mt-4">

<p className="m-0" style={style.subHeading}>Certificate Status:</p>
<div className="d-flex" style={{alignItems:'center'}}>
<h4 className="m-0" style={style.heading}> 
 ACTIVE</h4>
 <i className="mdi mdi-check-circle-outline text-success display-5 me-2" style={{fontSize:16}} />

</div>

</div>

<div className="w-50 mt-4">

<p className="m-0" style={style.subHeading}>Year of Start:</p>
<h4 className="m-0" style={style.heading}>{state.year_of_start}</h4>

</div>

<div className="w-50 mt-4">

<p className="m-0" style={style.subHeading}>Year of Graduation:</p>
<h4 className="m-0" style={style.heading}>{state.year_of_graduation}</h4>

</div>

<div className="w-50 mt-4">

<p className="m-0" style={style.subHeading}>Blockchain ID:</p>
<h5 className="m-0" style={style.heading}>{state.blockchain_id}</h5>

</div>
</div>
</div>

            </Col>
          </Row>
        </Container>
      </div>
      

    </React.Fragment>
  )
}

const style = {
  heading:{
    fontSize:14,
    letterSpacing:0.5,
    fontWeight:600,
    lineHeight:1.5,
 
    color:'#444'
  },
  subHeading:{
    fontSize:14,
    letterSpacing:0.5,
    fontWeight:600,
    lineHeight:1.5,
 
    color:'grey'
  }
}

export default Credential
