


import React, { useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { ceil, isEmpty, set } from "lodash";
import "bootstrap/dist/css/bootstrap.min.css";
import TableContainer from "../../components/Common/TableContainerManageUser";
import * as Yup from "yup";
import { useFormik } from "formik";
import axios from "axios";




import {
  Button,
  Col,
  Row,
  UncontrolledTooltip,

  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  UncontrolledDropdown,

  
  Input,
  FormFeedback,
  Label,
  Card,
  CardBody,
  Table,
} from "reactstrap";
import moment from "moment";

//Import Flatepicker
import "flatpickr/dist/themes/material_blue.css";
import Flatpickr from "react-flatpickr";

import classnames from "classnames";

function AddNewUser() {
  //meta title
  document.title = "TheRollNumber";

  const navigate = useNavigate();

  const location = useLocation();


  const [modal, setModal] = useState(false);
  const [modal1, setModal1] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [cases, setCases] = useState([]);
  const [cancelNotificationType, setCancelNotificationType] = useState('')
  const [enableCancel, setEnableCancel] = useState(false);



  const [orderList, setOrderList] = useState([]);
  const [order, setOrder] = useState(null);



  const [state, setState] = React.useState({

    first_name: "",
    last_name: "",
    address: "",
    mobile: "",
    city: "",
    address: "",
    country: "",
    state: "",
    pin_code: "",
    joining_date: new Date(),
    admin_status: "active",
    email: "",
    isEdit: false,





});


const [roles, setRoles] = useState([]);

const [components, setComponents] = useState([]);

  const [verticalActiveTab, setVerticalActiveTab] = useState("1");

const handleInputChange = (e) => {
    const { id, value } = e.target;
    setState((prevState) => ({
        ...prevState,
        [id]: value,
    }));
};

const toggleVertical = (tab) => {

  if (verticalActiveTab !== tab) {
    setVerticalActiveTab(tab);
  }
}



  const [selectedNotification, setSelectedNotification] = useState({});

  const toggleViewModal = () => setModal1(!modal1);

  useEffect(() => {

    console.log('location state==>',location.state)

    if(location.state) {


      const { first_name, last_name, email, mobile, address, joining_date, status } = location.state;

      setState({...state, first_name, last_name, email, mobile, address, joining_date, admin_status: status, isEdit: true})

    

    }

    get_roles();

    get_components();



  }, []);




  const setRolePermissions = async(roles_data)=>{

    const { user_id } = location.state;

    const config = {
      headers: {
        'Content-Type': 'application/json'
      }
    };
   
   await axios.get(`${process.env.REACT_APP_BASE_URL}/employer/admin/user_roles/${user_id}`, {}, config)

    .then(response => {

      console.log('response rolepermissions==>',response)

      const data = response.data.data;

     console.log('rolepermissions data==>',data,  roles_data)

      let arr = [];


      // enable checkbox if role is assigned to user

      roles_data.map((item)=> {

        let role = data.find((role)=> role.role_id == item.id)

        if(role) {
          arr.push({
            role: item.role_name,
            checked: true,
            id: item.id
          })
        }else{
          arr.push({
            role: item.role_name,
            checked: false,
            id: item.id
          })
        }

      })

      console.log('set roles arr==>',arr)

      setRoles(arr)


  


    })

    .catch(error => {

      console.log('error from server==>',error)

    })
  }


  const setComponentsPermissions = async(components_data)=>{
    const { user_id } = location.state;

    const config = {
      headers: {
        'Content-Type': 'application/json'
      }
    };
   
   await axios.get(`${process.env.REACT_APP_BASE_URL}/employer/admin/user_components/${user_id}`, {}, config)

    .then(response => {

      console.log('response components permission==>',response)

      const data = response.data.data;

     console.log('user components data==>',data,  components_data)

      let arr = [];


      // enable checkbox if role is assigned to user

      components_data.map((item)=> {

        let comp = data.find((comp)=> comp.component_id == item.id)

        if(comp) {
          arr.push({
            component: item.name,
            checked: true,
            id: item.id
          })
        }else{
          arr.push({
            component: item.name,
            checked: false,
            id: item.id
          })
        }

      })

      console.log('set component arr==>',arr)

      setComponents(arr)


  


    })

    .catch(error => {

      console.log('error from server==>',error)

    })
  }


  const get_roles = async () => {

    const config = {
      headers: {
        'Content-Type': 'application/json'
      }
    };
   
   await axios.get(`${process.env.REACT_APP_BASE_URL}/employer/admin/roles`, {}, config)

    .then(response => {

      console.log('response roles==>',response)

      const data = response.data.data;

     console.log('data==>',data)

     let arr = [];

      data.map((item)=> {


        arr.push({
          role: item.role_name,
          checked: false,
          id: item.id
        })

      })

     setRoles(arr)

      if(location.state) {
        setRolePermissions(data)
      }


    })

    .catch(error => {

      console.log('error from server==>',error)

    })


  }


  const get_components = async () => {

    const config = {
      headers: {
        'Content-Type': 'application/json'
      }
    };
   
    axios.get(`${process.env.REACT_APP_BASE_URL}/service/components`, {}, config)

    .then(response => {

      console.log('response components==>',response)

      const data = response.data;

     console.log('data==>',data)

     let arr = [];

      data.map((item)=> {

        arr.push({
          component: item.name,
          checked: false,
          id: item.id
        })

      })


      setComponents(arr)

      if(location.state) {

        setComponentsPermissions(data)
      }
  


    })

    .catch(error => {

      console.log('error from server==>',error)

    })


  }



  const [isLoading, setLoading] = useState('')



  const toggle = () => {
    if (modal) {
      setModal(false);
      setOrder(null);
    } else {
      setModal(true);
    }
  };



  const selectAll = (value, data) => {

    console.log('select all', value)

    let arr = [...data]

    arr.map((item)=> {

      item.select = value

    } )

    setCases(arr)

    setEnableCancel(value)



  }

  //delete order
  const [deleteModal, setDeleteModal] = useState(false);

  const onClickDelete = (order) => {
    setOrder(order);
    // setDeleteModal(true);
  };

  const handleDeleteOrder = () => {
    if (order && order.id) {
      // dispatch(onDeleteOrder(order.id));
      // setDeleteModal(false);
      setOrder("");
    }
  };
  const handleOrderClicks = () => {
    setOrderList("");
    setIsEdit(false);
    toggle();
  };

  const columns = useMemo(
    () => [
      {
        Header: (cellProps) => <div className="form-check font-size-16" >
          <input className="form-check-input"  name="all" onClick={(e)=>{

            selectAll(e.target.checked, cellProps.data)
          
          }} type="checkbox" id="checkAll" />
          <label className="form-check-label" htmlFor="checkAll"></label>
        </div>,
        accessor: 'select',
        width: '20px',
        filterable: false,
        disableFilters: false,
        
      
        Cell: (cellProps) => (
          <div className="form-check font-size-16" >
            <Input className="form-check-input" type="checkbox" onClick={()=> {
              let arr = [...cellProps.data]


              // console.log('index', cellProps)
              arr[cellProps.row.index].select = !arr[cellProps.row.index].select

              setCases(arr)

              let selected = arr.filter((item)=> item.select === true)

              if(selected.length > 1) {
                setEnableCancel(true)
              }else{
                setEnableCancel(false)
              }

            
            }} checked={cellProps.value}   />
            <label className="form-check-label" htmlFor="checkAll"></label>
          </div>
        )
      },
      {
        Header: "User Name",
        accessor: "request_id",
        width: "150px",
        style: {
          textAlign: "center",
          width: "10%",
          background: "#0000",
        },
        filterable: true,
        Cell: (cellProps) => {
          return <Link to={`#`} disabled={true} onClick={(e)=>
          {
            e.preventDefault();

            const  status = cellProps.row.original.verification_status;

            if(status == 'Approved' || status == 'Dispatch done' || status == 'Complete' || status == 'QC complete') {
              
              navigate('/edit-progress', {state: cellProps.row.original}) 

            }

          }
          } >{cellProps.value}</Link>;
        },
      },
      {
        Header: "Email ID",
        accessor: "location",
        filterable: true,
        Cell: (cellProps) => {
          return cellProps.value;
        },
      },
      {
        Header: "Location",
        accessor: "case_received_date",
        filterable: true,
        Cell: (cellProps) => {
          return moment(cellProps.value).format("DD-MM-YYYY");
        },
      },
      {
        Header: "Role",
        accessor: "candidate_name",
        filterable: true,
        Cell: (cellProps) => {
          return cellProps.value;
        },
      },
    
      {
        Header: "Status",
        accessor: "candidate_father_name",
        filterable: true,
        Cell: (cellProps) => {
          return cellProps.value;
        },
      },

  
  
    
      {
        Header: "Action",
        accessor: "action",
        disableFilters: true,
        Cell: (cellProps) => {

          const status  = cellProps.row.original.verification_status;

          const enableView = (status == 'Approved' || status == 'Dispatch done' || status == 'Complete' || status == 'QC complete') ? true : false;

          const enableCancel = (status == 'Dispatch done' || status == 'Complete' || status == 'QC complete') ? false : true;

          return (
            <div className="d-flex text-center align-items-center" style={{justifyContent:'space-evenly', width:50}} >
               {enableView && <Link
                to="#"
                className="text-success"
                onClick={(e) => {

                  e.preventDefault();
                  console.log('cellProps==>',cellProps.row.original)

                  navigate('/edit-progress', {state: cellProps.row.original}) 

                }}
              >
              
                <i className="mdi mdi-eye font-size-20" id="edittooltip2" />
                <UncontrolledTooltip placement="top" target="edittooltip2">
                  View Report
                </UncontrolledTooltip>
              </Link>}
              {enableCancel && <Link
                to="#"
                className="text-danger"
                onClick={() => {
setCancelNotificationType('single')
                  setSelectedNotification(cellProps.row.original);
                  toggleConfirm();
                }}
              >
              
                <i className="bx bx-stop-circle font-size-18" id="edittooltip" />
                <UncontrolledTooltip placement="top" target="edittooltip">
                  Stop Verification
                </UncontrolledTooltip>
              </Link>}
          
            </div>
          );
        },
      },
    ],
    []
  );

  const [modalConfirm, setModalConfirm] = useState(false);

  const toggleConfirm = () => setModalConfirm(!modalConfirm);



const submit = async (action) => {



  const config = {
    headers: {
      'Content-Type': 'application/json'
    }
  };


  if(state.first_name === '' || state.last_name === '' || state.email === '' || state.mobile === '' || state.address === '' || state.joining_date === '') {

    alert('Please fill all the fields');

    return;

  }

  if(state.mobile.length !== 10) {

    alert('Please enter valid mobile number');

    return;

  }

  if(roles.filter((item)=> item.checked === true).length === 0) {

    alert('Please select atleast one role');

    return;

  }

  if(components.filter((item)=> item.checked === true).length === 0) {

    alert('Please select atleast one component');


    return;

  }

  
  let selectedRoles = roles.filter((item)=> item.checked === true).map((item)=> item.id);

  let selectedComponents = components.filter((item)=> item.checked === true).map((item)=> item.id);

const body = {
  first_name: state.first_name,
  last_name: state.last_name,
  email: state.email,
  mobile: state.mobile,
  roles: selectedRoles,
  components: selectedComponents,
  account_status: state.admin_status,

}

console.log('create admin user body==>', body);


if(action === 'insert') {

 await axios.post(`${process.env.REACT_APP_BASE_URL}/employer/admin/create`, body, config)
  .then(response => {
    console.log('add admin user response',response);




  })
  .catch(error => {

    console.log('add admin user error==>',error);
  });
}else if("update"){

  body.user_id = location.state.user_id;

  console.log('update admin user body==>', body);

  await axios.put(`${process.env.REACT_APP_BASE_URL}/employer/admin/user`, body, config)
  .then(response => {
    console.log('update admin user response',response);




  })
  .catch(error => {

    console.log('update admin user error==>',error);
  });


}

}


  return (
    <React.Fragment>

      <div className="page-content">
        <div className="container-fluid">
          {/* <Breadcrumbs title="Ecommerce" breadcrumbItem="Orders" /> */}
          <Row>
            <Col xs="2">

<Card>
  <CardBody>
    
  <Nav pills className="flex-column">

<NavItem >
  <NavLink
    style={{ cursor: "pointer", fontWeight:'unset', fontSize:13 }}
    className={classnames({
      "mb-2": true,
      active: verticalActiveTab === "1",
    })}
    onClick={() => {
      toggleVertical("1");
    }}
  >
    User Information
  </NavLink>
</NavItem>

<NavItem >
  <NavLink
    style={{ cursor: "pointer", fontWeight:'unset', fontSize:13 }}
    className={classnames({
      "mb-2": true,
      active: verticalActiveTab === "2",
    })}
    onClick={() => {
      toggleVertical("2");
    }}
  >
    Role Manager
  </NavLink>
</NavItem>

<NavItem >
  <NavLink
    style={{ cursor: "pointer", fontWeight:'unset', fontSize:13 }}
    className={classnames({
      "mb-2": true,
      active: verticalActiveTab === "3",
    })}
    onClick={() => {
      toggleVertical("3");
    }}
  >
    Components Manager
  </NavLink>
</NavItem>



</Nav>
    </CardBody>
</Card>

              </Col>
            
                <Col xs="10">
                  <Card>
                    <CardBody>
                      <TabContent
                        activeTab={verticalActiveTab}
                        className="text-muted mt-4 mt-md-0"
                      >
                        <TabPane tabId="1">
                        <Row>
                        <p className="card-title mb-4" style={{fontSize:20, fontWeight:'500'}}>Manage Users</p>

<Col lg="6">
    <div className="mt-3 d-flex" style={styles.box}>
        <Label for="basicpill-firstname-input1" style={styles.boxLabel} className="control-label me-3" >
            First Name
            <span className="text-danger mx-1">*</span>
        </Label>
        <Input
            type="text"
            className="form-control me-3"
            value={state.first_name}
            // disabled={selectedValue === 'registration_no' ? false : true}
           onChange={handleInputChange}
            style={styles.boxInput}
            suppressHydrationWarning
            id="first_name"
        />


    </div>
</Col>
<Col lg="6">
    <div className="mt-3 d-flex" style={styles.box} >
        <Label for="basicpill-firstname-input1" style={styles.boxLabel} className="control-label me-3" >
            Last Name
            {/* <span className="text-danger mx-1">*</span> */}

        </Label>
        <Input
            type="text"
            className="form-control me-3"
            value={state.last_name}
            style={styles.boxInput}

            // disabled={selectedValue === 'registration_no' ? false : true}
            onChange={handleInputChange}
            suppressHydrationWarning
            id="last_name"
        />


    </div>
</Col>
<Col lg="6">
    <div className="mt-3 d-flex" style={styles.box} >
        <Label for="basicpill-firstname-input1" style={styles.boxLabel} className="control-label me-3" >
            Email
            {/* <span className="text-danger mx-1">*</span> */}

        </Label>
        <Input
            type="text"
            className="form-control me-3"
            value={state.email}
            style={styles.boxInput}

            disabled={state.isEdit}
            onChange={handleInputChange}
            suppressHydrationWarning
            id="email"
        />


    </div>
</Col>

<Col lg="6">
    <div className="mt-3 d-flex" style={styles.box} >
        <Label for="basicpill-firstname-input1" style={styles.boxLabel} className="control-label me-3" >
            Mobile
            {/* <span className="text-danger mx-1">*</span> */}

        </Label>
        <Input
            type="text"
            className="form-control me-3"
            value={state.mobile}
            style={styles.boxInput}

            // disabled={selectedValue === 'registration_no' ? false : true}
            onChange={handleInputChange}
            suppressHydrationWarning
            id="mobile"
            placeholder="eg. 9876543210"
        />


    </div>
</Col>
<Col lg="6">
    <div className="mt-3 d-flex" style={styles.box}>
        <Label for="basicpill-firstname-input1" style={styles.boxLabel} className="control-label me-3" >
            Address
            {/* <span className="text-danger mx-1">*</span> */}
        </Label>
        <Input
            type="text"
            className="form-control me-3"
            value={state.address}
            // disabled={selectedValue === 'registration_no' ? false : true}
            onChange={handleInputChange}
            style={styles.boxInput}
            suppressHydrationWarning
            id="address"
            placeholder="eg 123, xyz street"
            // placeholder="Contact Person"
        />


    </div>
</Col>

<Col lg="6">
    <div className="mt-3 d-flex" style={styles.box}>
        <Label for="basicpill-firstname-input1" style={styles.boxLabel} className="control-label me-3" >
            Joining Date
            {/* <span className="text-danger mx-1">*</span> */}
        </Label>
        <Input
            type="date"
            className="form-control me-3"
            value={state.joining_date}
            // disabled={selectedValue === 'registration_no' ? false : true}
            onChange={handleInputChange}
            style={styles.boxInput}
            suppressHydrationWarning
            id="joining_date"
      
            // placeholder="Contact Person"
        />


    </div>
</Col>

<Col lg="6">
                        <div className="d-flex mt-4" style={{justifyContent:'space-between', width:'60%'}}>
                        <Label for="basicpill-firstname-input1" style={styles.boxLabel} className="control-label me-3" >
                                    Status
                                    {/* <span className="text-danger mx-1">*</span> */}
                                </Label>

                                <div className="form-check me-3 d-flex">
<div className="form-check me-3">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="exampleRadios1"
                            id="exampleRadios7"
                            value="active"
                            checked={state.admin_status === 'active' ? true : false}
                            defaultChecked
                            onChange={(e)=>{
                              let value = e.target.value;
                              
                              setState({...state, admin_status: value});
                            }
                          }
                            
                          />
                          <label
                            className="form-check-label"
                            htmlFor="exampleRadios7"
                          >
                            Active
                          </label>
                        </div>

                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="exampleRadios1"
                            checked={state.admin_status === 'inactive' ? true : false}
                            id="exampleRadios2"
                            value="inactive"
                            onChange={(e)=>{
                                let value = e.target.value;
                                
                                setState({...state, admin_status: value});
                            }
                          }
                          />
                          <label
                            className="form-check-label"
                            htmlFor="exampleRadios2"
                          >
                            Inactive
                          </label>

              

                        
                        </div>
                        </div>
                   
                     
</div>
                            </Col>

<Col lg="12">
<div className="mt-5 text-center"  >
  {
    state.isEdit ? <button onClick={()=>submit("update")} className="btn btn-primary" style={{ width: 150, }} >Update</button> : <button className="btn btn-primary" style={{ width: 150, }} onClick={()=>submit('insert')} >Create</button>
  }
{/* <button className="btn btn-primary" style={{ width: 150, }} onClick={create} >Create</button> */}
</div>
</Col>
</Row>
                        </TabPane>
                        <TabPane tabId="2">
<div className="d-flex" style={{justifyContent:'space-between'}}>
<Table striped bordered style={{width:'50%'}}>
          <thead>
            <tr>
              <th style={{width:100}}>Assign</th>
              <th>Role Name</th>
            </tr>
          </thead>
          <tbody>
            {roles.map((item, index) => (
              <tr key={item}>
                <td>
                  <input
                    type="checkbox"
                    checked={item.checked}
                    onChange={() => {
                       
                      let arr = [...roles]

                      arr[index].checked = !arr[index].checked

                      setRoles(arr)
                    }}
                  />
                </td>
                <td>{item.role}</td>
              </tr>
            ))}
          </tbody>
        </Table>

</div>
           


                    
                        </TabPane>
                        <TabPane tabId="3">
                    <div className="d-flex" style={{justifyContent:'space-between'}}>
                    <Table striped bordered style={{width:'50%'}}>
          <thead>
            <tr>
              <th style={{width:100}}>Select</th>
              <th>Component Name</th>
            </tr>
          </thead>
          <tbody>
            {components.map((item, index) => (
              <tr key={item}>
                <td>
                  <input
                    type="checkbox"
                    checked={item.checked}
                    onChange={() => {
                       
                      let arr = [...components]

                      arr[index].checked = !arr[index].checked

                      setComponents(arr)
                    }}
                  />
                </td>
                <td>{item.component}</td>
              </tr>
            ))}
          </tbody>
        </Table>


                    </div>

                        </TabPane>
                        </TabContent>




                    </CardBody>
                  </Card>
                </Col>
            
          </Row>


         
        </div>
      </div>

    </React.Fragment>
  );
}
AddNewUser.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
};

export default AddNewUser;

const styles = {

  para: {
      fontSize: 12
  },
  box: {
      fontSize: 16,
      fontWeight: "unset",
      justifyContent: "space-between",
      alignItems: "center",
  },
  boxLabel: {
      fontSize: 13,
      fontWeight: "400",
  },
  boxInput: {
      fontSize: 13,
      width: '70%',
      padding: 8,
      fontWeight: "unset",
  }

}
