import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Card, CardBody, Col, Container, Row, Table } from "reactstrap";
import { isEmpty, map } from "lodash";

//Import Breadcrumb
// import Breadcrumbs from "../../components/Common/Breadcrumb";

//Import Image
// import logo from "../../assets/images/logo-dark.png";
// import logoLight from "../../assets/images/logo-light.png";
// import { getInvoiceDetail as onGetInvoiceDetail } from "../../store/invoices/actions";
//redux
// import { useSelector, useDispatch } from "react-redux";
// import { createSelector } from "reselect";

const Report = (props) => {
  //meta title
  document.title =
    "TheRollnumber | Report";


  const location = useLocation();

  const navigate = useNavigate();

    useEffect(() => {

   
      console.log(location)
      
      const id = location.pathname.split('/')[3];

  
      navigate(`/admin/report`, {
  
        state: {  id: id 
        }
  
       })
  
    }, []);


  const printInvoice = () => {
    window.print();
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>

       
        </Container>
      </div>
    </React.Fragment>
  );
};


export default Report;
