


import React, { useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import { Link, useNavigate, useLocation, useParams } from "react-router-dom";
import { ceil, get, isEmpty, set } from "lodash";
import "bootstrap/dist/css/bootstrap.min.css";
import TableContainer from "../../components/Common/TableContainerListCases";
import * as Yup from "yup";
import { useFormik } from "formik";
import axios from "axios";
import Accordion from "../../../components/Accordian";

import Cookies from "js-cookie";
import Loader from "../../components/Loader";



import {
  Button,
  Col,
  Row,
  UncontrolledTooltip,
  
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  Input,
  FormFeedback,
  Label,
  Card,
  CardBody,
  Table,
} from "reactstrap";
import moment from "moment";

//Import Flatepicker
import "flatpickr/dist/themes/material_blue.css";
import Flatpickr from "react-flatpickr";

function ViewEpfo() {
  //meta title
  document.title = "TheRollNumber";

  const navigate = useNavigate();

  const params = useParams();

  const location = useLocation();


  const [user, setUser] = useState(null)

  const [tds, setTds] = useState(null)

  const [state, setState] = useState({
    epfo_no:"",
    otp:"",
    epfo_client_id:"",
    isOtp:false,
    epfo_data:"",
  
    epfo_kyc:"",
    epfo_bank:"",
    epfo_companies:[],
    epfo_passbook:[],
    contentData:[],
  isLoading:false,
  activeSections: [],
  collapsed: true,
  multipleSelect: false,
  })

  useEffect(() => {

    console.log('location and params===>', location, params)

    get_requested_data()

    search_wallet(params.walletID)


  }, []);
  
  const search_wallet = async (value) => {

    const config = {
      headers: {
        'Content-Type': 'application/json'
      }
    };

 
    axios.get(`${process.env.REACT_APP_BASE_URL}/service/wallet/${value}`, {}, config)
    .then(response => {
      console.log('wallet data',response.data);

      let data = response.data;

      console.log('data==>',data);

      setUser(data.user);


    })
    .catch(error => {
  
  
      console.log('error==>',error);
    });

  }

  const compareLatestYear =(a, b)=> {
    const yearA = parseInt(a.assessment_year.split("-")[1], 10);
    const yearB = parseInt(b.assessment_year.split("-")[1], 10);

  
    return yearB - yearA; // Sort in descending order (latest year first)
  }

  const compareLatestYear2 =(a, b)=> {

    const yearA = parseInt(a[1].assessment_year,b[1].assessment_year.split("-")[1], 10);
    const yearB = parseInt(b[1].assessment_year,b[1].assessment_year.split("-")[1], 10);

  
     return yearB - yearA; // Sort in descending order (latest year first)
  }


  const get_requested_data = async () => {

    const caseData = {
      walletID: params.walletID,
      org_client_id: params.client_id
    }

    const config = {
      headers: {
        'Content-Type': 'application/json',

      }
    };

    const body = {

      org_user_client_id: caseData.org_client_id,
    
    }

    console.log('body requested ==>',body);

     await axios.post(`${process.env.REACT_APP_BASE_URL}/service/requestedData/${caseData.walletID}`, body, config)
      .then(response => {
        console.log('requested data', response);

        let data = response.data.data

        console.log('data==>', data);

        let epfo = data.find((d)=>Object.keys(d)[0] == 'epfo')?.epfo

        console.log('epfo==>', epfo);

        if(epfo){

          let companies = epfo.epfo_company;

          let passbook = epfo.epfo_passbook;

          let epfo_kyc = epfo.epfo_kyc;

          let epfo_bank = epfo.epfo_bank;

          let epfo_employee = epfo.epfo_employee;

          

           let contentData =  companies.map((c)=>{

    
              return {
                title: c.company_name,
                id: c.establishment_id,
                content: passbook.filter((i)=> {

                  let member_id = i.member_id;
            
            let newId = member_id.substring(0, member_id.length - 7).replace(/\s/g, "");
            
             return newId == c.establishment_id.replace(/\s/g, "");
            
            
                })
               }

        

             
           })

            console.log('contentData==>', contentData);


          setState({
            ...state,
             epfo_no: epfo.epfo_employee?.pf_uan,
             epfo_companies: companies,
             epfo_bank: epfo_bank,
             epfo_data: epfo_employee,
             epfo_kyc: epfo_kyc,
             contentData: contentData,
             epfo_passbook:  passbook, isLoading:false})
        
        }else{
          setState({...state, isLoading:false})
        }

      })
      .catch(error => {
        console.log('error', error);

      })

  
 
  }




  const [isLoading, setLoading] = useState(false)

  const columns = useMemo(
    () => [


      {
        Header: "Member ID",
        accessor: "member_id",
        filterable: true,
        Cell: (cellProps) => {
          return cellProps.value;
        },
      },

  
      {
        Header: "Date of Joining",
        accessor: "doj_epf",
        filterable: true,
        Cell: (cellProps) => {
          return cellProps.value;
        },
      },

      {
        Header: "Approved On",
        accessor: "approved_on",
        filterable: true,
        Cell: (cellProps) => {
          return cellProps.value;
        },
      },

      {
        Header: "Month",
        accessor: "month",
        filterable: true,
        Cell: (cellProps) => {
          return cellProps.value;
        },
      },

      {
        Header: "Employer Share",
        accessor: "employer_share",
        filterable: true,
        Cell: (cellProps) => {
          return cellProps.value;
        },
      },

   

     
    ],
    [state.contentData]
  );


  return (
    <React.Fragment>

      <div className="page-content">
        <div className="container-fluid">
          {/* <Breadcrumbs title="Ecommerce" breadcrumbItem="Orders" /> */}
          <Row>
            {
            
                <Col xs="12">
   
                      {
                       state.contentData.length > 0 && state.contentData.map((item, index) => {

                          return   <Card> <CardBody><div key={index}>
                        <div>
      <div  style={{justifyContent:'space-between'}}>

<Row>

  <Col lg={12}>
  <p className="text-primary" style={{fontSize:18,marginBottom:30, fontWeight:'500', textAlign:'center'}}>{item.title}</p>

    </Col>

  <Col lg={4}>
  <div style={{display:'flex'}}>

<p style={{fontSize:16, fontWeight:'500'}}>Candidate name</p>
<p style={{fontSize:16, fontWeight:'300'}}>: {user?.first_name}</p>
</div>

  </Col>
  <Col lg={4}>
  <div style={{display:'flex'}}>

<p style={{fontSize:16, fontWeight:'500'}}>Pf uan</p>
<p style={{fontSize:16, fontWeight:'300'}}>: {state.epfo_data?.pf_uan}</p>
</div>


  </Col>
  <Col lg={4}>
  <div style={{display:'flex'}}>

<p style={{fontSize:16, fontWeight:'500'}}>establishment_id</p>
<p style={{fontSize:16, fontWeight:'300'}}>: {item.id}</p>
</div>

  </Col>




</Row>

<Row className="mt-3">

  <Col lg={4}>
  <div style={{display:'flex'}}>

<p style={{fontSize:16, fontWeight:'500'}}>Father name</p>
<p style={{fontSize:16, fontWeight:'300'}}>: {state.epfo_data?.father_name}</p>
</div>

    </Col>

    <Col lg={4}>
    <div style={{display:'flex'}}>

<p style={{fontSize:16, fontWeight:'500'}}>Account number</p>
<p style={{fontSize:16, fontWeight:'300'}}>: {state.epfo_bank?.account_number}</p>
</div>
      </Col>

      <Col lg={4}>
      <div style={{display:'flex'}}>

<p style={{fontSize:16, fontWeight:'500'}}>IFSC</p>
<p style={{fontSize:16, fontWeight:'300'}}>: {state.epfo_bank?.ifsc}</p>
</div>
        </Col>

</Row>

 
<Row className="mt-3">

<Col lg={4}>
<div style={{display:'flex'}}>

<p style={{fontSize:16, fontWeight:'500'}}>Pan number</p>
<p style={{fontSize:16, fontWeight:'300'}}>: {state.epfo_kyc?.pan_number}</p>
</div>

  </Col>

  <Col lg={4}>
  <div style={{display:'flex'}}>

<p style={{fontSize:16, fontWeight:'500'}}>Aadhaar number</p>
<p style={{fontSize:16, fontWeight:'300'}}>: {state.epfo_kyc?.aadhaar_number}</p>
</div>
    </Col>

 

</Row>

      </div>

      <TableContainer
                        columns={columns}
                        data={item.content}
                        isFilter={false}
                        

                        isGlobalFilter={false}
                        isAddOptions={true}
         
                        customPageSize={12}
                        isPagination={true}
                        filterable={false}
                        iscustomPageSizeOptions={true}
                        tableClass="align-middle table-nowrap table-check"
                        theadClass="table-light"
                        pagination="pagination pagination-rounded justify-content-end mb-2"
                      />


    </div>
    </div>
    </CardBody></Card>
  
                        })

  
  }

                
                </Col>
            }
          </Row>


        </div>
      </div>

    </React.Fragment>
  );
}
ViewEpfo.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
};

export default ViewEpfo;
