import React, { useEffect } from 'react';
import {View, StyleSheet, Text, Image } from '@react-pdf/renderer';
import InvoiceTableHeader from './InvoiceTableHeader'
import InvoiceTableRow from './InvoiceTableRow'
import InvoiceTableBlankSpace from './InvoiceTableBlankSpace'
import InvoiceTableFooter from './InvoiceTableFooter'
import ProfileImg from '../../report_images/profile-default.png'

const tableRowsCount = 11;

const styles = StyleSheet.create({
    tableContainer: {
        flexDirection: 'row',
        flexWrap: 'wrap',

        padding:20,
        paddingVertical:8
      
       
    },
    tableText:{
      fontSize: 10,
      fontFamily:'Montserrat-Light',
      width: '100%',
    },
    container: {
    marginTop: 24,
  },
    text:{
      fontSize: 13,
      color: '#061c33',
      lineHeight:1,
      textAlign: 'center',
      fontFamily:'Montserrat',
    
    },
    box: {
      width: '100%', // Specify the width of the box
      height: 24, // Specify the height of the box
      backgroundColor: '#d4ddf1',
      justifyContent: 'center', // Center horizontally
      alignItems: 'center', // Center vertically
      margin: 'auto', // Center the box itself if within a larger container
    },
    table: {
      display: "table",
      width: "auto",
      borderStyle: "solid",
      borderWidth: 0.8,
      borderColor: '#bfbfbf',
      borderRightWidth: 0,
      borderBottomWidth: 0,
    },
    row: {
      flexDirection: "row",
      width: "100%",
    },
    col: {
      borderStyle: "solid",
      height:'auto',
       padding:5,

      borderWidth: 0.8,
   
      borderColor: '#bfbfbf',
      borderLeftWidth: 0,
      borderTopWidth: 0,
    },
    profile:{
      width:'100%',
      display: "flex",
      marginTop:10,
      flexDirection: "row",
      justifyContent: "space-between",
     
     
    },
    imageBox:{
      width:'25%',
      height:100,
      alignSelf:'center',
      // backgroundColor: 'lightgray',
      justifyContent: 'center', // Center horizontally
      alignItems: 'center', // Center vertically
      margin: 'auto', // Center the box itself if within a larger container
    }
});


const doc_names = {
  "aadhaar":"Aadhaar Card",
  "pan":"PAN Card",
  "passport":"Passport",
  "doctor":"Doctor",
  "nurse":"Nurse",
  "voter_id":"Voter ID",
}



  const InvoiceItemsTable = ({invoice, data,  requestedData, candidate}) => {

    const [components, setComponents] = React.useState([]);

    useEffect(() => {

      console.log('candiate==>',candidate);

    }, [candidate]);

    useEffect(() => {


   getComponentName();

    }, []);

    const getComponentName = () => {

            const sortList = ["aadhaar", "pan", "voter_id", "dl", "doctor", "nurse", "education", "employment","credit_score", "epfo", "tds"];

const sortedObj = requestedData.sort((a, b) => {

  // console.log('a==>',sortList.indexOf(Object.keys(a)[0]));
    return (
        sortList.indexOf(Object.keys(a)[0]) - sortList.indexOf(Object.keys(b)[0])
    );
});
      let temp = [];

      
      sortedObj.map((item, index) => {

        console.log('item======>', item);

        if(Object.keys(item)[0] == 'pan'){
            
          temp.push({

            name: 'PAN Card',
            id: item.pan.pan_number,
            status: "Verified",
          
        })

        }
        
        if(Object.keys(item)[0] == 'aadhaar'){
            
          temp.push({

            name: 'Aadhaar Card',
            id: item.aadhaar.aadhaar_number,
            status: "Verified",
          
        })

        }

        
        if(Object.keys(item)[0]== 'voter_id'){

          temp.push({

            name: 'Voter ID',
            id: item.voter_id.epic_no,
            status: "Verified",
          
        })

        }

        if(Object.keys(item)[0]== 'doctor'){

          temp.push({

            name: 'Doctor',
            id: item.doctor.doctor_id,
            status: "Verified",
          
        })

        }

        if(Object.keys(item)[0]== 'nurse'){
              
            temp.push({
  
              name: 'Nurse',
              id: item.nurse.id_number,
              status: "Verified",
            
          })
  
          }

          if(Object.keys(item)[0]== 'credit_score'){
              
            temp.push({
  
              name: 'Credit Report',
              id: item.credit_score[0].credit_score,
              status: "Verified",
            
          })
  
          }

        if(Object.keys(item)[0] == 'education'){
            
          temp.push({

            name: 'Education',
            id: "",
            status: "Verified",
          
        })

        }

        if(Object.keys(item)[0] == 'employment'){
            
          temp.push({

            name: 'Employment',
            id: "",
            status: "Verified",
          
        })

        }


  
      })

      console.log('temp==>',temp);
      setComponents(temp);

    }

    return (

      <View style={styles.container}>
 <View style={styles.tableContainer} >

<View style={styles.box}>
  <Text  style={styles.text}>Profile Details</Text>
</View>

<View style={styles.profile}>

{/* image section */}

<View style={styles.imageBox}>

<Image src={ProfileImg} style={{width: 80, alignSelf:'flex-start'}} />
</View>


{/* table */}
<View style={[styles.table, {width:'70%'}]}>
<View style={styles.row}>
          <View style={[styles.col, {width:'35%'}]}>
            <Text >Wallet ID</Text>
            </View>
            <View style={[styles.col, {width:'65%'}]}>
            <Text style={styles.tableText}>{candidate?.id_identity_user}</Text>
            </View>
    </View>
    <View style={styles.row}>
          <View style={[styles.col, {width:'35%'}]}>
            <Text >Name</Text>
            </View>
            <View style={[styles.col, {width:'65%'}]}>
            <Text style={styles.tableText}>{candidate?.first_name} {candidate?.last_name}</Text>
            </View>
    </View>

    <View style={styles.row}>
          <View style={[styles.col, {width:'35%', 
        }]}>
            <Text >Permanent address</Text>
            </View>
            <View style={[styles.col, {width:'65%', 
          flexDirection:'row',
          flexWrap:'wrap'

          }]}>
            <Text style={styles.tableText}>{candidate?.permanent_address}
</Text>
            </View>
    </View>

    <View style={styles.row}>
          <View style={[styles.col, {width:'35%', 
        }]}>
            <Text >Current address</Text>
            </View>
            <View style={[styles.col, {width:'65%', 
          flexDirection:'row',
          flexWrap:'wrap'

          }]}>
            <Text style={styles.tableText}>{candidate?.present_address}
</Text>
            </View>
    </View>




    <View style={styles.row}>
          <View style={[styles.col, {width:'35%'}]}>
            <Text >Date of Verification</Text>
            </View>
            <View style={[styles.col, {width:'65%'}]}>
            <Text >{data.timestamp}</Text>
            </View>
    </View>
  </View>
</View>


  {/* <InvoiceTableHeader />

  {
    components.length > 0 && <InvoiceTableRow items={components} data={requestedData} />
  }
   */}
  {/* <InvoiceTableBlankSpace rowsCount={ tableRowsCount - invoice.items.length} />
  <InvoiceTableFooter items={invoice.items} /> */}
</View>

<View style={styles.tableContainer} >

<View style={styles.box}>
  <Text  style={styles.text}>Result</Text>
</View>





{/* table */}
<View style={[styles.table, {width:'100%'}]}>
    <View style={styles.row}>
          <View style={[styles.col, {width:'35%'}]}>
            <Text >Risk</Text>
            </View>
            <View style={[styles.col, {width:'65%'}]}>
            <Text ></Text>
            </View>
    </View>
    <View style={styles.row}>
          <View style={[styles.col, {width:'35%'}]}>
            <Text >Summary</Text>
            </View>
            <View style={[styles.col, {width:'65%'}]}>
            <Text >
</Text>
            </View>
    </View>

    <View style={styles.row}>
          <View style={[styles.col, {width:'35%'}]}>
            <Text >AML/PEP</Text>
            </View>
            <View style={[styles.col, {width:'65%'}]}>
            <Text ></Text>
            </View>
    </View>
 
  
  </View>


  {/* <InvoiceTableHeader />

  {
    components.length > 0 && <InvoiceTableRow items={components} data={requestedData} />
  }
   */}
  {/* <InvoiceTableBlankSpace rowsCount={ tableRowsCount - invoice.items.length} />
  <InvoiceTableFooter items={invoice.items} /> */}
</View>

<View style={styles.tableContainer} >

<View style={styles.box}>
  <Text  style={styles.text}>Verification Summary</Text>
</View>





{/* table */}
<View style={[styles.table, {width:'100%'}]}>
    <View style={styles.row}>
          <View style={[styles.col, {width:'35%'}]}>
            <Text >Component</Text>
            </View>
            <View style={[styles.col, {width:'35%'}]}>
            <Text >Document ID</Text>
            </View>
            <View style={[styles.col, {width:'35%'}]}>
            <Text >Status</Text>
            </View>
    </View>


    {
    components.length > 0 && 

    components.map((item, index) => {
      return (
        <View style={styles.row}>
        <View style={[styles.col, {width:'35%'}]}>
          <Text style={styles.tableText}>{item.name}</Text>
          </View>
          <View style={[styles.col, {width:'35%'}]}>
          <Text style={styles.tableText}>{item.id}</Text>
          </View>
          <View style={[styles.col, {width:'35%'}]}>
          <Text style={styles.tableText}>{item.status}</Text>
          </View>
  </View>
      )
    }
  
      )
  }
   

  
  </View>


  {/* <InvoiceTableHeader />

  {
    components.length > 0 && <InvoiceTableRow items={components} data={requestedData} />
  }
   */}
  {/* <InvoiceTableBlankSpace rowsCount={ tableRowsCount - invoice.items.length} />
  <InvoiceTableFooter items={invoice.items} /> */}
</View>
      </View>
   
    )
  };
  
  export default InvoiceItemsTable