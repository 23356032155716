import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Link, useNavigate } from "react-router-dom";
import {
    Card, CardBody, Col, Container, Row, Table,
    Label,
    Input,
    Form,
    FormGroup,

    Nav,
    NavItem,
    NavLink,
    TabContent,
    TabPane, CardTitle


} from "reactstrap";
import { isEmpty, map, set } from "lodash";
import Dropzone from "react-dropzone";
import * as XLSX from 'xlsx';
import Papa from 'papaparse';
import axios from 'axios';
import Loader from "../../../components/Loader";

import classnames from "classnames";



const AddSla = (props) => { 
    //meta title
    document.title =
        "TheRollNumber";


    const [data, setData] = React.useState([]);

    const [loading, setLoading] = React.useState(false);

    const [disabled, setDisabled] = React.useState(false);

    const [components, setComponents] = React.useState([]);

    const [values, setValues] = React.useState([

    ])

    const [locations, setLocation] = React.useState([

]);


    const Navigate = useNavigate();

    const [user, setUser] = React.useState({});

    const get_components = async () => {

    
        const config = {
          headers: {
            'Content-Type': 'application/json'
          }
        };
       
        axios.get(`${process.env.REACT_APP_BASE_URL}/service/components`, {}, config)
    
        .then(response => {
    
          console.log('response components==>',response)
    
          const data = response.data;
    
         console.log('data==>',data)

         let obj = {}

            let arr = []

            let options = []

            // set 1 to 90 days

            for(let i = 1; i <= 90; i++) {

                options.push(i)

            }

    
          data.map((item)=> {

            if(item.status){

                obj[item.code] = "";

                arr.push({
                    component: item.name,
                    code: item.code,
                    value: "",
                    
                    checked: false,
                    id: item.id
                  })

            }
    
            
    
          })

          setValues(options)

          setState({
            ...state,
            ...obj
          
          });
    
    
          setComponents(arr)
    
          setLoading(false);
    
    
    
    
        })
    
        .catch(error => {
    
          setLoading(false);
    
          console.log('error from server==>',error)
    
        })
    
    
      }

    const [state, setState] = React.useState({

        wef: "",
        wet: "",

    });

    const handleInputChange = (e) => {
        const { id, value } = e.target;
        setState((prevState) => ({
            ...prevState,
            [id]: value,
        }));
    };

    const getAllLocation = async () => {

  
  
        const config = {
          headers: {
            'Content-Type': 'application/json'
          }
        };
    
    
      
       await axios.get(`${process.env.REACT_APP_BASE_URL}/service/companyLocation`, {}, config)
        .then(response => {
          console.log('all location response',response.data);
    
            let data = response.data;

            let arr = []


    
            data.map((location, index) => {

                arr.push({id: location.LocationID, name: location.location_name, selected: false});
            }
            )

            setLocation(arr);
          
      
        })
        .catch(error => {
      
          console.log('error==>',error);
        });
  
      };

const add_user = async () => {

     console.log('state==>', state);

//       const company_name = JSON.parse(localStorage.getItem('authUser')).user.company_name;


//     const config = {
//       headers: {
//         'Content-Type': 'application/json'
//       }
//     };

// const body = {
//     first_name: state.first_name,
//     last_name: state.last_name,
//     email: state.email,
//     company_name: company_name,
//     phone_number: state.contact_person,
//     role: state.role,
//     account_status: state.client_status,
//     company_location: JSON.stringify(state.client_location)

// }

// console.log('body==>', body);
  
//    await axios.post(`${process.env.REACT_APP_BASE_URL}/employer/addUser`, body, config)
//     .then(response => {
//       console.log('add user response',response);

//       props.reFetch()

  
//     })
//     .catch(error => {
  
//       console.log('add user error==>',error);
//     });
  
  }

  useEffect(() => {


    get_components();


}, []);


    // useEffect(() => {


    //     if (props.data && props.isEdit) {

    //         const user =  JSON.parse(localStorage.getItem('authUser')).user;

    //         let data = { ...props.data };
    //         console.log('data==>', data);
    //         // setState(data);

    //         setState({...state, 
            
    //             first_name: data.first_name,
    //             last_name: data.last_name,
    //             email: data.email,
    //             contact_person: data.phone_number,
    //         });

    //         if(user.email === data.email) {

              
    //             setDisabled(true);

                

    //         }
    //     }

    // }, [props.data]);


    const update_user = () => {

        const config = {
          headers: {
            'Content-Type': 'application/json'
          }
        };
      
        const body = {
            first_name: state.first_name,
            last_name: state.last_name,
            email: state.email,
            phone_number: state.contact_person,
            role: state.role,
            account_status: state.client_status,
            company_location: JSON.stringify(state.client_location)
        
        }
        
        console.log('body==>', body);
          
           axios.put(`${process.env.REACT_APP_BASE_URL}/employer/updateUser`, body, config)
            .then(response => {
              console.log('update user response',response);
        
              props.reFetch()

          
            })
            .catch(error => {
          
              console.log('update user error==>',error);
            });

    }




    return (
        <React.Fragment>


            <Card>
                <CardBody>
   

<Row>

<Col md={3} >

<FormGroup>
    <Label style={styles.boxLabel} for="wef">W.E.F</Label>
    <Input type="date" style={styles.boxInput}  id={'wef'} value={state.wef}  onChange={(e) => {
        handleInputChange(e)
    }} />
</FormGroup>

</Col>

<Col md={3} >

<FormGroup>
    <Label style={styles.boxLabel} for="wet">W.E.T</Label>
    <Input type="date" style={styles.boxInput}  id={'wet'} value={state.wet}  onChange={(e) => {
        handleInputChange(e)
    }} />
</FormGroup>

</Col>
    {
        components.map((item, index) => {

            return (
                <Col md={3} key={index}>

                    <FormGroup>
                        <Label style={styles.boxLabel} for="first_name">{item.component}</Label>


                        <select className="form-select" style={styles.boxInput}  id={item.code} value={state[item.code]}  onChange={(e) => {
                            handleInputChange(e)
                        }} >
                            <option value="">Select</option>

                            {
                                values.map((value, index) => {

                                    return <option key={index} value={value}>{value}</option>
                                })
                            }

                            </select>


                        {/* <Input type="number" style={styles.boxInput} placeholder="0.0"  id={item.code} value={state[item.code]}  onChange={(e) => {
                            handleInputChange(e)
                        }} /> */}
                    </FormGroup>

                </Col>
            )
        })
    }
    </Row>                  

                    <div className="mt-5 text-center"  >
                        {
                            props.isEdit ? <button className="btn btn-primary" style={{ width: 150, }} onClick={() => { }} >Update</button>
                                : <button className="btn btn-primary" style={{ width: 150, }} onClick={add_user} >Save</button>
                        }
                        </div>

                </CardBody>
            </Card>

        </React.Fragment>
    );
};


export default AddSla;

const styles = {

    para: {
        fontSize: 12
    },
    box: {
        fontSize: 16,
        fontWeight: "unset",
        justifyContent: "space-between",
        alignItems: "center",
    },
    boxLabel: {
        fontSize: 13,
        fontWeight: "400",
    },
    boxInput: {
        fontSize: 13,
        width: '70%',
        padding: 8,
        fontWeight: "unset",
    }

}
