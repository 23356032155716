


import React, { useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { isEmpty } from "lodash";
import "bootstrap/dist/css/bootstrap.min.css";
import TableContainer from "../components/Common/TableContainerCases";
import * as Yup from "yup";
import { useFormik } from "formik";
import Papa from 'papaparse'
import axios from 'axios';
import Cookies from 'js-cookie';


import * as XLSX from 'xlsx';


import {
  Button, 
  Col,
  Row,
TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,

  Card,
  CardBody,
  Table,
} from "reactstrap";
import moment from "moment";

import classnames from "classnames";

//Import Flatepicker
import "flatpickr/dist/themes/material_blue.css";
import Flatpickr from "react-flatpickr";
import Loader from "../components/Loader";

function Cases() {
  //meta title
  document.title = "TheRollNumber";

  const navigate = useNavigate();

  const location = useLocation();

  const [activeTab, setActiveTab] = useState("1");

  const toggleTab = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  }



  const [modal, setModal] = useState(false);
  const [modal1, setModal1] = useState(false);
  const [isEdit, setIsEdit] = useState(false);

  const [orderList, setOrderList] = useState([]);
  const [order, setOrder] = useState(null);
  const [data, setData] = useState([]);
  const [data2, setData2] = useState([]);
  const [caseData, setCases] = useState([]);




  const toggleViewModal = () => setModal1(!modal1);

  useEffect(() => {

    fetch_Wallet_details();
    

  }, []);


  const fetch_Wallet_details = async () => {
    const config = {
      headers: {
        'Content-Type': 'application/json'
      }
    };
   
    axios.get(`${process.env.REACT_APP_BASE_URL}/service/wallet_details/1`, {}, config)

    .then(response => {

      console.log('response from server==>',response)

      const res = response.data

      let arr = [];

      let arr2 =[]

       res.map((item, index) => {

         if(item.search_status == 'Found'){

           arr.push(item)

         }else{
          
           arr2.push(item)
         }

      })

      setData(arr)

      setData2(arr2)

      cases();


      // Navigate('/verify', {state: {zipFile: response.data}})

      

    })

    .catch(error => {

      console.log('error from server==>',error)


    })
  }

  useEffect(() => {

    if(caseData.length > 0){


      let arr = [...caseData];

      console.log('data',data);

      let arr2 = [...data, ...data2];

      let merge_arr = arr2.map((item, index) => {

        let found = arr.find((element) => element.walletID == item.walletID);

        if(found){

          item['notification_status'] = 'sent';
          item['request_id'] = found.request_id;

        }

        return item;

      }
      )

      let found = [...merge_arr].filter((item) => item.notification_status == 'sent');

      let not_found = [...merge_arr].filter((item) => item.notification_status != 'sent');

      setData(found);


    let new_arr =   not_found.map((item, index) => {

        let found = arr.find((element) => element.email == item.email);

        if(found){

          item['notification_status'] = found.notification_status;
          item['request_id'] = found.request_id;

        }

       })

      setData2(not_found);

      console.log('merge_arr',not_found);

    }

  }, [caseData]);


  const [loading, setLoading] = useState('')



  const toggle = () => {
    if (modal) {
      setModal(false);
      setOrder(null);
    } else {
      setModal(true);
    }
  };

  //delete order
  const [deleteModal, setDeleteModal] = useState(false);

  const cases = async() =>{

    const config = {
      headers: {
        'Content-Type': 'application/json'
      }
    };
   
    await axios.get(`${process.env.REACT_APP_BASE_URL}/service/cases`, {}, config)

    .then(response => {

      console.log('response from server==>',response)

    setCases(response.data)

      // setData(merge_arr);

    })

    .catch(error => {

      console.log('error from server==>',error)
    })

  }





  const columns = useMemo(
    () => [
   

      {
        Header: "Wallet ID",
        accessor: "walletID",
        filterable: true,
        Cell: (cellProps) => {
          return cellProps.value;
        },
      },
      {
        Header: "Candidate Name",
        accessor: "candidate_name",
        filterable: true,
        Cell: (cellProps) => {
          return cellProps.value;
        },
      },
      {
        Header: "Package Name",
        accessor: "packageType",
        filterable: true,
        Cell: (cellProps) => {
          return cellProps.value;
        },
      },
      {
        Header: "Order ID",
        accessor: "request_id",
        filterable: true,
        Cell: (cellProps) => {
          return cellProps.value;
        },
      },

      {
        Header: "Email",
        accessor: "email",
        filterable: true,
        Cell: (cellProps) => {
          return cellProps.value;
        },
      },


      {
        Header: "Notification status",
        accessor: "notification_status",
        filterable: true,
        Cell: (cellProps) => {
          return cellProps.value;
        },
      },

      {
        Header: "Verification status",
        accessor: "verification_status",
        filterable: true,
        Cell: (cellProps) => {
          return cellProps.value;
        },
      },

      {
        Header: "Timestamp",
        accessor: "timestamp",
        filterable: true,
        Cell: (cellProps) => {
          return cellProps.value;
        },
      },
 


    ],
    []
  );

  const columns2 = useMemo(
    () => [
   

      {
        Header: "Wallet ID",
        accessor: "walletID",
        filterable: true,
        Cell: (cellProps) => {
          return cellProps.value;
        },
      },
      {
        Header: "Candidate Name",
        accessor: "candidate_name",
        filterable: true,
        Cell: (cellProps) => {
          return cellProps.value;
        },
      },
      {
        Header: "Package Name",
        accessor: "packageType",
        filterable: true,
        Cell: (cellProps) => {
          return cellProps.value;
        },
      },
      {
        Header: "Order ID",
        accessor: "request_id",
        filterable: true,
        Cell: (cellProps) => {
          return cellProps.value;
        },
      },

      {
        Header: "Email",
        accessor: "email",
        filterable: true,
        Cell: (cellProps) => {
          return cellProps.value;
        },
      },


      {
        Header: "Notification status",
        accessor: "notification_status",
        filterable: true,
        Cell: (cellProps) => {
          return cellProps.value;
        },
      },

      {
        Header: "Verification status",
        accessor: "verification_status",
        filterable: true,
        Cell: (cellProps) => {
          return cellProps.value;
        },
      },

      {
        Header: "Timestamp",
        accessor: "timestamp",
        filterable: true,
        Cell: (cellProps) => {
          return cellProps.value;
        },
      },
 


    ],
    []
  );

  const handleOrderClicks = (rowInfo) => {

  }

  const get_package_details = async (wallet, type) => {

    console.log('package details wallet', wallet, type)
    

    const config = {
      headers: {
        'Content-Type': 'application/json'
      }
    };
   
    await axios.get(`${process.env.REACT_APP_BASE_URL}/service/package_detail/${wallet.packageType}`, {}, config)

    .then(async response => {

      // console.log('response from server==>',response)

      if(type == 'send'){

        console.log('send');

        console.log('data',response.data[0]);

     await send_bgv(response.data[0], wallet);


       
        }

      if(type == 'create'){

        console.log('create');

        console.log('data',response.data[0]);

        await create_request(response.data[0], wallet);

      }


    })

    .catch(error => {

      console.log('error from server==>',error)
    })

  }


  const assignCase = async(item)=>{

    const user = JSON.parse(Cookies.get('authUser'))


    const config = {

      headers: {
        'Content-Type': 'application/json'
      }

    };


    const body = {
      case_id: item.request_id,
      org_emp_id: user.user_id,
      org_client_id: item.org_client_id,
      team: 'Data Entry Team Member',
      case_status: 'Assigned'
     
    }

    console.log('id==>', body)

    axios.post(`${process.env.REACT_APP_BASE_URL}/employer/admin/assignCase`, body, config)

    .then(response => {

      console.log('assign case==>',response)


    })

    .catch(error => {

      console.log('error from server==>',error)

    })
  }

  const send_bgv = async (obj, wallet) => {

    const location = JSON.parse(localStorage.getItem('authUser')).user.company_location


    console.log('send bgv', obj, wallet);
  
    const config = {
      headers: {
        'Content-Type': 'application/json'
      }
    };

  

    let body = {
      id_identity_user: wallet.walletID,
      org_id: obj.org_id,
      title: obj.title,
      email: wallet.email,
      education: obj.education,
      employment: obj.employment,
      requester_category: obj.requester_category,
      identity: obj.identity,
      professional_identity: obj.professional_identity,
      req_data: obj.req_data,
      requester_fcm_token:"23423f32423423",
      company_location: location,
      candidate_dob: wallet.candidate_dob,
      candidate_name: wallet.candidate_name,
      packageType: wallet.packageType,
      candidate_father_name: wallet.candidate_father_name,
      body:"Request for documents"
  
      
  }
  
  
  
  console.log('body==>',body);
  
  // https://trnapi.therollnumber.com
  
   await axios.post(`${process.env.REACT_APP_BASE_URL}/service/request_identity_org`, body, config)
    .then(response => {
      console.log('bgv response',response);

      
      const data = response.data

      console.log('notification data==>',data);

      assignCase(data);

  
    })
    .catch(error => {
  
      console.log('error==>',error);
    });
  
  }

  const create_request = async (obj, wallet) => {

    const location = JSON.parse(localStorage.getItem('authUser')).user.company_location

    console.log('create bgv');
  
    const config = {
      headers: {
        'Content-Type': 'application/json'
      }
    };


    let body = {
      org_id: obj.org_id,
      title: obj.title,
      location: location,
      education: obj.education,
      email: wallet.email,
      employment: obj.employment,
      requester_category: obj.requester_category,
      identity: obj.identity,
      professional_identity: obj.professional_identity,
      candidate_dob: wallet.candidate_dob,
      candidate_name: wallet.candidate_name,
      candidate_father_name: wallet.candidate_father_name,
      packageType: wallet.packageType,
      req_data: obj.req_data,

  
      
  }
  

  
   await axios.post(`${process.env.REACT_APP_BASE_URL}/service/queue_notification`, body, config)
    .then(response => {
      console.log('bgv response',response);

      const data = response.data

      console.log('notification created data==>',data);

      assignCase(data);
  
    })
    .catch(error => {
  
      console.log('error==>',error);
    });
  
  }


  const bulk_request = async() => {

    setLoading(true);

    let promises = []

    console.log('promise');

    let merge = [...data, ...data2];

   let arr = merge.map((item, index) => {

      let promise = new Promise(async(resolve, reject) => {

      if(item.search_status == 'Found'){

try {

  await get_package_details(item, 'send');

  resolve();
  
} catch (error) {

  console.log('error',error);
  reject();

}

      }else{

        await get_package_details(item, 'create');

        resolve();
      }


    })


    promises.push(promise);

    })

    await Promise.all(promises);
    console.log('done');

    setLoading(false);

    fetch_Wallet_details();

    deleteUploadedSheet();
    

    navigate('/admin/my-cases')

    

  }

  const deleteUploadedSheet = async() => {

    const config = {
      headers: {
        'Content-Type': 'application/json'
      }
    };



    await axios.delete(`${process.env.REACT_APP_BASE_URL}/service/clearUploadedSheet/1`, {}, config)
    .then(response => {
      console.log('delete uploaded sheet response',response);
  
    })
    .catch(error => {
  
      console.log('error deleting sheet==>',error);
    });

  }

  return (
    <React.Fragment>

      <div className="page-content">
        {loading && <Loader/>}
        <div className="container-fluid">
          <Row>
            
            
                <Col xs="12">
                  <Card>
                    <CardBody>

                    <div className="crypto-buy-sell-nav">
                    <Nav tabs className="nav-tabs-custom" role="tablist">
                      <NavItem>
                        <NavLink
                          className={classnames({
                            active: activeTab === "1",
                          })}
                          onClick={() => {
                            toggleTab("1");
                          }}
                        >
                          With Wallet
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          className={classnames({
                            active: activeTab === "2",
                          })}
                          onClick={() => {
                            toggleTab("2");
                          }}
                        >
                          With Email
                        </NavLink>
                      </NavItem>
                    </Nav>

                    <TabContent
                      activeTab={activeTab}
                      className="crypto-buy-sell-nav-content p-4"
                    >
                      <TabPane tabId="1" id="buy">
                    <div>
                      <TableContainer
                        columns={columns}
                        data={data}
                        isGlobalFilter={true}
                        isAddOptions={true}
                        handleOrderClicks={handleOrderClicks}
                        customPageSize={10}
                        isPagination={true}
                        filterable={false}
                        iscustomPageSizeOptions={true}
                        tableClass="align-middle table-nowrap table-check"
                        theadClass="table-light"
                        pagination="pagination pagination-rounded justify-content-end mb-2"
                      />

                    </div>
                      </TabPane>

                      <TabPane tabId="2">
                      <div>
                      <TableContainer
                        columns={columns2}
                        data={data2}
                        isGlobalFilter={true}
                        isAddOptions={true}
                        handleOrderClicks={handleOrderClicks}
                        customPageSize={10}
                        isPagination={true}
                        filterable={false}
                        iscustomPageSizeOptions={true}
                        tableClass="align-middle table-nowrap table-check"
                        theadClass="table-light"
                        pagination="pagination pagination-rounded justify-content-end mb-2"
                      />

                      </div>
                      </TabPane>
                    </TabContent>

                    <button onClick={bulk_request} className="btn btn-primary mt-3">Send</button>
                  </div>

                
                    </CardBody>
                  </Card>
                </Col>

            
            
          </Row>
     
        </div>
      </div>

    </React.Fragment>
  );
}


export default Cases;
