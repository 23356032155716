import React, { useState, useEffect } from 'react';


import { Container, Row, Col,Button, Progress, Card, CardBody,Input, Table, Form,   FormFeedback
} from "reactstrap";

import * as Yup from "yup";
import { useFormik } from "formik";
// import FeatherIcon from "feather-icons-react";

// import {CloudDownload, Search, Print, ViewColumn, FilterList} from '@material-ui/icons'


const PassbookDetail =(props)=>{
  return (
    <div>
  
      <div className="d-flex w-100" style={{justifyContent:'space-between', paddingRight:10}} >
      <p>Passbook Detail</p>

        <div >
        <i class="fas fa-search" style={{marginRight:30}}></i>
<i class="fas fa-cloud-download-alt" style={{marginRight:30}}></i>
<i class="fas fa-print" style={{marginRight:10}}></i>
        </div>

</div>
      <Table striped bordered hover>
  <thead>
    <tr>
      <th>Member id</th>
      {/* <th>Employee Share</th>
      <th>Employer Share</th> */}
                      <th>Date of Joining</th>

      <th>Approved On</th>
      <th>Month</th>
    </tr>
  </thead>
  <tbody>
    {props.data.filter((i)=> {

      let member_id = i.member_id;

let newId = member_id.substring(0, member_id.length - 7).replace(/\s/g, "");

 return newId == props.eid.replace(/\s/g, "");


    }).map(item => (
      <tr key={item.id}>
        <td>{item.member_id}</td>
        {/* <td>{item.employeeShare}</td>
        <td>{item.employerShare}</td> */}
                <td>{item.doj_epf}</td>

        <td>{item.approved_on}</td>
        <td>{item.month}</td>
      </tr>
    ))}
  </tbody>
</Table>


    </div>
  )
}

const TDSDATA =(props)=>{
  return (
    <div>
  
      <div className="d-flex w-100" style={{justifyContent:'space-between', paddingRight:10}} >
      <p>Tds data</p>

        <div >
        <i class="fas fa-search" style={{marginRight:30}}></i>
<i class="fas fa-cloud-download-alt" style={{marginRight:30}}></i>
<i class="fas fa-print" style={{marginRight:10}}></i>
        </div>

</div>
      <Table striped bordered hover>
  <thead>
    <tr>
      <th>Name of Deductor</th>

                      <th> Assessment year </th>

      <th>Pan no</th>
      <th>Date of Booking</th>
    </tr>
  </thead>
  <tbody>
    {props.data.filter((i)=> {

if(props.assessment_year == i.assessment_year){
  return i
}


}). map(item => (
      <tr key={item.id}>
        <td>{item.name_of_deductor}</td>

                <td>{item.assessment_year}</td>

        <td>{item.pan_no}</td>
        <td>{item.date_of_booking}</td>
      </tr>
    ))}
  </tbody>
</Table>


    </div>
  )
}


const EPFO =({onInputChange,handleEpfoOtp, generateOtpEpfo,submitEpfo, data})=>{

  const epfoRegex = '/^([a-zA-Z]){2}/[0-9]{5}/[0-9]{7}?$/';

  const validation = useFormik({
    enableReinitialize: true,

    initialValues: {
      epfo: "",
    },
    validationSchema: Yup.object().shape({
        epfo: Yup.string()
        .required('EPFO number is required')
        .matches(epfoRegex, 'Invalid EPFO number'),
    }),
  
    
    onSubmit: (values) => {
      // console.log(values)


 
    }
  });



    return (
        <div className="container-fluid">
          
          <Row className="py-3 px-2">
        <Col className="col-12 col-lg-4">
        <div className="align-items-center">
                                                {/* <img src={client1} className="me-3" height="32" alt="" /> */}
                                                <p className="mb-0 d-inline fw-normal h6">EPFO</p>
                                            </div>
        </Col>
        <Col className="col-12 col-lg-4">

          {
            // data?<p className="mb-0 d-inline fw-normal h6">{data.aadhaar_number}</p>:

            <Form 
            onSubmit={(e) => {
              e.preventDefault();
              validation.handleSubmit();
              return false;
            }}
         
            
            >

            <Row>
            
              <Col className="md-8 col-lg-6 col-12" >

                {
                  data == null && 
                  <Input
                  name="epfo"
                  id="epfo"
                  type="text"
                  // value={aadhaar}
                  onChange={(e)=>onInputChange(e.target.value, 'epfo')}
   
                  className="form-control py-2"
                  placeholder="epfo number"
                  />
                }
       

            </Col>
            <Col className="md-4 col-lg-6 col-12" >
              {/* {
                isLoading &&

                <div id="animation" style={{position:'absolute', bottom:7, top:8, width:60}}></div>

              } */}

              {
                data ==null && 

                <div>
                <input
                type="button"
                id="generate"
                style={{width:151}}
                onClick={generateOtpEpfo}
                name="generate"
                className="btn btn-primary py-2"
                value="generate OTP"
                />
                 
    
                          </div>
              }
  
            </Col>
            </Row>
            
            </Form>

          }

         </Col>
         <Col className="col-12 col-lg-4 d-flex" style={{ justifyContent:'end'}}>

          {
            data==null && 

            <Form>

            <Row>
            
              <Col className="md-8 col-12 col-lg-6">
            <Input
            name="epfo_otp"
            id="epfo_otp"
            type="text"
            onChange={handleEpfoOtp}
            
            className="form-control py-2"
            placeholder="Enter OTP"
            />
            </Col>
            <Col className="md-3 col-12 col-lg-6">
            <input
            type="button"
            id="submit"
            onClick={submitEpfo}
            name="send"
            className="btn btn-primary py-2"
            value="Submit"
            />
            </Col>
     
            </Row>
            
            </Form>

          }
         {/* <FeatherIcon icon="chevron-down" style={{marginRight:10}}  /> */}

         </Col>


      </Row>
      </div>
    )
}

const ITR =()=>{
    return (
        <div className="container-fluid">
        <Row className="py-3 px-2">
          <Col className="col-4">
          <div className="align-items-center">
                                                  {/* <img src={client3} className="me-3" height="32" alt="" /> */}
                                                  <p className="mb-0 d-inline fw-normal h6">ITR</p>
                                              </div>
          </Col>
          <Col className="col-4">
          <Form>

<Row>

<Col className="md-8">
<Input
name="aadhaar"
id="email"
type="text"

className="form-control py-2"
placeholder="ID number"
/>
</Col>
<Col className="md-4">
<input
type="button"
id="submit"

name="send"
className="btn btn-primary py-2"
value="Verify"
/>
</Col>
</Row>

</Form>
           </Col>
           <Col className="col-3">

           </Col>
           <Col className="md-1 text-center d-flex" style={{alignItems:'center', justifyContent:'flex-end'}}>
{/* <FeatherIcon icon="chevron-down" style={{marginRight:10}}  /> */}
</Col>
        </Row>
      </div>
    )
}



export { EPFO, PassbookDetail, ITR, TDSDATA};