import React, { useEffect, useState } from "react";
import PropTypes from 'prop-types';
import { Card, CardBody, Col, Row } from "reactstrap";
import ReactApexChart from "react-apexcharts";
import getChartColorsArray from "../../../components/Common/ChartsDynamicColor";
import { useSelector, useDispatch } from 'react-redux';
// import { dashboardBlogVisitorData } from '../../../MainStore/slices/dashboard-blog/actions';
import { Line } from 'react-chartjs-2';

import moment from 'moment'
import axios from 'axios'

import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file

import { DateRangePicker } from 'react-date-range';
import { addDays } from 'date-fns';



const CardUser = ({ dataColors, data }) => {
  // const apexCardUserChartColors = getChartColorsArray(dataColors);
  const [duration, setDuration] = useState("year");
  // const dispatch = useDispatch();
  const [show, setShow] = useState(false);

  const [cases, setCases] = useState([])

  const lineData = {
    // Define the labels for the x-axis

  
  };

  const [creds, setCreds] = useState({
      
      today: 0,
  
      month: 0,
  
      year: 0
  })

  const [graph_data, setGraphData] = useState({})

  const options2 = {
    scales: {
      yAxes: [
        {
          // stacked: true,
          
          gridLines: {
            display: true,
            color: "rgba(255,99,132,0.2)"
          },
          ticks: {
            // suggestedMax: 100,
            stepSize:1,
            suggestedMin: 0,
            callback: function(value) {
              return Number.isInteger(value) ? value : null; // Only show integer values
            }
          }
        }
      ],
      xAxes: [
        {
          gridLines: {
            display: false
          }
        }
      ]
    }
  };

  const [categories, setCategories] = useState([])


  const [date, setDate] = useState({

    start: '',
      
      end: ''

  })

  const [state, setState] = useState([
    {
      startDate: new Date(),
      endDate: addDays(new Date(), 7),
      key: 'selection'
    }
  ]);

  
  


  const pickerRef = React.useRef(null)

  React.useEffect(() => {

    const handleClickOutside = (event) => {

      if (pickerRef.current && !pickerRef.current.contains(event.target)) {

        setShow(false)

      }

    }

    document.addEventListener("mousedown", handleClickOutside);

    return () => {

      document.removeEventListener("mousedown", handleClickOutside);

    };

  }, [pickerRef]);





  const series = [
    {
      name: "Issued",
      data: [],
    },
    {
      name: "Revoked",
      data: [],
    },
  ];

  const options = {
    chart: {
      height: 350,
      type: "area",
      toolbar: {
        show: false,
      },
    },
    colors: '#000',
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
      width: 2,
    },
    fill: {
      type: "gradient",
      gradient: {
        shadeIntensity: 1,
        inverseColors: false,
        opacityFrom: 0.45,
        opacityTo: 0.05,
        stops: [20, 100, 100, 100],
      },
    },
    xaxis: {
      categories: [],
    },

    markers: {
      size: 3,
      strokeWidth: 3,

      hover: {
        size: 4,
        sizeOffset: 2,
      },
    },
    legend: {
      position: "top",
      horizontalAlign: "right",
    },
  };

  useEffect(() => {

    console.log('data', data);

    get_data()



  }, []);


  const get_data = async() => {

    const config = {
      headers: {
        'Content-Type': 'application/json'
      }
    };
  

  
    axios.get(`${process.env.REACT_APP_BASE_URL}/service/cases`, {}, config)
    .then(async response => {
      console.log('all cases',response.data);

      const res = response.data

    let all = response.data

    let six_month = res.filter((item)=> moment(item.timestamp).format('YYYY-MM') === moment().subtract(6, 'months').format('YYYY-MM'))

    let today = res.filter((item)=> moment(item.timestamp).format('YYYY-MM-DD') === moment().format('YYYY-MM-DD'))

    let this_month = res.filter((item)=> moment(item.timestamp).format('YYYY-MM') === moment().format('YYYY-MM'))

    let this_year = res.filter((item)=> moment(item.timestamp).format('YYYY') === moment().format('YYYY'))

    let one_month = res.filter((item)=> moment(item.timestamp).format('YYYY-MM') === moment().subtract(1, 'months').format('YYYY-MM'))

    let one_year = res.filter((item)=> moment(item.timestamp).format('YYYY-MM') === moment().subtract(1, 'years').format('YYYY-MM'))

    let labels = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October','November','December']



    let datasets = [
      {
        label: 'All Verifications',
        // Define the data points for the y-axis
        data: [0,0,0,0,0,0,0,0,0,0,0,0],
        fill: false,
        backgroundColor: 'rgb(75,192,192)',
        borderColor: 'rgba(75,192,192,0.2)',
        tension: 0.1 // This will smooth the line
      },
      {
        label: 'Verification Performed',
        // Define the data points for the y-axis
        data: [0,0,0,0,0,0,0,0,0,0,0,0],
        fill: false,
        backgroundColor: 'green',
        borderColor: 'green',
        tension: 0.1 // This will smooth the line
      },
      {
        label: 'Pending Verification',
        // Define the data points for the y-axis
        data: [0,0,0,0,0,0,0,0,0,0,0,0],
        fill: false,
        backgroundColor: 'warning',
        borderColor: 'warning',
        tension: 0.1 // This will smooth the line
      },
  
    ]

    console.log('graph data', datasets)

     res.map((item)=> {

      let month = moment(item.timestamp).format('MMMM')

      let index = labels.indexOf(month)

      console.log('index', index, labels)

      if(index !== -1){

            datasets[0].data[index] = datasets[0].data[index] + 1
        

        if(item.verification_status === 'Complete'){
            
          datasets[1].data[index] = datasets[1].data[index] + 1
      }

      if(item.verification_status !== 'Complete'){
            
        datasets[2].data[index] = datasets[2].data[index] + 1
    }




      }

    

    })

    console.log('datasets====>', datasets)

    setGraphData({
      ...graph_data,
      labels: [...labels],
      datasets: [...datasets],
    })

    console.log('six month',six_month)

    console.log('one month',one_month)

    console.log('one year',one_year)

    console.log('all',all)

    setCases(all)

    setCreds({
      ...creds,
      today: today.length,
      month: this_month.length,
      year: this_year.length
    })




    }
      
      )
  }

  const filterCred = async() => {

    console.log('state', state)

    const first = moment(state[0].startDate).format('YYYY-MM-DD')

    const second = moment(state[0].endDate).format('YYYY-MM-DD')

    const startDate = moment(first); // Replace with your start date
    const endDate = moment(second);   // Replace with your end date
  
    const labels = [];
    let currentDate = startDate.clone();

    let arr = []

    let arr2 = []

    let arr3 = []
  
    while (currentDate.isSameOrBefore(endDate)) {
      labels.push(currentDate.format('YYYY-MM-DD'));
      arr.push(0)
      arr2.push(0)
      arr3.push(0)

      currentDate.add(1, 'day');
    }

    console.log('labels', labels)

    let datasets = [
      {
        label: 'All Verifications',
        // Define the data points for the y-axis
        data: [0,0,0,0,0,0,0,0,0,0,0,0],
        fill: false,
        backgroundColor: 'rgb(75,192,192)',
        borderColor: 'rgba(75,192,192,0.2)',
        tension: 0.1 // This will smooth the line
      },
      {
        label: 'Verification Performed',
        // Define the data points for the y-axis
        data: [0,0,0,0,0,0,0,0,0,0,0,0],
        fill: false,
        backgroundColor: 'green',
        borderColor: 'green',
        tension: 0.1 // This will smooth the line
      },
      {
        label: 'Pending Verification',
        // Define the data points for the y-axis
        data: [0,0,0,0,0,0,0,0,0,0,0,0],
        fill: false,
        backgroundColor: 'warning',
        borderColor: 'warning',
        tension: 0.1 // This will smooth the line
      },
   
    ]

    console.log('certs', cases)

    const filtered_data = cases.filter((item)=>
    {
    
      let date = moment(item.timestamp).format('YYYY-MM-DD');
    
      console.log('date', date, date >= first && date <= second)
    
      return date >= first && date <= second
    
    
    }
    
         
         
         )

        //  const issued = filtered_data.filter((item)=> item.status === 0)

        //   const revoked = filtered_data.filter((item)=> item.status === 1)

    // console.log('filtered data issued revoked', issued, revoked)

    filtered_data.map((item)=> {

      let date = moment(item.timestamp).format('YYYY-MM-DD')

      let index = labels.indexOf(date)

      // console.log('index', index, labels, item.status)

      if(index !== -1){

        
        datasets[0].data[index] = datasets[0].data[index] + 1
        

        if(item.verification_status === 'Complete'){
            
          datasets[1].data[index] = datasets[1].data[index] + 1
      }

      if(item.verification_status !== 'Complete'){
            
        datasets[2].data[index] = datasets[2].data[index] + 1
    }




      }

    

    })

    console.log('datasets====>', datasets)

    setGraphData({  
      ...graph_data,
      labels: [...labels],
      datasets: [...datasets],
    })



  //   console.log('filtered data', filtered_data)

  //   let uniqueDataArray = filtered_data.filter((item, index, self) => {
  //     return self.findIndex(innerItem => moment(innerItem.timestamp).format("MM") === moment(item.timestamp).format("MM")) === index;
  // });

  // console.log('unique', uniqueDataArray)

  //   let issued = filtered_data.filter((item)=> item.fetched === 0)

  //   let revoked = filtered_data.filter((item)=> item.status === 1)



    setShow(false)

  }

  return (
    <React.Fragment>
      <Col xl={12}>
      

        <Card>
          <CardBody>
            <div className="d-flex flex-wrap">
              <h5 className="card-title me-2">Verification stats</h5>
              <div className="ms-auto">

   <button type="button" className="btn btn-light btn-sm" onClick={()=> setShow(true)}>
    Choose date
    </button>


            
                <div className="toolbar d-flex flex-wrap gap-2 text-end">

                { show &&  
                
                <div ref={pickerRef} style={{marginLeft:30}}>

<div style={{ position:'absolute', padding:10, width:'60%', backgroundColor:'#fff', zIndex:9999,top:-10, left:0, right:0}}>

<DateRangePicker
onChange={item => setState([item.selection])}
style={{height:'100%'}}
showSelectionPreview={true}
moveRangeOnFirstSelection={false}
scroll={{ enabled: true }}
months={1}
ranges={state}

direction="vertical"
/>

<div style={{backgroundColor:"#fff", padding:10, }}>
<button className='btn btn-primary d-block' onClick={()=> filterCred()}>submit</button>

</div>

</div>
                </div>
                
                }
                  {/* <button type="button" className="btn btn-light btn-sm" onClick={() => visitorDurationData('all')}>
                    ALL
                  </button>{" "}
                  <button type="button" className="btn btn-light btn-sm" onClick={() => visitorDurationData('onemonth')}>
                    1M
                  </button>{" "}
                  <button type="button" className="btn btn-light btn-sm" onClick={() => visitorDurationData('sixmonth')}>
                    6M
                  </button>{" "}
                  <button type="button" className="btn btn-light btn-sm active" onClick={() => visitorDurationData('year')}>
                    1Y
                  </button>{" "} */}
                </div>
              </div>
            </div>

            <Row className="text-center">
              <Col lg={4}>
                <div className="mt-4">
                  <p className="text-muted mb-1">Today</p>
                  <h5>{creds?.today}</h5>
                </div>
              </Col>

              <Col lg={4}>
                <div className="mt-4">
                  <p className="text-muted mb-1">This Month</p>
                  <h5>
                    {creds.month}{" "}
                    <span className="text-success font-size-13">
                      {/* 0.2 % <i className="mdi mdi-arrow-up ms-1"></i> */}
                    </span>
                  </h5>
                </div>
              </Col>

              <Col lg={4}>
                <div className="mt-4">
                  <p className="text-muted mb-1">This Year</p>
                  <h5>
                    {creds.year}{" "}
                    <span className="text-success font-size-13">
                      {/* 0.1 % <i className="mdi mdi-arrow-up ms-1"></i> */}
                    </span>
                  </h5>
                </div>
              </Col>
            </Row>

            <hr className="mb-4" /> 
            <div id="area-chart" dir="ltr">
            <Line data={graph_data} options={options2} />
              {/* <ReactApexChart
                options={options}
                series={graph_data}
                type="area"
                height={350}
                className="apex-charts"v
              /> */}
            </div>
          </CardBody>
        </Card>
      </Col>
    </React.Fragment>
  );
};

CardUser.propTypes = {
  options: PropTypes.any,
  series: PropTypes.any
};

export default CardUser;