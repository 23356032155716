import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Modal,
  ModalBody,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,

  CardBody,
  Table,
  CardSubtitle,
  Card,
Button,
  Container,
} from "reactstrap";


import axios from 'axios';
import moment from 'moment';

import { Link, useLocation, useNavigate } from "react-router-dom";

import logo from '../../assets/images/trn_logo.png';

import Cookies from 'js-cookie';




const Header = (props) => {

    const navigate = useNavigate()

    const [menu, setMenu] = useState(false);

    const [cam, setCam] = useState(false);

    const [data_entry, setDataEntry] = useState(false);

    const [process_team, setProcessTeam] = useState(false);

    const [enableAttestation, setEnableAttestation] = useState(false);

    const [allow, setAllow] = useState({
      cam:false,
      data_entry_team_member:false,
      process_team_leader:false,
      process_team_member:false,
      administrator:false,
      qc_component:false,
      qc_case:false


    });

    const [settings , setSettings] = useState(false);

    const [qc, setQc] = useState(false);

   useEffect(() => {


    console.log('header', props)

    if(checkRoute(props.location, 'admin')){
      setAdminHeader()
    }

   }, []);

  

   const setAdminHeader = () => {

    console.log('header cookies', JSON.parse(Cookies.get('authUser')))

    const user = JSON.parse(Cookies.get('authUser'))

    allowHeaderLinks(user.roles)

    get_components()

   }

   const allowHeaderLinks = async(selectedRoles) => {

    console.log('allow header user selectedRoles==>',selectedRoles)

    const config = {
      headers: {
        'Content-Type': 'application/json'
      }
    };
   
    axios.get(`${process.env.REACT_APP_BASE_URL}/employer/admin/roles`, {}, config)

    .then(response => {

      console.log('response roles==>',response)

      const data = response.data.data;

      console.log('all roles', data)

      const filteredArray = data.map((item)=>{

        if(selectedRoles.includes(item.id)){

          return {
            ...item,
            allow: true
           }

        }else{

          return {
            ...item,
            allow:false
          }

        }

        

      }).filter(item => item.allow == true)

      // const filteredArray = data.filter(element => selectedRoles.includes(element.id));

      console.log('filtered array', filteredArray)

      let allowData = {...allow}

     filteredArray.map((item)=>{

      if(item.role_name == 'Process Team Leader'){

      
        allowData.process_team_leader = true

      

      }

      if(item.role_name == 'Process Team Member'){

        allowData.process_team_member = true


      }


      if(item.role_name == 'Data Entry Team Member'){

        allowData.data_entry_team_member = true


      }

      
      if(item.role_name == 'Client Account Manager'){

        allowData.cam = true


      }

      if(item.role_name == 'Administrator'){

        allowData.process_team_leader = true
        allowData.cam = true
        allowData.administrator = true
        allow.qc_case = true;
        allow.data_entry_team_member = true;

        allow.qc_component = true;

        allow.process_team_member = true




      }

      if(item.role_name == 'QC Component Manager'){

        allowData.qc_component = true

      }

      if(item.role_name == 'QC Case Manager'){

        allowData.qc_case = true

      }


     })

     setAllow(allowData)

  


    })

    .catch(error => {

      console.log('error from server==>',error)

    })

   }


   
  const get_components = async () => {

    const config = {
      headers: {
        'Content-Type': 'application/json'
      }
    };
   
    axios.get(`${process.env.REACT_APP_BASE_URL}/service/components`, {}, config)

    .then(response => {

      console.log('response components in header==>',response)

      const data = response.data;

     console.log('data==>',data)

     let attestation = data.filter(item => item.name == 'Attestation')[0]

     setComponentsPermissions(attestation.id)


    //  if(attestation.status == 1){

    //   setEnableAttestation(true)
    //  }



   

    })

    .catch(error => {

      console.log('error from server==>',error)

    })


  }

  const setComponentsPermissions = async(id)=>{

    const user = JSON.parse(Cookies.get('authUser'))

    const user_id = user.user_id

    console.log('user id==>',user)


    const config = {
      headers: {
        'Content-Type': 'application/json'
      }
    };
   
   await axios.get(`${process.env.REACT_APP_BASE_URL}/employer/admin/user_components/${user_id}`, {}, config)

    .then(response => {

      console.log('response components permission==>',response)

      const data = response.data.data;

     console.log('user components data==>',data)

      let arr = [];

      let isExist =  data.findIndex((item)=> item.component_id == id)

      if(isExist > -1){

        setEnableAttestation(true)
      }


      // enable checkbox if role is assigned to user

      // components_data.map((item)=> {

      //   let comp = data.find((comp)=> comp.component_id == item.id)

      //   if(comp) {
      //     arr.push({
      //       component: item.name,
      //       checked: true,
      //       id: item.id
      //     })
      //   }else{
      //     arr.push({
      //       component: item.name,
      //       checked: false,
      //       id: item.id
      //     })
      //   }

      // })

      console.log('set component arr==>',data)

      // setComponents(arr)


  


    })

    .catch(error => {

      console.log('error from server==>',error)

    })
  }



   function checkRoute(url, routeName) {
    // Split the URL into parts based on the "/" separator

  
    const parts = url.split('/');
  
    // Filter out any empty strings in case the URL starts with a "/"
    const filteredParts = parts.filter(part => part.length > 0);
  
    // Check if the first non-empty part is "admin"
    if (filteredParts.length > 0 && filteredParts[0] === routeName) {
      return true;
    }
    return false;
  }

    return (
        <header id="page-topbar" style={{left:0,}}>
        <div className="navbar-header">
          <div className="d-flex" style={{justifyContent:'space-between'}}>
            <div className="navbar-brand-box">
       
       <img src={logo} alt="" width={200} style={{cursor:'pointer'}} onClick={() => navigate('/')} />

            
            </div>

  
          </div>

          {
             checkRoute(props.location, 'admin') && 
              <div className="d-flex" style={{maxWidth:'70%', minWidth:'28%', justifyContent:'space-between', alignItems:'center'}}>

<Link to="/admin/dashboard"  style={{fontSize:15, marginRight:20}} >
        Dashboard
          </Link>


{  allow.cam &&        <Dropdown
        isOpen={cam}
        toggle={() => setCam(!cam)}
        className="d-inline-block"
      >
        <DropdownToggle
          className="btn header-item"
          id="page-header-user-dropdown"
          tag="button"
        >
      
          <span className="d-none d-xl-inline-block ms-2 me-1 text-primary" style={{fontSize:15}}>CAM</span>
          <i className="mdi mdi-chevron-down d-none d-xl-inline-block" />
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-end">
          <DropdownItem tag="a" href="#" onClick={(e)=>{
            e.preventDefault()
            navigate('/admin/list-cases')
          }}>
      
            List Cases
          </DropdownItem>
  
     
        </DropdownMenu>
      </Dropdown>
}


      { allow.data_entry_team_member &&   <Dropdown
        isOpen={data_entry}
        toggle={() => setDataEntry(!data_entry)}
        className="d-inline-block"
      >
        <DropdownToggle
          className="btn header-item"
          id="page-header-user-dropdown"
          tag="button"
        >
      
          <span className="d-none d-xl-inline-block ms-2 me-1 text-primary" style={{fontSize:15}}>Data Entry Team</span>
          <i className="mdi mdi-chevron-down d-none d-xl-inline-block" />
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-end">
          <DropdownItem tag="a" href="#" 
          
          onClick={(e)=>{
            e.preventDefault()
            navigate('/admin/my-cases')
          }}
          >
      
            My Cases
          </DropdownItem>
          <DropdownItem tag="a" href="#"
          
          onClick={(e)=>{
            e.preventDefault()
            navigate('/admin/create-case')
          }}
          >
      
      Create Cases
    </DropdownItem>

    <DropdownItem tag="a" href="#" 
    
    onClick={(e)=>{
      e.preventDefault()
      navigate('/admin/new-cases')
    }}
    >
      
      New Cases By Client
    </DropdownItem>

    {/* <DropdownItem tag="a" href="/profile">
      
      Transfer Case
    </DropdownItem> */}
     
        </DropdownMenu>
      </Dropdown>}



{(allow.process_team_leader || allow.process_team_member) && <Dropdown
        isOpen={process_team}
        toggle={() => setProcessTeam(!process_team)}
        className="d-inline-block"
      >
        <DropdownToggle
          className="btn header-item"
          id="page-header-user-dropdown"
          tag="button"
        >
      
          <span className="d-none d-xl-inline-block ms-2 me-1 text-primary" style={{fontSize:15}}>Process Team</span>
          <i className="mdi mdi-chevron-down d-none d-xl-inline-block" />
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-end">
          {allow.process_team_leader && <DropdownItem tag="a" href="#"
          
          onClick={(e)=>{
            e.preventDefault()
            navigate('/admin/leader-cases')
          }}
          >
      
            Leader 
          </DropdownItem>}
          <DropdownItem tag="a" href="#"
          
          onClick={(e)=>{
            e.preventDefault()
            navigate('/admin/member-cases')
          }}
          >
      
      Member
    </DropdownItem>


     
        </DropdownMenu>
      </Dropdown>}

          {/* <Link to="/client/account" style={{fontSize:15,}}>
        QC
          </Link> */}

{(allow.qc_component || allow.qc_case) && <Dropdown
        isOpen={qc}
        toggle={() => setQc(!qc)}
        className="d-inline-block"
      >
        <DropdownToggle
          className="btn header-item"
          id="page-header-user-dropdown"
          tag="button"
        >
      
          <span className="d-none d-xl-inline-block ms-2 me-1 text-primary" style={{fontSize:15}}>QC</span>
          <i className="mdi mdi-chevron-down d-none d-xl-inline-block" />
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-end">
          <DropdownItem tag="a" href="#"
          
          onClick={(e)=>{
            e.preventDefault()
            navigate('/admin/qc-component-level')
          }}

          >
      
            QC Component Level 
          </DropdownItem>
          <DropdownItem tag="a" href="#"
          
          onClick={(e)=>{
            e.preventDefault()
            navigate('/admin/qc-case-level')
          }}

          >
      
      QC Case Level
    </DropdownItem>


     
        </DropdownMenu>
      </Dropdown>}
                                   
          <Link to="/#" className="mx-2" onClick={()=>{
            navigate('/admin/list-cases')
          }} style={{fontSize:15}}>
        MIS Report
          </Link>

   

        { allow.administrator &&  <Dropdown
        isOpen={settings}
        toggle={() => setSettings(!settings)}
        className="d-inline-block"
      >
        <DropdownToggle
          className="btn header-item"
          id="page-header-user-dropdown"
          tag="button"
        >
      
          <span className="d-none d-xl-inline-block ms-2 me-1 text-primary" style={{fontSize:15}}>Settings</span>
          <i className="mdi mdi-chevron-down d-none d-xl-inline-block" />
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-end">
          <DropdownItem tag="a" href="#"
          
          onClick={(e)=>{
            e.preventDefault()
            navigate('/admin/manage-users')
          }}
          >
      
            Manage Users 
          </DropdownItem>
          <DropdownItem tag="a" href="#"
          
          onClick={(e)=>{
            e.preventDefault()
            navigate('/admin/manage-clients')
          }}

          >
      
      Manage Clients
    </DropdownItem>

    <DropdownItem tag="a" href="#"
    
    onClick={(e)=>{
      e.preventDefault()
      navigate('/admin/manage-locations')
    }}

    >
      
      Manage Locations
    </DropdownItem>

    <DropdownItem tag="a" href="/profile">
      
      Manage TAT Settings
    </DropdownItem>

    <DropdownItem tag="a" href="#" 
    
    onClick={(e)=>{
      e.preventDefault()
      navigate('/admin/manage-companies')
    }}

    >
      
      Manage Companies
    </DropdownItem>

    <DropdownItem tag="a" href="/profile">
      
      Manage Universities
    </DropdownItem>

    <DropdownItem tag="a" href="/profile">
      
      Manage Colleges
    </DropdownItem>

    <DropdownItem tag="a" href="/profile">
      
      Manage Holidays
    </DropdownItem>

    <DropdownItem tag="a" href="/profile">
      
      Manage State/Cites
    </DropdownItem>

    <DropdownItem tag="a" href="/profile">
      
      Manage Api Settings
    </DropdownItem>

    <DropdownItem tag="a" href="#" 
        onClick={(e)=>{
          e.preventDefault()
          navigate('/admin/email-settings')
        }}
    >
      
      Manage Email Settings
    </DropdownItem>

    <DropdownItem tag="a" href="/profile">
      
      Manage Email Templates
    </DropdownItem>
     
        </DropdownMenu>
      </Dropdown>}


   {
      enableAttestation &&  
      <Link to="https://attestation.therollnumber.com" className="mx-2" target="_blank" style={{fontSize:15}}>
        Attestation
          </Link>  
}
  <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="d-inline-block"
      >
        <DropdownToggle
          className="btn header-item"
          id="page-header-user-dropdown"
          tag="button"
        >
      
          <span className="d-none d-xl-inline-block ms-2 me-1 text-primary" style={{fontSize:15}}>MR mohan</span>
          <i className="mdi mdi-chevron-down d-none d-xl-inline-block" />
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-end">
          <DropdownItem tag="a" href="#"
          
          onClick={(e)=>{
            e.preventDefault()
            navigate('/admin/profile')
          }}

          >
            {" "}
            <i className="bx bx-user font-size-16 align-middle me-1" />
            MR mohan
          </DropdownItem>
          <DropdownItem tag="a" href="#" 
          onClick={()=>{
            Cookies.remove('authUser')
            navigate('/admin/login')
          }}
          >
            {" "}
            <i className="bx bx-exit font-size-16 align-middle me-1" />
            Sign Out
          </DropdownItem>
     
        </DropdownMenu>
      </Dropdown>

        
              </div>
          }

   { checkRoute(props.location, 'client') &&      <div className="d-flex" style={{width:'60%', justifyContent:'space-evenly'}}>


<Link to="/client/dashboard"  style={{fontSize:15, }}>
        Dashboard
          </Link>          

<Link to="/client/on-going-cases"  style={{fontSize:15, }}>
        On Going Cases
          </Link>

        <Link to="/client/closed-cases" style={{fontSize:15,}}>
        Closed Cases
          </Link>

          <Link to="/client/account" style={{fontSize:15,}}>
        My Account
          </Link>
                                   
          <Link to="/#" className="mx-2" onClick={()=>{
            localStorage.removeItem('authUser')
            navigate('/client/login')
          }} style={{fontSize:15}}>
        Logout
          </Link>
    </div>}
        </div>
      </header>
    )


}

export default Header