import React, { useEffect } from 'react';
import {View, StyleSheet, Text, Image } from '@react-pdf/renderer';
import InvoiceTableHeader from './InvoiceTableHeader'
import InvoiceTableRow from './InvoiceTableRow'
import InvoiceTableBlankSpace from './InvoiceTableBlankSpace'
import InvoiceTableFooter from './InvoiceTableFooter'
import Aadhaar from '../../aadhaar.png'
import moment from 'moment';
// import Logo from '../../report_images/trnlogo-cropped.png'

const tableRowsCount = 11;
const borderColor = '#061c33'

const styles = StyleSheet.create({
  tableContainer: {
      flexDirection: 'row',
      flexWrap: 'wrap',
      marginTop: 24,
      padding:20,
      paddingVertical:8
    
     
  },
  tableText:{
    fontSize: 10,
    fontFamily:'Montserrat-Light',
    width: '100%',
  },
  container: {
  marginTop: 24,
},
  text:{
    fontSize: 13,
    color: '#061c33',
    lineHeight:1,
    textAlign: 'center',
    fontFamily:'Montserrat',
  
  },
  box: {
    width: '100%', // Specify the width of the box
    height: 24, // Specify the height of the box
    backgroundColor: '#d4ddf1',
    justifyContent: 'center', // Center horizontally
    alignItems: 'center', // Center vertically
    margin: 'auto', // Center the box itself if within a larger container
  },
  table: {
    display: "table",
    width: "auto",
    borderStyle: "solid",
    borderWidth: 0.8,
    borderColor: '#bfbfbf',
    borderRightWidth: 0,
    borderBottomWidth: 0,
  },
  row: {
    flexDirection: "row",
    width: "100%",
  },
  col: {
    borderStyle: "solid",
    height:'auto',
     padding:5,

    borderWidth: 0.8,
 
    borderColor: '#bfbfbf',
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },
  profile:{
    width:'100%',
   
    marginTop:10,

   
   
  },
  imageBox:{
    width:'20%',
    height:100,
    alignSelf:'center',

    justifyContent: 'center', // Center horizontally
    alignItems: 'center', // Center vertically
    margin: 'auto', // Center the box itself if within a larger container
  }
});


const doc_names = {
  "aadhaar":"Aadhaar Card",
  "pan":"PAN Card",
  "passport":"Passport",
  "doctor":"Doctor",
  "nurse":"Nurse",
  "voter_id":"Voter ID",
}



  const TdsComp = ({invoice, data, logo,  requestedData}) => {

    const [components, setComponents] = React.useState([]);
    const [tdsData, setTDS] = React.useState([]);
    const [tdsObj, setTDSObj] = React.useState({});

    useEffect(() => {

      console.log('tds requestedData==>',data);

      const TDS = data?.tds?.map((item, index)=> {

        return {
            ...item,
            name_of_deductor: data?.tds_data.find((i)=>{

              return i.tds_id ==  item.tds_id;
            })?.name_of_deductor,
            total_amount_paid: data?.tds_data.find((i)=>{
              return i.tds_id ==  item.tds_id;
            })?.total_amount_paid,
            content: data?.tds_data.filter((i)=>{
        
         return i.tds_id ==  item.tds_id;
             
            } )
        }
      })

      const obj = {}
      const TDS2 = data?.tds_data.filter((item, index)=> {

        // add unique name of deductor to obj and add all the content to it

        // remove spaces from name_of_deductor


        const name = item.name_of_deductor.replace(/\s/g, '');


        if(obj[name]){

          obj[name].push(item)

        }else{

          obj[name] = [item]

        }




      
        

      })

      console.log('TDS2==>',obj);


      setTDS(TDS)

      setTDSObj(obj)

      // const filterData = section.content.filter((i)=>{
  
        
      // })

    }, []);


    return (

<View style={styles.tableContainer} >

<View style={styles.box}>
  <Text  style={styles.text}>Tds</Text>
</View>

<View style={styles.profile}>



    <View style={[styles.table, {width:'100%', marginTop:10}]}>
<View style={styles.row}>
          <View style={[styles.col, {width:'35%'}]}>
            <Text >Pan Number</Text>
            </View>
            <View style={[styles.col, {width:'65%'}]}>
            <Text style={styles.tableText}>{data?.itr_26AS?.pan_no}</Text>
            </View>
    </View>




  </View>

  <View style={[styles.table, {marginTop:10}]}>
<View style={styles.row}>
              <View style={[styles.col, {width:'100%'}]}>
                <Text style={[ {textAlign:'center'}]}>comments</Text>
                </View>
              
        </View>
        <View style={styles.row}>
              <View style={[styles.col, {width:'100%'}]}>
                <Text style={styles.tableText}>sdfsdf</Text>
                </View>
             
        </View>
</View>

  {/* <View style={[styles.table, {width:'100%', marginTop:20}]}>
    <View style={styles.row}>
          <View style={[styles.col, {width:'35%'}]}>
            <Text >Company name</Text>
            </View>
            <View style={[styles.col, {width:'25%'}]}>
            <Text >Member ID</Text>
            </View>
            <View style={[styles.col, {width:'20%'}]}>
            <Text >Date of joining</Text>
            </View>
            <View style={[styles.col, {width:'20%'}]}>
            <Text >Date of Exit</Text>
            </View>
    </View>


        <View style={styles.row}>
        <View style={[styles.col, {width:'35%'}]}>
          <Text style={styles.tableText}>CAPGEMINI TECHNOLOGY SERVICES INDIA LTD</Text>
          </View>
          <View style={[styles.col, {width:'25%'}]}>
          <Text style={styles.tableText}>MHBAN00187340000635116</Text>
          </View>
          <View style={[styles.col, {width:'20%'}]}>
          <Text style={styles.tableText}>2023-02-16</Text>
          </View>
          <View style={[styles.col, {width:'20%'}]}>
          <Text style={styles.tableText}>2023-02-16</Text>
          </View>
  </View>

   

  
  </View> */}

  

  {
    data?.tds?.length > 0 && 

          Object.keys(tdsObj).map((item, index)=> {

            // console.log('item tdsObj==>', tdsObj[item]);

            return (
<View  style={{marginTop:20}} >
{/* <View style={{
  flexDirection:'row',
  justifyContent:'space-between',
}}>
<Text>Assessment year: </Text>

<Text>{item.assessment_year}</Text>

</View> */}

<View style={{
  flexDirection:'row',
  justifyContent:'space-between',
}}>
<Text>Total amount paid:</Text>

<Text>{tdsObj[item][0].total_amount_paid}</Text>

</View>




        <View style={[styles.table, {width:'100%', marginTop:10}]}  >
            <View style={styles.row}>
              <View style={[styles.col, {width:'100%'}]}>
                <Text style={[ {textAlign:'center'}]}>{tdsObj[item][0].name_of_deductor}</Text>
                </View>
             
        </View>
        <View style={styles.row} >
              {/* <View style={[styles.col, {width:'40%'}]}>
                <Text >Name of deductor</Text>
                </View> */}
                <View style={[styles.col, {width:'25%'}]}>
                <Text >Transaction Date</Text>
                </View>
                <View style={[styles.col, {width:'25%'}]}>
                <Text >Date of Booking</Text>
                </View>
                <View style={[styles.col, {width:'25%'}]}>
                <Text >Amount paid</Text>
                </View>
                <View style={[styles.col, {width:'25%'}]}>
                <Text >Tax deducted</Text>
                </View>
                <View style={[styles.col, {width:'25%'}]}>
                <Text >Tds deposited</Text>
                </View>
                <View style={[styles.col, {width:'25%'}]}>
                <Text >Assessment year</Text>
                </View>
        </View>
    
    
          { 
            tdsObj[item]?.sort((a, b) => {
              return new Date(b.date_of_booking) - new Date(a.date_of_booking);

            }).map((content, i)=> {

     
            return (

          <View style={styles.row}  >
            {/* <View style={[styles.col, {width:'40%'}]}>
              <Text style={styles.tableText}>{content?.name_of_deductor}</Text>
              </View> */}
              <View style={[styles.col, {width:'25%'}]}>
              <Text style={styles.tableText}>{content?.transaction_date}</Text>
              </View>
              <View style={[styles.col, {width:'25%'}]}>
              <Text style={styles.tableText}>{content?.date_of_booking}</Text>
              </View>
         
              <View style={[styles.col, {width:'25%'}]}>
              <Text style={styles.tableText}>{content?.amount_paid}</Text>
              </View>
              <View style={[styles.col, {width:'25%'}]}>
              <Text style={styles.tableText}>{content?.tax_deducted}</Text>
              </View>
              <View style={[styles.col, {width:'25%'}]}>
              <Text style={styles.tableText}>{content?.tds_deposited}</Text>
              </View>

              <View style={[styles.col, {width:'25%'}]}>
              <Text style={styles.tableText}>{content?.assessment_year}</Text>
              </View>
           
      </View>)}
      )}
    
       
    
      
      </View>
      </View>
    )
          }
      )
  
    
  }


</View>



</View>
    )
  };
  
  export default TdsComp