import Cookies from "js-cookie";
import React, { useEffect } from "react";
import { Navigate } from "react-router-dom";

const Authmiddleware = (props) => {

     useEffect(() => {

      console.log('authmiddleware', props)

     }, []);

  if (props.route == '/client' && !localStorage.getItem("authUser")) {


    return (
      <Navigate to={{ pathname: "/client/login", state: { from: props.location } }} />
    );
  }

  // check cookies 

  if(props.route == '/admin' && Cookies.get('authUser') === undefined){

 

    return (
      <Navigate to={{ pathname: "/admin/login", state: { from: props.location } }} />
    );

  }

  return (<React.Fragment>

    {props.children}
  </React.Fragment>);
};

export default Authmiddleware;
