


import React, { useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { get, isEmpty, set } from "lodash";
import "bootstrap/dist/css/bootstrap.min.css";
import * as Yup from "yup";
import { useFormik } from "formik";
import axios from "axios";
import classnames from "classnames";
// import {Buffer} from 'buffer';
// import { PDFDownloadLink, pdf, usePDF, BlobProvider } from '@react-pdf/renderer';




import {
  Button,
  Col,
  Row,
  UncontrolledTooltip,
  Modal,
  ModalHeader,
  ModalBody,
  Collapse,
  Form,
  Input,
  FormFeedback,
  Label,
  Card,
  CardBody,
  Table,
} from "reactstrap";
import moment from "moment";

//Import Flatepicker
import "flatpickr/dist/themes/material_blue.css";
import Flatpickr from "react-flatpickr";
import Accordion from "../components/Accordian";
import { PassbookDetail, EPFO, TDSDATA } from "../components/IdentityInfo";
// import PdfDocViewer from "../components/PdfDocViewer";
// import FeatherIcon from "feather-icons-react";

function EditProgress() {
  //meta title
  document.title = "TheRollNumber";

  const navigate = useNavigate();

  // const [instance, updateInstance] = usePDF({ document: PdfDocViewer });


  const location = useLocation();

  const [isOpen, setIsOpen] = useState({});

  const [caseData, setCaseData] = useState({});

  const [education, setEducation] = useState([]);

  const [employment, setEmployment] = useState([]);

  const [user, setUser] = useState({});

  const [report_status, setReportStatus] = useState('');

  const [activityLogs, setActivityLogs] = useState([]);

  const [requestedDocs, setRequestedDocs] = useState([]);

  const [requestedData, setRequestedData] = useState([]);

const [credit_score, setCreditScore] = useState({});

  const [epfo_data, setEpfoData] = useState({});
  const [epfo_companies, setEpfoCompanies] = useState([]);
  const [epfo_passbook, setEpfoPassbook] = useState([]);

  const [itr, setItr] = useState({});

  const [tds, setTds] = useState([]);


  const [tds_data, setTdsData] = useState([]);

  const [modalConfirm, setModalConfirm] = useState(false);

  const [modalConfirm2, setModalConfirm2] = useState(false);

  const toggleConfirm = () => setModalConfirm(!modalConfirm);

  const toggleConfirm2 = () => setModalConfirm2(!modalConfirm2);



  const [docNames, setDocNames] = useState({
    aadhaar: "Aadhaar Card",
    pan: "Pan Card",
    voter_id: "Voter Id",
    driving_licence: "Driving License",
   
  });
 

  // Toggle function to open or close an accordion item
  const toggle2 = (index) => {
    setIsOpen((prevState) => ({
      ...prevState,
      [index]: !prevState[index]
    }));
  };


  const [section, setSection] = useState([

    
  ]);

  const [components, setComponents] = useState([
    {
      name:'Aadhaar Card',
    

    }
  ]);
  const [modal, setModal] = useState(false);
  const [modal1, setModal1] = useState(false);
  const [isEdit, setIsEdit] = useState(false);

  const [orderList, setOrderList] = useState([]);
  const [order, setOrder] = useState(null);
  const [col1, setcol1] = useState(true);
  const [col2, setcol2] = useState(false);
  const [col3, setcol3] = useState(false);
  const [col4, setcol4] = useState(false);
  const [col5, setcol5] = useState(false);
  const [col6, setcol6] = useState(false);
  const [col7, setcol7] = useState(false);
  const [col8, setcol8] = useState(false);
  const [col9, setcol9] = useState(false);
  const [col10, setcol10] = useState(false);
  const [col11, setcol11] = useState(false);
  const [col12, setcol12] = useState(false);
  const [col13, setcol13] = useState(false);
  const [col14, setcol14] = useState(false);
  const [col15, setcol15] = useState(false);

  const t_col1 = () => {
    setcol1(!col1);

  };

  const t_col2 = () => {
    setcol2(!col2);


  };

  const t_col3 = () => {
    setcol3(!col3);


  };

  const t_col4 = () => {
    setcol4(!col4);
    setcol1(false);
    setcol2(false);
    setcol3(false)
    setcol5(false)

  };

  const t_col5 = () => {
    setcol5(!col5);
    setcol1(false);
    setcol2(false);
    setcol3(false)
    setcol4(false)

  };

  const t_col6 = () => {
    setcol6(!col6);


  };

  const t_col7 = () => {
    setcol7(!col7);

  }

  const t_col8 = () => {
    setcol8(!col8);

  }

  const t_col9 = () => {
    setcol9(!col9);

  }

  const t_col10 = () => {

    setcol10(!col10);

  }

  const t_col11 = () => {

    setcol11(!col11);

  }

  const t_col12 = () => {

    setcol12(!col12);

  }


  // validation

  const toggleViewModal = () => setModal1(!modal1);

  useEffect(() => {

    get_case();

  }, []);


  // useEffect(() => {

  //   if(!instance.loading){
  //     console.log('instance==>',instance);

  //   }

  // }, [instance]);


  const viewReport = async() => {

    // window.open(`http://localhost:3002/admin/view-report/${caseData.request_id}`, '_blank');

    // alert(process.env.REACT_APP_URL);

    window.open(`${process.env.REACT_APP_URL}/client/view-report/${caseData.request_id}`, '_blank');


    // navigate('/report', { replace: true, state: { id: caseData.request_id } })


  }

  const get_requested_data = async () => {

    console.log('caseData',caseData);

    const config = {
      headers: {
        'Content-Type': 'application/json',

      }
    };

    const body = {

      org_user_client_id: caseData.org_client_id,
    
    }

    console.log('body requested ==>',body);

    axios.post(`${process.env.REACT_APP_BASE_URL}/service/requestedData/${caseData.walletID}`, body, config)
    .then(response => {
      console.log('requested data',response);

      let data = response.data.data;

      console.log('data==>',data);

      if (data.length > 0){
        setRequestedData(data);


        let isEducation = data.find((doc) => Object.keys(doc)[0] === 'education');

        console.log('isEducation==>',isEducation?.education);

        if (isEducation && isEducation.education.length > 0){
          setEducation(isEducation.education);
        }

    

        let isCreditScore = data.find((doc) => Object.keys(doc)[0] === 'credit_score');

        console.log('isCreditScore==>',isCreditScore?.credit_score);

        if (isCreditScore && isCreditScore.credit_score.length > 0){

          
          setCreditScore(isCreditScore.credit_score[0]);
        }

        let isEmployment = data.find((doc) => Object.keys(doc)[0] === 'employment');

        console.log('isEmployment==>',isEmployment?.employment);

        if (isEmployment && isEmployment.employment.length > 0){
          setEmployment(isEmployment.employment);
        }

        let isEpfo = data.find((doc) => Object.keys(doc)[0] === 'epfo');

        // console.log('isEpfo==>',Object.keys(isEpfo.epfo).length);

        if (isEpfo && Object.keys(isEpfo.epfo).length > 0){

          

          console.log('epfo data==>',isEpfo.epfo.epfo_company);
  
          setEpfoData(isEpfo.epfo.epfo_employee);

          setEpfoCompanies(isEpfo.epfo.epfo_company);

          setEpfoPassbook(isEpfo.epfo.epfo_passbook);
          
         

        }

        let isItr = data.find((doc) => Object.keys(doc)[0] === 'tds');

        console.log('isItr==>',isItr?.tds);

        if (isItr && isItr.tds.tds.length > 0){

       

          setItr(isItr.tds.itr_26AS);

          setTds(isItr.tds.tds);

          setTdsData(isItr.tds.tds_data);



        }



      }
      

    })
    .catch(error => {
  
      
  
      console.log('error==>',error);
    });

 
  }

  useEffect(() => {

    if (Object.keys(caseData).length > 0){
      get_request_identity_org();
      activity_logs();
      get_requested_data();
    }

  }, [caseData]);

  const Aadhaar = ({item}) => {

    return (
      <div>
        
      <Table className="table mb-0">
        <thead className="table-dark">
          <tr>
            <th style={{width:'50%'}}>Verification Component</th>
            <th style={{width:'50%'}}>Details Provided By The Candidate</th>
      
      
          </tr>
      
        </thead>
        <tbody>
        {/* <tr>
            <td className="table-light">Profile Image</td>
            <td className="table-light">
            <img src={`data:image/jpeg;base64,${item.profile_image}`} style={{width:100, height:100}} alt="profile_image"/>
            </td>
      
          </tr> */}
          <tr>
            <td className="table-light">Aadhar Card Number</td>
            <td className="table-light">{item?.aadhaar_number}</td>
      
          </tr>
      
         <tr>
            <td className="table-light">Candidate Name</td>
            <td className="table-light">{item?.full_name}</td>
      
          </tr>
      
          <tr>
            <td className="table-light">DOB As Per Aadhaar</td>
            <td className="table-light">{item?.dob}</td>
      
          </tr>
      
          <tr>
            <td className="table-light">Care Of</td>
            <td className="table-light">{item?.care_of}</td>
      
          </tr>
      
          <tr>
            <td className="table-light">Full Address As Per Aadhaar</td>
            <td className="table-light">{item?.address}</td>
      
          </tr>
      
      
          <tr>
            <td className="table-light">Verified By</td>
            <td className="table-light"></td>
      
          </tr>
      
          <tr>
            <td className="table-light">Verified On</td>
            <td className="table-light">{moment(item.timestamp).format('DD-MM-YYYY')}</td>
      
          </tr>
      
          <tr>
            <td className="table-light">Attachment (Candidate)</td>
            <td className="table-light">
              <Link to={`${item?.uploaded_document}`} target="_blank">
              Click here
              </Link>
            </td>
      
          </tr>
          <tr>
            <td className="table-light">Attachment (By TheRollNumber)</td>
            <td className="table-light">

            </td>
      
          </tr>
      
        </tbody>
      </Table>
      


      <Table className="table mb-0 mt-3">
      <thead className="table-dark">
          <tr>
            <th className="text-center" style={{width:'100%'}}>Comment</th>
            
      
      
          </tr>
      
        </thead>
        <tbody>
          <tr>
            <td className="table-light" style={{height:100}}></td>
       
      
          </tr>
          </tbody>
        </Table>
              </div>
    )
  }


  const Pan = ({item}) => {

    return (
      <div>
        
      <Table className="table mb-0">
        <thead className="table-dark">
          <tr>
            <th style={{width:'50%'}}>Verification Component</th>
            <th style={{width:'50%'}}>Details Provided By The Candidate</th>
      
      
          </tr>
      
        </thead>
        <tbody>
        {/* <tr>
            <td className="table-light">Profile Image</td>
            <td className="table-light">
            <img src={`data:image/jpeg;base64,${item.profile_image}`} style={{width:100, height:100}} alt="profile_image"/>
            </td>
      
          </tr> */}
          <tr>
            <td className="table-light">PAN Card Number</td>
            <td className="table-light">{item?.pan_number}</td>
      
          </tr>
      
         <tr>
            <td className="table-light">Candidate Name</td>
            <td className="table-light">{item?.full_name}</td>
      
          </tr>
      
    
      
        
      
      
          <tr>
            <td className="table-light">Verified By</td>
            <td className="table-light"></td>
      
          </tr>
      
          <tr>
            <td className="table-light">Verified On</td>
            <td className="table-light">{moment(item.timestamp).format('DD-MM-YYYY')}</td>
      
          </tr>
          <tr>
            <td className="table-light">Attachment (Candidate)</td>
            <td className="table-light">
              <Link to={`${item?.uploaded_document}`} target="_blank">
              Click here
              </Link>
            </td>
      
          </tr>
          <tr>
            <td className="table-light">Attachment (By TheRollNumber)</td>
            <td className="table-light">
              {/* <Link to={`${item?.uploaded_document}`} target="_blank">
              Click here
              </Link> */}
            </td>
      
          </tr>
      
        </tbody>
      </Table>
      
      <Table className="table mb-0 mt-3">
      <thead className="table-dark">
          <tr>
            <th className="text-center" style={{width:'100%'}}>Comment</th>
            
      
      
          </tr>
      
        </thead>
        <tbody>
          <tr>
            <td className="table-light" style={{height:100}}></td>
       
      
          </tr>
          </tbody>
        </Table>
              </div>
    )
  }

  const VoterId = ({item}) => {

    return (
      <div>
        
      <Table className="table mb-0">
        <thead className="table-dark">
          <tr>
            <th style={{width:'50%'}}>Verification Component</th>
            <th style={{width:'50%'}}>Details Provided By The Candidate</th>
      
      
          </tr>
      
        </thead>
        <tbody>

        <tr>
            <td className="table-light">Epic Number</td>
            <td className="table-light">{item?.epic_no}</td>
      
          </tr>
      
         <tr>
            <td className="table-light">Candidate Name</td>
            <td className="table-light">{item?.name}</td>
      
          </tr>
      
    
      
          <tr>
            <td className="table-light">Gender</td>
            <td className="table-light">{item?.gender}</td>
      
          </tr>

          <tr>
            <td className="table-light">Area</td>
            <td className="table-light">{item?.area}</td>
      
          </tr>
      
          <tr>
            <td className="table-light">Verified On</td>
            <td className="table-light">{moment(item.timestamp).format('DD-MM-YYYY')}</td>
      
          </tr>
      
          <tr>
            <td className="table-light">Attachment (Candidate)</td>
            <td className="table-light">
              <Link to={`${item?.uploaded_document}`} target="_blank">
              Click here
              </Link>
            </td>
      
          </tr>
          <tr>
            <td className="table-light">Attachment (By TheRollNumber)</td>
            <td className="table-light">
              {/* <Link to={`${item?.uploaded_document}`} target="_blank">
              Click here
              </Link> */}
            </td>
      
          </tr>
      
        </tbody>
      </Table>
      
      <Table className="table mb-0 mt-3">
      <thead className="table-dark">
          <tr>
            <th className="text-center" style={{width:'100%'}}>Comment</th>
            
      
      
          </tr>
      
        </thead>
        <tbody>
          <tr>
            <td className="table-light" style={{height:100}}></td>
       
      
          </tr>
          </tbody>
        </Table>
              </div>
    )
  }

  const DL = ({item}) => {

    return (
      <div>
        
      <Table className="table mb-0">
        <thead className="table-dark">
          <tr>
            <th style={{width:'50%'}}>Verification Component</th>
            <th style={{width:'50%'}}>Details Provided By The Candidate</th>
      
      
          </tr>
      
        </thead>
        <tbody>

        <tr>
            <td className="table-light">License Number</td>
            <td className="table-light">{item?.license_number}</td>
      
          </tr>
      
         <tr>
            <td className="table-light">Candidate Name</td>
            <td className="table-light">{item?.name}</td>
      
          </tr>
      
          <tr>
            <td className="table-light">DOB</td>
            <td className="table-light">{item?.dob}</td>
      
          </tr>
      
      
          <tr>
            <td className="table-light">Gender</td>
            <td className="table-light">{item?.gender}</td>
      
          </tr>

          <tr>
            <td className="table-light">Address</td>
            <td className="table-light">{item?.permanent_address}</td>
      
          </tr>
      
          <tr>
            <td className="table-light">Verified On</td>
            <td className="table-light">{moment(item.timestamp).format('DD-MM-YYYY')}</td>
      
          </tr>

          <tr>
            <td className="table-light">Attachment (Candidate)</td>
            <td className="table-light">
              <Link to={`${item?.uploaded_document}`} target="_blank">
              Click here
              </Link>
            </td>
      
          </tr>
          <tr>
            <td className="table-light">Attachment (By TheRollNumber)</td>
            <td className="table-light">
              {/* <Link to={`${item?.uploaded_document}`} target="_blank">
              Click here
              </Link> */}
            </td>
      
          </tr>
      
      
        </tbody>
      </Table>
      
      <Table className="table mb-0 mt-3">
      <thead className="table-dark">
          <tr>
            <th className="text-center" style={{width:'100%'}}>Comment</th>
            
      
      
          </tr>
      
        </thead>
        <tbody>
          <tr>
            <td className="table-light" style={{height:100}}></td>
       
      
          </tr>
          </tbody>
        </Table>
              </div>
    )
  }

  const Nurse = ({item}) => {

    return (
      <div>
        
      <Table className="table mb-0">
        <thead className="table-dark">
          <tr>
            <th style={{width:'50%'}}>Verification Component</th>
            <th style={{width:'50%'}}>Details Provided By The Candidate</th>
      
      
          </tr>
      
        </thead>
        <tbody>

        <tr>
            <td className="table-light">Id Number</td>
            <td className="table-light">{item?.id_number}</td>
      
          </tr>
      
         <tr>
            <td className="table-light">Candidate Name</td>
            <td className="table-light">{item?.nurse_name}</td>
      
          </tr>
      
          <tr>
            <td className="table-light">Registered As</td>
            <td className="table-light">{item?.registered_as}</td>
      
          </tr>
      
      
          <tr>
            <td className="table-light">Status</td>
            <td className="table-light">{item?.status}</td>
      
          </tr>

          <tr>
            <td className="table-light">State Council</td>
            <td className="table-light">{item?.state_council}</td>
      
          </tr>
      
          <tr>
            <td className="table-light">Verified On</td>
            <td className="table-light">{moment(item.timestamp).format('DD-MM-YYYY')}</td>
      
          </tr>

          <tr>
            <td className="table-light">Attachment (Candidate)</td>
            <td className="table-light">
              <Link to={`${item?.uploaded_document}`} target="_blank">
              Click here
              </Link>
            </td>
      
          </tr>
          <tr>
            <td className="table-light">Attachment (By TheRollNumber)</td>
            <td className="table-light">
              {/* <Link to={`${item?.uploaded_document}`} target="_blank">
              Click here
              </Link> */}
            </td>
      
          </tr>
      
      
        </tbody>
      </Table>
      
      <Table className="table mb-0 mt-3">
      <thead className="table-dark">
          <tr>
            <th className="text-center" style={{width:'100%'}}>Comment</th>
            
      
      
          </tr>
      
        </thead>
        <tbody>
          <tr>
            <td className="table-light" style={{height:100}}></td>
       
      
          </tr>
          </tbody>
        </Table>
              </div>
    )
  }

  const Doctor = ({item}) => {

    return (
      <div>
        
      <Table className="table mb-0">
        <thead className="table-dark">
          <tr>
            <th style={{width:'50%'}}>Verification Component</th>
            <th style={{width:'50%'}}>Details Provided By The Candidate</th>
      
      
          </tr>
      
        </thead>
        <tbody>

        <tr>
            <td className="table-light">Doctor ID</td>
            <td className="table-light">{item?.doctor_id}</td>
      
          </tr>
      
         <tr>
            <td className="table-light">Candidate Name</td>
            <td className="table-light">{item?.full_name}</td>
      
          </tr>
      
          <tr>
            <td className="table-light">Parent Name</td>
            <td className="table-light">{item?.parent_name}</td>
      
          </tr>
      
      
          <tr>
            <td className="table-light">Registration Number</td>
            <td className="table-light">{item?.registration_number}</td>
      
          </tr>

          <tr>
            <td className="table-light">State Medical Council</td>
            <td className="table-light">{item?.state_medical_council}</td>
      
          </tr>

          <tr>
            <td className="table-light">Qualification</td>
            <td className="table-light">{item?.qualification}</td>
      
          </tr>
      
          <tr>
            <td className="table-light">Registration Date</td>
            <td className="table-light">{item?.registration_date}</td>
      
          </tr>
          <tr>
            <td className="table-light">Verified On</td>
            <td className="table-light">{moment(item.timestamp).format('DD-MM-YYYY')}</td>
      
          </tr>

          <tr>
            <td className="table-light">Attachment (Candidate)</td>
            <td className="table-light">
              <Link to={`${item?.uploaded_document}`} target="_blank">
              Click here
              </Link>
            </td>
      
          </tr>
          <tr>
            <td className="table-light">Attachment (By TheRollNumber)</td>
            <td className="table-light">
              {/* <Link to={`${item?.uploaded_document}`} target="_blank">
              Click here
              </Link> */}
            </td>
      
          </tr>
      
      
        </tbody>
      </Table>
      
      <Table className="table mb-0 mt-3">
      <thead className="table-dark">
          <tr>
            <th className="text-center" style={{width:'100%'}}>Comment</th>
            
      
      
          </tr>
      
        </thead>
        <tbody>
          <tr>
            <td className="table-light" style={{height:100}}></td>
       
      
          </tr>
          </tbody>
        </Table>
              </div>
    )
  }



  const CA = ({item}) => {

    return (
      <div>
        
      <Table className="table mb-0">
        <thead className="table-dark">
          <tr>
            <th style={{width:'50%'}}>Verification Component</th>
            <th style={{width:'50%'}}>Details Provided By The Candidate</th>
      
      
          </tr>
      
        </thead>
        <tbody>

        <tr>
            <td className="table-light">Membership Number</td>
            <td className="table-light">{item?.membership_no}</td>
      
          </tr>
      
         <tr>
            <td className="table-light">Candidate Name</td>
            <td className="table-light">{item?.member_name}</td>
      
          </tr>
      
          <tr>
            <td className="table-light">Gender</td>
            <td className="table-light">{item?.gender}</td>
      
          </tr>
      
      
          <tr>
            <td className="table-light">Gender</td>
            <td className="table-light">{item?.gender}</td>
      
          </tr>

          <tr>
            <td className="table-light">Professional Address</td>
            <td className="table-light">{item?.professional_address}</td>
      
          </tr>

          <tr>
            <td className="table-light">Member Status</td>
            <td className="table-light">{item?.member_status}</td>
      
          </tr>

          <tr>
            <td className="table-light">Cop Status</td>
            <td className="table-light">{item?.cop_status}</td>
      
          </tr>
      
          <tr>
            <td className="table-light">Associate Or Fellow</td>
            <td className="table-light">{item?.associate_or_fellow}</td>
      
          </tr>
      
          <tr>
            <td className="table-light">Attachment (Candidate)</td>
            <td className="table-light">
              <Link to={`${item?.uploaded_document}`} target="_blank">
              Click here
              </Link>
            </td>
      
          </tr>
          <tr>
            <td className="table-light">Attachment (By TheRollNumber)</td>
            <td className="table-light">
              {/* <Link to={`${item?.uploaded_document}`} target="_blank">
              Click here
              </Link> */}
            </td>
      
          </tr>
     
      
      
        </tbody>
      </Table>
      
      <Table className="table mb-0 mt-3">
      <thead className="table-dark">
          <tr>
            <th className="text-center" style={{width:'100%'}}>Comment</th>
            
      
      
          </tr>
      
        </thead>
        <tbody>
          <tr>
            <td className="table-light" style={{height:100}}></td>
       
      
          </tr>
          </tbody>
        </Table>
              </div>
    )
  }


  const renderData = (doc)=> {

    // console.log('doc==>',doc);

    if (doc && Object.keys(doc)[0] == 'aadhaar') {

      console.log('aadhaar doc found==>',doc);

      const item = doc.aadhaar;

      return (
       <Aadhaar item={item}/>
      )
     
    }

    if (doc && Object.keys(doc)[0] == 'pan') {

      console.log('pan doc found==>',doc);

      const item = doc.pan;

      return (
       <Pan item={item}/>
      )
     
    }

    if (doc && Object.keys(doc)[0] == 'voter_id') {
        
        console.log('voter_id doc found==>',doc);
  
        const item = doc.voter_id;
  
        return (
        <VoterId item={item}/>
        )
      
      }

      if (doc && Object.keys(doc)[0] == 'driving_licence') {
          
          console.log('driving_licence doc found==>',doc);
    
          const item = doc.driving_licence;
    
          return (
          <DL item={item}/>
          )
        
        }

        if (doc && Object.keys(doc)[0] == 'icai') {

          console.log('ca doc found==>',doc);
    
          const item = doc.icai;
    
          return (
          <CA item={item}/>
          )
         
        }

        if (doc && Object.keys(doc)[0] == 'nurse') {
            
            console.log('nurse doc found==>',doc);
      
            const item = doc.nurse;
      
            return (
            <Nurse item={item}/>
            )
          
          }

          if (doc && Object.keys(doc)[0] == 'doctor') {

            console.log('doctor doc found==>',doc);
      
            const item = doc.doctor;
      
            return (
            <Doctor item={item}/>
            )
           
          }

  }


  const get_request_identity_org = async () => {

    const org_client_id = caseData?.org_client_id;

    console.log('org_client_id',org_client_id);

    const config = {
      headers: {
        'Content-Type': 'application/json'
      }
    };


    
    axios.get(`${process.env.REACT_APP_BASE_URL}/service/request_identity_org/${org_client_id}`, {}, config)
    .then(response => {
      console.log('requested identity org response',response);

      let data = response.data.data[0];

      console.log('request identity data==>',data);

      let req_data = JSON.parse(data.req_data);
      console.log('req_data==>',req_data);

      let newSection = [
        {
          name: "Identity",
          components:[],
          isOpen: false,
        },
        {
          name: "Professional Identity",
          components:[],
          isOpen: false,
        },
    
        
      ];

      let arr = [];

      if(data.identity){

        let identity = req_data.identity;

        let obj =    {
          name: "Identity",
          components:[],
          isOpen: false,
        }

        identity.map((item, index) => {

          obj.components.push({
            name:item,
            isOpen:false  
          })

        })

        if(identity.length > 0){

         arr.push(obj); 

        }
        // setSection([...section,
        
        //   obj
        // ]);

       

      }

      if(data.professional_identity){



        let professional_identity = req_data.professional_identity;

       let obj =   {
        name: "Professional Identity",
        components:[],
        isOpen: false,
      }

        professional_identity.map((item, index) => {

          obj.components.push({
            name:item,
            isOpen:false  
          })

        })

        if(professional_identity.length > 0){
          arr.push(obj);

        }


        // setSection([...section,
        //   obj
   
      
        // ]);

      }

      setSection(arr);



    })
    .catch(error => {
  
      
  
      console.log('error==>',error);
    });

  }

  const activity_logs = async () => {

    const config = {
      headers: {
        'Content-Type': 'application/json'
      }
    };

    const {org_client_id, request_id} = caseData;

    const body = {

      org_client_id: org_client_id,
      case_id: request_id

    }

  
    axios.post(`${process.env.REACT_APP_BASE_URL}/service/activity_logs`, body, config)
    .then(response => {
      console.log('activity logs',response);

     setActivityLogs(response.data);


    })
    .catch(error => {
  
      
  
      console.log('error==>',error);
    });

  }

  const get_wallet = async (value) => {

    const config = {
      headers: {
        'Content-Type': 'application/json'
      }
    };

  
    axios.get(`${process.env.REACT_APP_BASE_URL}/service/wallet/${value}`, {}, config)
    .then(response => {
      console.log('wallet data',response.data);

      let data = response.data;

      console.log('data==>',data);

      setUser(data.user);



    })
    .catch(error => {
  
      
  
      console.log('error==>',error);
    });

  }

  const approve = async() => {


    const config = {
      headers: {
        'Content-Type': 'application/json'
      }
    };

    const {notification_id} = caseData;

    const body = {


      verification_status: 'Complete',
      org_emp_id: "",
      org_client_id: caseData.org_client_id,
      case_id: caseData.request_id


    }

    console.log('approve case body==>',body);

  
    axios.put(`${process.env.REACT_APP_BASE_URL}/service/admin/case/${notification_id}`, body, config)
    .then(response => {
      console.log('update case res',response);


      navigate('/client/on-going-cases');



    })
    .catch(error => {
  
      
  
      console.log('update case error==>',error);
    });

  }
  const get_case = async () => {

   
    const data = location.state;

    console.log('param data',data)

    if (data) {
      setCaseData(data);
      get_wallet(data.walletID);
    }



  }


  // const [isLoading, setLoading] = useState('')



  const toggle = () => {
    if (modal) {
      setModal(false);
      setOrder(null);
    } else {
      setModal(true);
    }
  };


  //delete order
  const [deleteModal, setDeleteModal] = useState(false);

  const onClickDelete = (order) => {
    setOrder(order);
    // setDeleteModal(true);
  };

  const handleDeleteOrder = () => {
    if (order && order.id) {
      // dispatch(onDeleteOrder(order.id));
      // setDeleteModal(false);
      setOrder("");
    }
  };
  const handleOrderClicks = () => {
    setOrderList("");
    setIsEdit(false);
    toggle();
  };

  const update_case = async(status) => {

    console.log('status==>',status);

    const config = {
      headers: {
        'Content-Type': 'application/json'
      }
    };

    const {notification_id} = caseData;

    const body = {

      notification_id: notification_id,
      verification_status: status

    }

    console.log('update case body==>',body);

  
    axios.put(`${process.env.REACT_APP_BASE_URL}/service/case_status`, body, config)
    .then(response => {
      console.log('update case res',response);


      navigate('/on-going-cases');



    })
    .catch(error => {
  
      
  
      console.log('update case error==>',error);
    });

  }


  const resend_verification = ()=>{

    console.log(caseData)

    const body = {

      case_id: caseData.request_id,

    }

    const config = {
      headers: {
        'Content-Type': 'application/json'
      }
    };

    console.log('update case body==>',body);

  
    axios.post(`${process.env.REACT_APP_BASE_URL}/employer/admin/resend_verification`, body, config)
    .then(response => {
      console.log('update case res',response);


      navigate('/client/on-going-cases');



    })
    .catch(error => {
  
      
  
      console.log('update case error==>',error);
    });
  }


  return (
    <React.Fragment>
      {/* <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteOrder}
        onCloseClick={() => setDeleteModal(false)}
      /> */}
      <div className="page-content">
        <div className="container-fluid">
          {/* <Breadcrumbs title="Ecommerce" breadcrumbItem="Orders" /> */}

          <Row>
            <Col lg="12">
              <div className="d-flex mb-4" style={{justifyContent:'space-between'}}>
                <h2 className="page-title">{caseData?.candidate_name}</h2>

                <button className="btn btn-primary"  onClick={()=>navigate('/on-going-cases')}>back</button>

              </div>
            <div className="table-responsive">
                    <Table className="table mb-3">
                      <thead className="table-dark" >
                        <tr>
                          <th>Case Ref.#</th>
                          <th>Location</th>
                          <th>Case In Date</th>
                          <th>Case Due Date</th>
                          <th>Candidate Name</th>

                          <th>Candidate Father's Name</th>
                          <th>Candidate Dob</th>
                          <th>Last Updated On</th>
                          <th>Verification Status</th>

                        </tr>
                      </thead>
                      <tbody>
                        {caseData &&<tr className="table-light">
                          <th >{caseData?.request_id}</th>
                          <td>{caseData?.location}</td>
                          <td>{moment(caseData?.timestamp).format('DD-MM-YYYY')}</td>
                          <td></td>
                          <td>{caseData?.candidate_name}</td>

                          <td>{caseData?.candidate_father_name}</td>
                          <td>{caseData?.candidate_dob}</td>
                          <td></td>
                          <td>{caseData?.verification_status}</td>
                        </tr>}
                    
                      </tbody>
                    </Table>
                  </div>


              </Col>
          </Row>

          <Row>
            <Col lg="3">

            <Card>
                  <CardBody>

                    <div className="p-2 bg-light">
                    <p style={{fontSize:16}} className="mb-4 px-3">Activity Log</p>


{/* {
  activityLogs.map((item, index)=>(
    <div className="row pr-2 p-3  pt-0 pb-0">
    <ul  class="activity-content">
      <li  class="box app-arrow-left-top" >
                          <span >
                          {item.description} - on {moment(item.timestamp).format('DD-MM-YYYY hh:mm:ss')}   </span></li>
                     
                     
                    </ul>
    </div>
  ))
} */}

{
  activityLogs.map((item, index)=>(
    <div className="row pr-2 p-3  pt-0 pb-0">
    <ul  class="activity-content">
      <li  class="box app-arrow-left-top" >
                          <span >
                          {item.status==null?'New':item.status} - on {moment(item.timestamp).format('dddd, DD-MMM-YY hh:mm:ss')} {"<"}  By - {(item.updated_by == null || item.updated_by == "")?"System Scheduler": item.updated_by} {">"}</span>
                         
                          
                          
                          </li>



                     
                    </ul>
    </div>
  ))
}
          
                    </div>
          
                    </CardBody>
                </Card>
            
            </Col>
            <Col lg="9">



            

            <Card>
                  <CardBody>

                  <div className="p-2">

 
<div >
          <div className="text-muted">

          <p style={{fontSize:18}}>Personal Details</p>

<Table className="table mb-0">
  <thead className="table-dark">
    <tr>
      <th style={{width:'50%'}}>Candidate Data</th>
      <th style={{width:'50%'}}></th>


    </tr>

  </thead>
  <tbody>
    <tr>
      <td className="table-light">First Name</td>
      <td className="table-light">{user?.first_name}</td>
     
    </tr>
    <tr>
      <td className="table-light">Last Name</td>
      <td className="table-light">{user?.last_name}</td>

    </tr>

  <tr>
      <td className="table-light">Father's Name</td>
      <td className="table-light"></td>

    
  </tr>
  <tr>
      <td className="table-light">Date of Birth</td>
      <td className="table-light"></td>

  </tr>

  <tr>
      <td className="table-light">Email</td>
      <td className="table-light">{user?.email}</td>

  </tr>

  <tr>
      <td className="table-light">Mobile No</td>
      <td className="table-light">{user?.phone_number}</td>

  </tr>

  <tr>
      <td className="table-light">Gender</td>
      <td className="table-light"></td>

  </tr>

  {/* <tr>
      <td className="table-light">Cateogry</td>
      <td className="table-light"></td>

  </tr> */}

  </tbody>
</Table>
           
          </div>
        </div>

      </div>



                  <div className="accordion" id="accordion">
    
   

      <div className="accordion-item m-2">
        <h2 className="accordion-header" id="headingOne" >
          <button
            className={classnames(
              "accordion-button",
              "fw-medium",
              { collapsed: !col2 }
            )}
            style={{
              cursor:'pointer',
              fontWeight:'200',
              fontSize:18
            }}
            type="button"
            onClick={t_col2}
          >
          Verification Components
          </button>
        </h2>

        

        <Collapse isOpen={col2} className="accordion-collapse">

  <div className="accordion p-3" id="accordion3">
    {
      section.map((item, index) => (
        <div className="accordion-item m-2" key={index}>
          <h2 className="accordion-header" id={`heading${index}`}>
            <button
              className={classnames("accordion-button", { collapsed: !item.isOpen })}
              type="button"
             
              onClick={() => {
                const newItems = section.map((sectionItem, sectionIndex) => {
                  if (sectionIndex === index) {
                    return {
                      ...sectionItem,
                      isOpen: !sectionItem.isOpen
                    };
                  }
                  return {
                    ...sectionItem,
                    
                  };
                });
                setSection(newItems);
              }}
              style={{ cursor: "pointer",
              fontWeight:'200',

              fontSize:16
            
            }}
            >
            {item.name}
            </button>
          </h2>
          <Collapse isOpen={item.isOpen}  className="accordion-collapse">
            {/* Content inside the accordion item */}
            <div className="accordion-body">
              {
                item.components.map((component, componentIndex) => {

                  const docData = requestedData.find((doc) => Object.keys(doc)[0] === component.name);

                  const renderedData = renderData(docData);

return (
                  <div className="accordion-item m-2" key={componentIndex}>
                    <h2 className="accordion-header" id={`heading${componentIndex}`}>
                      <button
                        className={classnames("accordion-button", "fw-medium", { collapsed: !component.isOpen})}
                        type="button"
                        onClick={() => {
                          const newItems = item.components.map((componentItem, componentItemIndex) => {
                            if (componentItemIndex === componentIndex) {
                              return {
                                ...componentItem,
                                isOpen: !componentItem.isOpen
                              };
                            }
                            return {
                              ...componentItem,
                              isOpen: false
                            };
                          });
                          const newSection = section.map((sectionItem, sectionIndex) => {
                            if (sectionIndex === index) {
                              return {
                                ...sectionItem,
                                components: newItems
                              };
                            }
                            return sectionItem;
                          });
                          setSection(newSection);
                        
                        }}
                        style={{ cursor: "pointer" }}
                      >
                      {docNames[component.name]?docNames[component.name]:component.name}
                      </button>
                    </h2>
                    <Collapse isOpen={component.isOpen} className="accordion-collapse">
                      {/* Content inside the accordion item */}
                      <div className="accordion-body">

{
  renderedData
}
                
                      </div>
                    </Collapse>
                  </div>
                )

})
              }
            </div>
          </Collapse>
        </div>
      ))
    }
    </div>
  

        </Collapse>
      </div>

      <div className="accordion-item m-2">
        <h2 className="accordion-header" id="headingOne">
          <button
            className={classnames(
              "accordion-button",
              "fw-medium",
              { collapsed: !col1 }
            )}
            type="button"
            onClick={t_col1}
            style={{ cursor: "pointer",
            fontWeight:'200',
            fontSize:18
          
          }}
          >
          Education
          </button>
        </h2>

        

        <Collapse isOpen={col1} className="accordion-collapse">


  
<div className="accordion-body">
          <div className="text-muted">

          <p style={{fontSize:18}}>Education</p>

{
   education.length > 0 && education.map((item, index) => (
  <Table className="table mb-0">
  <thead className="table-dark">
    <tr>
      <th>Education Details</th>
      <th></th>
    </tr>

  </thead>
  <tbody>
  <tr>
      <td className="table-light">Board of University</td>
      <td className="table-light">{item.university_name}</td>

  </tr>

  <tr>
      <td className="table-light">Course</td>
      <td className="table-light">{item.type_of_graduation}</td>

  </tr>


  <tr>
      <td className="table-light">Percentage/CGPA</td>
      <td className="table-light">{item.CGPA}</td>

  </tr>

  <tr>
      <td className="table-light">Graduation Year</td>
      <td className="table-light">{item.year_of_graduation}</td>

  </tr>

  <tr>
      <td className="table-light">Start Date</td>
      <td className="table-light">{item.year_of_start}</td>

  </tr>

  <tr>
      <td className="table-light">End Date</td>
      <td className="table-light">{item.year_of_graduation}</td>

  </tr>

  <tr>
      <td className="table-light">Final Status</td>
      <td className="table-light"></td>

  </tr>
  </tbody>
</Table>

    ))
}
           
          </div>
        </div>
        </Collapse>
      </div>

 
    

      <div className="accordion-item m-2">
        <h2 className="accordion-header" id="headingOne">
          <button
            className={classnames(
              "accordion-button",
              "fw-medium",
              { collapsed: !col3 }
            )}
            type="button"
            onClick={t_col3}
            style={{ cursor: "pointer",
            fontWeight:'200',
            fontSize:18
          
          }}
          >
          Employment
          </button>
        </h2>

        

        <Collapse isOpen={col3} className="accordion-collapse">


<div className="accordion-body">
          <div className="text-muted">

<Table className="table mb-0">
  <thead className="table-dark">
  <tr>
      <th>Employment Details</th>
      <th></th>
    </tr>
    </thead>
  <tbody>
    </tbody>
</Table>

{
   employment.length > 0 && employment.map((item, index) => (
  <Table className="table mb-0 mt-3">
  <thead className="table-dark">


  </thead>
  <tbody>
  <tr>
      <td className="table-light w-25">Company name</td>
      <td className="table-light w-75">{item.company_name}</td>

  </tr>

  <tr>
      <td className="table-light w-25">Designation</td>
      <td className="table-light w-75">{item.designation}</td>

  </tr>

  <tr>
      <td className="table-light w-25">Employee ID</td>
      <td className="table-light w-75">{item.emp_id}</td>

  </tr>

  <tr>
      <td className="table-light w-25">Reason for Leaving</td>
      <td className="table-light w-75"></td>

  </tr>

  <tr>
      <td className="table-light w-25">Attachment</td>
      <td className="table-light w-75">

        <div className="d-flex w-50" style={{justifyContent:'space-between'}}>
        <Link to={`${item?.offer_letter_path}`} target="_blank">
       Offer Letter
      </Link>
      <Link to={`${item?.payslip_path}`} target="_blank">
       Payslip path
      </Link>
      <Link to={`${item?.exp_letter_path}`} target="_blank">
       Experience Letter
      </Link>
        </div>

      </td>

  </tr>

  <tr>
      <td className="table-light">Date of joining</td>
      <td className="table-light">{item.doj}</td>

  </tr>

  <tr>
      <td className="table-light">Date of relieving</td>
      <td className="table-light">{item.doe}</td>

  </tr>


  <tr>
      <td className="table-light">Final Status</td>
      <td className="table-light"></td>

  </tr>
  </tbody>
</Table>

    ))
}
           
          </div>
        </div>
        </Collapse>
   
            
         
</div>

<div className="accordion-item m-2">
        <h2 className="accordion-header" id="headingOneEpfo">
          <button
            className={classnames(
              "accordion-button",
              "fw-medium",
              { collapsed: !col6 }
            )}
            type="button"
            onClick={t_col6}
            style={{ cursor: "pointer",
            fontWeight:'200',
            fontSize:18
          
          }}
          >
          Epfo
          </button>
        </h2>

        

        <Collapse isOpen={col6} className="accordion-collapse">


  
<div className="accordion-body">
          <div className="text-muted">

          <p style={{fontSize:18}}>Epfo Details</p>

          <div  >
                        

                            {
epfo_data && 

<div className="table-responsive pb-3" >
<p style={{fontSize:16, textAlign:'center'}} className="mb-4">Employee Verification by UAN Number</p>

 <Row>
   <Col className="col-4">

     <div style={{}}>

       <div className="d-flex">
       <p style={{fontSize:13}}>Name:</p>
     <p style={{fontSize:13}}>{epfo_data?.full_name}</p>
       </div>

       <p style={{fontSize:13}}>
         Date of Birth: <span>{epfo_data?.dob}</span>
       </p >


     </div>
   </Col>
   <Col className="col-4">
   <div style={{display:'flex'}}>
     <p style={{fontSize:13}}>Father Name:</p>
     <p style={{fontSize:13}}>{epfo_data?.father_name}</p>

     </div>
   </Col>
   <Col className="col-4">
   <div style={{display:'flex'}}>
     <p style={{fontSize:13}}>UAN Number:</p>
     <p>{epfo_data?.pf_uan}</p>

     </div>
   </Col>
 </Row>
 <Row>
   <Col className="col-12">
    
    {
      epfo_companies && 

      epfo_companies.map((item)=>{
        return (
          <Accordion header={
            <div className="container-fluid">
            
            <Row>
            <Col className="col-11">
            <Row className="pt-3 text-center">
            <Col className="col-6 text-center">
            <p style={{fontSize:13}}>{item?.company_name}</p>
            
            </Col>
            <Col className="col-6 text-center">
            <p style={{fontSize:13}}>{item?.establishment_id}</p>
            
            </Col>
            
            </Row>
            </Col>
            <Col className="col-1 d-flex" style={{alignItems:"center", justifyContent:'end'}}>
            {/* <FeatherIcon icon="chevron-down" style={{marginRight:10}}  /> */}

            <i class="fas fa-chevron-down" style={{marginRight:10}}></i>
            
            </Col>
            </Row>
            
            
            
            </div>
            }>
            <div className="table-responsive">
            
       
            <PassbookDetail eid={item?.establishment_id} data={epfo_passbook}/>
            
            </div>
            </Accordion>
        )
      })
 
    }

   </Col>
 </Row>
</div>
                            }
                     

                         
                        </div>


           
          </div>
        </div>
        </Collapse>
      </div>

      <div className="accordion-item m-2">
        <h2 className="accordion-header" id="headingOneTds">
          <button
            className={classnames(
              "accordion-button",
              "fw-medium",
              { collapsed: !col7 }
            )}
            type="button"
            onClick={t_col7}
            style={{ cursor: "pointer",
            fontWeight:'200',
            fontSize:18
          
          }}
          >
          TDS
          </button>
        </h2>

        

        <Collapse isOpen={col7} className="accordion-collapse">

        <div className="accordion-body">
          <div className="text-muted">

          <p style={{fontSize:18}}>TDS Details</p>

          <div  >
                        

                            {
itr && 

<div className="table-responsive pb-3" >
<p style={{fontSize:16, textAlign:'center'}} className="mb-4">Employee Verification by UAN Number</p>

 <Row>
   <Col className="col-12">

     <div style={{}}>

       <div className="d-flex">
       <p style={{fontSize:13}}>PAN:</p>
     <p style={{fontSize:13}}>{itr?.pan_no}</p>
       </div>




     </div>
   </Col>

 </Row>
 <Row>
   <Col className="col-12">
    
    {
      itr && 

      tds.map((item)=>{
        return (
          <Accordion header={
            <div className="container-fluid">
            
            <Row>
            <Col className="col-11">
            <Row className="pt-3 text-center">
            <Col className="col-6 text-center">
            <p style={{fontSize:13}}>{item?.assessment_year}</p>
            
            </Col>
            <Col className="col-6 text-center">
            <p style={{fontSize:13}}>{item?.pan_no}</p>
            
            </Col>
            
            </Row>
            </Col>
            <Col className="col-1 d-flex" style={{alignItems:"center", justifyContent:'end'}}>
            {/* <FeatherIcon icon="chevron-down" style={{marginRight:10}}  /> */}

            <i class="fas fa-chevron-down" style={{marginRight:10}}></i>
            
            </Col>
            </Row>
            
            
            
            </div>
            }>
            <div className="table-responsive">
            
       
            <TDSDATA assessment_year={item.assessment_year}   data={tds_data}/>
            
            </div>
            </Accordion>
        )
      })
 
    }

   </Col>
 </Row>
</div>
                            }
                     

                         
                        </div>


           
          </div>
        </div>
        </Collapse>
      </div>

      
      <div className="accordion-item m-2">
        <h2 className="accordion-header" id="headingEight">
          <button
            className={classnames(
              "accordion-button",
              "fw-medium",
              { collapsed: !col8 }
            )}
            type="button"
            onClick={t_col8}
            style={{ cursor: "pointer",
            fontWeight:'200',
            fontSize:18
          
          }}
          >
          Physical Verification
          </button>
        </h2>

        

        <Collapse isOpen={col8} className="accordion-collapse">


  
<div className="accordion-body">
          <div className="text-muted">

          <Table className="table mb-0">
  <thead className="table-dark">
    <tr>
      <th>Address Details</th>
      <th></th>
    </tr>

  </thead>
  <tbody>
  <tr>
      <td className="table-light">Permanent Address</td>
      <td className="table-light"></td>

  </tr>

  <tr>
      <td className="table-light">State</td>
      <td className="table-light"></td>

  </tr>


  <tr>
      <td className="table-light">City</td>
      <td className="table-light"></td>

  </tr>

  <tr>
      <td className="table-light">Pincode</td>
      <td className="table-light"></td>

  </tr>

  <tr>
      <td className="table-light">Refrence name</td>
      <td className="table-light"></td>

  </tr>

  <tr>
      <td className="table-light">Refrence relation</td>
      <td className="table-light"></td>

  </tr>

  <tr>
      <td className="table-light">Reference mobile</td>
      <td className="table-light"></td>

  </tr>

  <tr>
      <td className="table-light">Candidate residence since</td>
      <td className="table-light"></td>

  </tr>

  <tr>
      <td className="table-light">Candidate residence until</td>
      <td className="table-light"></td>

  </tr>

  <tr>
      <td className="table-light">House Type</td>
      <td className="table-light"></td>

  </tr>


  <tr>
      <td className="table-light">Latitude/Longitude</td>
      <td className="table-light"></td>

  </tr>
  </tbody>
</Table>
           
          </div>
        </div>
        </Collapse>
      </div>


      <div className="accordion-item m-2">
        <h2 className="accordion-header" id="headingNine">
          <button
            className={classnames(
              "accordion-button",
              "fw-medium",
              { collapsed: !col9 }
            )}
            type="button"
            onClick={t_col9}
            style={{ cursor: "pointer",
            fontWeight:'200',
            fontSize:18
          
          }}
          >
          Court Records
          </button>
        </h2>

        

        <Collapse isOpen={col9} className="accordion-collapse">


  
<div className="accordion-body">
          <div className="text-muted">

          <Table className="table mb-0">
  <thead className="table-dark">
    <tr>
      <th>Court verification</th>
      <th></th>
    </tr>

  </thead>

  <tbody>
  <tr>
      <td className="table-light">Personal assistant</td>
      <td className="table-light"></td>
      </tr>
      <tr>
      <td className="table-light">Permanent Address</td>
      <td className="table-light"></td>
      </tr>
      <tr>
      <td className="table-light">District and state</td>
      <td className="table-light"></td>
      </tr>
      <tr>
      <td className="table-light">Final status</td>
      <td className="table-light"></td>
      </tr>
    </tbody>  
</Table>
           
          </div>
        </div>
        </Collapse>
      </div>

      <div className="accordion-item m-2">
        <h2 className="accordion-header" id="headingTen">
          <button
            className={classnames(
              "accordion-button",
              "fw-medium",
              { collapsed: !col10 }
            )}
            type="button"
            onClick={t_col10}
            style={{ cursor: "pointer",
            fontWeight:'200',
            fontSize:18
          
          }}
          >
          Credit Report
          </button>
        </h2>

        

        <Collapse isOpen={col10} className="accordion-collapse">


  
<div className="accordion-body">
          <div className="text-muted">

        <Table className="table mb-0">
  <thead className="table-dark">
    <tr>
      <th>Credit Details</th>
      <th></th>
    </tr>

  </thead>

  <tbody>
  
  
  
      <tr>
      <td className="table-light">Pan</td>
      <td className="table-light">{credit_score.pan}</td>
      </tr>
      <tr>
      <td className="table-light">Name</td>
      <td className="table-light">{credit_score.name}</td>
      </tr>
      <tr>
      <td className="table-light">Mobile</td>
      <td className="table-light">{credit_score.mobile}</td>
      </tr>
      <tr>
      <td className="table-light">Credit score</td>
      <td className="table-light">{credit_score.credit_score}</td>
      </tr>
    </tbody>  
</Table>
        
          </div>
        </div>
        </Collapse>
      </div>

      <div className="accordion-item m-2">
        <h2 className="accordion-header" id="headingEleven">
          <button
            className={classnames(
              "accordion-button",
              "fw-medium",
              { collapsed: !col11 }
            )}
            type="button"
            onClick={t_col11}
            style={{ cursor: "pointer",
            fontWeight:'200',
            fontSize:18
          
          }}
          >
          Drug Test
          </button>
        </h2>

        

        <Collapse isOpen={col11} className="accordion-collapse">


  
<div className="accordion-body">
          <div className="text-muted">

          {/* <p style={{fontSize:18}}>Education</p> */}


           
          </div>
        </div>
        </Collapse>
      </div>
    </div>

         <div>
          <div className="d-flex w-50" style={{justifyContent:'space-between', margin:'0 auto'}}>
          {/* <PDFDownloadLink document={<PdfDocViewer />}    fileName="somename.pdf">
      {({ blob, url, loading, error }) => (loading ? 'Loading document...' : 'Download now!')}
    </PDFDownloadLink> */}



            <Button color="primary" 
            // disabled={(caseData.report == 'FR' || caseData.report == 'IR' || caseData.verification_status == 'Complete')?false:true}
             className="mt-2" onClick={viewReport}>
              View Verification Report
            </Button>
             <Button color="primary" disabled={caseData.verification_status == 'Dispatch Done'?false:true} className="mt-2" onClick={resend_verification}>
              Resend For Verification
            </Button>
           <Button color="primary" disabled={caseData.verification_status == 'Dispatch Done'?false:true} className="mt-2" onClick={approve}>
              Approve Case
            </Button>
          </div>
         </div>


    
                    </CardBody>
                </Card>

               
            </Col>
          </Row>
      
          <Modal isOpen={modal} centered={true} toggle={toggle}>
            <ModalHeader toggle={toggle} tag="h4">
              Add Candidate
            </ModalHeader>  
            <ModalBody>
          
            </ModalBody>
          </Modal>

          <Modal isOpen={modalConfirm} centered={true} toggle={toggleConfirm}>
            <ModalHeader toggle={toggleConfirm} tag="h4">
              Approve Case
            </ModalHeader>
            <ModalBody>
         
                <Row>
                  <Col className="col-12">
                    
                      <p style={{fontSize:16, textAlign:'center'}}>Are you sure you want to approve?</p>
                    
              
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div className="text-center">
                      <button
                        type="button"
                        onClick={()=>{
                          update_case('Complete');
                          setModalConfirm(false);
                        }}
                        className="btn btn-primary save-user me-2"
                      >
                        Yes
                      </button>
                      <button
                        type="button"
                        onClick={() => { setModalConfirm(false) }}
                        className="btn btn-primary save-user"
                      >
                        No
                      </button>
                    </div>
                  </Col>
                </Row>
        
            </ModalBody>
          </Modal>

          <Modal isOpen={modalConfirm2} centered={true} toggle={toggleConfirm2}>
            <ModalHeader toggle={toggleConfirm2} tag="h4">
              Re-verification
            </ModalHeader>
            <ModalBody>
         
                <Row>
                  <Col className="col-12">
                    
                      <p style={{fontSize:16, textAlign:'center'}}>Are you sure you want to Re-verify?</p>
                    
              
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div className="text-center">
                      <button
                        type="button"
                        onClick={()=>{
                          update_case('Re-verification');
                          setModalConfirm(false);
                        }}
                        className="btn btn-primary save-user me-2"
                      >
                        Yes
                      </button>
                      <button
                        type="button"
                        onClick={() => { setModalConfirm(false) }}
                        className="btn btn-primary save-user"
                      >
                        No
                      </button>
                    </div>
                  </Col>
                </Row>
        
            </ModalBody>
          </Modal>
        </div>
      </div>

    </React.Fragment>
  );
}
EditProgress.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
};

export default EditProgress;
