import React, { Component, Fragment,useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import {PDFViewer, Font} from '@react-pdf/renderer'
import Invoice from './components/reports/Invoice'
import invoice from './data/invoice'
import axios from 'axios';
import source from '../../../assets/fonts/Montserrat-SemiBold.ttf'
import source2 from '../../../assets/fonts/Montserrat-Regular.ttf';
import source3 from '../../../assets/fonts/Montserrat-Light.ttf';

Font.register({ family: 'Montserrat', src: source });
Font.register({ family: 'Montserrat-Regular', src: source2 });
Font.register({ family: 'Montserrat-Light', src: source3 });


const App =()=> {
  const location = useLocation();

  useEffect(() => {

    // get param from url

    // const url = window.location.href;

    // const urlArr = url.split('/');

    // const id = urlArr[urlArr.length - 1];

    const id = location.state.id;

    getQCcaseComponents(id);

    get_components();


    getCase(id);


     

  }, []);

  

  const [data, setData] = useState([]);


  const [isLoading, setIsLoading] = useState(true);

  const [user, setUser] = useState({});

  const [requestedData, setRequestedData] = useState([]);

  const [components, setComponents] = useState([]);

  const [selectedComponents, setSelectedComponents] = useState([]);

  const [cases, setCases] = useState([]);


  const get_components = async () => {

    const config = {
      headers: {
        'Content-Type': 'application/json'
      }
    };
   
    axios.get(`${process.env.REACT_APP_BASE_URL}/service/components`, {}, config)

    .then(response => {

      console.log('response components==>',response)

      const data = response.data;

     console.log('data==>',data)

     let arr = [];

      data.map((item)=> {

        arr.push({
          component: item.name,
          code: item.code,
          checked: false,
          id: item.id
        })

      })


      setComponents(arr)




    })

    .catch(error => {

      console.log('error from server==>',error)

    })


  }


  const getQCcaseComponents = async (id) => {


    const config = {
      headers: {
        'Content-Type': 'application/json'
      }
    };


    if(id){
   
   await axios.get(`${process.env.REACT_APP_BASE_URL}/employer/admin/qc_case_components/${id}`, {}, config)

    .then(response => {


      console.log('response qc cases==>',response)

      const data = response.data.data;

      console.log('qc cases==>',data);

      let arr = []

      data.map((item)=> {

        if(item.report !== null && item.report !== ''){

          arr.push({
            ...item,
            select:false
          })

        }
 
 
 
       })
 
       console.log('qc cases filtered arr==>',arr)
     
 
       setCases(arr)


    })

    .catch(error => {


      console.log('error qc cases from server==>',error)

    })
  }

  }


  const get_requested_data = async (id, walletID) => {


    setIsLoading(true);

    const config = {
      headers: {
        'Content-Type': 'application/json'
      }
    };

    const body = {

      org_user_client_id: id,
    
    }

    console.log('body requested ==>',body);

    axios.post(`${process.env.REACT_APP_BASE_URL}/service/requestedData/${walletID}`, body, config)
    .then(response => {
      console.log('requested data',response);

      let data = response.data.data;

      console.log('requested data==>',data);

      setRequestedData(data);

      setIsLoading(false);


    })
    .catch(error => {
  
      setIsLoading(false);
  
      console.log('error==>',error);
    });

 
  }


  const get_wallet = async (value) => {

    const config = {
      headers: {
        'Content-Type': 'application/json'
      }
    };

  
    axios.get(`${process.env.REACT_APP_BASE_URL}/service/wallet/${value}`, {}, config)
    .then(response => {
      console.log('wallet data',response.data);

      let data = response.data;

      console.log('user data==>',data);

      setUser(data.user);



    })
    .catch(error => {
  
      
  
      console.log('error==>',error);
    });

  }


  const getCase = async (id) => {

    
    const config = {
      headers: {
        'Content-Type': 'application/json'
      }
    };



    axios.get(`${process.env.REACT_APP_BASE_URL}/service/case/${id}`, {}, config)
    .then((response) => {
      console.log(response.data);
      setData(response.data[0]);
      get_wallet(response.data[0].walletID);
      get_requested_data(response.data[0].org_client_id, response.data[0].walletID);
    })
    .catch((error) => {
      console.log(error);
    });

  }

  useEffect(() => {

    if(cases.length > 0 && components.length > 0){

      let arr = [];

      cases.map((item)=> {

       let comp = components.findIndex((element) => element.id === item.component_id);

        if(comp !== -1){
  
          arr.push(components[comp].code);
  
        }

      })

      setSelectedComponents(arr);

    
    }

  }, [components, cases]);
 
    return (
      
            <PDFViewer width="100%"  style={{height:'100vh'}}>

              {
                !isLoading && <Invoice
                 data={data}
                 candidate={user}
                  comp={selectedComponents}
                   invoice={invoice}
                    requestedData={requestedData}/>

              }
            </PDFViewer>
  
    );
  
}

export default App;