import React, { useEffect, useRef , useState} from 'react';
import { Page, Document, Image, StyleSheet,View, usePDF, Text } from '@react-pdf/renderer';
import InvoiceTitle from './InvoiceTitle'
import BillTo from './BillTo'
import InvoiceNo from './InvoiceNo'
import InvoiceItemsTable from './InvoiceItemsTable'

import InvoiceThankYouMsg from './InvoiceThankYouMsg'
import logo from '../../report_images/therollnumber_logo.png'
import ComponentDetail from './ComponentDetail';
import AadhaarComp from './AadhaarComp';
import PanComp from './PanComp';
import DLComp from './DLComp';
import VoterIDComp from './VoterIDComp';
import DoctorComp from './DoctorComp';
import CAComp from './CAComp';
import NurseComp from './NurseComp';
import Header from './Header';
import Footer from './Footer';
import AadhaarLogo from '../../aadhaar.png'
import PanCardLogo from '../../report_images/income-tax.png'
import DLLogo from '../../LICENSE.png'
import VoterIDLogo from '../../voterid.png'
import NurseLogo from '../../NUID.png'
import CALogo from '../../ICIA.png'
import EducationComp from './EducationComp';
import EmploymentComp from './EmploymentComp';
import EpfoComp from './Epfo';
import TdsComp from './Tds';
import CreditScore from './CreditScore';



const styles = StyleSheet.create({
    page: {
        fontFamily: 'Helvetica',
        backgroundColor:'#061c33',
        fontSize: 11,
        paddingTop: 30,
        paddingLeft:60,
        paddingRight:60,
        lineHeight: 1.5,
        flexDirection: 'column',
    }, 
    logo: {
        width:200,
      marginBottom:30,
        
    }
  });

  const Invoice = ({invoice, comp, data, candidate, documents, requestedData, qcData, components})=>{


    // const [colors, setColors] = useState(
    //   {
    //     'Green': 'green',
    //     'Red': '#ffdddd',
    //     'Yellow': 'yellow',
    //     'Amber': '#FFBF00',
    //   }
    // );

    const [colorcode, setColorCode] = useState('Green')

    const [colors, setColors] = useState(
      {
        'Green': {
          backgroundColor: '#DDFEDF',
          color: 'darkgreen'
        },
        'Red': {
          backgroundColor: '#ffdddd',
          color: '#fc0404'
        },
        'Yellow': {
          backgroundColor: '#F7F5BC',
          color: '#e47200'
        },
        'Amber': {
          backgroundColor: '#F7E6B8',
          color: 'orange'
        },
      }
    );

    useEffect(() => {

      let data = qcData;

      let red_index = data.findIndex((item) => item.colorcode === 'Red')
      
      let yellow_index = data.findIndex((item) => item.colorcode === 'Yellow')
   
       let amber_index = data.findIndex((item) => item.colorcode === 'Amber')
   
       let green_index = data.findIndex((item) => item.colorcode === 'Green')
   
       if(red_index !== -1){
   
           setColorCode('Red')
   
       }else if(yellow_index !== -1){
   
           setColorCode('Yellow')
   
       }else if(amber_index !== -1){
   
           setColorCode('Amber')
   
       }else if(green_index !== -1){
   
           setColorCode('Green')
   
       }else{
   
           setColorCode('Green')
       }
       
   

    }, []);


    const [document, updateDocument] = usePDF();

    const [sortedData, setSortedData] = useState([]);

    const [show, setShow] = useState({
      epfo: false,
      tds: false,
      aadhaar: false,
      pan: false,
      dl: false,
      voter_id: false,
      doctor: false,
      nurse: false,
      education: false,
      employment: false,
      creditScore: false,
    });


    useEffect(() => {



      const epfo = comp.findIndex((obj) => obj === 'epfo');

      const tds = comp.findIndex((obj) => obj === 'tds');
      
      const aadhaar = comp.findIndex((obj) => obj === 'aadhaar');
      
      const pan = comp.findIndex((obj) => obj === 'pan');
      
      const dl = comp.findIndex((obj) => obj === 'driving_licence');

      const icai = comp.findIndex((obj) => obj === 'icai');
      
      const voter_id = comp.findIndex((obj) => obj === 'voter_id');
      
      const doctor = comp.findIndex((obj) => obj === 'doctor');
      
      const nurse = comp.findIndex((obj) => obj === 'nurse');
      
      const education = comp.findIndex((obj) => obj === 'education');
      
      const employment = comp.findIndex((obj) => obj === 'employment');
      
      const creditScore = comp.findIndex((obj) => obj === 'credit_score');




let obj = {
  epfo: epfo > -1 ? true : false,
  tds: tds > -1 ? true : false,
  aadhaar: aadhaar > -1 ? true : false,
  pan: pan > -1 ? true : false,
  dl: dl > -1 ? true : false,
  voter_id: voter_id > -1 ? true : false,
  doctor: doctor > -1 ? true : false,
   icai: icai > -1 ? true : false,
  nurse: nurse > -1 ? true : false,
  education: education > -1 ? true : false,
  employment: employment > -1 ? true : false,
  creditScore: creditScore > -1 ? true : false,

}

console.log('employment ==>',employment, tds, epfo, requestedData);

let isTds = requestedData.findIndex((item)=> Object.keys(item)[0] == 'tds');

let isEpfo = requestedData.findIndex((item)=> Object.keys(item)[0] == 'epfo');

console.log('isTds==>',isTds, isEpfo);

if(obj.employment && (isTds > -1 || isEpfo > -1)){
  
  // requestedData.map((item, index)=>{

  //   console.log('requested data item==>',Object.keys(item)[0]);

  //   if(Object.keys(item)[0] == 'tds'){
  //     obj.tds = true;
  //     obj.employment = false;
  //   }
    
  //   if(Object.keys(item)[0] == 'epfo'){
  //     obj.epfo = true;
  //     obj.employment = false;
  //   }

  // })

  if(isTds > -1){
    obj.tds = true;
    obj.employment = false;
  }

  if(isEpfo > -1){
    obj.epfo = true;
    obj.employment = false;
  }
    
}

console.log('invoice obj==>',data, obj, comp, requestedData);

setShow({
  ...show,
  ...obj
})

// setSortedData(sortedObj);

    }, []);

    const pageRef = useRef(null);

  
      const aadhaar = (item)=>{

        const comp_id = components.find((obj)=> obj.code == 'aadhaar').id;

        const caseData = qcData.find((obj)=> obj.component_id == comp_id);

        console.log('aadhaar caseData==>',caseData);

        console.log('documents', documents);

        const doc_identity = documents.filter((item)=>item.component_id == comp_id);

        const attachments = doc_identity.filter((item)=> item.show_in_report == 1);  

        console.log('attachments==>',attachments);


        return (
          <Page size="A4" style={[styles.page, {backgroundColor:'#fff', padding:0}]}>
             <Header/>
           <AadhaarComp invoice={invoice} attachments={attachments} colors={colors} caseData={caseData} data={item} logo={AadhaarLogo} requestedData={requestedData} />
           <Footer data={data} />

           
       </Page>
        )

      }

      const Pan = (item)=>{

        const comp_id = components.find((obj)=> obj.code == 'pan').id;

        const caseData = qcData.find((obj)=> obj.component_id == comp_id);

        console.log('pan caseData==>',caseData);

        const doc_identity = documents.filter((item)=>item.component_id == comp_id);

        const attachments = doc_identity.filter((item)=> item.show_in_report == 1);  

        console.log('pan comp attachment', attachments)

        return (
          <Page size="A4" style={[styles.page, {backgroundColor:'#fff', padding:0}]}>
           <Header/>
          <PanComp invoice={invoice} attachments={attachments} colors={colors} logo={PanCardLogo} caseData={caseData} data={item} requestedData={requestedData} />
        <Footer data={data} />
          
      </Page>
        )

      }

      const Credit = (item)=>{

        const comp_id = components.find((obj)=> obj.code == 'credit_score').id;

        const caseData = qcData.find((obj)=> obj.component_id == comp_id);

        console.log('pan caseData==>',caseData);

        return (
          <Page size="A4" style={[styles.page, {backgroundColor:'#fff', padding:0}]}>
           <Header/>
          <CreditScore invoice={invoice} colors={colors} logo={PanCardLogo} data={item} caseData={caseData} requestedData={requestedData} />
        <Footer data={data} />
          
      </Page>
        )

      }

      const Employment = (item)=>{

        return (
          <Page size="A4" style={[styles.page, {backgroundColor:'#fff', padding:0}]}>
           <Header/>
          <EmploymentComp invoice={invoice} colors={colors} logo={PanCardLogo} data={item} requestedData={requestedData} />
        <Footer data={data} />
          
      </Page>
        )

      }

      const Epfo = (item)=>{

        const comp_id = components.find((obj)=> obj.code == 'employment').id;


        const caseData = qcData.find((obj)=> obj.component_id == comp_id);

      

        return (
          <Page size="A4" ref={pageRef} style={[styles.page, {backgroundColor:'#fff', padding:0}]} >
           <Header/>
          <EpfoComp invoice={invoice} colors={colors}  logo={PanCardLogo} caseData={caseData} data={item} requestedData={requestedData} />
        <Footer data={data} />
          
      </Page> 
        )

      }

      const Tds = (item)=>{

        return (
          <Page size="A4" style={[styles.page, {backgroundColor:'#fff', padding:0}]}>
           <Header/>
          <TdsComp invoice={invoice} colors={colors} logo={PanCardLogo} data={item} requestedData={requestedData} />
        <Footer data={data} />
          
      </Page>
        )

      }
      
      const Education = (item)=>{

        const comp_id = components.find((obj)=> obj.code == 'education').id;

        const caseData = qcData.filter((obj)=> obj.component_id == comp_id);

        console.log('education caseData==>',caseData);

        const doc_identity = documents.filter((item)=>item.component_id == comp_id);

        const attachments = doc_identity.filter((item)=> item.show_in_report == 1);  

        


        return (
          <Page size="A4" minPresenceAhead={100} style={[styles.page, {backgroundColor:'#fff', padding:0}]}>
           <Header/>
          <EducationComp invoice={invoice} attachments={attachments} caseData={caseData} colors={colors} logo={PanCardLogo} data={item} requestedData={requestedData} />
         <Footer data={data} />
          
       </Page>
        )

      }
      

      const DL = (item)=>{

        const comp_id = components.find((obj)=> obj.code == 'driving_licence').id;

        const caseData = qcData.find((obj)=> obj.component_id == comp_id);

        console.log('dl caseData==>',caseData);

        const doc_identity = documents.filter((item)=>item.component_id == comp_id);

        const attachments = doc_identity.filter((item)=> item.show_in_report == 1);  


        return (
          <Page size="A4" style={[styles.page, {backgroundColor:'#fff', padding:0}]}>
                      <Header/>

           <DLComp invoice={invoice} attachments={attachments} data={item} colors={colors} caseData={caseData} logo={DLLogo} requestedData={requestedData} />
           <Footer data={data} />

           
       </Page>
        )

      }

      const voter = (item)=>{

        const comp_id = components.find((obj)=> obj.code == 'voter_id').id;

        const caseData = qcData.find((obj)=> obj.component_id == comp_id);

        console.log('voter caseData==>',caseData);

        const doc_identity = documents.filter((item)=>item.component_id == comp_id);

        const attachments = doc_identity.filter((item)=> item.show_in_report == 1);  


        return (
          <Page size="A4" style={[styles.page, {backgroundColor:'#fff', padding:0}]}>
                      <Header/>

           <VoterIDComp invoice={invoice} colors={colors} attachments={attachments} caseData={caseData} logo={VoterIDLogo} data={item} requestedData={requestedData} />
         
           <Footer data={data} />

       </Page>
        )

      }
      

      const doctor = (item)=>{

        const comp_id = components.find((obj)=> obj.code == 'doctor').id;

        const caseData = qcData.find((obj)=> obj.component_id == comp_id);

        console.log('doctor caseData==>',caseData);

        const doc_identity = documents.filter((item)=>item.component_id == comp_id);

        const attachments = doc_identity.filter((item)=> item.show_in_report == 1);  


        return (
          <Page size="A4" style={[styles.page, {backgroundColor:'#fff', padding:0}]}>
                                 <Header/>

           <DoctorComp invoice={invoice} attachments={attachments} colors={colors} data={item} caseData={caseData} logo={CALogo} requestedData={requestedData} />
           <Footer data={data} />

           
       </Page>
        )

      }

      const nurse = (item)=>{

        const comp_id = components.find((obj)=> obj.code == 'nurse').id;

        const caseData = qcData.find((obj)=> obj.component_id == comp_id);

        console.log('nurse caseData==>',caseData);

        const doc_identity = documents.filter((item)=>item.component_id == comp_id);

        const attachments = doc_identity.filter((item)=> item.show_in_report == 1);  


        return (
          <Page size="A4" style={[styles.page, {backgroundColor:'#fff', padding:0}]}>
                                            <Header/>

           <NurseComp invoice={invoice} attachments={attachments} colors={colors} data={item} caseData={caseData} logo={NurseLogo} requestedData={requestedData} />
           <Footer data={data} />

           
       </Page>
        )

      }
      
      
      const icai = (item)=>{

        const comp_id = components.find((obj)=> obj.code == 'icai').id;

        const caseData = qcData.find((obj)=> obj.component_id == comp_id);

        console.log('icai caseData==>',caseData);

        const doc_identity = documents.filter((item)=>item.component_id == comp_id);

        const attachments = doc_identity.filter((item)=> item.show_in_report == 1);  

        console.log('ca data==>',item);

        return (
          <Page size="A4" style={[styles.page, {backgroundColor:'#fff', padding:0}]}>
                                            <Header/>

           <CAComp invoice={invoice} attachments={attachments} colors={colors} data={item} caseData={caseData} logo={NurseLogo} requestedData={requestedData} />
           <Footer data={data} />

           
       </Page>
        )

      }
      
    
    return (
                   <Document>

                    {/* First page */}
                <Page size="A4" style={styles.page} >
                    <Image style={styles.logo} src={logo} />
                    <InvoiceTitle title='Background Verification Report' candidate={candidate} data={data} requestedData={requestedData}/>
                    <InvoiceNo invoice={invoice} data={data}/>
                    <BillTo invoice={invoice} requestedData={requestedData} candidate={candidate} />
                    <InvoiceThankYouMsg case={data} qcData={qcData} />

                    
                </Page>
                <Page size="A4" style={[styles.page, {backgroundColor:'#fff', padding:0}]}>
             <Header />
                    <InvoiceItemsTable invoice={invoice} colorcode={colorcode}  docs={components} qcData={qcData} caseData={data} candidate={candidate} requestedData={requestedData} />

                    
                  <Footer data={data} /> 
                
                </Page>

                {
                  show.aadhaar && requestedData.length > 0 && requestedData.map((item, index)=>{

                    if(Object.keys(item)[0]== 'aadhaar'){
                  
                      return aadhaar(item.aadhaar);
                    }
                  
                  
                  })
                }

{
                  show.employment && requestedData.length > 0 && requestedData.map((item, index)=>{

                    if(Object.keys(item)[0]== 'employment'){
                  
                      return Employment(item.employment);
                    }
                  
                  
                  })
                }


{
                  show.pan && requestedData.length > 0 && requestedData.map((item, index)=>{
                    
                      if(Object.keys(item)[0]== 'pan'){
                    
                        return Pan(item.pan);
                      }
                    
                    
                    }
                  )
                }

{
                  show.creditScore && requestedData.length > 0 && requestedData.map((item, index)=>{
                    
                      if(Object.keys(item)[0]== 'credit_score'){
                    
                        return Credit(item.credit_score[0]);
                      }
                    
                    
                    }
                  )
                }

{
                  show.voter_id && requestedData.length > 0 && requestedData.map((item, index)=>{
                        
                          if(Object.keys(item)[0]== 'voter_id'){
                        
                            return voter(item.voter_id);
                          }
                        
                        }
                  )
           }

{
                  show.icai && requestedData.length > 0 && requestedData.map((item, index)=>{
                        
                          if(Object.keys(item)[0]== 'icai'){
                        
                            return icai(item.icai);
                          }
                        
                        }
                  )
           }

{
                  show.dl && requestedData.length > 0 && requestedData.map((item, index)=>{
                      
                        if(Object.keys(item)[0]== 'driving_licence'){
                      
                          return DL(item.driving_licence);
                        }
                      
                      }

                  )
                }

{
                  show.doctor && requestedData.length > 0 && requestedData.map((item, index)=>{
                          
                            if(Object.keys(item)[0]== 'doctor'){
                          
                              return doctor(item.doctor);
                            }
                          
                          }
                  )
           }

           {
                  show.nurse && requestedData.length > 0 && requestedData.map((item, index)=>{

                    if(Object.keys(item)[0]== 'nurse'){
                  
                      return nurse(item.nurse);
                    }
                  
                  } 

                  )

           }

{
                  show.education && requestedData.length > 0 && requestedData.map((item, index)=>{

                    if(Object.keys(item)[0]== 'education'){
                  
                      return Education(item.education);
                    }
                  
                  }
                  )
                }


                {
                  (show.tds && show.epfo) && requestedData.length > 0 && requestedData.map((item, index)=>{

                    console.log('epfo called==>',item);
                      
                    if(Object.keys(item)[0]== 'tds' && Object.keys(item)[0]== 'tds'){
                      
                          return Epfo(item);
                    }
                      
                      
                      }   
                  )
                }

                
{
                  (show.epfo && !show.tds) && requestedData.length > 0 && requestedData.map((item, index)=>{

                    console.log('epfo called==>',item);
                      
                    if(Object.keys(item)[0]== 'epfo' && !Object.keys(item)[0]== 'tds'){
                      
                          return Epfo(item);
                    }
                      
                      
                      }   
                  )
                }


{
                  (show.tds && !show.epfo) && requestedData.length > 0 && requestedData.map((item, index)=>{

                    console.log('epfo called tds==>',Object.keys(item)[0]);

                      
                    if(Object.keys(item)[0]== 'tds' && !show.epfo){

                      console.log('epfo called tds==>',item);

                      
                          return Epfo(item);
                    }
                      
                      
                      }   
                  )
                }

    
            </Document>
    )
  }
  

  
  export default Invoice