import React, { useEffect, useMemo } from "react";
import PropTypes from "prop-types";
import { Link, useNavigate } from "react-router-dom";
import {
    Card, CardBody, Col, Container, Row, Table,
    Label,
    Input,
    Form,
    FormGroup,
UncontrolledTooltip,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    
    Nav,
    NavItem,
    NavLink,
    TabContent,
    TabPane, CardTitle


} from "reactstrap";
import { isEmpty, map, set } from "lodash";
import Dropzone from "react-dropzone";
import * as XLSX from 'xlsx';
import Papa from 'papaparse';
import axios from 'axios';
import Loader from "../../components/Loader";

import classnames from "classnames";
import TableContainer from "../../components/Common/TableContainerManageUser";
import User from "./User";



const ManageUsers = (props) => {
    //meta title
    document.title =
        "TheRollNumber";


    const [data, setData] = React.useState([]);

    const [loading, setLoading] = React.useState(false);

    const [editUser, setEditUser] = React.useState({});

    const [isEdit, setIsEdit] = React.useState(false);

    const [alluser, setAllUser] = React.useState([]);

    const [userRole, setUserRole] = React.useState('');

    const [level, setLevel] = React.useState('');

    const [modal, setModal] = React.useState(false);

    const toggle = () => setModal(!modal);


    const Navigate = useNavigate();

    useEffect(() => {
        getAllUser();

        setRole();
    }, []);


    const columns = useMemo(
        () => [
       
          {
            Header: "First Name",
            accessor: "first_name",
            width: "150px",
            style: {
              textAlign: "center",
              width: "10%",
              background: "#0000",
            },
            filterable: true,
            Cell: (cellProps) => {
              return cellProps.value;
            },
          },
          {
            Header: "Last Name",
            accessor: "last_name",
            filterable: true,
            Cell: (cellProps) => {
              return cellProps.value;
            },
          },
          {
            Header: "Email ID",
            accessor: "email",
            filterable: true,
            Cell: (cellProps) => {
    
              // console.log('cellProps',cellProps)
    
              return cellProps.value;
            },
          },
        
          {
            Header: "Contact",
            accessor: "phone_number",
            filterable: true,
            Cell: (cellProps) => {
    
              // console.log('cellProps',cellProps)
    
              return cellProps.value;
            },
          },

          {
            Header: "Role",
            accessor: "role",
            filterable: true,
            Cell: (cellProps) => {
    
              // console.log('cellProps',cellProps)
    
              return cellProps.value;
            },
          },

          {
            Header: "Status",
            accessor: "account_status",
            filterable: true,
            Cell: (cellProps) => {
    
              // console.log('cellProps',cellProps)
    
              return cellProps.value;
            },
          },
        
        
          {
            Header: "Action",
            accessor: "action",
            disableFilters: true,
            Cell: (cellProps) => {
              return (
                <div className="d-flex gap-3" >
                  <Link
                    to="#"
                  
                    className="text-success"
                    onClick={() => {
                      // handleOrderClick(orderData);

                       console.log(cellProps.row.original);

                        const role = JSON.parse(localStorage.getItem('authUser')).user.role;
                        const email = JSON.parse(localStorage.getItem('authUser')).user.email;

                       

                       if(level == 'owner' && (cellProps.row.original.email == email)){
                        
                        setIsEdit(true);
                        setEditUser(cellProps.row.original);
                       setModal(true);
                       }else if(role == 'admin' && cellProps.row.original.level !== 'owner'){

                        
                        setIsEdit(true);
                        setEditUser(cellProps.row.original);
                       setModal(true);
                       }else if(role == 'location_user' && (cellProps.row.original.email == email)){
                      
                        setIsEdit(true);
                        setEditUser(cellProps.row.original);
                        setModal(true);
                       }

                     
                       

                    
                  
                    }}
                  >
                    <i  className="mdi mdi-pencil font-size-18" id="edittooltip" />
                    <UncontrolledTooltip placement="top" target="edittooltip" >
                      Edit
                    </UncontrolledTooltip>
                  </Link>
            
                </div>
              );
            },
          },
    
    
    
        ],
        []
      );

    const handleOrderClicks = () => {

        setIsEdit(false);
      setEditUser({});
      toggle();

    };


    
  const getAllUser = async () => {

   
    console.log('send bgv');
  
    const config = {
      headers: {
        'Content-Type': 'application/json'
      }
    };


  
   await axios.get(`${process.env.REACT_APP_BASE_URL}/employer/allUser`, {}, config)
    .then(response => {
      console.log('all user response',response);

        let data = response.data.data;

        setData(data);
  
    })
    .catch(error => {
  
      console.log('error==>',error);
    });
  
  }

  const reFetch = async () => {

    setModal(false);

    getAllUser();

  }

  const setRole = () => {

    const role = JSON.parse(localStorage.getItem('authUser')).user.role;

    const userLevel = JSON.parse(localStorage.getItem('authUser')).user.level;

    setUserRole(role);

    setLevel(userLevel);

  }

    return (
        <React.Fragment>

<Modal isOpen={modal} centered={true} size="xl" toggle={toggle}>
            <ModalHeader toggle={toggle} tag="h4">
              Add User
            </ModalHeader>  
            <ModalBody>
          <User data={editUser} isEdit={isEdit} reFetch={reFetch} />
            </ModalBody>
          </Modal>
            <Card>
                <CardBody>
                    <p className="h4 mb-4">Manage Users</p>

                    <TableContainer
                        columns={columns}
                        data={data}
                        isGlobalFilter={false}
                        isAddOptions={true}
                        role={userRole}
                        handleOrderClicks={handleOrderClicks}
                        customPageSize={10}
                        isPagination={true}
                        filterable={false}
                        iscustomPageSizeOptions={true}
                        tableClass="align-middle table-nowrap table-check"
                        theadClass="table-light"
                        pagination="pagination pagination-rounded justify-content-end mb-2"
                      />

                </CardBody>
            </Card>

        </React.Fragment>
    );
};


export default ManageUsers;

const styles = {

    para: {
        fontSize: 12
    },
    box: {
        fontSize: 16,
        fontWeight: "unset",
        justifyContent: "space-between",
        alignItems: "center",
    },
    boxLabel: {
        fontSize: 13,
        fontWeight: "400",
    },
    boxInput: {
        fontSize: 13,
        width: '70%',
        padding: 8,
        fontWeight: "unset",
    }

}
