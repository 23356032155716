import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Link, useNavigate } from "react-router-dom";
import {
    Card, CardBody, Col, Container, Row, Table,
    Label,
    Input,
    Form,
    FormGroup,

    Nav,
    NavItem,
    NavLink,
    TabContent,
    TabPane, CardTitle


} from "reactstrap";
import { isEmpty, map, set } from "lodash";
import Dropzone from "react-dropzone";
import * as XLSX from 'xlsx';
import Papa from 'papaparse';
import axios from 'axios';
// import Loader from "../../components/Loader";

import classnames from "classnames";
import LocationSearchInput from "./LocatioinSearchInput";



const Location = (props) => {
    //meta title
    document.title =
        "TheRollNumber";


    const [data, setData] = React.useState([]);

    const [loading, setLoading] = React.useState(false);

    const [locations, setLocation] = React.useState([{
        id: 1,
        name: "AP",
        selected: false
    },
    {
        id: 2,
        name: "HQ",
        selected: false
    },
    {
        id: 3,
        name: "KONDAPUR",
        selected: false
    }]);


    const Navigate = useNavigate();

    const [user, setUser] = React.useState({});



    const [state, setState] = React.useState({

      location_name: "",
        address: "",
        country: "",
        state: "",
        city: "",
        pincode: "",
        contact_person: "",
        email: "",
        landline: "",
        mobile: "",
        client_status: "active",
 
    });

    const handleInputChange = (e) => {
        const { id, value } = e.target;
        setState((prevState) => ({
            ...prevState,
            [id]: value,
        }));
    };

const add_location = async () => {

    const config = {
      headers: {
        'Content-Type': 'application/json'
      }
    };

const body = {
    location_name: state.location_name,
    address: state.address,
    country: state.country,
    state: state.state,
    city: state.city,
    pincode: state.pincode,
    contact_person: state.contact_person,
    email: state.email,
    landline: state.landline,
    mobile: state.mobile,
    client_status: state.client_status,
}

console.log('body==>', body);
  
   await axios.post(`${process.env.REACT_APP_BASE_URL}/service/companyLocation`, body, config)
    .then(response => {
      console.log('add location response',response);

      props.reFetch()

  
    })
    .catch(error => {
  
      console.log('add location error==>',error);
    });
  
  }


    useEffect(() => {


        if (props.data && props.isEdit) {
            let data = { ...props.data };
            console.log('data==>', data);
            // setState(data);


            setState({...state, 
            
                location_name: data.location_name,
                city: data.city,
                state: data.state,
                country: data.country,
                address: data.address,
                pincode: data.pincode,
                landline: data.landline,
                mobile: data.mobile,

                client_status: data.status,
          
                email: data.email,
                contact_person: data.contact_person,
            });
        }

    }, [props.data]);


    const update_location = () => {

const id = props.data.LocationID;

console.log('id==>', id);

        const config = {
          headers: {
            'Content-Type': 'application/json'
          }
        };
      
        const body = {
         
            location_name: state.location_name,
            address: state.address,
            country: state.country,
            state: state.state,
            city: state.city,
            pincode: state.pincode,
            contact_person: state.contact_person,
            email: state.email,
            landline: state.landline,
            mobile: state.mobile,
            client_status: state.client_status,

        
        }
        
        console.log('body==>', body);
          
        //    axios.put(`${process.env.REACT_APP_BASE_URL}/service/companyLocation/${id}`, body, config)
        //     .then(response => {
        //       console.log('update user response',response);
        
        //       props.reFetch()

          
        //     })
        //     .catch(error => {
          
        //       console.log('update user error==>',error);
        //     });

    }


const handleSelectLocation = (result) => {

    console.log('result==>', result);

    const address_components = result.address_components;   

    let country = '';
    let state = '';
    let city = '';
    let pincode = '';

    address_components.map((item, index) => {
        if (item.types.includes('country')) {
            country = item.long_name;
        }
        if (item.types.includes('administrative_area_level_1')) {
            state = item.long_name;
        }
        if (item.types.includes('locality')) {
            city = item.long_name;
        }
        if (item.types.includes('postal_code')) {
            pincode = item.long_name;
        }
    });

    console.log('pincode==>', pincode)

    setState({...state,

        address: result.formatted_address,
        country: country,
        state: state,
        city: city,
        pincode: pincode
    });

}

    return (
        <React.Fragment>


            <Card>
                <CardBody>


                    <Row>
<Col lg="12">
    <LocationSearchInput onSelect={handleSelectLocation} />
    </Col>
                        <Col lg="6">
                            <div className="mt-3 d-flex" style={styles.box}>
                                <Label for="basicpill-firstname-input1" style={styles.boxLabel} className="control-label me-3" >
                                    Location Name
                                    <span className="text-danger mx-1">*</span>
                                </Label>
                                <Input
                                    type="text"
                                    className="form-control me-3"
                                    value={state.location_name}
                                    // disabled={selectedValue === 'registration_no' ? false : true}
                                   onChange={handleInputChange}
                                    style={styles.boxInput}
                                    suppressHydrationWarning
                                    id="location_name"
                                />


                            </div>
                        </Col>
                        <Col lg="6">
                            <div className="mt-3 d-flex" style={styles.box} >
                                <Label for="basicpill-firstname-input1" style={styles.boxLabel} className="control-label me-3" >
                                    Address
                                    {/* <span className="text-danger mx-1">*</span> */}

                                </Label>
                                <Input
                                    type="text"
                                    className="form-control me-3"
                                    value={state.address}
                                    style={styles.boxInput}

                                    // disabled={selectedValue === 'registration_no' ? false : true}
                                    onChange={handleInputChange}
                                    suppressHydrationWarning
                                    id="address"
                                />


                            </div>
                        </Col>

                        <Col lg="6">
                            <div className="mt-3 d-flex" style={styles.box}>
                                <Label for="basicpill-firstname-input1" style={styles.boxLabel} className="control-label me-3" >
                                    Country
                                    <span className="text-danger mx-1">*</span>
                                </Label>
                                <Input
                                    type="text"
                                    className="form-control me-3"
                                    disabled={props.isEdit}
                                    value={state.country}
                                    // disabled={selectedValue === 'registration_no' ? false : true}
                                    onChange={handleInputChange}
                                    style={styles.boxInput}
                                    suppressHydrationWarning
                                    id="country"
                                />


                            </div>
                        </Col>
                        <Col lg="6">
                            <div className="mt-3 d-flex" style={styles.box} >
                                <Label for="basicpill-firstname-input1" style={styles.boxLabel} className="control-label me-3" >
                                    State
                                    <span className="text-danger mx-1">*</span>

                                </Label>
                                <Input
                                    type="text"
                                    className="form-control me-3"
                                    value={state.state}
                                    style={styles.boxInput}

                                    // disabled={selectedValue === 'registration_no' ? false : true}
                                    onChange={handleInputChange}
                                    suppressHydrationWarning
                                    id="state"
                                />


                            </div>
                        </Col>

            
                        <Col lg="6">
                            <div className="mt-3 d-flex" style={styles.box} >
                                <Label for="basicpill-firstname-input1" style={styles.boxLabel} className="control-label me-3" >
                                    City
                                    <span className="text-danger mx-1">*</span>

                                </Label>
                                <Input
                                    type="text"
                                    className="form-control me-3"
                                    value={state.city}
                                    style={styles.boxInput}

                                    // disabled={selectedValue === 'registration_no' ? false : true}
                                    onChange={handleInputChange}
                                    suppressHydrationWarning
                                    id="city"
                                />


                            </div>
                        </Col>

                        <Col lg="6">
                            <div className="mt-3 d-flex" style={styles.box} >
                                <Label for="basicpill-firstname-input1" style={styles.boxLabel} className="control-label me-3" >
                                    Pincode
                                    {/* <span className="text-danger mx-1">*</span> */}

                                </Label>
                                <Input
                                    type="text"
                                    className="form-control me-3"
                                    value={state.pincode}
                                    style={styles.boxInput}

                                    // disabled={selectedValue === 'registration_no' ? false : true}
                                    onChange={handleInputChange}
                                    suppressHydrationWarning
                                    id="pincode"
                                />


                            </div>
                        </Col>

                        <Col lg="6">
                            <div className="mt-3 d-flex" style={styles.box} >
                                <Label for="basicpill-firstname-input1" style={styles.boxLabel} className="control-label me-3" >
                                    Contact Person
                                    {/* <span className="text-danger mx-1">*</span> */}

                                </Label>
                                <Input
                                    type="text"
                                    className="form-control me-3"
                                    value={state.contact_person}
                                    style={styles.boxInput}

                                    // disabled={selectedValue === 'registration_no' ? false : true}
                                    onChange={handleInputChange}
                                    suppressHydrationWarning
                                    id="contact_person"
                                />


                            </div>
                        </Col>

                        <Col lg="6">
                            <div className="mt-3 d-flex" style={styles.box} >
                                <Label for="basicpill-firstname-input1" style={styles.boxLabel} className="control-label me-3" >
                                    Email ID
                                    <span className="text-danger mx-1">*</span>

                                </Label>
                                <Input
                                    type="text"
                                    className="form-control me-3"
                                    value={state.email}
                                    style={styles.boxInput}

                                    // disabled={selectedValue === 'registration_no' ? false : true}
                                    onChange={handleInputChange}
                                    suppressHydrationWarning
                                    id="email"
                                />


                            </div>
                        </Col>

                        <Col lg="6">
                            <div className="mt-3 d-flex" style={styles.box} >
                                <Label for="basicpill-firstname-input1" style={styles.boxLabel} className="control-label me-3" >
                                    Landline
                                    {/* <span className="text-danger mx-1">*</span> */}

                                </Label>
                                <Input
                                    type="text"
                                    className="form-control me-3"
                                    value={state.landline}
                                    style={styles.boxInput}

                                    // disabled={selectedValue === 'registration_no' ? false : true}
                                    onChange={handleInputChange}
                                    suppressHydrationWarning
                                    id="landline"
                                />


                            </div>
                        </Col>

                        <Col lg="6">
                            <div className="mt-3 d-flex" style={styles.box} >
                                <Label for="basicpill-firstname-input1" style={styles.boxLabel} className="control-label me-3" >
                                    Mobile
                                    {/* <span className="text-danger mx-1">*</span> */}

                                </Label>
                                <Input
                                    type="text"
                                    className="form-control me-3"
                                    value={state.mobile}
                                    style={styles.boxInput}

                                    // disabled={selectedValue === 'registration_no' ? false : true}
                                    onChange={handleInputChange}
                                    suppressHydrationWarning
                                    id="mobile"
                                />


                            </div>
                        </Col>

                        <Col lg="6">
                        <div className="d-flex mt-5" style={{justifyContent:'space-between', width:'60%'}}>
                        <Label for="basicpill-firstname-input1" style={styles.boxLabel} className="control-label me-3" >
                                    Status
                                    {/* <span className="text-danger mx-1">*</span> */}
                                </Label>

                                <div className="form-check me-3 d-flex">
<div className="form-check me-3">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="exampleRadios1"
                            id="exampleRadios7"
                            value="active"
                            checked={state.client_status === 'active' ? true : false}
                            defaultChecked
                            onChange={(e)=>{
                              let value = e.target.value;
                              
                              setState({...state, client_status: value});
                            }
                          }
                            
                          />
                          <label
                            className="form-check-label"
                            htmlFor="exampleRadios7"
                          >
                            Active
                          </label>
                        </div>

                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="exampleRadios1"
                            checked={state.client_status === 'inactive' ? true : false}
                            id="exampleRadios2"
                            value="inactive"
                            onChange={(e)=>{
                                let value = e.target.value;
                                
                                setState({...state, client_status: value});
                            }
                          }
                          />
                          <label
                            className="form-check-label"
                            htmlFor="exampleRadios2"
                          >
                            Inactive
                          </label>

              

                        
                        </div>
                        </div>
                   
                     
</div>
                            </Col>
                    </Row>

                    <div className="mt-5 text-center"  >
                        {
                            props.isEdit ? <button className="btn btn-primary" style={{ width: 150, }} onClick={() => { update_location() }} >Update</button>
                                : <button className="btn btn-primary" style={{ width: 150, }} onClick={() => { add_location() }} >Add</button>
                        }
                        </div>

                </CardBody>
            </Card>

        </React.Fragment>
    );
};


export default Location;

const styles = {

    para: {
        fontSize: 12
    },
    box: {
        fontSize: 16,
        fontWeight: "unset",
        justifyContent: "space-between",
        alignItems: "center",
    },
    boxLabel: {
        fontSize: 13,
        fontWeight: "400",
    },
    boxInput: {
        fontSize: 13,
        width: '70%',
        padding: 8,
        fontWeight: "unset",
    }

}
