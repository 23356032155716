


import React, { useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import { Link, useNavigate } from "react-router-dom";
import { ceil, isEmpty, set } from "lodash";
import "bootstrap/dist/css/bootstrap.min.css";
import TableContainer from "../../components/Common/TableContainerManageUser";
import * as Yup from "yup";
import { useFormik } from "formik";
import axios from "axios";
import Cookies from "js-cookie";




import {
  Button,
  Col,
  Row,
  UncontrolledTooltip,
  
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  Input,
  FormFeedback,
  Label,
  Card,
  CardBody,
  Table,
} from "reactstrap";
import moment from "moment";

//Import Flatepicker
import "flatpickr/dist/themes/material_blue.css";
import Flatpickr from "react-flatpickr";

function ManageUsers() {
  //meta title
  document.title = "TheRollNumber";

  const navigate = useNavigate();


  const [modal, setModal] = useState(false);
  const [modal1, setModal1] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [cases, setCases] = useState([]);
  const [cancelNotificationType, setCancelNotificationType] = useState('')
  const [enableCancel, setEnableCancel] = useState(false);
  const [roles, setRoles] = useState([])

  const [orderList, setOrderList] = useState([]);
  const [order, setOrder] = useState(null);

  // validation


  const [selectedNotification, setSelectedNotification] = useState({});

  const toggleViewModal = () => setModal1(!modal1);

  useEffect(() => {

    getAdminUsers();

    get_roles()

  }, []);


  
  const get_roles = async () => {



    const config = {
      headers: {
        'Content-Type': 'application/json'
      }
    };


      await axios.get(`${process.env.REACT_APP_BASE_URL}/employer/admin/roles`, {}, config)

      .then(response => {
  
        console.log('response roles==>',response)
  
        const data = response.data.data;
  
       console.log('data==>',data)
           
        setRoles(data)
  
      //  let filteredArray = data.filter((item)=> roles.includes(item.id) )

       
  
  
      })
  
      .catch(error => {

        console.log('error from server==>',error)

  
      })


  

  }

  const getRolesName = (roles_data) => {

    console.log('roles names====>',roles_data)

    let rolesArray = roles_data.split(',');

    console.log('rolesArray==>',rolesArray)

    let roleNames = [];

    rolesArray.map((item)=> {

      let role = roles_data.find((role)=> role.id == item)

      console.log('role==>',role)

      roleNames.push(role.name)

    })

    console.log('roleNames==>',roleNames)

    return roleNames.join(',')

  }


  const getAdminUsers = async () => {

    const config = {
      headers: {
        'Content-Type': 'application/json'
      }
    };
   
    axios.get(`${process.env.REACT_APP_BASE_URL}/employer/admin/users`, {}, config)

    .then(response => {

      console.log('admin response cases==>',response.data.data)

      const data = response.data.data;


     setCases(data)


    })

    .catch(error => {

      console.log('error from server==>',error)

    })


  }

  const [isLoading, setLoading] = useState('')



  const toggle = () => {
    if (modal) {
      setModal(false);
      setOrder(null);
    } else {
      setModal(true);
    }
  };

  const handleOrderClick = () => {
   
    navigate("/add-new-user");

  };

  const selectAll = (value, data) => {

    console.log('select all', value)

    let arr = [...data]

    arr.map((item)=> {

      item.select = value

    } )

    setCases(arr)

    setEnableCancel(value)



  }

  //delete order
  const [deleteModal, setDeleteModal] = useState(false);

  const onClickDelete = (order) => {
    setOrder(order);
    // setDeleteModal(true);
  };


  const handleOrderClicks = () => {
    navigate("/admin/add-new-user");
  };

  const columns = useMemo(
    () => [
      {
        Header: (cellProps) => <div className="form-check font-size-16" >
          <input className="form-check-input"  name="all" onClick={(e)=>{

            selectAll(e.target.checked, cellProps.data)
          
          }} type="checkbox" id="checkAll" />
          <label className="form-check-label" htmlFor="checkAll"></label>
        </div>,
        accessor: 'select',
        width: '20px',
        filterable: false,
        disableFilters: false,
        
      
        Cell: (cellProps) => (
          <div className="form-check font-size-16" >
            <Input className="form-check-input" type="checkbox" onClick={()=> {
              let arr = [...cellProps.data]


              // console.log('index', cellProps)
              arr[cellProps.row.index].select = !arr[cellProps.row.index].select

              setCases(arr)

              let selected = arr.filter((item)=> item.select === true)

              if(selected.length > 1) {
                setEnableCancel(true)
              }else{
                setEnableCancel(false)
              }

            
            }} checked={cellProps.value}   />
            <label className="form-check-label" htmlFor="checkAll"></label>
          </div>
        )
      },
      {
        Header: "User Name",
        accessor: "first_name",
        width: "150px",
        style: {
          textAlign: "center",
          width: "10%",
          background: "#0000",
        },
        filterable: true,
        Cell: (cellProps) => {
          return <Link to={`#`} disabled={true} onClick={(e)=>
          {
            e.preventDefault();



          }
          } >{cellProps.row.original.first_name}</Link>;
        },
      },
      {
        Header: "Email ID",
        accessor: "email",
        filterable: true,
        Cell: (cellProps) => {
          return cellProps.value;
        },
      },
      {
        Header: "Location",
        accessor: "case_received_date",
        filterable: true,
        Cell: (cellProps) => {
          return moment(cellProps.value).format("DD-MM-YYYY");
        },
      },
      {
        Header: "Role",
        accessor: "candidate_name",
        filterable: true,
        Cell:  (cellProps) => {

          console.log('get roles =============>',  cellProps.row.original.roles.join(", ") ) 

        

          return cellProps.row.original.roles.join(", ") ;
        },
      },
    
      {
        Header: "Status",
        accessor: "status",
        filterable: true,
        Cell: (cellProps) => {
          return cellProps.value;
        },
      },

  
  
    
      {
        Header: "Action",
        accessor: "action",
        disableFilters: true,
        Cell: (cellProps) => {

   

          return (
            <div className="d-flex text-center align-items-center" style={{justifyContent:'space-evenly', width:50}} >
               <Link
                to="#"
                className="text-success"
                onClick={(e) => {

                  e.preventDefault();
                  console.log('cellProps==>',cellProps.row.original)

                  navigate('/admin/add-new-user', {state: cellProps.row.original}) 

                }}
              >
              
                <i className="mdi mdi-pencil font-size-20" id="edittooltip2" />
                <UncontrolledTooltip placement="top" target="edittooltip2">
                  Edit User
                </UncontrolledTooltip>
              </Link>
     
          
            </div>
          );
        },
      },
    ],
    []
  );

  const [modalConfirm, setModalConfirm] = useState(false);

  const toggleConfirm = () => setModalConfirm(!modalConfirm);



  const handleCancelClicks = () => {

    console.log('cancel clicked', cases)

   setCancelNotificationType('multiple')

   setModalConfirm(true)

   
  }


  return (
    <React.Fragment>

      <div className="page-content">
        <div className="container-fluid">
          {/* <Breadcrumbs title="Ecommerce" breadcrumbItem="Orders" /> */}
          <Row>
            {
            
                <Col xs="12">
                  <Card>
                    <CardBody>
                      <p className="card-title mb-4" style={{fontSize:20, fontWeight:'500'}}>Manage Users</p>



                     { cases.length >0 && <TableContainer
                        columns={columns}
                        data={cases.length > 0 ? cases : []}
                        isFilter={true}
                        isGlobalFilter={false}
                        isAddOptions={true}
                        enableCancel={enableCancel}
                        handleOrderClicks={handleOrderClicks}
                        handleCancelClicks={handleCancelClicks}
                        customPageSize={10}
                        isPagination={true}
                        filterable={false}
                        iscustomPageSizeOptions={true}
                        tableClass="align-middle table-nowrap table-check"
                        theadClass="table-light"
                        pagination="pagination pagination-rounded justify-content-end mb-2"
                      />}
                    </CardBody>
                  </Card>
                </Col>
            }
          </Row>



        </div>
      </div>

    </React.Fragment>
  );
}
ManageUsers.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
};

export default ManageUsers;
