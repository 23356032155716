import React, {useEffect} from "react";
import ReactEcharts from "echarts-for-react";
import getChartColorsArray from "../../components/Common/ChartsDynamicColor";

const Pie = ({ dataColors, data }) => {
  const PieEChartColors = getChartColorsArray(dataColors);

  const options = {
    toolbox: {
      show: false,
    },
    tooltip: {
      trigger: "item",
      formatter: "{a} <br/>{b} : {c} ({d}%)",
    },
    legend: {
      orient: "vertical",
      show:true,
      left: "left",
      data: ["WIP", "Notification sent",  "Candidate approved",
       "Candidate rejected", "Partially shared", "Re-verification", "Notification read", "Insufficiency data"  ],
      textStyle: {
        color: ["#8791af"],
   
       
      },
    },
    color: ["#4CAF50", "#FFC107", "#228B22", "#F44336", "#9C27B0", "#3F51B5", "#7a3582", "#FFBF00"],
    series: [
      {
        name: "",
        type: "pie",
        radius: "55%",
        
        center: ["50%", "50%"],
        data: [
          { value: 0, name: "WIP" },
          { value: 0, name: "Notification sent" },
          { value: 0, name: "Candidate approved" },
          { value: 0, name: "Candidate rejected" },
          { value: 0, name: "Partially shared" },
          { value: 0, name: "Re-verification" },
          { value: 0, name: "Notification read" },
          { value: 0, name: "Insufficiency data" }
        ],
        itemStyle: {
          emphasis: {
            shadowBlur: 10,
          
            shadowOffsetX: 0,
            shadowColor: "rgba(0, 0, 0, 0.5)",
          },
          
        },

        
      },
    ],
  };

  const [state, setState] = React.useState(options);

  useEffect(() => {

    if(data.length > 0){

      const arr = [...options.series[0].data];

      data.map((item, index) => {

        if(item.verification_status == 'WIP'){

          arr[0].value = arr[0].value + 1;

        }
        if(item.verification_status == 'Notification sent'){

          arr[1].value = arr[1].value + 1;

        }
        if(item.verification_status == 'Approved'){

          arr[2].value = arr[2].value + 1;

        }
        if(item.verification_status == 'Candidate rejected'){

          arr[3].value = arr[3].value + 1;

        }
        if(item.verification_status == 'Partially shared'){

          arr[4].value = arr[4].value + 1;

        }
        if(item.verification_status == 'Re-verification'){

          arr[5].value = arr[5].value + 1;

        }
        if(item.verification_status == 'Notification read'){

          arr[6].value = arr[6].value + 1;

        }
        if(item.verification_status == 'Insufficiency data'){

          arr[7].value = arr[7].value + 1;

        }


    }


      )
      console.log(arr, 'arr')

      setState({
        ...state,
        series: [
          {
            name: "",
            type: "pie",
            radius: "55%",
            
            center: ["50%", "50%"],
            data: arr,
            itemStyle: {
              emphasis: {
                shadowBlur: 10,
              
                shadowOffsetX: 0,
                shadowColor: "rgba(0, 0, 0, 0.5)",
              },
              
            },
    
            
          },
        ],
      });


    }

    

  }, [data]);


  return (
    <React.Fragment>
      <ReactEcharts style={{ height: "350px" }} option={state} />
    </React.Fragment>
  );
};
export default Pie;
