


import React, { useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import { Link, useNavigate } from "react-router-dom";
import { ceil, isEmpty, set } from "lodash";
import "bootstrap/dist/css/bootstrap.min.css";
import TableContainer from "../../components/Common/TableContainerOnGoing";
import * as Yup from "yup";
import { useFormik } from "formik";
import axios from "axios";

import {
  OrderId,
  BillingName,
  Date,
  Total,
  PaymentStatus,
  PaymentMethod,
} from "./EcommerceOrderCol";

//redux
// import { useSelector, useDispatch } from "react-redux";
// import { createSelector } from "reselect";
import EcommerceOrdersModal from "./EcommerceOrdersModal";

import {
  Button,
  Col,
  Row,
  UncontrolledTooltip,
  
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  Input,
  FormFeedback,
  Label,
  Card,
  CardBody,
  Table,
} from "reactstrap";
import moment from "moment";

//Import Flatepicker
import "flatpickr/dist/themes/material_blue.css";
import Flatpickr from "react-flatpickr";

function ClosedCases() {
  //meta title
  document.title = "TheRollNumber";

  const navigate = useNavigate();


  const [modal, setModal] = useState(false);
  const [modal1, setModal1] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [cases, setCases] = useState([]);
  const [cancelNotificationType, setCancelNotificationType] = useState('')
  const [enableCancel, setEnableCancel] = useState(false);

  const [orderList, setOrderList] = useState([]);
  const [order, setOrder] = useState(null);

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      orderId: (order && order.orderId) || "",
      billingName: (order && order.billingName) || "",
      orderdate: (order && order.orderdate) || "",
      total: (order && order.total) || "",
      paymentStatus: (order && order.paymentStatus) || "Paid",
      badgeclass: (order && order.badgeclass) || "success",
      paymentMethod: (order && order.paymentMethod) || "Mastercard",
    },
    validationSchema: Yup.object({
      orderId: Yup.string()
        .matches(/[0-9\.\-\s+\/()]+/, "Please Enter Valid Order Id")
        .required("Please Enter Your Order Id"),
      billingName: Yup.string().required("Please Enter Your Billing Name"),
      orderdate: Yup.string().required("Please Enter Your Order Date"),
      total: Yup.string()
        .matches(/[0-9\.\-\s+\/()]+/, "Please Enter Valid Amount")
        .required("Total Amount"),
      paymentStatus: Yup.string().required("Please Enter Your Payment Status"),
      badgeclass: Yup.string().required("Please Enter Your Badge Class"),
      paymentMethod: Yup.string().required("Please Enter Your Payment Method"),
    }),
    onSubmit: (values) => {
      if (isEdit) {
        const updateOrder = {
          id: order ? order.id : 0,
          orderId: values.orderId,
          billingName: values.billingName,
          orderdate: values.orderdate,
          total: values.total,
          paymentStatus: values.paymentStatus,
          paymentMethod: values.paymentMethod,
          badgeclass: values.badgeclass,
        };
        // update order
        // dispatch(onUpdateOrder(updateOrder));
        // validation.resetForm();
      } else {
        const newOrder = {
          id: Math.floor(Math.random() * (30 - 20)) + 20,
          orderId: values["orderId"],
          billingName: values["billingName"],
          orderdate: values["orderdate"],
          total: values["total"],
          paymentStatus: values["paymentStatus"],
          paymentMethod: values["paymentMethod"],
          badgeclass: values["badgeclass"],
        };
        // save new order
        // dispatch(onAddNewOrder(newOrder));
        validation.resetForm();
      }
      toggle();
    },
  });

  const [selectedNotification, setSelectedNotification] = useState({});

  const toggleViewModal = () => setModal1(!modal1);

  useEffect(() => {

    get_all_cases();

  }, []);



  const get_all_cases = async () => {

    const config = {
      headers: {
        'Content-Type': 'application/json'
      }
    };
   
    axios.get(`${process.env.REACT_APP_BASE_URL}/service/cases`, {}, config)

    .then(response => {

      console.log('response cases==>',response)

      const data = response.data;

      let arr = []

     data.map((item)=> {

      if(item.verification_status === 'Complete'){
      
      arr.push({
        ...item,
        select:false
      })

    }

      })

      setCases(arr)

    })

    .catch(error => {

      console.log('error from server==>',error)

    })


  }

  const [isLoading, setLoading] = useState('')



  const toggle = () => {
    if (modal) {
      setModal(false);
      setOrder(null);
    } else {
      setModal(true);
    }
  };

  const handleOrderClick = (arg) => {
    const order = arg;
    setOrder({
      id: order.id,
      orderId: order.orderId,
      billingName: order.billingName,
      orderdate: order.orderdate,
      total: order.total,
      paymentStatus: order.paymentStatus,
      paymentMethod: order.paymentMethod,
      badgeclass: order.badgeclass,
    });

    setIsEdit(true);

    toggle();
  };

  const selectAll = (value, data) => {

    console.log('select all', value)

    let arr = [...data]

    arr.map((item)=> {

      item.select = value

    } )

    setCases(arr)

    setEnableCancel(value)



  }

  //delete order
  const [deleteModal, setDeleteModal] = useState(false);

  const onClickDelete = (order) => {
    setOrder(order);
    // setDeleteModal(true);
  };

  const handleDeleteOrder = () => {
    if (order && order.id) {
      // dispatch(onDeleteOrder(order.id));
      // setDeleteModal(false);
      setOrder("");
    }
  };
  const handleOrderClicks = () => {
    setOrderList("");
    setIsEdit(false);
    toggle();
  };

  const columns = useMemo(
    () => [
      {
        Header: (cellProps) => <div className="form-check font-size-16" >
          <input className="form-check-input"  name="all" onClick={(e)=>{

            selectAll(e.target.checked, cellProps.data)
          
          }} type="checkbox" id="checkAll" />
          <label className="form-check-label" htmlFor="checkAll"></label>
        </div>,
        accessor: 'select',
        width: '20px',
        filterable: false,
        disableFilters: false,
        
      
        Cell: (cellProps) => (
          <div className="form-check font-size-16" >
            <Input className="form-check-input" type="checkbox" onClick={()=> {
              let arr = [...cellProps.data]


              // console.log('index', cellProps)
              arr[cellProps.row.index].select = !arr[cellProps.row.index].select

              setCases(arr)

              let selected = arr.filter((item)=> item.select === true)

              if(selected.length > 1) {
                setEnableCancel(true)
              }else{
                setEnableCancel(false)
              }

            
            }} checked={cellProps.value}   />
            <label className="form-check-label" htmlFor="checkAll"></label>
          </div>
        )
      },
      {
        Header: "Case Ref",
        accessor: "request_id",
        width: "150px",
        style: {
          textAlign: "center",
          width: "10%",
          background: "#0000",
        },
        filterable: true,
        Cell: (cellProps) => {
          return <Link to={`#`} onClick={(e)=>
          {
            e.preventDefault();
            navigate('/client/edit-progress', {state: cellProps.row.original}) 
          }
          } >{cellProps.value}</Link>;
        },
      },
      {
        Header: "Location",
        accessor: "location",
        filterable: true,
        Cell: (cellProps) => {
          return cellProps.value;
        },
      },
      {
        Header: "Candidate Name",
        accessor: "candidate_name",
        filterable: true,
        Cell: (cellProps) => {
          return cellProps.value;
        },
      },
      {
        Header: "Email",
        accessor: "email",
        filterable: true,
        Cell: (cellProps) => {
          return cellProps.value;
        },
      },
      {
        Header: "Candidate Father Name",
        accessor: "candidate_father_name",
        filterable: true,
        Cell: (cellProps) => {
          return cellProps.value;
        },
      },

      {
        Header: "Candidate DOB",
        accessor: "candidate_dob",
        filterable: true,
        Cell: (cellProps) => {
          return cellProps.value;
        },
      },

      {
        Header: "Case In Date",
        accessor: "timestamp",
        filterable: true,
        Cell: (cellProps) => {
          return moment(cellProps.value).format("DD-MM-YYYY");
        },
      },

      {
        Header: "Case Due Date",
        accessor: "dueDate",
        filterable: true,
        Cell: (cellProps) => {
          return <BillingName {...cellProps} />;
        },
      },
      {
        Header: "Package Type",
        accessor: "packageType",
        filterable: true,
        Cell: (cellProps) => {
          return cellProps.value;
        },
      },
      {
        Header: "Verification Status",
        accessor: "verification_status",
        filterable: true,
        Cell: (cellProps) => {
          return cellProps.value;
        },
      },
  
      {
        Header: "Last Updated On",
        accessor: "paymentStatus",
        filterable: true,
        Cell: (cellProps) => {
          return <PaymentStatus {...cellProps} />;
        },
      },

      {
        Header: "Action",
        accessor: "action",
        disableFilters: true,
        Cell: (cellProps) => {

       

          return (
            <div className="d-flex text-center align-items-center" style={{justifyContent:'space-evenly', width:50}} >
              <Link
                to="#"
                className="text-success"
                onClick={(e) => {

                  e.preventDefault();
                  console.log('cellProps==>',cellProps.row.original)

                  navigate('/client/edit-progress', {state: cellProps.row.original}) 

                }}
              >
              
                <i className="mdi mdi-eye font-size-20" id="edittooltip2" />
                <UncontrolledTooltip placement="top" target="edittooltip2">
                  View Report
                </UncontrolledTooltip>
              </Link>
     
          
            </div>
          );
        },
      },
  
    ],
    []
  );

  const [modalConfirm, setModalConfirm] = useState(false);

  const toggleConfirm = () => setModalConfirm(!modalConfirm);




  const handleCancelClicks = () => {

    console.log('cancel clicked', cases)


   setModalConfirm(true)

   
  }



  return (
    <React.Fragment>
      <EcommerceOrdersModal isOpen={modal1} toggle={toggleViewModal} />

      <div className="page-content">
        <div className="container-fluid">
          {/* <Breadcrumbs title="Ecommerce" breadcrumbItem="Orders" /> */}
          <Row>
            {
            
                <Col xs="12">
                  <Card>
                    <CardBody>
                      <p className="card-title mb-4" style={{fontSize:20, fontWeight:'500'}}>Closed Cases</p>



                      <TableContainer
                        columns={columns}
                        data={cases}
                        isFilter={true}
                        isGlobalFilter={false}
                        isAddOptions={false}
                        enableCancel={enableCancel}
                        handleOrderClicks={handleOrderClicks}
                        handleCancelClicks={handleCancelClicks}
                        customPageSize={10}
                        isPagination={true}
                        filterable={false}
                        iscustomPageSizeOptions={true}
                        tableClass="align-middle table-nowrap table-check"
                        theadClass="table-light"
                        pagination="pagination pagination-rounded justify-content-end mb-2"
                      />
                    </CardBody>
                  </Card>
                </Col>
            }
          </Row>


 

        </div>
      </div>

    </React.Fragment>
  );
}
ClosedCases.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
};

export default ClosedCases;
