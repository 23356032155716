import PropTypes from "prop-types";
import React from "react";

import { Row, Col, CardBody, Card, Alert, 

  TabContent,
  TabPane,
  Nav,
  NavLink,
  NavItem,
  CardText,
  
  Container, Form, Input, FormFeedback, Label } from "reactstrap";
import classnames from "classnames";

//redux
// import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import withRouter from "../../components/Common/withRouter";

// Formik validation
import * as Yup from "yup";
import { useFormik } from "formik";

// actions
// import { loginUser, socialLogin } from "../../store/actions";

// import images
// import profile from "assets/images/profile-img.png";
import logo from "../../assets/images/logo.svg";
import axios from "axios";
// import { setProfile } from "MainStore/slices/e-commerce/actions";

const Login = props => {

  //meta title
  document.title = "TheRollNumber";

  // const dispatch = useDispatch();

  const [errorInfo, setErrorInfo] = React.useState('')
  const [activeTab1, setactiveTab1] = React.useState("5");

  const toggle1 = tab => {
    if (activeTab1 !== tab) {
      setactiveTab1(tab);
    }
  };

  const validation = useFormik({
    // enableReinitialize : use this  flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: Yup.object({
      email: Yup.string().required("Please Enter Your Email"),
      password: Yup.string().required("Please Enter Your Password"),
    }),
    onSubmit: (values) => {
      login(values)
    }
  });

  const login = async(values) => {

    let obj = {
      email: values.email,
      password: values.password,
    }

    const config = {
      headers: {
        'Content-Type': 'application/json'
      }
    };
  
    console.log('form data',obj)
  
   await axios.post(`${process.env.REACT_APP_BASE_URL}/employer/login`,obj, config)
    .then(response => {
      console.log('login successfull',response);
  
      localStorage.setItem('authUser', JSON.stringify(response.data));

       props.router.navigate('/client/dashboard')

      // dispatch(setProfile(response.data))


  
    })
    .catch(error => {
  
      // setErrorInfo(error.response.data.message)
  
      console.log('error occured on login==>',error);
    });
  }


  // const { error } = useSelector(state => ({
  //   error: state.Login?.error,
  // }));

    const signIn = type => {
        // dispatch(socialLogin(type, props.router.navigate));
    };

  //for facebook and google authentication
  const socialResponse = type => {
    signIn(type);
  };

  //handleTwitterLoginResponse
  // const twitterResponse = e => {}

  return (
    <React.Fragment>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>

<h4 className="text-center text-secondary">Employer Login</h4>

              <Card className="overflow-hidden">
              <CardBody className="">



          
                      <Row>
                        <Col sm="12">
                   
                  <div>
                    <Link to="/" className="logo-light-element">
                      <div className="avatar-md profile-user-wid mb-4">
                        <span className="avatar-title rounded-circle bg-light">
                        <img
                            src={logo}
                            alt=""
                            className="rounded-circle"
                            height="34"
                          />
                        </span>
                      </div>
                    </Link>
                  </div>
                
                        </Col>
                      </Row>
              
                      <Row>
                        <Col sm="12">
                        <div>
                    <Link to="/" className="logo-light-element">
                      <div className="avatar-md profile-user-wid mb-4">
                        <span className="avatar-title rounded-circle bg-light">
                        <img
                            src={logo}
                            alt=""
                            className="rounded-circle"
                            height="34"
                          />
                        </span>
                      </div>
                    </Link>
                  </div>
                  <div className="p-2">

                    <Alert color="danger" isOpen={errorInfo?true:false} >
                      <div>{errorInfo}</div>
                    </Alert>
                    <Form
                      className="form-horizontal"
                      onSubmit={(e) => {
                        e.preventDefault();
                        validation.handleSubmit();
                        return false;
                      }}
                    >
                      {/* {error ? <Alert color="danger">{error}</Alert> : null} */}

                      <div className="mb-3">
                        <Label className="form-label">Email</Label>
                        <Input
                          name="email"
                          className="form-control"
                          placeholder="Enter email"
                          type="email"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.email || ""}
                          invalid={
                            validation.touched.email && validation.errors.email ? true : false
                          }
                        />
                        {validation.touched.email && validation.errors.email ? (
                          <FormFeedback type="invalid">{validation.errors.email}</FormFeedback>
                        ) : null}
                      </div>

                      <div className="mb-3">
                        <Label className="form-label">Password</Label>
                        <Input
                          name="password"
                          value={validation.values.password || ""}
                          type="password"
                          placeholder="Enter Password"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          invalid={
                            validation.touched.password && validation.errors.password ? true : false
                          }
                        />
                        {validation.touched.password && validation.errors.password ? (
                          <FormFeedback type="invalid">{validation.errors.password}</FormFeedback>
                        ) : null}
                      </div>

                      <div className="form-check">
                        <input
                          type="checkbox"
                          className="form-check-input"
                          id="customControlInline"
                        />
                        <label
                          className="form-check-label"
                          htmlFor="customControlInline"
                        >
                          Remember me
                        </label>
                      </div>

                      <div className="mt-3 d-grid">
                        <button
                          className="btn btn-primary btn-block"
                          type="submit"
                        >
                          Login
                        </button>
                      </div>

                    </Form>
                  </div>
                        </Col>
                      </Row>
                  

              
                </CardBody>
              </Card>
              <div className="mt-5 text-center">
            
                <p>
                  © {new Date().getFullYear()} Crafted with{" "}
                  <i className="mdi mdi-heart text-danger" /> by TheRollNumber
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(Login);

Login.propTypes = {
  history: PropTypes.object,
};
