import React, { useEffect, useMemo } from "react";
import PropTypes from "prop-types";
import { Link, useNavigate } from "react-router-dom";
import {
    Card, CardBody, Col, Container, Row, Table,
    Label,
    Input,
    Form,
    FormGroup,
UncontrolledTooltip,
    Nav,
    NavItem,
    NavLink,
    TabContent,
    TabPane, CardTitle


} from "reactstrap";
import { isEmpty, map, set } from "lodash";
import Dropzone from "react-dropzone";
import * as XLSX from 'xlsx';
import Papa from 'papaparse';
import axios from 'axios';
import Loader from "../../components/Loader";

import classnames from "classnames";

import moment from "moment";


import TableContainer from "../../components/Common/TableContainerUsers";



const ListPenalties = (props) => {
    //meta title
    document.title =
        "TheRollNumber";


    const [data, setData] = React.useState([]);

    const [loading, setLoading] = React.useState(false);


    const Navigate = useNavigate();

    useEffect(() => {

      getPenalties();

  }, []);

  const getPenalties = () => {

    const org_id = JSON.parse(localStorage.getItem('authUser')).user.org_id;


    const config = {
      headers: {
        'Content-Type': 'application/json',

      }
    };

  
    axios.get(`${process.env.REACT_APP_BASE_URL}/service/penalties/${org_id}`, {}, config)
    .then(response => {

      console.log('penalties ',response.data);

      setData(response.data);

    })
    .catch(error => {
      console.log('error',error);
    });

  }

    const columns = useMemo(
        () => [
       
          {
            Header: "W.E.F",
            accessor: "WEF",
            width: "150px",
            style: {
              textAlign: "center",
              width: "10%",
              background: "#0000",
            },
            filterable: true,
            Cell: (cellProps) => {
              return  moment(cellProps.value).format('DD-MM-YYYY');
            },
          },
          {
            Header: "W.E.T",
            accessor: "WET",
            filterable: true,
            Cell: (cellProps) => {
              return moment(cellProps.value).format('DD-MM-YYYY');
            },
          },
          {
            Header: "From Days",
            accessor: "from",
            filterable: true,
            Cell: (cellProps) => {
    
              // console.log('cellProps',cellProps)
    
              return cellProps.value;
            },
          },
        
          {
            Header: "To Days",
            accessor: "to",
            filterable: true,
            Cell: (cellProps) => {
    
              // console.log('cellProps',cellProps)
    
              return cellProps.value;
            },
          },

          {
            Header: "Penalty %",
            accessor: "penalty",
            filterable: true,
            Cell: (cellProps) => {
    
              // console.log('cellProps',cellProps)
    
              return cellProps.value;
            },
          },

    

      
    
        ],
        []
      );

    const handleOrderClicks = () => {
    };


    return (
        <React.Fragment>


            <Card>
                <CardBody>
                    <p className="h4 mb-4">List Penalty</p>

                    <TableContainer
                        columns={columns}
                        data={data}
                        isGlobalFilter={false}
                        isAddOptions={true}
                        handleOrderClicks={handleOrderClicks}
                        customPageSize={10}
                        isPagination={true}
                        filterable={false}
                        iscustomPageSizeOptions={true}
                        tableClass="align-middle table-nowrap table-check"
                        theadClass="table-light"
                        pagination="pagination pagination-rounded justify-content-end mb-2"
                      />


                </CardBody>
            </Card>

        </React.Fragment>
    );
};


export default ListPenalties;

const styles = {

    para: {
        fontSize: 12
    },
    box: {
        fontSize: 16,
        fontWeight: "unset",
        justifyContent: "space-between",
        alignItems: "center",
    },
    boxLabel: {
        fontSize: 13,
        fontWeight: "400",
    },
    boxInput: {
        fontSize: 13,
        width: '70%',
        padding: 8,
        fontWeight: "unset",
    }

}
