import React, { useEffect } from 'react';
import {View, StyleSheet, Text } from '@react-pdf/renderer';
import InvoiceTableHeader from './InvoiceTableHeader'
import InvoiceTableRow from './InvoiceTableRow'
import InvoiceTableBlankSpace from './InvoiceTableBlankSpace'
import InvoiceTableFooter from './InvoiceTableFooter'

const tableRowsCount = 11;
const borderColor = '#061c33'

const styles = StyleSheet.create({
    tableContainer: {
        flexDirection: 'row',
        flexWrap: 'wrap',
        marginTop: 24,
        borderWidth: 1,
        borderColor: '#061c33',
    },
    row: {
      flexDirection: 'row',
      borderBottomColor: '#000',
      borderBottomWidth: 1,
      alignItems: 'center',
      height: 24,
      fontStyle: 'bold',
  },
    container: {
      flexDirection: 'row',
      borderBottomColor: '#061c33',
      backgroundColor: '#061c33',
      borderBottomWidth: 1,
      alignItems: 'center',
      height: 24,
      textAlign: 'center',
      fontStyle: 'bold',
      flexGrow: 1,
  },
  description: {
      width: '100%',
      color: '#fff',
      textAlign: 'center',
      borderRightColor: borderColor,
      borderRightWidth: 1,
  },
  qty: {
      width: '50%',
      color: '#000',
      borderRightColor: borderColor,
      borderRightWidth: 1,
  },
  rate: {
      width: '30%',
      color: '#000',
      borderRightColor: borderColor,
      borderRightWidth: 1,
  },
  amount: {
      width: '15%'
  },
});


const doc_names = {
  "aadhaar":"Aadhaar Card",
  "pan":"PAN Card",
  "passport":"Passport",
  "doctor":"Doctor",
  "nurse":"Nurse",
  "voter_id":"Voter ID",
}



  const ComponentDetail = ({invoice,  requestedData}) => {

    const [components, setComponents] = React.useState([]);

    useEffect(() => {

      console.log('invoice table row  requestedData==>',requestedData);


    }, []);


    return (
    <View style={styles.tableContainer}>
         <View style={styles.container}>
        <Text style={styles.description}>Aadhaar</Text>

        {/* <Text style={styles.amount}>Amount</Text> */}
    </View>

    <View style={styles.row} >
            <Text style={styles.qty}>Aadhaar number</Text>
            <Text style={styles.qty}></Text>
      
        </View>
    </View>
    )
  };
  
  export default ComponentDetail