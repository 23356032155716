import PropTypes from 'prop-types';
import React from "react";
import { Routes, Route } from "react-router-dom";
// Import Routes all
import { authProtectedRoutes, publicRoutes } from "./routes";

// Import all middleware
import Authmiddleware from "./routes/route";



// Import scss
import "./assets/scss/theme.scss";
import AuthLayout from './Layout/AuthLayout';
import NonAuthLayout from './Layout/NonAuthLayout';


const App = () => {

 

  return (
    <React.Fragment>
      <Routes>

      {publicRoutes.map((route, idx) => (
          <Route
            path={route.path}
            element={
              <NonAuthLayout>
                {route.component}
              </NonAuthLayout>
            }
            key={idx}
            exact={true}
          />
        ))}

        {authProtectedRoutes.map((route, idx) => (
          <Route
            path={route.path}
            element={
             <Authmiddleware route={route.path} >
                <AuthLayout>{route.component}</AuthLayout>
                </Authmiddleware>
            }
            key={idx}
            exact={true}
          />
        ))}
      </Routes>
    </React.Fragment>
  );
};

App.propTypes = {
  layout: PropTypes.any
};

export default App;