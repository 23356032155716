import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Link, useNavigate } from "react-router-dom";
import {
    Card, CardBody, Col, Container, Row, Table,
    Label,
    Input,
    Form,
    FormGroup,

    Nav,
    NavItem,
    NavLink,
    TabContent,
    TabPane, CardTitle


} from "reactstrap";
import { isEmpty, map, set } from "lodash";
import Dropzone from "react-dropzone";
import * as XLSX from 'xlsx';
import Papa from 'papaparse';
import axios from 'axios';
import Loader from "../../components/Loader";

import classnames from "classnames";



const User = (props) => {
    //meta title
    document.title =
        "TheRollNumber";


    const [data, setData] = React.useState([]);

    const [loading, setLoading] = React.useState(false);

    const [disabled, setDisabled] = React.useState(false);

    const [locations, setLocation] = React.useState([

]);


    const Navigate = useNavigate();

    const [user, setUser] = React.useState({});



    const [state, setState] = React.useState({

        client_info: "",
        first_name: "",
        last_name: "",
        client_location:[],
  
        abbreviation: "",
        contact_person: "",
        address: "",
        country: "",
        state: "",
        city: "",
        pincode: "",
        role: "admin",
        email: "",
        landline: "",
        mobile: "",
        client_status: "active",

    });

    const handleInputChange = (e) => {
        const { id, value } = e.target;
        setState((prevState) => ({
            ...prevState,
            [id]: value,
        }));
    };

    const getAllLocation = async () => {

  
  
        const config = {
          headers: {
            'Content-Type': 'application/json'
          }
        };
    
    
      
       await axios.get(`${process.env.REACT_APP_BASE_URL}/service/companyLocation`, {}, config)
        .then(response => {
          console.log('all location response',response.data);
    
            let data = response.data;

            let arr = []
    
            data.map((location, index) => {

                arr.push({id: location.LocationID, name: location.location_name, selected: false});
            }
            )

            setLocation(arr);
          
      
        })
        .catch(error => {
      
          console.log('error==>',error);
        });
  
      };

const add_user = async () => {

      const company_name = JSON.parse(localStorage.getItem('authUser')).user.company_name;


    const config = {
      headers: {
        'Content-Type': 'application/json'
      }
    };

const body = {
    first_name: state.first_name,
    last_name: state.last_name,
    email: state.email,
    company_name: company_name,
    phone_number: state.contact_person,
    role: state.role,
    account_status: state.client_status,
    company_location: JSON.stringify(state.client_location)

}

console.log('body==>', body);
  
   await axios.post(`${process.env.REACT_APP_BASE_URL}/employer/addUser`, body, config)
    .then(response => {
      console.log('add user response',response);

      props.reFetch()

  
    })
    .catch(error => {
  
      console.log('add user error==>',error);
    });
  
  }

  useEffect(() => {

    getAllLocation();

}, []);


    useEffect(() => {


        if (props.data && props.isEdit) {

            const user =  JSON.parse(localStorage.getItem('authUser')).user;

            let data = { ...props.data };
            console.log('data==>', data);
            // setState(data);

            setState({...state, 
            
                first_name: data.first_name,
                last_name: data.last_name,
                email: data.email,
                contact_person: data.phone_number,
            });

            if(user.email === data.email) {

              
                setDisabled(true);

                

            }
        }

    }, [props.data]);


    const update_user = () => {

        const config = {
          headers: {
            'Content-Type': 'application/json'
          }
        };
      
        const body = {
            first_name: state.first_name,
            last_name: state.last_name,
            email: state.email,
            phone_number: state.contact_person,
            role: state.role,
            account_status: state.client_status,
            company_location: JSON.stringify(state.client_location)
        
        }
        
        console.log('body==>', body);
          
           axios.put(`${process.env.REACT_APP_BASE_URL}/employer/updateUser`, body, config)
            .then(response => {
              console.log('update user response',response);
        
              props.reFetch()

          
            })
            .catch(error => {
          
              console.log('update user error==>',error);
            });

    }




    return (
        <React.Fragment>


            <Card>
                <CardBody>
                {      
props.isEdit  ? <p className="h4 mb-4">Edit User</p>
: <p className="h4 mb-4">Add User</p>
}
          

                    <Row>

                        <Col lg="6">
                            <div className="mt-3 d-flex" style={styles.box}>
                                <Label for="basicpill-firstname-input1" style={styles.boxLabel} className="control-label me-3" >
                                    First Name
                                    <span className="text-danger mx-1">*</span>
                                </Label>
                                <Input
                                    type="text"
                                    className="form-control me-3"
                                    value={state.first_name}
                                    // disabled={selectedValue === 'registration_no' ? false : true}
                                   onChange={handleInputChange}
                                    style={styles.boxInput}
                                    suppressHydrationWarning
                                    id="first_name"
                                    placeholder="First Name"
                                />


                            </div>
                        </Col>
                        <Col lg="6">
                            <div className="mt-3 d-flex" style={styles.box} >
                                <Label for="basicpill-firstname-input1" style={styles.boxLabel} className="control-label me-3" >
                                    Last Name
                                    {/* <span className="text-danger mx-1">*</span> */}

                                </Label>
                                <Input
                                    type="text"
                                    className="form-control me-3"
                                    value={state.last_name}
                                    style={styles.boxInput}

                                    // disabled={selectedValue === 'registration_no' ? false : true}
                                    onChange={handleInputChange}
                                    suppressHydrationWarning
                                    id="last_name"
                                    placeholder="Last Name"
                                />


                            </div>
                        </Col>

                        <Col lg="6">
                            <div className="mt-3 d-flex" style={styles.box}>
                                <Label for="basicpill-firstname-input1" style={styles.boxLabel} className="control-label me-3" >
                                    Email
                                    {/* <span className="text-danger mx-1">*</span> */}
                                </Label>
                                <Input
                                    type="text"
                                    className="form-control me-3"
                                    disabled={props.isEdit}
                                    value={state.email}
                                    // disabled={selectedValue === 'registration_no' ? false : true}
                                    onChange={handleInputChange}
                                    style={styles.boxInput}
                                    suppressHydrationWarning
                                    id="email"
                                    placeholder="email"
                                />


                            </div>
                        </Col>
                        <Col lg="6">
                            <div className="mt-3 d-flex" style={styles.box} >
                                <Label for="basicpill-firstname-input1" style={styles.boxLabel} className="control-label me-3" >
                                    Contact
                                    {/* <span className="text-danger mx-1">*</span> */}

                                </Label>
                                <Input
                                    type="text"
                                    className="form-control me-3"
                                    value={state.contact_person}
                                    style={styles.boxInput}

                                    // disabled={selectedValue === 'registration_no' ? false : true}
                                    onChange={handleInputChange}
                                    suppressHydrationWarning
                                    id="contact_person"
                                    placeholder="Contact"
                                />


                            </div>
                        </Col>

                        <Col lg="6">
                            <div className="mt-3 d-flex" style={{justifyContent:'space-between'}}>
                                <Label for="basicpill-firstname-input1" style={styles.boxLabel} className="control-label me-3" >
                                    Location
                                    <span className="text-danger mx-1">*</span>
                                </Label>
<div className="me-3" style={{
    height: 150,
    width:'70%',
    borderRadius:4,
    border: '1px solid #ddd',
    padding: 10,
    overflowY: 'auto',
    
}}>

    {
        locations.map((location, index) => {
            return (
                <div className="form-check font-size-16" key={index}>
                    <input disabled={state.role == 'admin'?true:false} className="form-check-input" checked={location.selected} name={location.name} onClick={(e)=>{

                        let value = e.target.checked;
                        let locationData = locations;
                        locationData[index].selected = value;
                        setLocation(locationData);
                        let selectedLocation = [];
                        locationData.map((location, index) => {
                            if(location.selected) {
                                selectedLocation.push(location.name);
                            }
                        })
                        setState({...state, client_location: selectedLocation});
                    

                    }} type="checkbox" id={index} />
                    <label className="form-check-label" htmlFor={index}>{location.name}</label>
                </div>
            )
        })
    }

</div>
                          
               
                            </div>
                        </Col>
                        <Col lg="6">
                            <div className="mt-3 d-flex" style={styles.box} >
                                <Label for="basicpill-firstname-input1" style={styles.boxLabel} className="control-label me-3" >
                                    Role
                                    {/* <span className="text-danger mx-1">*</span> */}

                
                               
                                </Label>
                           
                                <div className="me-3" style={{width:'70%'}}>
                                    <select className="form-select"
                                     disabled={disabled}
                                     style={{ height:35}} value={state.role} onChange={(e)=> {


                                        let value = e.target.value;
                                        setState({...state, role: value});

}}>
<option value="admin">Admin</option>
<option value="location_user">Location user</option>

</select>
                                    </div>

                            </div>
                        </Col>

                        <Col lg="6">
                        <div className="d-flex mt-5" style={{justifyContent:'space-between', width:'60%'}}>
                        <Label for="basicpill-firstname-input1" style={styles.boxLabel} className="control-label me-3" >
                                    Status
                                    {/* <span className="text-danger mx-1">*</span> */}
                                </Label>

                                <div className="form-check me-3 d-flex">
<div className="form-check me-3">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="exampleRadios1"
                            disabled={disabled}
                            id="exampleRadios7"
                            value="active"
                            defaultChecked
                            onChange={(e)=>{
                              let value = e.target.value;
                              
                              setState({...state, client_status: value});
                            }
                          }
                            
                          />
                          <label
                            className="form-check-label"
                            htmlFor="exampleRadios7"
                          >
                            Active
                          </label>
                        </div>

                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="exampleRadios1"
                            id="exampleRadios2"
                            disabled={disabled}
                            value="inactive"
                            onChange={(e)=>{
                                let value = e.target.value;
                                
                                setState({...state, client_status: value});
                            }
                          }
                          />
                          <label
                            className="form-check-label"
                            htmlFor="exampleRadios2"
                          >
                            Inactive
                          </label>

              

                        
                        </div>
                        </div>
                   
                     
</div>
                            </Col>
                    </Row>

                    <div className="mt-5 text-center"  >
                        {
                            props.isEdit ? <button className="btn btn-primary" style={{ width: 150, }} onClick={() => { update_user() }} >Update</button>
                                : <button className="btn btn-primary" style={{ width: 150, }} onClick={() => { add_user() }} >Add</button>
                        }
                        </div>

                </CardBody>
            </Card>

        </React.Fragment>
    );
};


export default User;

const styles = {

    para: {
        fontSize: 12
    },
    box: {
        fontSize: 16,
        fontWeight: "unset",
        justifyContent: "space-between",
        alignItems: "center",
    },
    boxLabel: {
        fontSize: 13,
        fontWeight: "400",
    },
    boxInput: {
        fontSize: 13,
        width: '70%',
        padding: 8,
        fontWeight: "unset",
    }

}
