import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Link, useNavigate } from "react-router-dom";
import {
    Card, CardBody, Col, Container, Row, Table,
    Label,
    Input,
    Form,
    FormGroup,

    Nav,
    NavItem,
    NavLink,
    TabContent,
    TabPane, CardTitle


} from "reactstrap";
import { isEmpty, map, set } from "lodash";
import Dropzone from "react-dropzone";
import * as XLSX from 'xlsx';
import Papa from 'papaparse';
import axios from 'axios';
import Loader from "../../components/Loader";

import classnames from "classnames";



const ListComponents = (props) => {
    //meta title
    document.title =
        "TheRollNumber";


    const [data, setData] = React.useState([]);

    const [loading, setLoading] = React.useState(false);


    const Navigate = useNavigate();

    const [components, setComponent] = React.useState([
        
    ]);

    useEffect(() => {

        search_wallet();
    }, []);


    const search_wallet = async (value) => {

        const config = {
          headers: {
            'Content-Type': 'application/json'
          }
        };

        axios.get(`${process.env.REACT_APP_BASE_URL}/service/components`, {}, config)
        .then(response => {
          console.log('components data',response.data);
    
          let data = response.data;
    
          console.log('data==>',data);

          const filteredData = data.filter((item) => {
            return item.status === 1;
            }
            );
   
            setComponent(filteredData);
    
        })
        .catch(error => {
      
          
      
          console.log('error==>',error);
        });
    
      }

    return (
        <React.Fragment>


            <Card>
                <CardBody>
                    <p className="h4 mb-3">List Components</p>

                    <p>Following are a list of background verification components assigned to your account. In order to Add/Remove please contact your account manager.</p>

                    <Table className="table mb-0">
        <thead className="table-dark">
          <tr>
            <th style={{width:'100%'}}>Components Name</th>
    
          </tr>
      
        </thead>
        <tbody>
 
          {/* <tr>
            <td className="table-light">Aadhar Card Number</td>
            <td className="table-light">{item?.aadhaar_number}</td>
      
          </tr> */}

          {
            map(components, (item, index) => {
                return (
                    <tr>
                        <td className="table-light">{item.name}</td>
           
                    </tr>
                )
            })
          }
      
      
      
        </tbody>
      </Table>

                </CardBody>
            </Card>

        </React.Fragment>
    );
};


export default ListComponents;

const styles = {

    para: {
        fontSize: 12
    },
    box: {
        fontSize: 16,
        fontWeight: "unset",
        justifyContent: "space-between",
        alignItems: "center",
    },
    boxLabel: {
        fontSize: 13,
        fontWeight: "400",
    },
    boxInput: {
        fontSize: 13,
        width: '70%',
        padding: 8,
        fontWeight: "unset",
    }

}
